import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function EventsSideBar(props) {
  return (<SideBar {...props} 
    section="events"
    searchTitle="Search Events"
    createNewTitle="New Event"
    filterList={[
      { name:'keywords', value: 'Enter Keywords' }, 
    ]}
  />);
}

export default EventsSideBar;