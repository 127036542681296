import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { formatPriceAndCurrency } from '../../../shared/util/number';
import { Link } from 'react-router-dom';

import EngageBox from '../../../shared/uielements/Engage/EngageBox';
import EngageButtons from '../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import FavoritesButton from '../../../shared/uielements/Engage/EngageButtons/FavoritesButton';
import LoveButton from '../../../shared/uielements/Engage/EngageButtons/LoveButton';
import ViewsButton from '../../../shared/uielements/Engage/EngageButtons/ViewsButton';

import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';
import { titleCase } from '../../../shared/util/string';

import CarIcon from '../Autos/Assets/auto-exterior-color.svg';
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { toHttps } from "../../../shared/util/toHttps";

import DefaultAutoImage from "../../../assets/default/default-auto-listing-preview.jpg";


class ListingPreview extends Component {
    state = {
        afterDiscountPrice: '',
        oldAfterDiscountPrice: ''
    }

    componentDidMount() {
        let c = this.props.currency || 'USD';
        let p = this.props.price;
        let d = this.props.discountId;
        if (!!d) {
            let theDiscount = d.couponValue;
            if (d.couponCurrency) {
                if (d.couponCurrency === '%') {
                    p = Math.round((p - p * theDiscount / 100) * 100) / 100;
                } else {
                    theDiscount = theDiscount / 100;// Math.floor(theDiscount / 100) + (theDiscount % 100) / 100;
                    p = Math.round((p - theDiscount) * 100) / 100;
                }
            }
            if (p > 0) {
                this.setState({ afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            }
        }
    }
    componentDidUpdate() {
        let c = this.props.currency || 'USD';
        let p = this.props.price;
        let d = this.props.discountId;
        if (!!d) {
            let theDiscount = Number(d.couponValue);
            if (d.couponCurrency) {
                if (d.couponCurrency === '%') {
                    p = Math.round((p - p * theDiscount / 100) * 100) / 100;
                } else {
                    theDiscount = theDiscount / 100;// Math.floor(theDiscount / 100) + (theDiscount % 100) / 100;
                    p = Math.round((p - theDiscount) * 100) / 100;
                }
            }
            if (p > 0 && (this.state.oldAfterDiscountPrice !== this.state.afterDiscountPrice || !this.state.afterDiscountPrice)) {
                this.setState({ oldAfterDiscountPrice: this.state.afterDiscountPrice, afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            }
        }
    }

    // getDuration(b, e) {
    //     let d = moment(e).diff(moment(b), 'days') + 1;
    //     if (d === 1) return '1 day';
    //     else return d + ' days';
    // }

    // getPropertySpecs(specs) {
    //     let result = '';
    //     if (Number(specs.bedCount) > 0) {
    //         result = result + specs.bedCount + ' Bed';
    //         if (Number(specs.bedCount) > 1) result = result + 's';
    //     }
    //     if (Number(specs.bathCount) > 0) {
    //         if (result) result = result + ' | ';
    //         result = result + specs.bathCount + ' Bath';
    //         if (Number(specs.bathCount) > 1) result = result + 's';
    //     }
    //     if (specs.floorSize) {
    //         if (result) result = result + ' | ';
    //         result = result + specs.floorSize;
    //         if (Number(specs.floorSize) > 0) result = result + ' sqft';
    //     }
    //     return result;
    // }

    onClickItem = (e) => {
        let urlSlug = '';
        if (!this.props.slug) urlSlug = `/autos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.id}`;
        else urlSlug = `/autos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;

        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push(urlSlug || '/store');
        this.props.pageShouldRefresh(urlSlug);
    }

    // addDefaultSrc = (ev) => {
    //     imageSrc = DefaultImage;
    // };

    render() {
        let thisCouponValue = (!this.props.discountId) ? 0 : (this.props.discountId.couponCurrency === '%') ? Number(this.props.discountId.couponValue) : Math.floor(Number(this.props.discountId.couponValue) / 100) + (Number(this.props.discountId.couponValue) % 100) / 100;
        let saleButtonCta = (!!this.props.callForPricing || !this.props.addDiscount) ? (this.props.rentOrSell && this.props.rentOrSell === 'rent') ? (this.props.category === 'commercial') ? getTranslatedPhrase(this.props.userLanguage, 'Lease Now') :
            getTranslatedPhrase(this.props.userLanguage, 'Rent Now') : getTranslatedPhrase(this.props.userLanguage, 'Shop Now') : ((this.props.addDiscount === 'coupon') ?
                getTranslatedPhrase(this.props.userLanguage, 'Get') + ' ' +
                formatPriceAndCurrency(thisCouponValue, this.props.discountId.couponCurrency, ',') + ' ' +
                getTranslatedPhrase(this.props.userLanguage, 'Coupon') : getTranslatedPhrase(this.props.userLanguage, 'Get Deal'));
        if (this.props.section === 'services' && saleButtonCta === getTranslatedPhrase(this.props.userLanguage, 'Shop Now'))
            saleButtonCta = getTranslatedPhrase(this.props.userLanguage, 'Get Service');
        let currency = this.props.currency || 'USD';

        let buttonClassname = 'button button-clear button-small';
        if (!this.props.callForPricing && !!this.props.addDiscount)
            buttonClassname = 'button button-sale button-small';

        let { onClickOnFavorite = () => null } = this.props;

        let linkSlug = '';
        if (!this.props.slug) linkSlug = `/autos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.id}`;
        else linkSlug = `/autos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;

        let imageSrc = (!this.props.photo_url) ? this.props.featuredImage : (this.props.photo_url.split('|') || ['https://websitediag290.blob.core.windows.net/images/uploads/default/default-product-image.jpg'])[0].trim();

        imageSrc = toHttps(imageSrc);

        if (!imageSrc) {
            imageSrc = DefaultAutoImage;
        }



        let fspCost;

        if (this.props.price > 40000) {
            fspCost = Math.floor((this.props.price * 1 - (this.props.price * 3 / 100)))
        } else {
            fspCost = Math.floor((this.props.price * 1 - (this.props.price * 5 / 100)))
        }


        let carDaysOnMarket;

        if (this.props.dealer_id) {
            carDaysOnMarket = this.props.dom_active;
        }

        if (this.props.creationDate) {
            carDaysOnMarket = moment().diff(moment(this.props.creationDate), 'days');
        }

        if (carDaysOnMarket === 0 || carDaysOnMarket === '') {
            carDaysOnMarket = '0'
        }

        return (

            <div className="category-list_item store-item-preview"> 

                {!!fspCost &&
                    <ReactTooltip place="bottom" type="info" effect="solid" backgroundColor="#555555" />}

                    <div className="cat-head-bar">
                        <p className="cat-cond">{getTranslatedPhrase(this.props.userLanguage, this.props.productCondition || this.props.inventory_type)}</p>

                        <EngageBox
                            websiteSection={(!this.props.id) ? 'autos' : 'cars'}
                            itemId={this.props.itemId}
                            favoriteItemId={this.props.favoriteItemId}
                            authorId={this.props.authorId}
                            onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>
               
                    <div className="cat-title">
                        <p className="slp-title">{this.props.title || this.props.heading}</p>
                        <p className="slp-brand" style={{ marginTop: '.4rem' }}><span style={{ display: 'inline-block', marginRight: '.2rem', marginLeft: '.3rem' }}><i className="fal fa-map-pin" /></span> <strong>{this.props.city}, {this.props.state}</strong></p>
                    </div>

                <Link className="store-listing-preview-img-bkg" to={linkSlug} onClick={this.onClickItem} style={{ backgroundImage: `url(${imageSrc})` }} ></Link>

                <div className="slp">

                    <div className="slp-pricing-header">
                        <div className="slp-pricing-header-price">
                            {(!this.props.price || (!!this.props.callForPricing && this.props.callForPricing === 'call-for-pricing'))
                                ? (<p className="store-listing-preview_price store-listing-preview_price-call">{getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}</p>)
                                : (!this.props.addDiscount)
                                    ? (<p className="store-listing-preview_price">{formatPriceAndCurrency(this.props.price, currency, ',', (this.props.rentOrSell && this.props.rentOrSell === 'rent') ? getTranslatedPhrase(this.props.userLanguage, 'month') : '')}</p>)
                                    : (<p className="store-listing-preview_price">{!this.state.afterDiscountPrice ? getTranslatedPhrase(this.props.userLanguage, 'FREE') : this.state.afterDiscountPrice}{' '}
                                        <span className="deals-was-price">
                                            {formatPriceAndCurrency(this.props.price, currency, ',', (this.props.rentOrSell && this.props.rentOrSell === 'rent') ? getTranslatedPhrase(this.props.userLanguage, 'month') : '')}
                                        </span>
                                    </p>
                                    )}

                            {(!!this.props.price && this.props.price !== "Call for Pricing" && !this.props.addDiscount && this.props.seller_type === "dealer") &&
                                <div className="fsp-cost">VSP:
                                    <span>{formatPriceAndCurrency(fspCost, currency, ',')}</span>
                                    <i data-multiline="true"
                                        data-tip="The Verve Suggested Price (VSP),<br> doesn't not reflect the value of the car,<br> or the deal offered by the dealer.<br> It is based on our assumption that this<br> is a good negotiation starting point."
                                        className="fal fa-info-circle" />
                                </div>
                            }
                        </div>


                        {/* <div className="slp-divider" /> */}

                        {/* <div className="slp-pricing-header-option">
                            <img src={CarIcon} alt="Car Condition" />
                            <p>
                                {getTranslatedPhrase(this.props.userLanguage, 'Condition')}:<br />
                                
                            </p>
                        </div> */}

                        {/* {!!carDaysOnMarket &&
                            <>
                                <div className="slp-divider" />

                                <div className="slp-pricing-header-option">
                                    <i className="fal fa-calendar-alt" />
                                    <p>
                                        {getTranslatedPhrase(this.props.userLanguage, 'On Market For')}:<br />
                                        <strong>{carDaysOnMarket} {getTranslatedPhrase(this.props.userLanguage, 'Days')} </strong>
                                    </p>
                                </div>
                            </>
                        } */}
                    </div>








                    
                    <p className="slp-brand"><span>{getTranslatedPhrase(this.props.userLanguage, 'Sold By')}</span> <strong>{titleCase(this.props.authorName)}</strong></p>
                    










                    <div className="slp-buttons">
                        <Link to={linkSlug} onClick={this.onClickItem} className={buttonClassname}>{saleButtonCta}</Link>

                        {/* <ListingPreviewAdminPanel
                            itemType={this.props.itemType}
                            itemSlug={this.props.slug}
                            itemId={(!this.props.id) ? this.props.itemId : this.props.favoriteItemId}
                            userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                        /> */}

                    </div>


                  

                 


                </div>
            </div>);
    }
}

const mapStateToListingPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToListingPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToListingPreviewProps, mapDispatchToListingPreviewProps)(withRouter(ListingPreview));