import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

class StepFourThankYou extends Component {
    render () {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="step-four_thank-you">
                <div className="modal_breadcrumb">{getTranslatedPhrase(this.props.userLanguage,'Ad Type')} &nbsp;&gt;&nbsp; 
                {getTranslatedPhrase(this.props.userLanguage,'Placement & Date')}  &nbsp;&gt;&nbsp; 
                {getTranslatedPhrase(this.props.userLanguage,'Payment')}</div>

                <div className="modal_body">
                    {(!this.props.errorMessage)?(<h4>{getTranslatedPhrase(this.props.userLanguage,'Thank You')}</h4>):(<h4>{getTranslatedPhrase(this.props.userLanguage,'Error')}!</h4>)}

                    {(!this.props.errorMessage && !this.props.confirmationNumber)?<LoadingSvg active={true} />:null}

                    {(!this.props.errorMessage && this.props.confirmationNumber)?
                    (<p>{getTranslatedPhrase(this.props.userLanguage,'Your confirmation no. is')}:<br /> #<span>{this.props.confirmationNumber}</span></p>):
                    (<FormFieldError>{this.props.errorMessage}</FormFieldError>)}
                    

                    <button className="button button-regular" onClick={this.props.onCloseModal}>{getTranslatedPhrase(this.props.userLanguage,'Close')}</button><br />

                    <Link onClick={this.props.onCloseModal} to="/purchase-history" className="advSphLink">{getTranslatedPhrase(this.props.userLanguage,'See Purchase History')}</Link>
                </div>
            </div>
        );
    }
}

const mapStateToStepFourThankYouProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToStepFourThankYouProps, null)(StepFourThankYou);