import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import StoreFeaturedPreview from './StoreFeaturedPreview';

export default function StoreFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={StoreFeaturedPreview}
        />
    );
}