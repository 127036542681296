import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import VideosFeaturedPreview from './VideosFeaturedPreview';

export default function VideosFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={VideosFeaturedPreview}
        />
    );
}