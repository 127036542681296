import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../shared/util/vars';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import ProfileAltImage from '../../../../assets/profile-alt.jpg';

import ListingMediaModal from '../../../../shared/components/Modals/Media/ListingMediaModal';
import EngageButtons from '../../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import Views from '../../../../shared/uielements/Engage/EngageButtons/ViewsButton';
import Rate from '../../../../shared/uielements/Engage/EngageButtons/RateButton';
import Share from '../../../../shared/uielements/Engage/EngageButtons/ShareButton';
import FollowButton from '../../../../shared/components/SideBar/Followone/Followone';
import ShareItem from '../../../../shared/uielements/Engage/ShareItem/ShareItem';

const ProfileHeader = (props) => {

    const [photos, setPhotos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(-1);
    const [clickedToShare, setClickedToShare] = useState(false);
    const [requestIsSent, setRequestIsSent] = useState(false);
    const [unfollowStatus, setUnfollowStatus] = useState(true);
    const [noPhotosInModal, setNoPhotosInModal] = useState({});

    const propsRequestIsSent = props.requestIsSent;

    const slug = props.slug;

    useEffect(() => {
        setTimeout(() => {
            getProfilePhotos();
        }, 500);
    }, [slug])

    useEffect(() => {
        if (propsRequestIsSent && propsRequestIsSent !== requestIsSent) {
            setRequestIsSent(propsRequestIsSent)
        }
    }, [propsRequestIsSent])

    const sendFollowRequest = async () => {
        let _data = new FormData();
        _data.append('toUserId', props.userId);

        let options = {
            url: hostNameAndPort + '/api/user/follow',
            method: 'post',
            data: _data
        };

        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                props.showFollowRequestAlert(getTranslatedPhrase(props.userLanguage, 'Follow request has been sent to') + ' ' + props.userName);
                setRequestIsSent(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const sendUnfollowRequest = async () => {
        let _data = new FormData();
        _data.append('toUserId', props.userId);
        let options = {
            url: hostNameAndPort + '/api/user/unfollow',
            method: 'post',
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                props.showUnfollowAlert(getTranslatedPhrase(props.userLanguage, 'You just stopped following') + ' ' + props.userName);
                setUnfollowStatus(true);
            }
        } catch (err) {
            // Network error
        }
    }


    const getProfilePhotos = async () => {
        try {
            let b = 'user-profile-photos';

            setPhotos([]);

            b = props.slug + '-profile-photos';

            let url = `${hostNameAndPort}/api/photos/${b}`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                let photos = data.item.extraPhotos.map(v => v.photoName);
                if (photos[0] !== data.item.featuredImage) {
                    setPhotos([data.item.featuredImage, ...photos])
                } else {
                    setPhotos(photos)
                }
                setNoPhotosInModal({})
            } else {
                setNoPhotosInModal({ cursor: 'initial' })
            }
        } catch (err) {
            if (process.env.NODE_ENV === 'development') console.log(err)
        }
    }


    const onClickToShare = () => {

        if (!props.userIsAuthenticated) {
            props.showLoginModal();
            return;
        }

        setClickedToShare(!clickedToShare);
    }

    const closeShareModal = () => {
        setClickedToShare(false);
    }

    const onCloseMediaModal = () => {
        setOpenModal(false)
    }

    const goBack = () => {
        let c = currentPhoto;
        if (!c) {
            c = photos.length - 1;
        } else {
            c = c - 1;
        }

        setCurrentPhoto(c);
    }

    const goForward = () => {
        let c = currentPhoto + 1;
        if (c >= photos.length) {
            c = 0;
        }

        setCurrentPhoto(c)
    }

    const onShareCopyLink = () => {
        ShareItem.ShareCopyLink({
            shareLink: props.shareLink
        });

        setClickedToShare(false);
    }

    const handleProfilePhotos = () => {
        if (photos.length) {
            setOpenModal(true);
            setCurrentPhoto(0);
        }
    }

    const addDefaultSingleSrc = (ev) => {
        ev.target.src = 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg';
    }

    return (
        <div className="profile_header profile_header_personal">

            {!props.isProfileOwner ? null : (<Link to="/profile-update" className="update-profile-image-icon"><i className="fal fa-camera-retro" /></Link>)}

            <div className="profile_header_cover-image" style={{ backgroundImage: `url(${ props.coverImage === 'https://websitediag290.blob.core.windows.net/images/profile-default-cover-new.jpg' ? ProfileAltImage : props.coverImage})` }}></div>

            <div className="profile_header_user-image"> 
                <h1>{props.userName}{'   '}

                    <div className="profile-social-container">
                        <span className="profile-social-container-buttons">
                            {props.stats &&
                                <EngageButtons>
                                    {(props.accountType === 'personal') ? null : (
                                        <Rate
                                            noStars={props.stats.averageRating}
                                            noReviews={props.stats.noReviews}
                                        />)}
                                    <Views noViews={props.stats.noViews} />
                                    <Share
                                        active={clickedToShare}
                                        shareLink={props.shareLink}
                                        shareImage={props.profileImage}
                                        onClick={onClickToShare}
                                        onClickShare={closeShareModal}
                                        onShareCopyLink={onShareCopyLink}
                                    />
                                </EngageButtons>
                            }
                        </span>

                        <div className="profile-follow-button">
                            <FollowButton
                                show={!props.isFollower && !!props.userIsAuthenticated && !props.isProfileOwner}
                                requestIsSent={requestIsSent} onClick={sendFollowRequest} />

                            <FollowButton
                                show={!!props.isFollower && !!props.userIsAuthenticated && !props.isProfileOwner}
                                unFollowButton={unfollowStatus}
                                onClick={sendUnfollowRequest} />
                        </div>
                    </div>
                </h1>

                <img src={props.profileImage} onError={addDefaultSingleSrc} alt={props.userName} onClick={handleProfilePhotos} style={noPhotosInModal} />
            </div>


            {(currentPhoto === -1) ? null : (
                <ListingMediaModal
                    active={openModal}
                    onClickCloseHandler={onCloseMediaModal}
                    photoElement={<img src={photos[currentPhoto]} alt={photos[currentPhoto]} />}
                    showArrows={(photos.length > 1)}
                    goBack={goBack}
                    goForward={goForward}
                />)}
        </div>
    )
}

const mapStateToPersonalProfileHeaderProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser,
    };
};

const mapDispatchToPersonalProfileHeaderProps = (
    dispatch
) => {
    return {
        showFollowRequestAlert: (u) => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        showUnfollowAlert: (u) => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        showLoginModal: () => {
            dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname });
        },
    };
};


export default connect(mapStateToPersonalProfileHeaderProps, mapDispatchToPersonalProfileHeaderProps)(ProfileHeader);