import React, { Component } from 'react';
import Backdrop from '../uielements/Backdrop/Backdrop';
import Box from './box';
import { connect } from 'react-redux';

import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import { getTranslatedPhrase } from './lang';
import EXIF from './exif-js';


class CropImageModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            image: null,
            cropButtonClassName: 'disabled',
            dataUrl: '',
            active: true,
            pixelCrop: {
                width: 0,
                height: 0
            },
            desiredWidth: 0,
            desiredHeight: 1,
            crop: {
                x: 0,
                y: 0,
                // aspect: 16 / 9,
            },
            //maxHeight: 80,
            imageAlreadyProvided: false,
            imageStyle: {
                transform: '',
                transformSafari: ''
            }
        };

        this.hiddenFileInput = React.createRef();
    }

    onImageLoaded = (image) => {
        let w = this.props.desiredWidth, h = this.props.desiredHeight;
        if (!h) {
            h = 1;
        }
        this.setState({
            crop: makeAspectCrop({
                x: 0,
                y: 0,
                aspect: w / h,
                width: 50,
            }, image.naturalWidth / image.naturalHeight),
            disabled: false,
            image,
        });
    }

    onCropComplete = (crop, pixelCrop) => {
        //console.log('onCropComplete, pixelCrop:', pixelCrop);
        if (pixelCrop.width >= this.props.desiredWidth && pixelCrop.height >= this.props.desiredHeight) {
            this.setState({ cropButtonClassName: 'regular' });
        } else {
            this.setState({ cropButtonClassName: 'disabled' });
        }
        this.setState({ pixelCrop });
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    }

    toBlob2 = (canvas, callback, type, quality) => {
        setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
        });
    }


    getCroppedImg = () => {
        return new Promise((resolve, reject) => {
            let { image, pixelCrop, imageStyle } = this.state;

            const w = image.naturalWidth;
            const h = image.naturalHeight;
            let cw = pixelCrop.width, ch = pixelCrop.height;

            const canvas = document.createElement('canvas');
            canvas.width = cw;
            canvas.height = ch;
            const ctx = canvas.getContext('2d');

            let angle, tx, ty,
                sx = pixelCrop.x,
                sy = pixelCrop.y;

            let a = imageStyle.transform;
            let isSafari = /Version\/[\d.]+.*Safari|AppleWebKit/.test(navigator.userAgent);
            let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isSafari && iOS) a = imageStyle.transformSafari;

            let tallImage = h > w;
            let tmp;
            switch (a) {
                case 'rotate(90deg)':
                    angle = 90; tx = pixelCrop.width; ty = 0;
                    tmp = sy;
                    if (tallImage) {
                        sy = h - cw - Math.round((h - w) / 2);
                    } else {
                        sy = h - cw - sx + Math.round((w - h) / 2);
                    }
                    sx = tmp + Math.round((w - h) / 2);
                    tmp = cw;
                    cw = ch;
                    ch = tmp;
                    break;
                case 'rotate(180deg)':
                    angle = 180; tx = pixelCrop.width; ty = pixelCrop.height;
                    sx = w - sx - pixelCrop.width;
                    sy = h - sy - pixelCrop.height;
                    break;
                case 'rotate(270deg)':
                    angle = 270; tx = 0; ty = pixelCrop.height;
                    tmp = sy;
                    if (tallImage) {
                        sy = sx + Math.round((h - w) / 2);
                    } else {
                        sy = sx - Math.round((w - h) / 2);
                    }
                    sx = w - ch - tmp - Math.round((w - h) / 2);
                    tmp = cw;
                    cw = ch;
                    ch = tmp;
                    break;
                default:
                    angle = 0;
                    break;
            }
            ctx.translate(tx, ty);
            ctx.rotate(angle * Math.PI / 180);

            // console.log(`${sx}x${sy}, ${pixelCrop.width}x${pixelCrop.height}`);
            ctx.drawImage(
                image,
                sx,
                sy,
                cw,
                ch,
                0,
                0,
                cw,
                ch
            );


            if (typeof canvas.toBlob == 'undefined') {
                this.toBlob2(canvas, function (blob) {
                    blob.name = 'cropped-photo.jpg';
                    resolve(blob);
                }, 'image/jpeg', 0.95);
            } else {
                canvas.toBlob(function (blob) {
                    blob.name = 'cropped-photo.jpg';
                    resolve(blob);
                }, 'image/jpeg', 0.95);
            }
        });
    }

    onChangeImageHandler = (e) => {
        let imageType = /^image\//;
        const file = e.target.files.item(0);

        if (!file || !imageType.test(file.type)) {
            return;
        }

        const reader = new FileReader();

        reader.onload = (e2) => {
            try {
                let img1 = e2.target.result;
                let _reactComp = this;
                EXIF.getData({ src: img1 }, function () {
                    let orientation = this.exifdata.Orientation || 0;
                    // console.log(orientation)
                    let angle = 0;
                    let angleSafari = 0;
                    // switch (orientation) {
                    //     case 1: angle = 0; angleSafari = 0; break;
                    //     case 2: angle = 0; angleSafari = 0; break;
                    //     case 3: angle = 180; angleSafari = 180; break;
                    //     case 4: angle = 180; angleSafari = 0; break;
                    //     case 5: angle = 90; angleSafari = 270; break;
                    //     case 6: angle = 90; angleSafari = 90; break;
                    //     case 7: angle = 270; angleSafari = 90; break;
                    //     case 8: angle = 270; angleSafari = 270; break;
                    //     default: angle = 0; angleSafari = 0; break;
                    // }

                    let isSafari = /Version\/[\d.]+.*Safari|AppleWebKit/.test(navigator.userAgent);
                    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                    let _transform = '';
                    let _transformSafari = '';

                    if (isSafari && iOS) _transformSafari = 'rotate(' + angleSafari + 'deg)';
                    else if (angle) _transform = 'rotate(' + angle + 'deg)';
                    _reactComp.setState({ dataUrl: img1, cropButtonClassName: 'disabled', orientation, imageStyle: { transform: _transform, transformSafari: _transformSafari } });
                });
            } catch (err) {
                if (process.env.NODE_ENV === 'development') console.log(err);
            }
        };

        reader.readAsDataURL(file);
    }

    onRotateImageHandler = () => {
        if (!this.state.dataUrl) return;
        let a = this.state.imageStyle.transform;
        switch (a) {
            case '':
                a = 'rotate(90deg)';
                break;
            case 'rotate(90deg)':
                a = 'rotate(180deg)';
                break;
            case 'rotate(180deg)':
                a = 'rotate(270deg)';
                break;
            case 'rotate(270deg)':
                a = 'rotate(0deg)';
                break;
            default:
                a = 'rotate(90deg)';
                break;
        }
        let b = this.state.imageStyle.transformSafari;
        switch (b) {
            case '':
                b = 'rotate(90deg)';
                break;
            case 'rotate(90deg)':
                b = 'rotate(180deg)';
                break;
            case 'rotate(180deg)':
                b = 'rotate(270deg)';
                break;
            case 'rotate(270deg)':
                b = 'rotate(0deg)';
                break;
            default:
                b = 'rotate(90deg)';
                break;
        }
        this.setState({ imageStyle: { ...this.state.imageStyle, transform: a, transformSafari: b } });
    }

    onCropImageComplete = () => {
        const { image, pixelCrop } = this.state;
        if (pixelCrop.width < this.props.desiredWidth || pixelCrop.height < this.props.desiredHeight) {
            return;
        }
        if (!!image) {
            this.setState({ imageStyle: { ...this.state.imageStyle, transform: '' } });
            if (!pixelCrop.width && !pixelCrop.height) {
                //this.setState();
            }
            else {
                this.getCroppedImg()
                    .then(result => {
                        if (!!result) {
                            this.props.onCropPhotoCompleted(result);
                            this.setState(this.initialState);
                        }
                    })
                    .catch(err => {
                        this.props.onCropPhotoCompleted(null);
                        this.setState(this.initialState);
                    });
            }
        } else {
            this.props.onCropPhotoCompleted(null);
            this.setState(this.initialState);
        }
    }

    popUploadImage = () => {
        this.hiddenFileInput.current.click();
    }

    componenDidUpdate() {
        let active = this.props.active;
        if (active !== this.state.active) {
            this.setState({ active });
            if (!active && this.state.dataUrl)
                this.setState(this.initialState);
        }
    }

    render() {

        if (!this.props.active) {
            return null;
        }



        return (
            <Box>
                <Backdrop show={this.props.active} clicked={() => this.props.onCropPhotoCompleted(null)} />
                <div className="modal-crop">
                    <div className="media-modal_box">
                        {this.state.dataUrl &&
                            <ReactCrop
                                {...this.state}
                                src={this.state.dataUrl}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                imageStyle={this.state.imageStyle}
                            />}
                        {/*<img src={this.props.imageName} alt={this.props.imageName} />*/}
                    </div>

                    <div className="media-modal_desc">


                        <div className="media-modal_desc_crop-image" style={{ marginRight: '1rem' }}>
                            <input type="file" ref={this.hiddenFileInput} onChange={this.onChangeImageHandler} accept="image/*" style={{ display: 'none' }} />
                            <button onClick={this.popUploadImage} className="button button-regular" style={{ borderRadius: '3rem' }}>Upload Image</button>
                        </div>

                        <span className="crop-modal-mobile-button">
                            <button className={`button button-${!this.state.dataUrl ? 'disabled' : 'regular'}`}
                                onClick={this.onRotateImageHandler}>
                                <i className="far fa-sync-alt" /> <span>{getTranslatedPhrase(this.props.userLanguage, 'Rotate Photo')}</span></button>
                        </span>

                        <span className="crop-modal-mobile-button">
                            <button className={`button button-${this.state.cropButtonClassName}`}
                                onClick={this.onCropImageComplete}>
                                <i className="far fa-crop" /> <span>{getTranslatedPhrase(this.props.userLanguage, 'Crop Photo')}</span></button>
                        </span>

                        {this.props.loggedInUser && this.props.loggedInUser.userLevel === 'admin' && (
                            <span className="crop-modal-mobile-button">
                                {"Orientation = " + (this.state.orientation || '?') + " userAgent = " + navigator.userAgent}
                            </span>)}

                        {!(this.props.desiredWidth && this.props.desiredHeight) ? null :
                            (<div className="media-modal_desc_crop-desc">
                                <span>{getTranslatedPhrase(this.props.userLanguage, 'Min')}: {this.props.desiredWidth} x {this.props.desiredHeight}</span>  <span>{getTranslatedPhrase(this.props.userLanguage, 'Selection')}: {this.state.pixelCrop.width} x {this.state.pixelCrop.height}</span>
                            </div>)}




                    </div>

                    <button className="modal_close" onClick={() => this.props.onCropPhotoCompleted(null)}><i className="fal fa-times" /></button>
                </div>
            </Box>
        );
    }
}

const mapStateToCropImageModalProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToCropImageModalProps, null)(CropImageModal);