import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTranslatedPhrase } from '../../util/lang';
import FormFieldError from '../../uielements/ErrorMessages/FormFieldError';
import CountryDropDown from '../Modals/Account/CountryDropDown';
import EngageButtons from '../../uielements/Engage/EngageButtons/EngageButtons';
import Modal from '../../uielements/Modal/Modal';


class SearchCatMobile extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            searchValues: [],
            firstTimeFilters: false,
            firstTimeCategory: 0,
            category: '',
            residential: 0,
            subcategory: '',
            oldSubcategory: 'sub',
            rating: 0,
            ratingClass: Array(5).fill('fal fa-star'),
            priceAscending: 0,
            priceRange: false,
            priceMin: '',
            priceMax: '',
            viewsDescending: false,
            withCoupons: false,
            condition: '',
            seller: '',
            makeModel: '',
            carMake: '',
            carModel: '',
            fuelType: '',
            yearMin: '',
            yearMax: '',
            exteriorColor: '',
            address: '',
            country: '',
            stateVar: '',
            city: '',
            title: '',
            zipCode: '',
            yearBuiltMin: '',
            yearBuiltMax: '',
            rentOrSell: 'sell',
            bathroomCount: '',
            bedroomCount: '',
            floorSizeMin: '',
            floorSizeMax: '',
            jobType: '',
            experienceLevel: '',
            datePosted: '',
            rateType: '',
            pool: false,
            hotTub: false,
            spa: false,
            breakfastIncluded: false,
            kitchen: false,
            restaurant: false,
            roomService: false,
            fitnessCenter: false,
            freeWifi: false,
            freeParking: false,
            ac: false,
            washerDryer: false,
            petFriendly: false,
            nonSmokingRooms: false,
            accessible: false,
            facilitiesForDisabledGuests: false,
            freeCancelation: false,
            reserveNowPayLater: false,
            beachFront: false,
            airportShuttle: false,
            errorMessage: '',
            errorMessage2: '',
            searchisOn: false,
            filterisOn: false,
        }
    }

    componentDidMount() {
        let a = this.props.searchFilterList;
        if (!a) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        let searchTerm = urlParams.get('keywords');
        const searchValues = Array(a.length);

        if (searchTerm) {
            if (searchTerm.startsWith('filter')) {
                searchTerm = searchTerm.replace(/^filters-/, '').replace(/-[0-9]*$/, '')
                searchValues[0] = searchTerm
                this.setState({ searchValues: searchValues });
            } else {
                searchValues[0] = searchTerm
                this.setState({ searchValues: searchValues });
            }
        } else {
            this.setState({ searchValues: Array(a.length).fill('') });
        }
    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);

        let iniState = { ...this.initialState };
        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (!cat) cat = this.props.category || '';
        if (cat === 'search') cat = '';
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (!subcat) subcat = this.props.subcategory || '';
        if (subcat.startsWith('filters')) subcat = '';
        if (subcat === 'all') subcat = '';
        // console.log(cat,this.state.category,this.state.residential)

        if (!cat.startsWith('filters') && cat !== this.state.category) {
            iniState.category = cat;
            iniState.subcategory = '';
            iniState.oldSubcategory = this.props.subcategory || 'sub';
            iniState.searchValues = this.state.searchValues;
            iniState.rating = 0;
            iniState.ratingClass = Array(5).fill('fal fa-star');
            iniState.firstTimeFilters = this.state.firstTimeFilters;
            iniState.firstTimeCategory = this.state.firstTimeCategory + 1;

            // console.log('time',this.state.firstTimeCategory);
            if (this.state.firstTimeCategory < 1) {
                this.setState({
                    category: cat,
                    subcategory: subcat,
                    oldSubcategory: this.props.subcategory || 'sub',
                    firstTimeCategory: this.state.firstTimeCategory + 1
                });
                // console.log('category "',cat,'" ',subcat)
            } else {
                let a = this.props.searchFilterList || [];
                let f = this.state.searchValues;
                let i = 0;
                // for (; i < a.length; i++) {
                //     if (a[i].name === 'keywords' && f[i].trim() !== cat) {
                //         f[i] = '';
                //     }
                // }

                iniState.searchValues = f;
                // console.log('init state',iniState)
                this.setState(iniState);
            }
        } else if (!(this.props.category || '').startsWith('filters') && subcat !== this.state.subcategory &&
            this.state.oldSubcategory && this.state.oldSubcategory !== subcat) {
            this.setState({
                subcategory: subcat,
                oldSubcategory: subcat || 'sub'
            });
            // console.log('change subcategory', subcat)
        }

        const ts = this.state;
        let changeState = false;

        const ns = {
            rating: Number(urlParams.get('rating')) || 0,
            category: urlParams.get('category') || '',
            subcategory: urlParams.get('subcategory') || '',
            title: urlParams.get('keywords') || '',
            residential: Number(urlParams.get('residential')) || 0,
            priceAscending: Number(urlParams.get('priceAscending')) || 0,
            priceMin: urlParams.get('priceMin') || '',
            priceMax: urlParams.get('priceMax') || '',
            priceRange: urlParams.get('priceRange') || false,
            viewsDescending: urlParams.get('viewsDescending') || false,
            withCoupons: urlParams.get('withCoupons') || false,
            condition: urlParams.get('condition') || '',
            seller: urlParams.get('seller') || '',
            makeModel: urlParams.get('makeModel') || '',
            carMake: urlParams.get('carMake') || '',
            carModel: urlParams.get('carModel') || '',
            fuelType: urlParams.get('fuelType') || '',
            yearMin: urlParams.get('yearMin') || '',
            yearMax: urlParams.get('yearMax') || '',
            exteriorColor: urlParams.get('exteriorColor') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || '',
            yearBuiltMin: urlParams.get('yearBuiltMin') || '',
            yearBuiltMax: urlParams.get('yearBuiltMax') || '',
            rentOrSell: urlParams.get('rentOrSell') || '',
            bathroomCount: urlParams.get('bathroomCount') || '',
            bedroomCount: urlParams.get('bedroomCount') || '',
            floorSizeMin: urlParams.get('floorSizeMin') || '',
            floorSizeMax: urlParams.get('floorSizeMax') || '',
            jobType: urlParams.get('jobType') || '',
            experienceLevel: urlParams.get('experienceLevel') || '',
            datePosted: urlParams.get('datePosted') || '',
            rateType: urlParams.get('rateType') || '',
            pool: urlParams.get('pool') || false,
            hotTub: urlParams.get('hotTub') || false,
            spa: urlParams.get('spa') || false,
            breakfastIncluded: urlParams.get('breakfastIncluded') || false,
            kitchen: urlParams.get('kitchen') || false,
            restaurant: urlParams.get('restaurant') || false,
            roomService: urlParams.get('roomService') || false,
            fitnessCenter: urlParams.get('fitnessCenter') || false,
            freeWifi: urlParams.get('freeWifi') || false,
            freeParking: urlParams.get('freeParking') || false,
            ac: urlParams.get('ac') || false,
            washerDryer: urlParams.get('washerDryer') || false,
            petFriendly: urlParams.get('petFriendly') || false,
            nonSmokingRooms: urlParams.get('nonSmokingRooms') || false,
            accessible: urlParams.get('accessible') || false,
            facilitiesForDisabledGuests: urlParams.get('facilitiesForDisabledGuests') || false,
            freeCancelation: urlParams.get('freeCancelation') || false,
            reserveNowPayLater: urlParams.get('reserveNowPayLater') || false,
            beachFront: urlParams.get('beachFront') || false,
            airportShuttle: urlParams.get('airportShuttle') || false
        };

        if (ns.priceMin === 'null') ns.priceMin = '';
        if (ns.priceMax === 'null') ns.priceMin = '';
        if (ns.yearBuiltMin === 'null') ns.yearBuiltMin = '';
        if (ns.yearBuiltMax === 'null') ns.yearBuiltMax = '';
        if (ns.floorSizeMin === 'null') ns.floorSizeMin = '';
        if (ns.floorSizeMax === 'null') ns.floorSizeMax = '';
        if (ns.viewsDescending === 'false') ns.viewsDescending = false; else if (ns.viewsDescending === 'true') ns.viewsDescending = true;
        if (ns.priceRange === 'false') ns.priceRange = false; else if (ns.priceRange === 'true') ns.priceRange = true;
        if (ns.withCoupons === 'false') ns.withCoupons = false; else if (ns.withCoupons === 'true') ns.withCoupons = true;
        if (ns.pool === 'false') ns.pool = false; else if (ns.pool === 'true') ns.pool = true;
        if (ns.hotTub === 'false') ns.hotTub = false; else if (ns.hotTub === 'true') ns.hotTub = true;
        if (ns.spa === 'false') ns.spa = false; else if (ns.spa === 'true') ns.spa = true;
        if (ns.breakfastIncluded === 'false') ns.breakfastIncluded = false; else if (ns.breakfastIncluded === 'true') ns.breakfastIncluded = true;
        if (ns.kitchen === 'false') ns.kitchen = false; else if (ns.kitchen === 'true') ns.kitchen = true;
        if (ns.restaurant === 'false') ns.restaurant = false; else if (ns.restaurant === 'true') ns.restaurant = true;
        if (ns.roomService === 'false') ns.roomService = false; else if (ns.roomService === 'true') ns.roomService = true;
        if (ns.fitnessCenter === 'false') ns.fitnessCenter = false; else if (ns.fitnessCenter === 'true') ns.fitnessCenter = true;
        if (ns.freeWifi === 'false') ns.freeWifi = false; else if (ns.freeWifi === 'true') ns.freeWifi = true;
        if (ns.freeParking === 'false') ns.freeParking = false; else if (ns.freeParking === 'true') ns.freeParking = true;
        if (ns.ac === 'false') ns.ac = false; else if (ns.ac === 'true') ns.ac = true;
        if (ns.washerDryer === 'false') ns.washerDryer = false; else if (ns.awasherDryer === 'true') ns.washerDryer = true;
        if (ns.petFriendly === 'false') ns.petFriendly = false; else if (ns.petFriendly === 'true') ns.petFriendly = true;
        if (ns.nonSmokingRooms === 'false') ns.nonSmokingRooms = false; else if (ns.nonSmokingRooms === 'true') ns.nonSmokingRooms = true;
        if (ns.accessible === 'false') ns.accessible = false; else if (ns.accessible === 'true') ns.accessible = true;
        if (ns.facilitiesForDisabledGuests === 'false') ns.facilitiesForDisabledGuests = false; else if (ns.facilitiesForDisabledGuests === 'true') ns.facilitiesForDisabledGuests = true;
        if (ns.freeCancelation === 'false') ns.freeCancelation = false; else if (ns.freeCancelation === 'true') ns.freeCancelation = true;
        if (ns.reserveNowPayLater === 'false') ns.reserveNowPayLater = false; else if (ns.reserveNowPayLater === 'true') ns.reserveNowPayLater = true;
        if (ns.beachFront === 'false') ns.beachFront = false; else if (ns.beachFront === 'true') ns.beachFront = true;
        if (ns.airportShuttle === 'false') ns.airportShuttle = false; else if (ns.airportShuttle === 'true') ns.airportShuttle = true;

        if (/\+/ig.test(ns.title)) {
            ns.title = encodeURIComponent(ns.title);

            console.log('uri', ns.title)
        }

        // if (/^filters-.+-[0-9]+$/.test(ns.title)) {
        //     console.log('AFTER', ns.title.replace(/^filters-/, '').replace(/-[0-9]+$/, ''))
        // }
        // if (!ns.address) {
        //     if (ns.city && !ns.zipCode) ns.address = ns.city;
        //     if (!ns.city && ns.zipCode) ns.address = ns.zipCode;
        // }

        // console.log('before', ns.category, ns.residential, cat);

        if (this.props.section === 'realestate') {
            if (!ns.category && ns.residential === 0) {
                ns.category = 'residential';
                ns.residential = 1;
            }
        }


        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        if (ns.category !== ts.category || ns.subcategory !== ts.subcategory) changeState = true;
        // ns.makeModel = ns.autoMake + ' ' + ns.autoModel;
        ns.makeModel = ns.makeModel.trim();
        switch (this.props.section) {
            case 'events':
                if (ns.priceAscending !== ts.priceAscending || ns.priceRange !== ts.priceRange || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons)
                    changeState = true;
                break;
            case 'food':
                if (ns.rating !== ts.rating || ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.address !== ts.address || ns.title !== ts.title)
                    changeState = true;
                break;
            case 'store':
                if (ns.rating !== ts.rating || ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.title !== ts.title ||
                    ns.country !== ts.country || ns.city !== ts.city || ns.state !== ts.state || ns.zipCode !== ts.zipCode)
                    changeState = true;
                break;
            case 'autos':
                if (ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax || ns.seller !== ts.seller ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.rating !== ts.rating || ns.makeModel !== ts.makeModel ||
                    ns.yearMin !== ts.yearMin || ns.yearMax !== ts.yearMax || ns.condition !== ts.condition || ns.exteriorColor !== ts.exteriorColor ||
                    ns.carMake !== ts.carMake || ns.carModel !== ts.carModel || ns.fuelType !== ts.fuelType ||
                    ns.country !== ts.country || ns.city !== ts.city || ns.state !== ts.state || ns.zipCode !== ts.zipCode)
                    changeState = true;
                break;
            case 'services':
                if (ns.rating !== ts.rating || ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax || ns.address !== ts.address ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.title !== ts.title || ns.rateType !== ts.rateType)
                    changeState = true;
                break;
            case 'realestate':
                if (ns.floorSizeMin !== ts.floorSizeMin || ns.floorSizeMax !== ts.floorSizeMax || ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.residential !== ts.residential ||
                    ns.yearBuiltMin !== ts.yearBuiltMin || ns.yearBuiltMax !== ts.yearBuiltMax || ns.title !== ts.title ||
                    ns.bathroomCount !== ts.bathroomCount || ns.bedroomCount !== ts.bedroomCount || ns.rentOrSell !== ts.rentOrSell || ns.address !== ts.address ||
                    ns.country !== ts.country || ns.city !== ts.city || ns.state !== ts.state || ns.zipCode !== ts.zipCode)
                    changeState = true;
                break;
            case 'travel':
                if (ns.rating !== ts.rating || ns.priceAscending !== ts.priceAscending || ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax ||
                    ns.viewsDescending !== ts.viewsDescending || ns.withCoupons !== ts.withCoupons || ns.title !== ts.title ||
                    ns.pool === ts.pool || ns.hotTub === ts.hotTub || ns.spa === ts.spa || ns.breakfastIncluded === ts.breakfastIncluded ||
                    ns.kitchen === ts.kitchen || ns.restaurant === ts.restaurant || ns.roomService === ts.roomService || ns.fitnessCenter === ts.fitnessCenter ||
                    ns.freeWifi === ts.freeWifi || ns.freeParking === ts.freeParking || ns.ac === ts.ac || ns.washerDryer === ts.washerDryer ||
                    ns.petFriendly === ts.petFriendly || ns.nonSmokingRooms === ts.nonSmokingRooms || ns.accessible === ts.accessible ||
                    ns.facilitiesForDisabledGuests === ts.facilitiesForDisabledGuests || ns.freeCancelation === ts.freeCancelation ||
                    ns.reserveNowPayLater === ts.reserveNowPayLater || ns.beachFront === ts.beachFront || ns.airportShuttle === ts.airportShuttle)
                    changeState = true;
                break;
            case 'jobs':
                if (ns.jobType !== ts.jobType || ns.experienceLevel !== ts.experienceLevel || ns.datePosted !== ts.datePosted || ns.title !== ts.title || ns.address !== ts.address)
                    changeState = true;
                break;
            default:
                break;
        }

        if (!this.state.firstTimeFilters) ns.firstTimeFilters = true;
        if (changeState && !this.state.firstTimeFilters) {
            let a = this.state.ratingClass;
            a.forEach((v, i) => (i < ns.rating) ? (a[i] = 'fas fa-star') : (a[i] = 'fal fa-star'));
            ns.ratingClass = a;
            ns.oldSubcategory = ns.subcategory;
            this.setState(ns);
        }
    }

    onChangeTextInput = (e, i) => {
        let v = e.target.value;
        if (v.length > 200) {
            return;
        }
        let a = this.state.searchValues;
        a[i] = v;
        this.setState({ searchValues: a });
    }

    onChangeRatingHandler = (e, n) => {
        e.preventDefault();
        let a = this.state.ratingClass;
        if (this.state.rating === n) n = 0;
        a.forEach((v, i) => (i < n) ? (a[i] = 'fas fa-star') : (a[i] = 'fal fa-star'));
        this.setState({ ratingClass: a, rating: n });
    }

    onChangeItem = (_value, _propName, regularChange) => {
        if (_propName === 'condition' && _value === 'New') this.setState({ yearMin: '', yearMax: '' });
        if (!!regularChange && _propName === 'priceAscending' && this.state.priceAscending === _value) _value = 0;
        if (!!regularChange && _propName === 'priceRange' && _value === false) this.setState({ priceMin: '', priceMax: '' });
        if (!!regularChange && _propName === 'seller' && this.state.seller === _value) _value = '';
        if (!!regularChange && _propName === 'condition' && this.state.condition === _value) _value = '';
        if (_propName === 'category') {
            if (!!regularChange && _value === this.state.category) _value = '';
            this.setState({ subcategory: '' });
        }
        if (_propName === 'residential') {
            if (!!regularChange && _value === this.state.residential) _value = 0;
            if (!_value || _value === 2) this.setState({ bedroomCount: '', bathroomCount: '' })
            this.setState({ subcategory: '' });
        }

        if (_propName === 'address' && _value.length > 500) return;
        this.setState({ [_propName]: _value });
    }

    onClearHandler = (e) => {
        e.preventDefault();

        let iniState = this.initialState;
        iniState.searchValues = this.state.searchValues;
        if (!!this.state.category) iniState.category = this.state.category;
        if (!!this.state.subcategory) iniState.subcategory = this.state.subcategory;

        let keywords = 'filters-' + Date.now();
        let f = this.props.searchFilterList;
        for (let i in iniState.searchValues) {
            if (f[i].name === 'keywords' && iniState.searchValues[i].trim()) {
                keywords = iniState.searchValues[i].trim();
                iniState.category = '';
                iniState.subcategory = '';
            }
        }
        iniState.rating = 0;
        iniState.ratingClass = Array(5).fill('fal fa-star');
        iniState.firstTimeCategory = this.state.firstTimeCategory + 1;
        this.setState(iniState);
        this.props.onSubmitSearch({ keywords, category: iniState.category, subcategory: iniState.subcategory });
        let nextLocation = `/${this.props.section}`;


        if (this.props.isProfilePage) {
            nextLocation = '/' + this.props.slug + '/' + this.props.section;
        } else if (iniState.category !== 'null') {
            // nextLocation = nextLocation + '/' + iniState.category + '/' + iniState.subcategory;
            nextLocation = nextLocation
        }

        this.props.history.push(nextLocation);
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        let mySearchQuery = {};
        let a = this.state.searchValues;
        let f = this.props.searchFilterList;
        for (let i in a) {
            mySearchQuery[f[i].name] = encodeURIComponent(a[i].trim());
            // a[i] = '';
        }
        if (Object.keys(mySearchQuery).indexOf('country') === -1) {
            let co = 'USA';
            let filter = this.props.searchQuery;
            if (filter) co = JSON.parse(this.props.searchQuery || {}).countryUpdated || 'USA';
            if (co) mySearchQuery.country = co;
        }
        this.setState({ searchValues: a });

        mySearchQuery.keywords = decodeURIComponent(mySearchQuery.keywords);

        this.props.onSubmitSearch(mySearchQuery);
        this.setState({ searchisOn: false });
    }

    onSubmitFiltersHandler = (e) => {
        e.preventDefault();
        let s = this.state;

        // console.log(s);

        let errorMessage2 = '';
        if (this.state.priceRange) {
            if (isNaN(this.state.priceMin)) errorMessage2 = 'Price Min should be a number.';
            if (this.state.priceMin && Number(this.state.priceMin) < 0) errorMessage2 = 'Price Min should be greater than 0.';
            if (isNaN(this.state.priceMax)) errorMessage2 = 'Price Max should be a number.';
        }
        if (this.props.section === 'autos' && this.state.condition !== 'New') {
            if (isNaN(this.state.yearMin)) errorMessage2 = 'Year Min should be a number.';
            if (this.state.yearMin && Number(this.state.yearMin) < 1900) errorMessage2 = 'Year Min should be greater than 1900.';
            if (isNaN(this.state.yearMax)) errorMessage2 = 'Year Max should be a number.';
            if (this.state.yearMax && Number(this.state.yearMax) > new Date().getFullYear()) errorMessage2 = 'Year Max should be smaller than the current year.';
        }
        if (errorMessage2) {
            this.setState({ errorMessage2 });
            return;
        } else this.setState({ errorMessage2 });

        let mySearchQuery = {};

        if (['autos', 'events', 'food', 'realestate', 'services', 'store', 'travel', 'jobs'].indexOf(this.props.section) >= 0) {
            mySearchQuery.priceAscending = s.priceAscending;
            mySearchQuery.priceMin = s.priceMin;
            mySearchQuery.priceMax = s.priceMax;
            mySearchQuery.viewsDescending = s.viewsDescending;
            mySearchQuery.priceRange = s.priceRange;
            mySearchQuery.withCoupons = s.withCoupons;
            mySearchQuery.address = s.address;
            mySearchQuery.city = s.city;
            mySearchQuery.stateVar = s.stateVar;
            mySearchQuery.country = s.country;
            mySearchQuery.zipCode = s.zipCode;
            if (s.title.trim()) {

                if (!/^filters-/.test(s.title.trim())) {
                    mySearchQuery.keywords = 'filters-' + s.title.trim() + '-' + Date.now();
                } else {
                    mySearchQuery.keywords = s.title.trim().replace(/-[0-9]+$/, '-' + Date.now());
                }
            }
            if (this.props.section !== 'events' && this.props.section !== 'realestate') mySearchQuery.rating = s.rating;
        }
        switch (this.props.section) {
            case 'store':
                mySearchQuery.condition = s.condition;
                break;
            case 'food':
                break;
            case 'autos':
                mySearchQuery.seller = s.seller;
                s.makeModel = s.makeModel.trim();
                mySearchQuery.carMake = s.carMake
                mySearchQuery.carModel = s.carModel;
                mySearchQuery.makeModel = s.makeModel;
                mySearchQuery.fuelType = s.fuelType;
                mySearchQuery.yearMin = s.yearMin;
                mySearchQuery.yearMax = s.yearMax;
                mySearchQuery.condition = s.condition;
                mySearchQuery.exteriorColor = s.exteriorColor;
                break;
            case 'realestate':
                mySearchQuery.floorSizeMin = s.floorSizeMin;
                mySearchQuery.floorSizeMax = s.floorSizeMax;
                mySearchQuery.yearBuiltMin = s.yearBuiltMin;
                mySearchQuery.yearBuiltMax = s.yearBuiltMax;
                mySearchQuery.rentOrSell = s.rentOrSell;
                mySearchQuery.bathroomCount = s.bathroomCount;
                mySearchQuery.bedroomCount = s.bedroomCount;
                mySearchQuery.residential = s.residential;
                break;
            case 'services':
                mySearchQuery.rateType = s.rateType;
                break;
            case 'travel':
                mySearchQuery.freeWifi = s.freeWifi;
                mySearchQuery.nonSmokingRooms = s.nonSmokingRooms;
                mySearchQuery.restaurant = s.restaurant;
                mySearchQuery.fitnessCenter = s.fitnessCenter;
                mySearchQuery.freeParking = s.freeParking;
                mySearchQuery.facilitiesForDisabledGuests = s.facilitiesForDisabledGuests;
                mySearchQuery.spa = s.spa;
                mySearchQuery.airportShuttle = s.airportShuttle;
                mySearchQuery.businessCenter = s.businessCenter;
                mySearchQuery.petFriendly = s.petFriendly;
                mySearchQuery.breakfastIncluded = s.breakfastIncluded;
                mySearchQuery.pool = s.pool;
                mySearchQuery.hotTub = s.hotTub;
                mySearchQuery.kitchen = s.kitchen;
                mySearchQuery.roomService = s.roomService;
                mySearchQuery.ac = s.ac;
                mySearchQuery.washerDryer = s.washerDryer;
                mySearchQuery.nonSmokingRooms = s.nonSmokingRooms;
                mySearchQuery.accessible = s.accessible;
                mySearchQuery.freeCancelation = s.freeCancelation;
                mySearchQuery.reserveNowPayLater = s.reserveNowPayLater;
                mySearchQuery.beachFront = s.beachFront;
                mySearchQuery.airportShuttle = s.airportShuttle;
                break;
            case 'jobs':
                mySearchQuery.jobType = s.jobType;
                mySearchQuery.experienceLevel = s.experienceLevel;
                mySearchQuery.datePosted = s.datePosted;
                mySearchQuery.address = s.address;
                mySearchQuery.city = s.city;
                mySearchQuery.stateVar = s.stateVar;
                mySearchQuery.country = s.country;
                mySearchQuery.zipCode = s.zipCode;
                break;
            default: break;
        }

        if (this.state.residential === 0) mySearchQuery.category = this.state.category;
        else if (this.state.residential === 1) mySearchQuery.category = 'residential';
        else if (this.state.residential === 2) mySearchQuery.category = 'commercial';
        else if (this.state.residential === 3) mySearchQuery.category = 'business-opportunities';
        if (!!this.state.subcategory) mySearchQuery.subcategory = this.state.subcategory;


        if (!mySearchQuery.keywords) mySearchQuery.keywords = 'filters-' + Date.now();

        if (mySearchQuery.category) mySearchQuery.category = mySearchQuery.category.replace(/&/g, '%26');
        if (mySearchQuery.subcategory) mySearchQuery.subcategory = mySearchQuery.subcategory.replace(/&/g, '%26');
        if (!mySearchQuery.country && !mySearchQuery.address && !mySearchQuery.zipCode && !mySearchQuery.city)
            mySearchQuery.country = JSON.parse(this.props.searchQuery || {}).countryUpdated || 'USA';
        // console.log('submit',mySearchQuery)
        mySearchQuery.state = mySearchQuery.stateVar;
        delete mySearchQuery.stateVar;
        let s_q = JSON.parse(this.props.searchQuery || '{}');
        if (!mySearchQuery.state && mySearchQuery.country === s_q.country) mySearchQuery.state = s_q.region;

        // console.log('onSubmitSearch', mySearchQuery)
        this.props.onSubmitSearch(mySearchQuery);
        this.setState({ filterIsOn: false });
    }

    onChangePriceItem = (value, propName) => {
        value = value.replace(/,/g, '').replace('.', '');
        this.setState({ [propName]: value });
    }

    displayPriceValue = (value) => {
        if (isNaN(value) || value === '') return '';
        if (value === '0' || value === 0) return '0';
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }

    onOpenSearchModal = () => {
        this.setState({ searchisOn: true });
    }

    onCloseSearchModal = () => {
        this.setState({ searchisOn: false });
    }

    onOpenFilterModal = () => {
        this.setState({ filterIsOn: true });
    }

    onCloseFilterModal = () => {
        this.setState({ filterIsOn: false });
    }

    render() {
        /** I am expecting props.searchFilterList=[ { name:'keywords', value: 'Enter Keywords' }, ...] 
         * and props.onSubmitSearch = function(searchQuery)
        */
        if (!this.props.searchFilterList || (this.state.searchValues && !this.state.searchValues.length)) {
            return null;
        }

        return (
            <>
                {/* ---------------------- SEARCH ---------------------- */}

                <button onClick={this.onOpenSearchModal}><i className="fal fa-search"></i></button>

                {this.state.searchisOn &&
                    <Modal closeModal={this.onCloseSearchModal} title={this.props.title}>
                        <div className="main-sidebar_box main-sidebar_box-search-box">
                            <form onSubmit={this.onSubmitHandler}>
                                {this.props.searchFilterList.map((v, i) => (v.name === 'country') ? (
                                    <CountryDropDown key="country-dropdown" countryPlaceholder={getTranslatedPhrase(this.props.userLanguage, v.value)} onChangeCountry={(e) => this.onChangeTextInput(e, i)} value={this.state.searchValues[i]} />
                                ) : (<input className="form-input-text form-input-full" type="text" key={v.value}
                                    placeholder={getTranslatedPhrase(this.props.userLanguage, v.value)}
                                    value={this.state.searchValues[i]}
                                    onChange={(e) => this.onChangeTextInput(e, i)}
                                />)
                                )}

                                {this.props.errorMessage &&
                                    <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.props.errorMessage)}</FormFieldError>}
                                <button type="submit" onClick={this.onSubmitHandler} className="button button-regular mgt-md button-search">{getTranslatedPhrase(this.props.userLanguage, 'Search')}</button>
                            </form>
                        </div>
                    </Modal>
                }



                {this.props.section === 'search' || this.props.section === 'user' || this.props.section === 'news' || this.props.section === 'blogs' || this.props.section === 'photos' || this.props.section === 'videos' || this.props.section === 'groups' ? null : (
                    <>
                        <button onClick={this.onOpenFilterModal}><i className="fal fa-filter"></i></button>

                        {this.state.filterIsOn &&
                            <Modal closeModal={this.onCloseFilterModal} title={getTranslatedPhrase(this.props.userLanguage, 'Filters')} >
                                <div className="main-sidebar_box main-sidebar_box-filters">
                                    {['autos', 'events', 'food', 'realestate', 'services', 'store', 'travel'].indexOf(this.props.section) < 0 ? null : (<>

                                        <div>
                                            <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Price')}</div>
                                            {(this.props.section !== 'autos' && this.props.section !== 'realestate') ? (<div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="form-input-lists" style={{ marginRight: "1rem" }}>
                                                    <input type="radio" name="displayPriceAscending" checked={this.state.priceAscending === 1} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="displayPriceAscending">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(1, 'priceAscending', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Low to High')}
                                                    </label>
                                                </div>
                                                <div className="form-input-lists">
                                                    <input type="radio" name="displayPriceAscending" checked={this.state.priceAscending === 2} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="displayPriceDescending">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(2, 'priceAscending', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'High to Low')}
                                                    </label>
                                                </div>
                                            </div>) : null}

                                            <div className="form-input-lists">
                                                <input type="checkbox" name="displayPriceAscending" checked={this.state.priceRange === true} onChange={() => null} style={{ display: "none" }} />
                                                <label htmlFor="displayPriceDescending">
                                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.priceRange, 'priceRange', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Price Range')}
                                                </label>
                                            </div>

                                            {!(this.state.priceRange) ? null : (<div className="price-range-box mgt-sm">
                                                <input type="text" className="form-input-text form-input-full" value={this.displayPriceValue(this.state.priceMin)} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Price Min')} onChange={e => this.onChangePriceItem(e.target.value, 'priceMin')} />
                                                <input type="text" className="form-input-text form-input-full" value={this.displayPriceValue(this.state.priceMax)} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Price Max')} onChange={e => this.onChangePriceItem(e.target.value, 'priceMax')} />
                                            </div>)}

                                        </div>


                                        <div>

                                            {(this.props.isProfilePage && this.props.section === 'realestate') ? null :
                                                <div className="form-input-lists">
                                                    <input type="checkbox" name="displayWithCoupons" checked={this.state.withCoupons} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="displayWithCoupons">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.withCoupons, 'withCoupons')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Deals')}
                                                    </label>
                                                </div>
                                            }

                                            {(this.props.section !== 'autos' && !this.props.section.startsWith('realestate')) ? (
                                                <>
                                                    <div style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: '2rem' }}>{getTranslatedPhrase(this.props.userLanguage, 'Popularity')}</div>
                                                    <div className="form-input-lists">
                                                        <input type="checkbox" name="displayViewsAscending" checked={this.state.viewsDescending} onChange={() => null} style={{ display: "none" }} />
                                                        <label htmlFor="displayViewsAscending">
                                                            <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.viewsDescending, 'viewsDescending')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Most Views')}
                                                        </label>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </>)}



                                    {['food', 'services', 'store', 'travel'].indexOf(this.props.section) < 0 ? null : (
                                        <div className="mgb-sm" style={{ paddingTop: "15px" }}>
                                            <EngageButtons>
                                                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Rating')}:</div>
                                                <span className="engage_buttons_rating" style={{ paddingLeft: "10px" }}>
                                                    {this.state.ratingClass.map((v, j) => (<button key={'rating' + j}
                                                        onClick={(e) => this.onChangeRatingHandler(e, j + 1)}>
                                                        <i className={v} />
                                                    </button>)
                                                    )}
                                                </span>
                                                <div style={{ fontSize: "1.5rem" }}>{getTranslatedPhrase(this.props.userLanguage, '& Up')} </div>
                                            </EngageButtons>
                                        </div>
                                    )}




                                    {this.props.section !== 'autos' && this.props.section !== 'store' ? null : (

                                        <>
                                            <div style={{ fontSize: "1.5rem", marginTop: "1rem", fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Condition')}</div>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="form-input-lists" style={{ marginRight: '1rem' }}>
                                                    <input type="radio" name="conditionUsed" checked={this.state.condition === 'New'} onChange={() => null} style={{ display: 'none' }} />
                                                    <label htmlFor="conditionUsed">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem('New', 'condition', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'New')}
                                                    </label>
                                                </div>
                                                <div className="form-input-lists">
                                                    <input type="radio" name="conditionNew" checked={this.state.condition === 'Used'} onChange={() => null} style={{ display: 'none' }} />
                                                    <label htmlFor="conditionNew" style={{ paddingLeft: "12px" }}>
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem('Used', 'condition', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Used')}
                                                    </label>
                                                </div>
                                            </div>


                                            {/*<div className="form-input-select mgt-sm mgb-sm">*/}
                                            {/*    <select className="form-input-full" value={this.state.condition} onChange={(e) => this.onChangeItem(e.target.value, 'condition')}>*/}
                                            {/*        <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Condition')}</option>*/}
                                            {/*        <option key={'item-a1'} value="New">{getTranslatedPhrase(this.props.userLanguage, 'New')}</option>*/}
                                            {/*        <option key={'item-a2'} value="Used">{getTranslatedPhrase(this.props.userLanguage, 'Used')}</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                        </>
                                    )}

                                    {this.props.section !== 'autos' ? null : (<>
                                        {this.state.condition === 'Used' && (<>

                                            <div style={{ fontSize: "1.5rem", marginTop: '1rem', marginBottom: '1rem', fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Select Year')}</div>

                                            <div className="price-range-box">
                                                <input className="form-input-text form-input-full" type="text" value={this.state.yearMin} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Year Min')} onChange={e => this.onChangeItem(e.target.value, 'yearMin')} />
                                                <input className="form-input-text form-input-full" type="text" value={this.state.yearMax} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Year Max')} onChange={e => this.onChangeItem(e.target.value, 'yearMax')} />
                                            </div></>)}

                                        <div style={{ clear: 'both' }} />

                                        {!this.props.isProfilePage &&

                                            <>
                                                <div style={{ fontSize: "1.5rem", marginTop: '1rem', fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Sold By')}</div>

                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-input-lists" style={{ marginRight: '1rem' }}>
                                                        <input type="radio" name="displaySaleAgent" checked={this.state.seller === 'dealer'} onChange={() => null} style={{ display: 'none' }} />
                                                        <label htmlFor="displaySaleAgent">
                                                            <span><i className="fal fa-check" onClick={(e) => this.onChangeItem('dealer', 'seller', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Dealer')}
                                                        </label>
                                                    </div>
                                                    <div className="form-input-lists">
                                                        <input type="radio" name="displaySaleAgent" checked={this.state.seller === 'owner'} onChange={() => null} style={{ display: 'none' }} />
                                                        <label htmlFor="displaySaleAgent" style={{ paddingLeft: "12px" }}>
                                                            <span><i className="fal fa-check" onClick={(e) => this.onChangeItem('owner', 'seller', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Owner')}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div style={{ clear: 'both' }} />
                                            </>
                                        }

                                        {/* <input type="text" value={this.state.makeModel} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Make and model')} onChange={e => this.onChangeItem(e.target.value, 'makeModel')} /> */}

                                        <div className="form-input-select mgb-sm mgt-sm">
                                            <select className="form-input-full" value={this.state.fuelType} onChange={e => this.onChangeItem(e.target.value, 'fuelType')}>
                                                <option value="" disabled="">{getTranslatedPhrase(this.props.userLanguage, 'Select Fuel Type')}</option>
                                                <option value="Biodiesel">Biodiesel</option>
                                                <option value="Compressed Natural Gas">Compressed Natural Gas</option>
                                                <option value="Diesel">Diesel</option>
                                                <option value="E85">E85</option>
                                                <option value="Electric">Electric</option>
                                                <option value="Hydrogen">Hydrogen</option>
                                                <option value="Lpg">Lpg</option>
                                                <option value="Natural Gas">Natural Gas</option>
                                                <option value="Premium Unleaded">Premium Unleaded</option>
                                                <option value="Unleaded">Unleaded</option>
                                            </select>
                                        </div>

                                        <div className="form-input-select mgb-sm mgt-sm">
                                            <select className="form-input-full" value={this.state.exteriorColor} onChange={(e) => this.onChangeItem(e.target.value, 'exteriorColor')}>
                                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Exterior Color')}</option>
                                                <option key={'item-b0'} value="Beige">{getTranslatedPhrase(this.props.userLanguage, 'Beige')}</option>
                                                <option key={'item-b1'} value="Black">{getTranslatedPhrase(this.props.userLanguage, 'Black')}</option>
                                                <option key={'item-b2'} value="Blue">{getTranslatedPhrase(this.props.userLanguage, 'Blue')}</option>
                                                <option key={'item-b3'} value="Brown">{getTranslatedPhrase(this.props.userLanguage, 'Brown')}</option>
                                                <option key={'item-b3a'} value="Gold">{getTranslatedPhrase(this.props.userLanguage, 'Gold')}</option>
                                                <option key={'item-b4'} value="Green">{getTranslatedPhrase(this.props.userLanguage, 'Green')}</option>
                                                <option key={'item-b5'} value="Gray">{getTranslatedPhrase(this.props.userLanguage, 'Gray')}</option>
                                                <option key={'item-b6'} value="Orange">{getTranslatedPhrase(this.props.userLanguage, 'Orange')}</option>
                                                <option key={'item-b6a'} value="Red">{getTranslatedPhrase(this.props.userLanguage, 'Red')}</option>
                                                <option key={'item-b6b'} value="Pink">{getTranslatedPhrase(this.props.userLanguage, 'Pink')}</option>
                                                <option key={'item-b6c'} value="Purple">{getTranslatedPhrase(this.props.userLanguage, 'Purple')}</option>
                                                <option key={'item-b7'} value="Silver">{getTranslatedPhrase(this.props.userLanguage, 'Silver')}</option>
                                                <option key={'item-b8'} value="White">{getTranslatedPhrase(this.props.userLanguage, 'White')}</option>
                                                <option key={'item-b9'} value="Yellow">{getTranslatedPhrase(this.props.userLanguage, 'Yellow')}</option>
                                            </select>
                                        </div>
                                    </>)}

                                    {/* {this.props.section !== 'food' ? null : (
                            <input style={{ paddingTop: "16px"}} type="text" value={this.state.address} placeholder={getTranslatedPhrase(this.props.userLanguage, 'City or ZIP code')} onChange={e => this.onChangeItem(e.target.value, 'address')} />
                        )} */}


                                    {this.props.section !== 'realestate' ? null : (<>

                                        {/* <input style={{ paddingTop: "16px"}} type="text" value={this.state.address} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Address, city or ZIP code')} onChange={e => this.onChangeItem(e.target.value, 'address')} /> */}


                                        {(this.props.isProfilePage && this.props.section === 'realestate') ? null :
                                            <>
                                                <div className="mgt-md" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Type')}</div>



                                                <div className="form-input-lists">
                                                    <input type="radio" name="residentialCommercial" checked={this.state.residential === 1} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="residentialCommercial">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(1, 'residential', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Residential')}
                                                    </label>
                                                </div>
                                                <div className="form-input-lists">
                                                    <input type="radio" name="residentialCommercial" checked={this.state.residential === 2} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="residentialCommercial">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(2, 'residential', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Commercial')}
                                                    </label>
                                                </div>
                                                <div className="form-input-lists">
                                                    <input type="radio" name="residentialOpportunities" checked={this.state.residential === 3} onChange={() => null} style={{ display: "none" }} />
                                                    <label htmlFor="residentialOpportunities">
                                                        <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(3, 'residential', true)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Business Opportunities')}
                                                    </label>
                                                </div>
                                            </>
                                        }

                                        {this.state.residential !== 3 &&
                                            <>
                                                <div className="price-range-box mgt-sm mgb-sm" style={{ float: 'none' }}>
                                                    <input className="form-input-text form-input-full mgt-sm" style={{ paddingTop: "16px" }} type="text" value={this.state.yearBuiltMin} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Year Built Min')} onChange={e => this.onChangeItem(e.target.value, 'yearBuiltMin')} />
                                                    <input className="form-input-text form-input-full mgt-sm" style={{ paddingTop: "16px" }} type="text" value={this.state.yearBuiltMax} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Year Built Max')} onChange={e => this.onChangeItem(e.target.value, 'yearBuiltMax')} />
                                                </div>


                                                <div className="form-input-select mgb-sm">
                                                    <select className="form-input-full mgb-sm" value={this.state.rentOrSell} onChange={(e) => this.onChangeItem(e.target.value, 'rentOrSell')}>
                                                        <option key={'item-da2'} value="sell">{getTranslatedPhrase(this.props.userLanguage, 'Buy')}</option>
                                                        <option key={'item-da1'} value="rent">{getTranslatedPhrase(this.props.userLanguage, 'Rent/Lease')}</option>
                                                    </select>
                                                </div>
                                            </>
                                        }

                                        {this.state.residential === 1 && (
                                            <div className="form-input-select mgb-sm">
                                                <select className="form-input-full mgb-sm" value={this.state.bedroomCount} onChange={(e) => this.onChangeItem(e.target.value, 'bedroomCount')}>
                                                    <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Bedroom Count')}</option>
                                                    <option key={'item-c1'} value="1">{getTranslatedPhrase(this.props.userLanguage, '1 Bedroom')}</option>
                                                    <option key={'item-c2'} value="2">{getTranslatedPhrase(this.props.userLanguage, '2 Bedrooms')}</option>
                                                    <option key={'item-c3'} value="3">{getTranslatedPhrase(this.props.userLanguage, '3 Bedrooms')}</option>
                                                    <option key={'item-c4'} value="4">{getTranslatedPhrase(this.props.userLanguage, '4 Bedrooms')}</option>
                                                    <option key={'item-c5'} value="5">{getTranslatedPhrase(this.props.userLanguage, '5 Bedrooms')}</option>
                                                    <option key={'item-c6'} value="6">{getTranslatedPhrase(this.props.userLanguage, '6 Bedrooms')}</option>
                                                    <option key={'item-c7'} value="7">{getTranslatedPhrase(this.props.userLanguage, '>6 Bedrooms')}</option>
                                                </select>
                                            </div>)}

                                        {this.state.residential === 1 && (
                                            <div className="form-input-select">
                                                <select className="form-input-full mgb-sm" value={this.state.bathroomCount} onChange={(e) => this.onChangeItem(e.target.value, 'bathroomCount')}>
                                                    <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Bathroom Count')}</option>
                                                    <option key={'item-d1'} value="1">{getTranslatedPhrase(this.props.userLanguage, '1 Bathroom')}</option>
                                                    <option key={'item-d2'} value="2">{getTranslatedPhrase(this.props.userLanguage, '2 Bathrooms')}</option>
                                                    <option key={'item-d3'} value="3">{getTranslatedPhrase(this.props.userLanguage, '3 Bathrooms')}</option>
                                                    <option key={'item-d4'} value="4">{getTranslatedPhrase(this.props.userLanguage, '>3 Bathrooms')}</option>
                                                </select>
                                            </div>)}
                                    </>)}

                                    {this.props.section !== 'jobs' ? null : (<>

                                        <div className="form-input-select">
                                            <select className="form-input-full mgb-sm" value={this.state.jobType} onChange={(e) => this.onChangeItem(e.target.value, 'jobType')}>
                                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Job Type')}</option>
                                                <option key={'item-e1'} value="full-time">{getTranslatedPhrase(this.props.userLanguage, 'Full Time')}</option>
                                                <option key={'item-e2'} value="part-time">{getTranslatedPhrase(this.props.userLanguage, 'Part Time')}</option>
                                                <option key={'item-e3'} value="internship">{getTranslatedPhrase(this.props.userLanguage, 'Internship')}</option>
                                                <option key={'item-e4'} value="contract">{getTranslatedPhrase(this.props.userLanguage, 'Contract')}</option>
                                                <option key={'item-e5'} value="temp-project">{getTranslatedPhrase(this.props.userLanguage, 'Temp / Project')}</option>
                                                <option key={'item-e6'} value="commission">{getTranslatedPhrase(this.props.userLanguage, 'Commission')}</option>
                                                <option key={'item-e7'} value="volunteer">{getTranslatedPhrase(this.props.userLanguage, 'Volunteer')}</option>
                                            </select>
                                        </div>

                                        <div className="form-input-select">
                                            <select className="form-input-full mgb-sm" value={this.state.experienceLevel} onChange={(e) => this.onChangeItem(e.target.value, 'experienceLevel')}>
                                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Experience Level')}</option>
                                                <option key={'item-f1'} value="entry-level">{getTranslatedPhrase(this.props.userLanguage, 'Entry Level')}</option>
                                                <option key={'item-f2'} value="mid-level">{getTranslatedPhrase(this.props.userLanguage, 'Mid Level')}</option>
                                                <option key={'item-f3'} value="senior">{getTranslatedPhrase(this.props.userLanguage, 'Senior')}</option>
                                                <option key={'item-f4'} value="director">{getTranslatedPhrase(this.props.userLanguage, 'Director')}</option>
                                                <option key={'item-f5'} value="vp">{getTranslatedPhrase(this.props.userLanguage, 'VP')}</option>
                                            </select>
                                        </div>

                                        <div className="form-input-select">
                                            <select className="form-input-text form-input-full mgb-sm" value={this.state.datePosted} onChange={(e) => this.onChangeItem(e.target.value, 'datePosted')}>
                                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Date Posted')}</option>
                                                <option key={'item-g1'} value="last-24-hours">{getTranslatedPhrase(this.props.userLanguage, 'Last 24 Hours')}</option>
                                                <option key={'item-g2'} value="last-3-days">{getTranslatedPhrase(this.props.userLanguage, 'Last 3 Days')}</option>
                                                <option key={'item-g3'} value="last-7-days">{getTranslatedPhrase(this.props.userLanguage, 'Last 7 Days')}</option>
                                                <option key={'item-g4'} value="last-14-days">{getTranslatedPhrase(this.props.userLanguage, 'Last 14 Days')}</option>
                                                <option key={'item-g5'} value="one-month-ago">{getTranslatedPhrase(this.props.userLanguage, 'Last Month')}</option>
                                            </select>
                                        </div>

                                        {/* <input style={{ paddingTop: "16px"}} type="text" value={this.state.address} placeholder={getTranslatedPhrase(this.props.userLanguage, 'City or ZIP code')} onChange={e => this.onChangeItem(e.target.value, 'address')} /> */}
                                    </>)}

                                    {this.props.section !== 'services' ? null : (<>

                                        <div className="form-input-select mgt-md mgb-sm">
                                            <select className="form-input-full" value={this.state.rateType} onChange={(e) => this.onChangeItem(e.target.value, 'rateType')}>
                                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Rate Type')}</option>
                                                <option key={'item-h1'} value="hour">{getTranslatedPhrase(this.props.userLanguage, 'Hour')}</option>
                                                <option key={'item-h2'} value="day">{getTranslatedPhrase(this.props.userLanguage, 'Day')}</option>
                                                <option key={'item-h3'} value="package">{getTranslatedPhrase(this.props.userLanguage, 'Package')}</option>
                                                <option key={'item-h4'} value="volunteer">{getTranslatedPhrase(this.props.userLanguage, 'Volunteer')}</option>
                                            </select>
                                        </div>
                                        {/* <input style={{ paddingTop: "16px"}} type="text" value={this.state.address} placeholder={getTranslatedPhrase(this.props.userLanguage, 'City or ZIP code')} onChange={e => this.onChangeItem(e.target.value, 'address')} /> */}
                                    </>)}

                                    {this.props.section !== 'travel' ? null : (<>
                                        <div className="mgt-md" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'Features')}</div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayPool" checked={this.state.pool} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayPool" >
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.pool, 'pool')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Swimming Pool')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayHotTub" checked={this.state.hotTub} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayHotTub">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.hotTub, 'hotTub')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Hot Tub')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displaySpa" checked={this.state.spa} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displaySpa">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.spa, 'spa')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'SPA')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayBreakfastIncluded" checked={this.state.breakfastIncluded} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayBreakfastIncluded">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.breakfastIncluded, 'breakfastIncluded')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Breakfast included')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayKitchen" checked={this.state.kitchen} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayKitchen">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.kitchen, 'kitchen')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Kitchen')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayRestaurant" checked={this.state.restaurant} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayRestaurant">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.restaurant, 'restaurant')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Restaurant')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayRoomService" checked={this.state.roomService} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayRoomService">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.roomService, 'roomService')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Room Service')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayFitnessCenter" checked={this.state.fitnessCenter} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayFitnessCenter">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.fitnessCenter, 'fitnessCenter')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Fitness Center')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayFreeWifi" checked={this.state.freeWifi} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayFreeWifi">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.freeWifi, 'freeWifi')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Free Wifi')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayFreeParking" checked={this.state.freeParking} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayFreeParking">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.freeParking, 'freeParking')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Free Parking')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayAc" checked={this.state.ac} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayAc">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.ac, 'ac')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'A/C')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayWasherDryer" checked={this.state.washerDryer} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayWasherDryer">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.washerDryer, 'washerDryer')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Washer / Dryer')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayPetFriendly" checked={this.state.petFriendly} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayPetFriendly">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.petFriendly, 'petFriendly')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Pet Friendly')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayNonSmokingRooms" checked={this.state.nonSmokingRooms} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayNonSmokingRooms">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.nonSmokingRooms, 'nonSmokingRooms')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Non-Smoking Rooms')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayAccessible" checked={this.state.accessible} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayAccessible">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.accessible, 'accessible')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Accessible')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayFacilitiesForDisabledGuests" checked={this.state.facilitiesForDisabledGuests} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayFacilitiesForDisabledGuests">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.facilitiesForDisabledGuests, 'facilitiesForDisabledGuests')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Facilities for Disabled Guests')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayFreeCancelation" checked={this.state.freeCancelation} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayFreeCancelation">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.freeCancelation, 'freeCancelation')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Free Cancelation')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayReserveNowPayLater" checked={this.state.reserveNowPayLater} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayReserveNowPayLater">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.reserveNowPayLater, 'reserveNowPayLater')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Reserve Now Pay Later')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayBeachFront" checked={this.state.beachFront} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayBeachFront">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.beachFront, 'beachFront')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Beach Front')}
                                            </label>
                                        </div>
                                        <div className="form-input-lists">
                                            <input type="checkbox" name="displayAirportShuttle" checked={this.state.airportShuttle} onChange={() => null} style={{ display: "none" }} />
                                            <label htmlFor="displayAirportShuttle">
                                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.airportShuttle, 'airportShuttle')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Airport shuttle')}
                                            </label>
                                        </div>
                                    </>)}


                                    {/* <input type="text"
                            placeholder={getTranslatedPhrase(this.props.userLanguage,plHolder)} 
                            value={this.state.searchValues[0]} 
                            onChange={(e) => this.onChangeTextInput(e, 0)}
                            /> */}

                                    <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.state.errorMessage2)}</FormFieldError>
                                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="col "><button type="button" onClick={this.onSubmitFiltersHandler} className="button button-regular button-small button-search">{getTranslatedPhrase(this.props.userLanguage, 'Apply')}</button></div>
                                        <div className="col"><button type="button" onClick={this.onClearHandler} className="button button-clear button-small button-search">{getTranslatedPhrase(this.props.userLanguage, 'Clear')}</button></div>
                                    </div>

                                </div>
                            </Modal>
                        }
                    </>
                )
                }
            </>
        );
    }
}

const mapStateToSearchProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        searchQuery: state.searchQuery
    };
};

export default connect(mapStateToSearchProps, null)(withRouter(SearchCatMobile));