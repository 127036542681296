import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import TravelFeaturedPreview from './TravelFeaturedPreview';

export default function TravelFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={TravelFeaturedPreview}
        />
    );
}