import React from "react";

import Axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { hostNameAndPort } from "../../../shared/util/vars";

// import ResellerLogo from "../../../assets/logos/reseller-logo-notag.svg";
// import CashinLogo from "../../../assets/logos/cash-in-logo-notag.svg";

class SettingsMenu extends React.Component {
  state = {
    accountType: "unknown",
    affiliateId: "",
    resellerId: "",
  };


  componentDidMount() {
    this.getUserDataFromServer();
  }


  getUserDataFromServer = async () => {
    let url = `${hostNameAndPort}/api/user/affiliate-info`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        if (
          data.user.accountType !== this.state.accountType ||
          data.user.resellerId !== this.state.resellerId ||
          data.user.affiliateId !== this.state.affiliateId
        ) {
          this.setState(data.user);
        }
      } else
        this.setState({
          accountType: "unknown",
          affiliateId: "",
          resellerId: ""
        });
    } catch (err) {
      // Network error
    }
  };

  render() {
    let windowLocationSearch = window.location.search;
    let urlParams = new URLSearchParams(windowLocationSearch);
    let slug = urlParams.get('slug') || '';
    // We only allow Profile Edit for other Customer Service accounts

    return (
      <>
        <div className="layout-background layout-background-settings-menu" />


          <div className="settings-menu">
            <h2>Settings</h2>

            <div className="settings-layout">
              <div className="settings-menu-list">
                <Link to={"/profile-update"+windowLocationSearch} className={this.props.ProfileLiActive}>
                  <i className="fal fa-user-cog" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Profile Update")}
              </span>
                </Link>

              {!slug?(<>
                <Link to="/account-settings" className={this.props.AccountLiActive}>
                  <i className="fa-light fa-sliders" />{" "}
                  <span>
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Account Settings"
                )}
              </span>
                </Link>


              {/*  <Link to="/listing-settings" className={this.props.ListingSettingsLiActive}>*/}
              {/*    <i className="fa-light fa-sliders" />{" "}*/}
              {/*    <span>*/}
              {/*  {getTranslatedPhrase(*/}
              {/*      this.props.userLanguage,*/}
              {/*      "Listing Settings"*/}
              {/*  )}*/}
              {/*</span>*/}
              {/*  </Link>*/}


                <a
                  href="https://followone.com/subscription-settings?website=carverve"
                  className={this.props.SubscriptionLiActive}
                  target="_blank"
                >
                  <i className="fal fa-layer-group" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Subscription")}
              </span>
                </a>


                <Link
                  to="/purchase-history"
                  className={this.props.PurchaseHistoryLiActive}
                >
                  <i className="fal fa-file-invoice-dollar" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Purchases")}
              </span>
                </Link>



                {/* <Link to="/privacy-settings" className={this.props.PrivacyLiActive}>
                  <i className="fal fa-lock-alt" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Privacy")}
              </span>
                </Link> */}

{/* 
                <Link to="/invite-friends" className={this.props.InviteLiActive}>
                  <i className="fal fa-users" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Invite Friends")}
              </span>
                </Link> */}


                <div className="settings-menu-divider" />


                <Link
                  to="/posts-history"
                  className={this.props.PostHistoryLiActive}
                >
                  <i className="fal fa-file-edit" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Listings")}
              </span>
                </Link>


                <Link
                  to="/manage-coupons"
                  className={this.props.ManangeCouponsLiActive}
                >
                  <i className="fal fa-tags" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Coupons")}
              </span>
                </Link>


                {/* <Link
                  to="/manage-promotions"
                  className={this.props.ManagePromotionsLiActive}
                >
                  <i className="fal fa-bullhorn" />{" "}
                  <span>
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Paid Ads"
                )}
              </span>
                </Link> */}

                <div className="settings-menu-divider" />





{/* 
                {this.state.resellerId ? (
                  <>
                  <Link
                    to="/reseller-affiliate-dashboard"
                    className={this.props.ResellerAffiliateDashboardLiActive}
                  >
                    <i className="fal fa-wallet" />{" "}
                    <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Reseller Dashboard")}
                </span>
                  </Link>
                  <div className="settings-menu-divider" />
                </>
                  ) : null} */}


                {/* {this.state.affiliateId && this.state.accountType === "personal" ? (
                  <><Link
                    to="/cash-in"
                    className={this.props.PaidNotificationsLiActive}
                  >
                    <i className="fal fa-dollar-sign" />{" "}
                    <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Ca$hin Affiliate")}
                </span>
                  </Link>
                  <div className="settings-menu-divider" />
                  </>
                ) : null} */}



                <a href="https://followone.com/delete-account" target="_blank" className={this.props.DeleteLiActive}>
                  <i className="fal fa-folder-times" />{" "}
                  <span>
                {getTranslatedPhrase(this.props.userLanguage, "Delete Account")}
              </span>
                </a>


                {/* {this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service') &&
                  <>
                    <div className="settings-menu-divider" />

                    <Link  className={this.props.SellerDashboardLiActive} to="/hfsdfsjfkjsafj4848j">
                      <i className="fal fa-handshake" />{" "}
                      <span>
                        {getTranslatedPhrase(this.props.userLanguage, "Seller Dashboard")}
                      </span>
                    </Link>

                    <Link to="/jkdf943i9grjr90gjr" className="hide-on-m">
                      <i className="fal fa-users" />{" "}
                      <span>
                        {getTranslatedPhrase(this.props.userLanguage, "Manage Users")}
                      </span>
                    </Link>

                    <Link to="/lkgjlkje9030reqwir"  className="hide-on-m">
                      <i className="fal fa-clone" />{" "}
                      <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Manage Listings")}
                      </span>
                    </Link>

                    <Link to="/styleguide"  className="hide-on-m">
                      <i className="fal fa-swatchbook" />{" "}
                      <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Style Guide")}
                      </span>
                    </Link>

                    {this.props.loggedInUser.userLevel === 'admin' &&
                    <Link className={this.props.ManageRealEstateLiActive} to="/gheivkjdi934jk49fs">
                      <i className="fal fa-file-edit" />{" "}
                      <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Manage Real Estate")}
                      </span>
                    </Link>
                    }


                  </>
                } */}



                {/* <Link className={this.props.ManageRealEstateLiActive} to="/gheivkjdi934jk49fs">
                  <i className="fal fa-file-edit" />{" "}
                  <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Manage Real Estate - Delete!")}
                  </span>
                </Link> */}



                <Link className="empty-space-mobile" to="/" />

            </>):null}
              </div>


              <div className="settings-content">
                {this.props.children}
              </div>
            </div>

          </div>



      </>
    );
  }
}

const mapStateToProfileSettingsNavProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    loggedInUser: state.loggedInUser
  };
};

export default connect(mapStateToProfileSettingsNavProps, null)(SettingsMenu);
