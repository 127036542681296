import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import Modal from '../../../uielements/Modal/Modal';
import { populateCountries, populateStates } from '../../../util/countries';

import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';

const AdvJobs = props => {
    const userLanguage = useSelector(state => state.userLanguage);
    const [category, setCategory] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [jobType, setJobType] = useState('');
    const [title, setTitle] = useState('');
    const [experienceLevel, setExperienceLevel] = useState('');
    const [datePosted, setDatePosted] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const [stateVar, setStateVar] = useState('');
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [countryElementId, setCountryElementId] = useState('');
    const [stateElementId, setStateElementId] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const { section } = props;
    const windowLocationSearch = window.location.search;

    useEffect(() => {
        setCategoryList(props.categoryList);
    }, [section]);

    const b1 = document.getElementById("stateElementForAdvJobs");
    const b2 = document.getElementById("countryElementForAdvJobs");
    const b2Len = (b2 && b2.options.length > 1) ? b2.options.length : 0;
    const b1Len = (b1 && b1.options.length) ? b1.options.length : 0;
    useEffect(() => {
        if (
            countryElementId
            && stateElementId
            && b1
            && b1Len
            && b2
            && b2Len
        ) {
            let cc = b2;
            let i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === country) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
            populateStates(countryElementId, stateElementId);
            cc = document.getElementById("stateElementForAdvJobs");
            i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === stateVar) break;

            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
        }
    }, [b1, b2, b2Len, b1Len]);

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;
    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                jobType,
                title,
                experienceLevel,
                datePosted,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            if (!!props.category) props.history.push('/jobs');
        }
    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {
            const ts = {
                category,
                jobType,
                title,
                experienceLevel,
                datePosted,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem(i, '');
            setCountry(currentCountry);
            return;
        }
        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        if (category !== cat) setCategory(cat);

        const ts = {
            category,
            jobType,
            title,
            experienceLevel,
            datePosted,
            country,
            stateVar,
            city,
            zipCode
        };
        let changeState = false;

        const ns = {
            category: urlParams.get('category') || '',
            jobType: urlParams.get('jobType'),
            experienceLevel: urlParams.get('experienceLevel'),
            datePosted: urlParams.get('datePosted'),
            address: urlParams.get('address') || '',
            title: urlParams.get('keywords') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || ''
        };

        let keywordsParts = ns.title.split('-');
        if (keywordsParts.length > 1) {
            keywordsParts.shift();
            keywordsParts.pop();
        }
        ns.title = keywordsParts.join('-');

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        if (ns.address !== ts.address || ns.jobType !== ts.jobType || ts.experienceLevel !== ns.experienceLevel || ts.datePosted !== ns.datePosted ||
            ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode || ns.title !== ts.title)
            changeState = true;


        if (changeState) {
            for (let i of Object.keys(ns)) {
                if (i !== 'address' && i !== 'stateVar' && i !== 'city' && i !== 'zipCode') onChangeItem(i, ns[i]);
            }
            if (!ns.address) {
                onChangeItem('stateVar', ns.stateVar);
                onChangeItem('city', ns.city);
                onChangeItem('zipCode', ns.zipCode);
                onChangeItem('address', ns.address);
            } else {
                let newCity = '', newState = '', newZipCode = '';
                let a = ns.address.split(',');
                if (a.length < 2) {
                    if (/[-A-Za-z0-9]*/i.test(a)) newZipCode = a[0].trim();
                    else newCity = a[0].trim();
                } else {
                    newCity = a[0].trim();
                    newState = a[1].trim();
                }
                onChangeItem('stateVar', newState);
                onChangeItem('city', newCity);
                onChangeItem('zipCode', newZipCode);
                onChangeItem('address', ns.address);
            }
        }
    }, [windowLocationSearch]);

    function onChangeItem(_prop, _value, regularChange) {
        let a = {
            category: setCategory,
            jobType: setJobType,
            experienceLevel: setExperienceLevel,
            datePosted: setDatePosted,
            country: setCountry,
            city: setCity,
            title: setTitle,
            stateVar: setStateVar,
            zipCode: setZipCode
        };
        if (_prop === 'datePosted' && datePosted === _value && !!regularChange) _value = '';
        if (_prop === 'experienceLevel' && experienceLevel === _value && !!regularChange) _value = '';

        if (a[_prop]) a[_prop](_value);

    }

    function onSubmitSearch(e) {
        e.preventDefault();
        let f;
        if (!title) f = "filters-" + Date.now();
        else f = "filters-" + title + "-" + Date.now();
        // let s_q = JSON.parse(searchQuery || '{}');
        let state = stateVar;
        // if (!state && country === s_q.country) state = s_q.region;
        if (!state && city) {
            setErrorMessage('Please select the state!');
            return;
        } else setErrorMessage('');

        let s = `/jobs/search/${f}?experienceLevel=${experienceLevel}&datePosted=${datePosted}&jobType=${jobType}&country=${country}&state=${state}&city=${city}&zipCode=${zipCode}&category=${category.replace(/&/g, '%26')}&keywords=${f}`;
        props.onSubmitHandler(e, s);
        props.closeModal();
    }

    return (
        <Modal
            title="Jobs Advanced Search"
            closeModal={props.closeModal}
            modalClass="medium"
            modalBodyClass="adv-body"
        >
            <div className="adv-search">
                <div className="adv-search-content">
                    <div className="adv-search-content-box">
                        <p><strong>KEYWORDS</strong></p>
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Enter a job title or keywords." value={title} onChange={e => onChangeItem('title', e.target.value)} />
                        <p><strong>CATEGORY</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={category} onChange={e => onChangeItem('category', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Category')}</option>
                                {categoryList.filter(v => (!/^\s*-+/.test(v))).map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>

                        <p><strong>EXPERIENCE LEVEL</strong></p>
                        <div className="form-input-lists">
                            <input type="checkbox" name="entrylevel" id="entrylevel" value="entrylevel" checked={experienceLevel === 'entry-level'} onChange={() => null} />
                            <label htmlFor="entrylevel" onClick={e => onChangeItem('experienceLevel', 'entry-level', true)}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Entry Level')}</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="midlevel" id="midlevel" value="midlevel" checked={experienceLevel === 'mid-level'} onChange={() => null} />
                            <label htmlFor="midlevel" onClick={e => onChangeItem('experienceLevel', 'mid-level', true)}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Mid Level')}</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="senior" id="senior" value="senior" checked={experienceLevel === 'senior'} onChange={() => null} />
                            <label htmlFor="senior" onClick={e => onChangeItem('experienceLevel', 'senior', true)}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Senior')}</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="director" id="director" value="director" checked={experienceLevel === 'director'} onChange={() => null} />
                            <label htmlFor="director" onClick={e => onChangeItem('experienceLevel', 'director', true)}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Director')}</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="vp" id="vp" value="vp" checked={experienceLevel === 'vp'} onChange={() => null} />
                            <label htmlFor="vp" onClick={e => onChangeItem('experienceLevel', 'vp', true)}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'VP')}</label>
                        </div>

                    </div>

                    <div className="adv-search-content-box">
                        <p ><strong>TYPE</strong></p>


                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={jobType} onChange={e => onChangeItem('jobType', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Job Type')}</option>
                                <option value="full-time">{getTranslatedPhrase(userLanguage, 'Full Time')}</option>
                                <option value="part-time">{getTranslatedPhrase(userLanguage, 'Part Time')}</option>
                                <option value="internship">{getTranslatedPhrase(userLanguage, 'Internship')}</option>
                                <option value="contract">{getTranslatedPhrase(userLanguage, 'Contract')}</option>
                                <option value="temp-project">{getTranslatedPhrase(userLanguage, 'Temp / Project')}</option>
                                <option value="commission">{getTranslatedPhrase(userLanguage, 'Commission')}</option>
                                <option value="volunteer">{getTranslatedPhrase(userLanguage, 'Volunteer')}</option>
                            </select>
                        </div>

                        <p><strong>DATE POSTED</strong></p>
                        <div className="form-input-lists">
                            <input type="checkbox" name="onedaygo" id="onedaygo" value="onedaygo" checked={datePosted === 'last-24-hours'} onChange={() => null} />
                            <label htmlFor="onedaygo" onClick={e => onChangeItem('datePosted', 'last-24-hours', true)}><i className="fal fa-check" />24 Hours Ago</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="threedaysago" id="threedaysago" value="threedaysago" checked={datePosted === 'last-3-days'} onChange={() => null} />
                            <label htmlFor="threedaysago" onClick={e => onChangeItem('datePosted', 'last-3-days', true)}><i className="fal fa-check" />3 Days Ago</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="sevendaysago" id="sevendaysago" value="sevendaysago" checked={datePosted === 'last-7-days'} onChange={() => null} />
                            <label htmlFor="sevendaysago" onClick={e => onChangeItem('datePosted', 'last-7-days', true)}><i className="fal fa-check" />7 Days Ago</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="onemonthago" id="onemonthago" value="onemonthago" checked={datePosted === 'one-month-ago'} onChange={() => null} />
                            <label htmlFor="onemonthago" onClick={e => onChangeItem('datePosted', 'one-month-ago', true)}><i className="fal fa-check" />One Month Ago</label>
                        </div>
                    </div>

                    <div className="adv-search-content-box">


                        <p><strong>LOCATION</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="countryElementForAdvJobs"
                                ref={n => {
                                    if (!countryElementId) setCountryElementId("countryElementForAdvJobs");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={country}
                                onChange={e => onChangeItem('country', e.target.value)}>
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="stateElementForAdvJobs"
                                ref={n => {
                                    if (!stateElementId) setStateElementId("stateElementForAdvJobs");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={stateVar}
                                onChange={e => onChangeItem('stateVar', e.target.value)}>
                                <option value="">Select State *</option>
                            </select>
                        </div>

                        {/*<input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Zip/Postal Code" value={zipCode} onChange={e => onChangeItem('zipCode', e.target.value)}/>*/}
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="City" value={city} onChange={e => onChangeItem('city', e.target.value)} />
                    </div>

                </div>
                <div><FormFieldError> {errorMessage} </FormFieldError></div>
                <div className="adv-search-buttons">
                    <button className="button button-regular" onClick={onSubmitSearch}>Search</button>
                    <button className="button button-white" onClick={props.closeModal}>Cancel Search</button>
                </div>
            </div>
        </Modal >
    )
}

export default connect(null, null)(AdvJobs);