import React, { Component } from 'react';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import actionType from '../../../../redux-store/action-type';
import { connect } from 'react-redux';
import StepOneSelect from './StepOneSelect';
import StepTwoOptions from './StepTwoOptions';
import StepThreePayment from './StepThreePayment';
import StepFourThankYou from './StepFourThankYou';
import { hostNameAndPort } from '../../../util/vars';
import Axios from 'axios';

class advertiseModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            modalActive: [true, false, false, false],
            websiteSection: '',
            originalWebsiteSection: '',
            isFromCarsDB: false,
            category: '',
            startDate: '',
            endDate: '',
            promoType: '',
            promoCharge: 0,
            stripeToken: '',
            gpaymentId: '',
            paymentSent: false,
            confirmationNumber: 0,
            errorMessage: '',
            itemId: '',
        };
    }

    componentDidMount() {
        this.setState(this.initialState);
        let t = this.props.itemType;
        this.setState({
            itemId: this.props.itemId,
            websiteSection: t,
            originalWebsiteSection: t,
            isFromCarsDB: this.props.isFromCarsDB || false,
            isFromRealestateDB: this.props.isFromRealestateDB || false
        });
    }

    componentDidUpdate() {
        if (this.props.itemId !== this.state.itemId) {
            let t = this.props.itemType;
            this.setState({
                itemId: this.props.itemId,
                websiteSection: t,
                originalWebsiteSection: t,
                isFromCarsDB: this.props.isFromCarsDB || false,
                isFromRealestateDB: this.props.isFromRealestateDB || false
            });
        }
        let t = this.state;
        if (!(t.promoCharge && t.stripeToken) && !(t.promoCharge && t.gpaymentId)) {
            return;
        }

        if (!t.confirmationNumber && !t.paymentSent) {
            this.setState({ paymentSent: true });

            let originalWebsiteSection = t.originalWebsiteSection;
            if (originalWebsiteSection === 'autos' && t.isFromCarsDB)
                originalWebsiteSection = 'cars';
            if (originalWebsiteSection === 'realestate' && !t.isFromRealestateDB)
                originalWebsiteSection = 'realestate-old';
                
            let _data = new FormData();
            //_data.append('userId', this.props.loggedInUser.userId);
            _data.append('promoType', t.promoType);
            _data.append('websiteSection', t.websiteSection);
            _data.append('originalWebsiteSection', originalWebsiteSection);
            _data.append('isFromCarsDB', t.isFromCarsDB);
            _data.append('isFromRealestateDB', t.isFromRealestateDB);
            _data.append('category', t.category);
            _data.append('chargePerDay', JSON.stringify(t.chargePerDay));
            _data.append('startDate', t.startDate);
            _data.append('endDate', t.endDate);
            _data.append('gpaymentId', t.gpaymentId.id || '');
            _data.append('stripeToken', t.stripeToken.id || '');
            _data.append('itemId', t.itemId);
            _data.append('amount', t.promoCharge);
            let options = {
                url: `${hostNameAndPort}/api/pay/adv`,
                method: 'post',
                data: _data,
            }
            Axios(options)
                .then(result => {
                    let { data } = result;
                    if (!data.error) {
                        this.setState({ confirmationNumber: data.confirmationNumber });
                    }
                    else {
                        this.setState({ confirmationNumber: -1, errorMessage: data.error });
                    }
                })
                .catch(err => {
                    this.setState({ confirmationNumber: -1, errorMessage: 'Network error ERRFEADVERTMD01' });
                });
        }
    }

    onClickCloseHandler = () => {
        this.setState(this.initialState);
        this.props.onCloseHandler();
    }

    activateNextModal = () => {
        let k = this.state.modalActive.indexOf(true);
        let n = this.state.modalActive.length;
        let modalActive;
        if (k + 1 < n) {
            modalActive = Array(n).fill(false);
            modalActive[k + 1] = true;
            this.setState({ modalActive });
        }
        else {
            // Reset everything
            this.setState(this.initialState);
        }
    }

    onReceiveUserInfo = (t) => {
        this.setState(t);
    }

    render() {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="modal-wrapper">
                <Backdrop show={this.props.active} clicked={this.onClickCloseHandler} />
                <div className="modal">
                    <StepOneSelect
                        active={this.state.modalActive[0]}
                        onMoveForward={this.activateNextModal}
                        onSubmitUserInfo={this.onReceiveUserInfo}
                        onCloseModal={this.onClickCloseHandler}
                        itemType={this.state.websiteSection}
                        itemId={this.state.itemId}
                        isFromCarsDB={this.state.isFromCarsDB}
                        isFromRealestateDB={this.state.isFromRealestateDB}
                    />
                    <StepTwoOptions
                        active={this.state.modalActive[1]}
                        onMoveForward={this.activateNextModal}
                        onSubmitUserInfo={this.onReceiveUserInfo}
                        promoType={this.state.promoType}
                        websiteSection={this.state.websiteSection}
                        itemId={this.state.itemId}
                    />
                    <StepThreePayment {...this.state}
                        active={this.state.modalActive[2]}
                        onMoveForward={this.activateNextModal}
                        onSubmitUserInfo={this.onReceiveUserInfo}
                    />
                    <StepFourThankYou
                        active={this.state.modalActive[3]}
                        onCloseModal={this.onClickCloseHandler}
                        confirmationNumber={this.state.confirmationNumber}
                        errorMessage={this.state.errorMessage} />

                    <span className="modal_close" onClick={this.onClickCloseHandler}><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateToAccountInformationModalProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        active: state.advertiseModalActive,
        itemType: state.advertiseItemType,
        itemId: state.advertiseItemId,
        isFromCarsDB: state.advertiseIsFromCarsDB,
        isFromRealestateDB: state.advertiseIsFromRealestateDB
    };
};

const mapDispatchToAccountInformationModalProps = (
    dispatch
) => {
    return {
        onCloseHandler: () => {
            dispatch({ type: actionType.ADVERTISE_MODAL, advertiseModalActive: false });
        },
    };
};

export default connect(mapStateToAccountInformationModalProps, mapDispatchToAccountInformationModalProps)(advertiseModal);