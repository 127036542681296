import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {titleCase} from '../../../shared/util/string';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';


class BlogFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
            <div className="featured-slider_slide">
                <div className="featured-slider_slide_image">
                    <img src={v.featuredImage} alt={v.title} />
                </div>

                <div className="featured-slider_slide_details">
                    <h1>{v.title}</h1>

                    <div className="featured-slider_slide_details_meta">
                        {getTranslatedPhrase(v.userLanguage,'in')}{' '}
                        {getTranslatedPhrase(v.userLanguage,titleCase(v.category.replace(/-/g, ' ')))} / {getTranslatedPhrase(v.userLanguage,'by')} {v.authorName}
                    </div>

                    <div className="featured-slider_slide_details_desc">
                        {v.blogContent.substring(0, 100)}
                    </div> 

                    <div className="featured-slider_slide_details_engage">
                        <EngageBox 
                            websiteSection={"blogs"}
                            itemId={v.itemId}
                            authorId={v.authorId}
                            withComments={true}
                            hideAllComments={true}
                            isDisabled={true}
                        />
                    </div>

                    <Link to={'/blogs/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug} className="button button-regular">{getTranslatedPhrase(v.userLanguage,'Read More')}</Link>
                </div>
                <div className="clb" />
            </div>
        );
    }
}


const mapStateToBlogFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToBlogFeaturedPreviewProps, null)(BlogFeaturedPreview);
