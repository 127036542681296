import axios from 'axios';
import { hostNameAndPort } from "../../../util/vars";
import { getTranslatedPhrase } from "../../../util/lang";

function userLanguage() {
    let cookie = document.cookie;
    if (cookie === undefined || !/\s*userLanguage\s*=\s*.*(;\s*|$)/.test(cookie)) {
        //document.cookie = `userLanguage()=${this.state.userLanguage()};max-age=900000;http-only=true`;
        return '';
    } else {
        let a = document.cookie;
        let v = a.substring( a.indexOf('userLanguage=')+13 );
        if (v.indexOf(';') >= 0)
            v = v.substring( 0, v.indexOf(';') ).trim();
        return v;
    }
}

class ShareItem {
    static ShareOnFollowone({  websiteSection, itemId, itemAuthorId  }) {
        //console.log(`share on followone ${websiteSection} with id ${itemId}`);
        return new Promise( (resolve, reject) => {
            
            let shareData = new FormData();
            shareData.append(`itemId`, itemId);
            shareData.append(`itemAuthorId`, itemAuthorId);
            let options = {
                url: `${hostNameAndPort}/api/engage/addshare/${websiteSection}`,
                method: 'post',
                data: shareData,
            };
            
            axios(options)
            .then(result => {
                let {data} = result;
                if (!data.error) {           
                    alert(getTranslatedPhrase(userLanguage(),'Your item has been shared'));
                    resolve(true);
                } else {
                    return reject(false);
                }
            })
            .catch(err => {
                return reject(false);
            });
        });
    }

    static ShareOnFacebook({  websiteSection, itemId, itemAuthorId }) {
        // alert(getTranslatedPhrase(userLanguage(),'Feature coming soon'));
        //console.log(`share on facebook ${websiteSection} with id ${itemId}`);
    }

    static ShareOnTwitter({  websiteSection, itemId, itemAuthorId }) {
        // alert(getTranslatedPhrase(userLanguage(),'Feature coming soon'));
        //console.log(`share on twitter ${websiteSection} with id ${itemId}`);
    }

    static ShareOnPinterest({  websiteSection, itemId, itemAuthorId }) {
        // alert(getTranslatedPhrase(userLanguage(),'Feature coming soon'));
        //console.log(`share on pinterest ${websiteSection} with id ${itemId}`);
    }

    static ShareOnEmail({  websiteSection, itemId, itemAuthorId }) {
        // alert(getTranslatedPhrase(userLanguage(),'Feature coming soon'));
        //console.log(`share on email ${websiteSection} with id ${itemId}`);
    }

    static ShareCopyLink({  shareLink  }) {
        // Create new element
        // var el = document.createElement('textarea');
        // // Set value (string to be copied)
        // el.value = shareLink
        // // Set non-editable to avoid focus and move outside of view
        // el.setAttribute('readonly', '');
        // el.style = {position: 'absolute', top: '-9999px'};
        // document.body.appendChild(el);
        // // Select text inside element
        // el.select();

        // var el2 = document.createElement('div');
        // // Change style or id of element
        // let el2Id = 'Link Copied!';
        // el2.className = 'link-copied';
        // el2.innerText = shareLink
        // // Set non-editable to avoid focus and move outside of view
        // el2.setAttribute('readonly', '');
        // el2.style = {position: 'absolute', top: '10px'};
        // document.body.appendChild(el2);
        // console.log(el2.innerText)


        // Copy text to clipboard
        // setTimeout(() => {
        //     let timeStart = Date.now();
        //     while (true) {
        //         let timeNow = Date.now();
        //         if (timeNow - timeStart > 1500 && timeNow - timeStart < 2500) {
        //             document.execCommand('copy');
        //             // console.log('Copied!')
        //             // navigator.clipboard.readText()
        //             // .then(clipText => {
        //             //     // We're not adding clipText to innerText
        //             //     // document.querySelector(`#${el2Id}`).innerText += clipText

        //             //     // You have to write the word 'Copied!' that will last for one second
        //             //     console.log(document.querySelector(`#${el2Id}`));
        //             // })
        //             // .catch(err => {
        //             //     console.log(err);
        //             // });
        //             break;
        //         }
        //     }
        // }, 500);
        // Remove temporary element
        // document.body.removeChild(el);
    }
}

export default ShareItem;