import Axios from "axios";
import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BackgroundImage from "../../../assets/home/home-search-realestate.jpg";
import actionType from "../../../redux-store/action-type";
import AdvRealEstateSearch from "../../../shared/components/Modals/Search/AdvRealEstate";
import { getTranslatedPhrase } from '../../../shared/util/lang';
// import { titleCase } from "../../../shared/util/string";
import { hostNameAndPort } from "../../../shared/util/vars";
import Modal from "../../../shared/uielements/Modal/Modal";


const RealEstateSearch = props => {
    const [category, setCategory] = useState("residential");
    const [subcategory, setSubcategory] = useState("");
    const [rentOrSell, setRentOrSell] = useState("sell");
    const [address, setAddress] = useState("");
    const [cityMissing, setCityMissing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [copyrightModal, setCopyrightModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [stateVar, setStateVar] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [priceAscending, setPriceAscending] = useState(0);
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [floorSizeMin, setFloorSizeMin] = useState('');
    const [floorSizeMax, setFloorSizeMax] = useState('');
    const [viewsDescending, setViewsDescending] = useState(false);
    const [withCoupons, setWithCoupons] = useState(false);
    const [rating, setRating] = useState(0);

    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);
    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const windowLocationSearch = window.location.search;

    async function getCategories() {
        let url = `${hostNameAndPort}/api/realestate/categories`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                setCategoryList(data.categories);
            }
        } catch (err) {
            // server, network error
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    const categoryListLength = categoryList.length;

    useEffect(() => {
        onChangeItem(category, 'category');
        onChangeItem(subcategory, 'subcategory');
    }, [categoryListLength]);




    function onChangeCategory(c) {

        if (c === 'none' || !c) {
            setSubcategoryList([]);
            return;
        }
        let i = 0;
        let s = [];
        while (i < categoryList.length && categoryList[i] !== c) i++;
        for (i++; i < categoryList.length && /^\s*-+/.test(categoryList[i]); i++) s.push(categoryList[i].replace(/^\s*-+\s*/, ''));
        setSubcategoryList(s);
    }

    function checkLogin() {
        if (!loggedInUser) props.onClickLoginButtonHandler("/realestate/new-property");
        else props.history.push("/realestate/new-property");
    }

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;

    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                subcategory,
                rentOrSell,
                address,
                country,
                stateVar,
                city,
                zipCode,
                priceAscending,
                priceMin,
                priceMax,
                floorSizeMin,
                floorSizeMax,
                viewsDescending,
                withCoupons,
                rating
            };



            if (!ts.category) {
                ts.category = 'residential';
            }

            for (let i of Object.keys(ts)) onChangeItem('', i);

            setCountry(currentCountry);
            if (!!props.category) props.history.push('/realestate');
        }
    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {
            const ts = {
                category,
                subcategory,
                rentOrSell,
                address,
                country,
                stateVar,
                city,
                zipCode,
                priceAscending,
                priceMin,
                priceMax,
                floorSizeMin,
                floorSizeMax,
                viewsDescending,
                withCoupons,
                rating
            };

            if (!ts.category) {
                ts.category = 'residential';
            }


            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            return;
        }
        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';
        if (category !== cat) {


            if (!cat) {
                cat = 'residential';
            }


            onChangeItem(cat, 'category');
            // setCategory(cat);
            // onChangeCategory(cat);
        }

        if (subcategory !== subcat) setSubcategory(subcat);

        const ts = {
            rentOrSell,
            address,
            country,
            stateVar,
            city,
            zipCode,
            priceAscending,
            priceMin,
            priceMax,
            floorSizeMin,
            floorSizeMax,
            viewsDescending,
            withCoupons,
            rating
        };

        let changeState = false;

        const ns = {
            rentOrSell: urlParams.get('rentOrSell') || 'sell',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || '',
            priceAscending: urlParams.get('priceAscending'),
            priceMin: urlParams.get('priceMin'),
            priceMax: urlParams.get('priceMax'),
            floorSizeMin: urlParams.get('setFloorSizeMin') || '',
            floorSizeMax: urlParams.get('setFloorSizeMax') || '',
            viewsDescending: urlParams.get('viewsDescending'),
            withCoupons: urlParams.get('withCoupons'),
            rating: urlParams.get('rating')
        };

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        if (ns.rentOrSell !== ts.rentOrSell || ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode || ns.floorSizeMax !== ts.floorSizeMax || ns.floorSizeMin !== ts.floorSizeMin)
            changeState = true;

        if (changeState) {
            for (let i of Object.keys(ns)) onChangeItem(ns[i], i);
            if (!ns.address) onChangeItem(ns.city + ', ' + ns.stateVar, 'address');
            // if (ns.category === 'business-opportunities') onChangeItem('', 'rentOrSell');
        }
    }, [windowLocationSearch]);

    function onChangeItem(_value, _prop, regularChange) {
        let changeNow = {
            country: setCountry,
            stateVar: setStateVar,
            city: setCity,
            zipCode: setZipCode,
            priceAscending: setPriceAscending,
            priceMin: setPriceMin,
            priceMax: setPriceMax,
            floorSizeMin: setFloorSizeMin,
            floorSizeMax: setFloorSizeMax,
            viewsDescending: setViewsDescending,
            withCoupons: setWithCoupons,
            rating: setRating
        };
        if (_prop === "category") {


            if (category === _value && regularChange) _value = 'residential';
            // if (_value === 'business-opportunities') onChangeItem('', 'rentOrSell');



            setCategory(_value);
            setSubcategory('');

            onChangeCategory(_value);
            return;
        }
        if (_prop === 'subcategory') {
            if (!category && !!_value) {
                let bussOpsCategories = ["Food and Beverage", "Gas Station", "Grocery", "Hospitality", "Manufacturing", "Other", "Professional Practice", "Retail", "Service", "Wholesale"];
                let residentialCategories = ["Apartments", "Duplexes", "Houses", "Mobile Homes", "Townhomes"];
                let nextCategory = 'commercial';
                if (bussOpsCategories.indexOf(_value) >= 0) nextCategory = 'business-opportunities';
                else if (residentialCategories.indexOf(_value) >= 0) nextCategory = 'residential';
                setCategory(nextCategory);
                onChangeCategory(nextCategory);
            }
            setSubcategory(_value);
            return;
        }
        if (_prop === "rentOrSell") {
            if (rentOrSell === _value && !!regularChange) setRentOrSell("sell");
            else setRentOrSell(_value);
            return;
        }
        if (_prop === "address" && _value.length < 500) {
            if (!_value.length) {
                changeNow.stateVar('');
                changeNow.city('');
            }
            onChangeAddress(null, { newValue: _value });
            return;
        }
        changeNow[_prop](_value);
    }

    function onChangeAddress(event, { newValue }) {
        if (/\\/g.test(newValue)) return;
        if (/^ +/.test(newValue)) newValue = newValue.replace(/^ +/, '');
        if (address.length < 2 && newValue.length > 1 && !cityList.length && !/^[0-9]+/.test(newValue)) getCityList(newValue.substring(0, 2));
        if (newValue.length < 2) {
            if (cityList.length)
                setCityList([]);
            setCityMissing(false);
        }
        if (/^, ?/.test(newValue)) {
            setCityMissing(true);
            newValue = newValue.replace(/^, ?/, '');
        }
        setAddress(newValue);
    }

    async function getCityList(value) {
        try {

            let localCountry;

            if (!country) {
                if (document.cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
                    let data = document.cookie.match(/fo_userCountry=([^;]*)(;|$)/);
                    localCountry = data[1] || 'USA';
                }
            } else {
                localCountry = country;
            }

            let result = await Axios(hostNameAndPort + '/api/cities/options-for/' + localCountry + '/' + value);
            let { data } = result;
            if (!data.error) {
                setCityList(data.items);
            } else setCityList([]);
        } catch (error) {
            setCityList([]);
        }
    }

    function getSuggestions(value = '') {
        if (/\\/g.test(value)) return cityList;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        let a = cityList;
        let r;
        if (value.length && value[value.length - 1] === ' ') {
            let v = value.substring(0, value.length - 1);
            r = new RegExp('^' + v + ',?', 'i');
        } else r = new RegExp('^' + value, 'i');
        a = a.filter(v => r.test(v));

        return inputLength === 0 ? [] : a;
    }

    function getSuggestionValue(suggestion) {
        return suggestion;
    }

    function renderSuggestion(suggestion) {
        return (
            <div>
                {suggestion}
            </div>
        );
    }

    function onSuggestionsFetchRequested({ value }) {
        setSuggestions(getSuggestions(value));
    }

    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function onSubmitRealEstateForm(e, s) {
        e.preventDefault();

        let f = "filters-" + Date.now();
        // Andrei Check - price range is still in the url, do we need to remove from there?
        // let priceRange = (priceMin && priceMin !== '0') || (priceMax && priceMax !== '0');
        let residential = (category === 'residential') ? 1 : (category === 'commercial') ? 2 : (category === 'business-opportunities') ? 3 : 0;

        let a = address;
        let realAddress = a;
        if (cityMissing) {
            realAddress = '';
            a = ', ' + a;
        }
        a = a.split(',');
        let city = "";
        let zipCode = "";
        let state = "";
        if (a.length === 2) {
            city = a[0].trim();
            state = a[1].trim();
            realAddress = '';
        } else if (a.length === 1) {
            a = a[0].trim();
            if (/^[-a-z' ]+$/.test(a.toLowerCase())) city = a;
            else if (/^[-a-z0-9]+$/.test(a.toLowerCase())) zipCode = a;
            else realAddress = a;
        }
        let s_q = JSON.parse(searchQuery || '{}');
        if (!state && country === s_q.country) state = s_q.region;


        if (!s) {
            props.history.push(`/realestate/search/${f}?priceAscending=0&priceRange=&priceMin=&priceMax=&viewsDescending=false&withCoupons=&rating=&residential=${residential}&address=${realAddress}&
city=${city}&zipCode=${zipCode}&state=${state}&country=${country}&yearBuiltMin=&yearBuiltMax=&rentOrSell=${rentOrSell}&bathroomCount=&bedroomCount=&category=${category}&subcategory=${subcategory}&keywords=${f}`);
        } else {
            s = s.replace(/filters-[0-9]*/g, f);
            props.history.push(s);
        }
    }

    const advancedSearchHandler = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const onCopyrightModal = () => {
        setCopyrightModal(!copyrightModal)
    }

    const inputProps = {
        placeholder: getTranslatedPhrase(userLanguage, 'Type a city, state.'),
        value: address,
        onChange: onChangeAddress
    };


    const allSubCategories = categoryList.map(v => v.replace(/^\s*-+\s*/, '')).filter(item => item !== "residential" && item !== "commercial" && item !== "business-opportunities").sort();


    return (
        <>
            {modalOpen && <AdvRealEstateSearch closeModal={closeModal} {...props} onSubmitHandler={onSubmitRealEstateForm} />}

            <div className="home-search-box real-estate-search-box" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="hsb-background" />
                <div className="hsb-container">
                    <h2>Real Estate</h2>
                    <form onSubmit={onSubmitRealEstateForm}>
                        <div className="hsb-filter-group">
                            <div className="hsb-filter">
                                <input type="radio" name="type" id="residential" checked={category === "residential"} onChange={() => null} /> <label htmlFor="residential" onClick={e => onChangeItem("residential", "category", true)}>{getTranslatedPhrase(userLanguage, 'Residential')}</label>
                                <input type="radio" name="type" id="commercial" checked={category === "commercial"} onChange={() => null} /> <label htmlFor="commercial" onClick={e => onChangeItem("commercial", "category", true)}>{getTranslatedPhrase(userLanguage, 'Commercial')}</label>
                                <input type="radio" name="type" id="business-opportunities" checked={category === "business-opportunities"} onChange={() => null} /> <label htmlFor="business-opportunities" onClick={e => onChangeItem("business-opportunities", "category", true)}>{getTranslatedPhrase(userLanguage, 'Biz Opportunities')}</label>
                            </div>
                        </div>

                        <div className="date-flex">

                            {category !== 'business-opportunities' &&
                                <div className="hsb-input hsb-input-realestate-select mgb-sm">
                                    <select className="form-input-full" name="transaction" id="transaction" value={rentOrSell} onChange={e => onChangeItem(e.target.value, "rentOrSell")}>
                                        {/* <option value="">{getTranslatedPhrase(userLanguage, 'Buy/Rent')}</option> */}
                                        <option value="sell">{getTranslatedPhrase(userLanguage, 'Buy')}</option>
                                        <option value="rent">{getTranslatedPhrase(userLanguage, 'Rent/Lease')}</option>
                                    </select>
                                </div>
                            }

                            <div className="hsb-input hsb-input-realestate-select mgb-sm" style={{ flex: category === 'business-opportunities' ? 1 : '' }}>
                                <select className="form-input-full" name="searchType" id="searchType" value={subcategory} onChange={e => onChangeItem(e.target.value, "subcategory")}>
                                    <option value="">{getTranslatedPhrase(userLanguage, 'Type')}</option>
                                    {!category &&
                                        allSubCategories.map((v, i) => (<option key={v + i} value={v}>{getTranslatedPhrase(userLanguage, v.replace(/_/g, '/'))}</option>))
                                    }

                                    {category &&
                                        subcategoryList.map((v, i) => (<option key={v + i} value={v}>{getTranslatedPhrase(userLanguage, v.replace(/_/g, '/'))}</option>))
                                    }
                                </select>
                            </div>
                        </div>


                        <div className="hsb-input hsb-input-realestate">
                            {/* <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Enter an address, city or ZIP code.")} value={address} onChange={e => onChangeItem(e.target.value, "address")} /> */}
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                        </div>

                        <div className="hsb-buttons">
                            <div>
                                <button className="button button-white" type="submit" onSubmit={onSubmitRealEstateForm}>{getTranslatedPhrase(userLanguage, 'Search')}</button>
                                {props.category && <button className="button button-white" type="button" onClick={advancedSearchHandler}>{getTranslatedPhrase(userLanguage, 'Advanced Search')}</button>}
                            </div>
                            <div>
                                <Link to="/realestate">{props.category ? getTranslatedPhrase(userLanguage, "Real Estate Home") : getTranslatedPhrase(userLanguage, "Home")}</Link> /
                                <button style={{ fontSize: "1.4rem", color: "#fff", marginLeft: "1rem" }} onClick={checkLogin}>{getTranslatedPhrase(userLanguage, 'Add Properties')}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="hsb-copyright" onClick={onCopyrightModal}>Powered by <strong>Followone Real Estate</strong></div>
            </div>

            {copyrightModal && <Modal
                title="Real Estate Licenses"
                closeModal={onCopyrightModal}
            >
                <div className="real-estate-brokerage">
                    <p>Followone Real Estate Inc. has a real estate brokerage license in the following states:</p>

                    <table>
                        <thead>
                            <tr>
                                <td>State</td>
                                <td>License No.</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Washington</td>
                                <td>20117919</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>}
        </>
    );
};

const mapDispatchToRealEstateSearchProps = dispatch => {
    return {
        onClickLoginButtonHandler: (returnUrl) => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl })
    };
};

export default connect(null, mapDispatchToRealEstateSearchProps)(withRouter(RealEstateSearch));