import React, { Component } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

class PhotoAreaPreview extends Component {
    render() {
        // Expecting an array with elements
        // { file: File, imagePreviewUrl: String, className: String }
        if (!this.props.photoList) return null;
        return (<div className="createNewBoxContent newPhotosAdded">
            <ResponsiveMasonry columnsCountBreakPoints={{350: 3}} gutter="10px" >
                <Masonry>
                    {this.props.photoList.map( (v,j) => (<div key={j} onClick={(e) =>this.props.onClick(j,'select')}>
                        <img className={v.className} src={v.imagePreviewUrl} alt={v.imageName} /><div className="featured-image-text" />
                            <button onClick={(e) =>this.props.onClick(j,'remove')}><i className="fal fa-times" /></button>
                            </div>) )}
                </Masonry>
            </ResponsiveMasonry>
        </div>);
    }
}

export default PhotoAreaPreview;
