import React, { Component } from 'react';
import Axios from 'axios';
import Box from '../../../util/box';
import { hostNameAndPort } from '../../../util/vars';
import { getTranslatedPhrase } from '../../../util/lang';
import {connect} from 'react-redux';

class ListingPolicies extends Component {
    state = {
        policyUserId: '',
        policies: {}
    }

    componentDidMount() {
        this.getPolicyDataFromServer();
    }

    componentDidUpdate() {
        if (this.state.policyUserId !== this.props.policyUserId) {
            this.setState({ policyUserId: this.props.policyUserId });
            this.getPolicyDataFromServer();
        }
    }

    getPolicyDataFromServer = async () => {
        let u = this.props.policyUserId;
        if (!u) {
            return;
        }
        let url = `${hostNameAndPort}/api/user/policies/${u}`;
        try {
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                this.setState({ policies: data.policies });
            }
        } catch(err) {
            // Network error
        }
    }


    render () {
        if (!this.props.show || !this.state.policyUserId) {
            return null;
        }

        let t = Object.keys(this.state.policies);
        let policiesNotEmpty = t.reduce( (a,v) => (a || this.state.policies[v]), false);

        return (!policiesNotEmpty)?null:(
            <div className="ProductDetailBox ProductDetailPolicies">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Policies')}</h4>

                {Object.keys(this.state.policies).map( v => (<Box key={v}><p><strong>{getTranslatedPhrase(this.props.userLanguage,v)}</strong></p>
                    <p>{this.state.policies[v] ? this.state.policies[v] : getTranslatedPhrase(this.props.userLanguage,"No policy listed.")}</p></Box>) 
                )}
               
            </div>
        );
    }
}

const mapStateToListingPoliciesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToListingPoliciesProps, null)(ListingPolicies);