import React, { useState, Suspense, lazy } from 'react';
import AutosNewPreview from './AutosNewPreview';
import AutosSideBar from './AutosSideBar';
import AutosSideBarMobile from './AutosSideBarMobile';
import AutosFeatured from './AutosFeatured';
import AutoHeader from '../../Home/Components/Auto';
import feaImage from '../../../assets/category/followone_cat_feature_auto.jpg';
const CategoryPageAutos = lazy(() => import('./category-page-autos'));

function CategoryPage(props) {
  return <Suspense fallback="Loading..."><CategoryPageAutos {...props} /></Suspense>;
}

function AutosNewCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <AutoHeader category section="autos" categoryList={categoryList} />
      </div>

      <CategoryPage
        section="autos"
        description="View Cars, Trucks, and Other Automobiles"
        ItemPreview={AutosNewPreview}
        SideBar={(props) => (<AutosSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<AutosSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={AutosFeatured}
      />
    </>
  );
}

export default AutosNewCategory;