import React, { useState, useEffect } from 'react';
import ReviewsTab from '../../../uielements/ReviewsTab/ReviewsTab';
import EngageButtons from '../../../uielements/Engage/EngageButtons/EngageButtons';
import Rate from '../../../uielements/Engage/EngageButtons/RateButton';
import { hostNameAndPort } from '../../../util/vars';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../util/lang';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';


function ListingReviews(props) {
    const [reviews, setReviews] = useState(props.reviewsList);
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(0);
    const [ratingClass, setRatingClass] = useState(Array(5).fill('fal fa-star'));
    const [userId, setUserId] = useState(props.authorId);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstTime, setFirstTime] = useState(true);

    const userLanguage = useSelector(state => state.userLanguage);
    const userIsAuthenticated = useSelector(state => state.userIsAuthenticated);
    const loggedInUser = useSelector(state => state.loggedInUser)

    const { authorId, itemId, websiteSection, reviewsList } = props;


    useEffect(() => {
        return function () {
            setReviews([]);
            setFirstTime(true);
        }
    }, [])

    useEffect(() => {
        if (reviewsList.length !== reviews.length || (!reviews.length && !reviewsList.length && firstTime)) {
            setUserId(authorId);
            setReviews(reviewsList);
            setFirstTime(false);
        } else if (reviews && reviewsList) {
            let i = 0;
            while (i < reviewsList.length) {
                if (reviews[i].review !== reviewsList[0].review ||
                    reviews[i].fromUserId !== reviewsList[0].fromUserId ||
                    reviews[i].reviewDate !== reviewsList[0].reviewDate) break;
                i++;
            }
            if (i < reviewsList.length) {
                setUserId(authorId);
                setReviews(reviewsList);
                setFirstTime(false);
            }
        }
    }, [authorId, reviewsList])

    const onChangeRatingHandler = (n) => {
        let a = ratingClass;
        a.forEach((v, i) => (i < n) ? (a[i] = 'fas fa-star') : (a[i] = 'fal fa-star'));
        setRatingClass(a);
        setRating(n);
    }

    const onChangeReviewTextHandler = (e) => {
        if (e.target.value.length > 1000) {
            return;
        }
        setReviewText(e.target.value);
    }

    const onPostReviewHadler = () => {
        let t = reviewText;
        let r = rating;
        let err = '';

        setErrorMessage('');
        if (!r) {
            err = 'Please choose rating';
        }
        if (!t) {
            err = 'Please leave a few lines';
        }
        if (err !== '') {
            setErrorMessage(err);
            return;
        }
        onChangeRatingHandler(0);
        setReviewText('');

        onAddReview({ reviewText: t, rating: r });
        if (!!props.onUpdateReviews) props.onUpdateReviews(r);
    }

    const onAddReview = async (r) => {
        let reviewDate = new Date();

        let oneReview = {
            itemId,
            review: reviewText,
            rating,
            reviewDate,
            itemAuthorId: authorId,
            fromUserName: loggedInUser.userName === 'Unknown User' ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : loggedInUser.userName,
        };

        let a = [oneReview, ...reviews]
        
        setReviews(a);

        let w = websiteSection;
        let itemData = new FormData();
        itemData.append('itemId', itemId);
        itemData.append('review', r.reviewText);
        itemData.append('rating', r.rating);
        itemData.append('reviewDate', reviewDate);
        itemData.append('itemAuthorId', authorId);
        let options = {
            url: `${hostNameAndPort}/api/engage/addreview/${w}`,
            method: 'post',
            data: itemData,
        };
        try {
            let result = await axios(options);
            let { data } = result;
            if (!data.error) {
                // No error!
            }
        } catch (err) {
            //
        }
    }


    if (!props.show) {
        return null;
    }

    return (
        <div className="ProductDetailBox ProductDetailReviews" id="reviews">
            <h4>{getTranslatedPhrase(userLanguage, 'Reviews')}</h4>

            {!userIsAuthenticated ? null : (
                <div className="write-review-box">

                    <p><strong>{getTranslatedPhrase(userLanguage, 'WRITE YOUR REVIEW')}</strong></p>

                    <textarea
                        value={reviewText}
                        onChange={onChangeReviewTextHandler}
                        className="form-input-textarea mgb-md"
                        placeholder={getTranslatedPhrase(userLanguage, 'Your review starts here') + '...'}>
                    </textarea>

                    <p><strong>{getTranslatedPhrase(userLanguage, 'WHAT\'S YOUR RATING?')}</strong></p>

                    <EngageButtons>
                        <span className="engage_buttons_rating">
                            {ratingClass.map((v, j) => (<button key={j}
                                onClick={() => onChangeRatingHandler(j + 1)}>
                                <i className={v} />
                            </button>)
                            )}
                        </span>
                        {/* <Rate noStars={rating} /> */}
                    </EngageButtons>

                    <div className="txtl"><FormFieldError>{getTranslatedPhrase(userLanguage, errorMessage)}</FormFieldError></div>
                    <button
                        className="button button-regular button-small mgt-md"
                        onClick={onPostReviewHadler}>{getTranslatedPhrase(userLanguage, 'Post Review')}</button>
                </div>)}

            <LoadingSvg active={props.isLoading} />


            {reviews.map((v, i) => (<ReviewsTab
                key={i}
                theReviewText={v.review}
                theReviewAuthor={v.fromUserName}
                theReviewDate={moment(v.reviewDate).local().format('LLL')}
            > <Rate noStars={v.rating} withoutNumber={true} />
            </ReviewsTab>)
            )}
        </div>
    );
}


export default ListingReviews;
