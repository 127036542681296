import React from 'react';
import AppRoutes from './AppRoutes';
import Axios from 'axios';
Axios.defaults.withCredentials = true;


function AppMain(props) {
  return (
    <main>
      <AppRoutes />
      <div className="clb" />
    </main>
  );
}

export default AppMain;