export function formatPrice(n, delim) {
    if (!Number(n)) {
        return n;
    }
    let res = '';
    let n1 = '' + n;
    let i = n1.indexOf('.');
    if (i < 0) {
        i = n1.length - 1;
    }
    else {
        res = n1.substring(i, n1.length);
        i--;
    }
    let k = i;
    while (i >= 0) {
        res = n1[i] + res;
        i--;
        if (!((k - i) % 3) && (i >= 0)) {
            res = delim + res;
        }
    }
    if (!res) {
        res = n1;
    }
    let numberParts = res.split('.');
    if (numberParts.length === 1) { }//res = res + '.00';
    else if (numberParts.length === 2) {
        if (numberParts[1].length === 0) res = res + '.00';
        else if (numberParts[1].length === 1) res = res + '0';
        else {
            numberParts[1] = numberParts[1].substring(0, 2);
            res = numberParts.join('.');
        }
    }
    return res;
}

export function formatPriceAndCurrency(a, b, c, d) {
    let r = (!d) ? '' : ` / ${d}`;
    let y = formatPrice(a, c);
    let x;
    switch (b) {
        case 'USD':
            x = '$' + y;
            break;
        case 'EUR':
            x = '€' + y;
            break;
        case 'JPY':
            x = '¥' + y;
            break;
        case 'GBP':
            x = '£' + y;
            break;
        case 'AUD':
            x = 'A$' + y;
            break;
        case 'CAD':
            x = 'C$' + y;
            break;
        case 'CNY':
            x = '元' + y;
            break;
        case 'HKD':
            x = 'HK$' + y;
            break;
        case 'NZD':
            x = 'NZ$' + y;
            break;
        case 'SEK':
            x = 'kr' + y;
            break;
        case 'SGD':
            x = 'SG$' + y;
            break;
        case 'NOK':
            x = 'kr' + y;
            break;
        case 'MXN':
            x = '$' + y;
            break;
        case 'INR':
            x = '₹' + y;
            break;
        case 'RUB':
            x = '₽' + y;
            break;
        case 'ZAR':
            x = 'R' + y;
            break;
        case 'TRY':
            x = '₺' + y;
            break;
        case 'BRL':
            x = 'R$' + y;
            break;
        case 'TWD':
            x = 'NT$' + y;
            break;

        case 'DKK':
            x = 'kr' + y;
            break;
        case 'PLN':
            x = 'zł' + y;
            break;
        case 'THB':
            x = '฿' + y;
            break;
        case 'IDR ':
            x = 'Rp' + y;
            break;
        case 'HUF':
            x = 'Ft' + y;
            break;
        case 'CZK':
            x = 'Kč' + y;
            break;
        case 'ILS':
            x = '₪' + y;
            break;

        case 'CLP':
            x = 'CLP$' + y;
            break;
        case 'PHP':
            x = '₱' + y;
            break;
        case 'AED':
            x = 'د.إ' + y;
            break;
        case 'COP':
            x = 'COL$' + y;
            break;
        case 'SAR':
            x = '﷼' + y;
            break;
        case 'MYR':
            x = 'RM' + y;
            break;
        default:
            x = y + b
            break;
    }

    return x + r;
}

export function displayPriceValue(value) {
    if (isNaN(value) || value === '') return '';
    if (value === '0' || value === 0) return '0';
    value = String(value).split('').reverse();
    let result = [];
    for (let i = 0; i < value.length; i++) {
        if (i === 2) result.push('.');
        else if ((i > 2) && (i % 3 === 2)) result.push(',');
        result.push(value[i]);
    }
    if (result.length > 1)
        while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
            result.pop();
    if (value.length && value.length <= 2) result.push('.', '0');
    result = result.reverse().join('');
    return result;
}


export function displayNumber(value) {
    if (isNaN(value) || value === '') return '';
    if (value === '0' || value === 0) return '0';
    value = value * 100;
    value = String(value).split('').reverse();
    let result = [];
    for (let i = 0; i < value.length; i++) {
        if (i === 2) result.push('.');
        else if ((i > 2) && (i % 3 === 2)) result.push(',');
        result.push(value[i]);
    }
    if (result.length > 1)
        while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
            result.pop();
    if (value.length && value.length <= 2) result.push('.', '0');
    result = result.reverse().join('');
    result = result.replace('.00', '')
    return result;
}