import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import PageNumbers from '../../../../shared/uielements/Pagination/page-numbers';
import Axios from 'axios';
import {hostNameAndPort} from '../../../../shared/util/vars';
import PPVPromoPreview from './PPVPromoPreview/PPVPromoPreview';
import FeaturedPromoPreview from './FeaturedPromoPreview/FeaturedPromoPreview';
import SponsoredPromoPreview from './SponsoredPromoPreview/SponsoredPromoPreview';
import {connect} from 'react-redux';
import SectionLoader from '../../../../shared/util/section-loader';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import SettingsMenu from "../SettingsMenu";

class ManagePromotions extends Component {

    state = {
        campaignList: [],
        filter: 'campaignType',
        value: '',
        currentPage: 1,
        itemsPerPage: 18,
        totalCount: 0,
        ppvCampaignCount: 0,
        featuredCampaignCount: 0,
        sponsoredCampaignCount: 0,
        loaded: false,
        initialLoading: true,
        typeNavList: [1, 0, 0, 0],
        statusNavList: [1, 0, 0, 0],
        affiliateId: '',
        resellerId: '',
        accountType: 'unknown',
        Loader: null
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setState({ loaded: true });
        setTimeout( () => {
            this.updateCampaignCount();
            this.initLoader(1,{filter: `{"showInactive":"true"}`});
            let user = this.props.loggedInUser;
            if (user) this.setState(user);
        }, 500);
    }
    
    componentDidUpdate(prevProps, prevState) {
        let user = this.props.loggedInUser;
        if (user && this.state.userId !== user.userId) {
            this.setState(user);
        }
        // Get the current page
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;
        
        let formParams = {};
        let categoryChanged = this.state.value !== prevState.value;
    
        if (categoryChanged) {
          this.setState({
            value: this.state.value,
            loaded: false
          });
          let f = {
            campaignType: this.state.value
          };
          formParams = {
            filter: JSON.stringify(f)
          };
          this.state.Loader.updateFormParams(formParams);
        } else if (currentPage !== this.state.currentPage) {
          this.setState({
            currentPage,
            loaded: false
          });
          this.state.Loader.updatePage(currentPage);
        }
    }

    initLoader = (page, formParams) => {
        this.setState({
          Loader: new SectionLoader({
            section: 'campaigns',
            cb: this.onLoad,
            options: {
              page,
              pageCount: this.state.itemsPerPage,
              formParams
            }
          })
        });
    }

    onLoad = (err) => {
        if (err && (process.env.NODE_ENV === 'development')) console.log(err);
        // window.scrollTo(0, 0);
        if (!this.state.Loader) {
            this.setState({ loaded: true });
            return;
        }
        this.setState({
            loaded: true,
            totalCount: this.state.Loader.totalCount,
            campaignList: this.state.Loader.items
        });
    }

    updateCampaignCount = () => {
        this.getCampaignCount({campaignType: 'ppv'}, 'ppvCampaignCount');
        this.getCampaignCount({campaignType: 'featured'}, 'featuredCampaignCount');
        this.getCampaignCount({campaignType: 'sponsored'}, 'sponsoredCampaignCount');
    }

    getCampaignCount = async (f,p) => {
        let campaignCountParams = new FormData();            
        campaignCountParams.append('filter', JSON.stringify(f));
        let options = {
                url: `${hostNameAndPort}/api/campaigns/count`,
                method: 'post',
                data: campaignCountParams
            }
        try {
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({ [p]: data.count });
            }
        } catch(err) {
            // Error
        }
    }

    onUpdatePageNumber = (n = 1) => {
        this.props.history.push(this.props.match.url + '?page=' + n);
      }

    onChangeCurrentTypeList = (i) => {
        if (i<4 && i>=0) {
            let a = Array(4).fill(0);
            a[i] = 1;
            let campaignType = [ 'all', 'ppv', 'featured', 'sponsored' ];
            this.setState({ typeNavList: a, value: campaignType[i] });
        }
    }

    onChangeCurrentStatusList = (i) => {
        if (i<4 && i>=0) {
            let a = Array(4).fill(0);
            a[i] = 1;
            this.setState({ statusNavList: a });
        }
    }

    render() {
        return(
            <SettingsMenu ManagePromotionsLiActive="active">



                <ProfileSettingsBox>

                    <h4>{getTranslatedPhrase(this.props.userLanguage, "Manage Promotions")}</h4>

                    <div className="mp-navgation">

                        <LoadingSvg active={!this.state.loaded} />
                        <div>
                            <span>{getTranslatedPhrase(this.props.userLanguage, "Choose Campaign Type")} </span>
                                <button onClick={(e) => this.onChangeCurrentTypeList(2)} className={!this.state.typeNavList[2]?'':'active'}>({this.state.featuredCampaignCount}) {getTranslatedPhrase(this.props.userLanguage, "Featured")}</button>
                                <button onClick={(e) => this.onChangeCurrentTypeList(3)} className={!this.state.typeNavList[3]?'':'active'}>({this.state.sponsoredCampaignCount}) {getTranslatedPhrase(this.props.userLanguage, "Sponsored")}</button>
                            { !this.state.affiliateId && this.state.accountType !== 'personal' && (
                                <button onClick={(e) => this.onChangeCurrentTypeList(1)} className={!this.state.typeNavList[1]?'':'active'}>({this.state.ppvCampaignCount}) GPPV</button>)}
                                <button onClick={(e) => this.onChangeCurrentTypeList(0)} className={!this.state.typeNavList[0]?'':'active'}>({this.state.ppvCampaignCount+this.state.sponsoredCampaignCount+this.state.featuredCampaignCount}) {getTranslatedPhrase(this.props.userLanguage, "All")}</button>
                        </div>
                        
                        {/* <div>
                            <span>Choose Campaign Status </span> 
                                <button onClick={(e) => this.onChangeCurrentStatusList(0)} className={!this.state.statusNavList[0]?'':'active'}>({this.state.ppvCampaignCount}) Active</button> 
                                <button onClick={(e) => this.onChangeCurrentStatusList(1)} className={!this.state.statusNavList[1]?'':'active'}>(0) Paused</button> 
                                <button onClick={(e) => this.onChangeCurrentStatusList(2)} className={!this.state.statusNavList[2]?'':'active'}>(0) Past</button> <br />  
                        </div> */}

                        {/* Paused campaign only for PPV, Sponsored & featured don't have  */}

                    </div>

                    <div className="mp-container">
                    {(!!this.state.typeNavList[0] || !!this.state.typeNavList[1]) && this.state.campaignList.map( (v,i) => (v.campaignType==='ppv')?(<PPVPromoPreview key={'ppv-'+i} {...v} />):null )}

                    {(!!this.state.typeNavList[0] || !!this.state.typeNavList[2]) && this.state.campaignList.map( (v,i) => (v.campaignType==='featured')?(<FeaturedPromoPreview key={'ppvf-'+i} {...v} />):null )}

                    {(!!this.state.typeNavList[0] || !!this.state.typeNavList[3]) && this.state.campaignList.map( (v,i) => (v.campaignType==='sponsored')?(<SponsoredPromoPreview key={'ppvs-'+i} {...v} />):null )}


                    <PageNumbers count={Math.ceil(this.state.totalCount/this.state.itemsPerPage)} 
                        activePage={this.state.currentPage}
                        onClick={this.onUpdatePageNumber} />
                       
                    </div>
                    
                </ProfileSettingsBox>


            </SettingsMenu>
        );
    }
}

const mapStateToManagePromotionsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToManagePromotionsProps, null)(ManagePromotions);