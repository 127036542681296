import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../util/lang';
import { titleCase } from '../../util/string';
import { connect } from 'react-redux';


class SubcategoriesCatMobile extends Component {
    state = {
        userLanguage: '',
        selectedSubcategory: 'Subcategory'
    }

    componentDidMount() {
        const windowLocationSearch = window.location.search;
        const urlParams = new URLSearchParams(windowLocationSearch);
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';

        this.setState({ selectedSubcategory: subcat })
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({
                userLanguage: v,
            });
        }
    }

    onSubmitHandler = (e) => {
        if (e !== this.props.activeSubcategory) {
            this.props.onChangeSubcategory(e);
        }
    }

    render() {
        if (!this.props.subcategoryList || !this.props.subcategoryList.length) {
            return null;
        }

        else {
            return (
                <div className="main-sidebar_box main-sidebar_box-subcategories">


                    <form>
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.selectedSubcategory} onChange={(e) => this.onSubmitHandler(e.target.value)}>
                                <option value="">Subcategory</option>

                                {this.props.subcategoryList.map((v, i) => (
                                    <option key={i} value={v}>{getTranslatedPhrase(this.props.userLanguage, (this.props.section === 'realestate') ? v.replace(/_/g, '/') : titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}

                            </select>
                        </div>
                    </form>



                    {/* <h4>{getTranslatedPhrase(this.props.userLanguage, 'Subcategories')}</h4>

                    <ul>
                        <li><button className={this.getClassNameFor('')} onClick={() => this.props.onChangeSubcategory('')}>{getTranslatedPhrase(this.props.userLanguage, 'All Subcategories')}</button></li>
                        {this.props.subcategoryList.slice(0, this.state.subcategoryCount).map((v, i) => (<li key={i}>
                            <button className={this.getClassNameFor(v)} onClick={() => this.onClickHandler(v)}></button>
                        </li>))}
                    </ul>

                    {!!this.props.subcategoryList && this.props.subcategoryList.length > 10 ? (<button onClick={this.onClickMore} className="show-more-link">{this.state.showMoreOrLess}</button>) : null} */}
                </div>
            );
        }
    }
}

const mapStateToSubCategoriesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage
    };
};

export default connect(mapStateToSubCategoriesProps, null)(SubcategoriesCatMobile);