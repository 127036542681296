import React, { Component } from 'react';
import { connect } from 'react-redux';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
// import {Link} from 'react-router-dom';
import { getTranslatedPhrase } from '../../../util/lang';
import { hostNameAndPort } from '../../../util/vars';
import moment from 'moment';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { currencyList } from '../../../util/string';


class createCouponModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            itemId: '',
            title: '',
            couponCurrency: '%',
            couponValue: '',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            // brandName: '',
            // redeemURL: '',
            // targetProducts: '',
            // category: '',
            // featuredImage: '',
            // file: null,
            // fImageDivRef: null,
            // openModal: false,
            // privacy: 'public',
            // hashTags: '',
            // categoriesSelect: [],
            temp: {
                mainTitle: 'CREATE COUPON CODE',
                errorMessage: '',
                couponSaved: false,
                postListingPressed: false,
            },
        };
    }

    componentDidMount() {
        this.setState(this.initialState);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.initialWarning && !this.state.temp.errorMessage) {
            this.setState({ temp: { ...this.state.temp, errorMessage: this.props.initialWarning } });
        }
        if (this.props.active && prevProps.active !== this.props.active) {
            if (!this.props.oldCoupon) {
                this.setState(this.initialState);
            } else {
                let c = this.props.oldCoupon;
                if (Object.keys(c).length) {
                    c.temp = { mainTitle: 'EDIT COUPON CODE' };
                    c.startDate = moment(c.startDate).format('YYYY-MM-DD');
                    c.endDate = moment(c.endDate).format('YYYY-MM-DD');
                    this.setState(c);
                }
            }
        }
    }

    onChangeCouponProp = (e, _propName, _sizeLimit, _propMsg) => {
        let ourCurrentValue = e.target.value;

        if (ourCurrentValue.length > _sizeLimit) {
            this.setState({ temp: { ...this.state.temp, errorMessage: `${getTranslatedPhrase(this.props.userLanguage, _propMsg)} ${getTranslatedPhrase(this.props.userLanguage, 'must be smaller than')} ${_sizeLimit} ${getTranslatedPhrase(this.props.userLanguage, 'characters')}` } });
            return;
        }
        if (_propName === 'couponCurrency' && this.state.couponCurrency !== ourCurrentValue) {
            this.setState({ couponValue: '' });
        }
        if (this.state.couponCurrency === '%' && _propName === 'couponValue' && (ourCurrentValue > 100 || ourCurrentValue < 0)) {
            this.setState({ temp: { ...this.state.temp, errorMessage: `${getTranslatedPhrase(this.props.userLanguage, 'Discount value is invalid.')}` } });
            return;
        }

        this.setState({ [_propName]: ourCurrentValue, temp: { ...this.state.temp, couponSaved: false, errorMessage: '' } });
    }

    onChangeCouponValue = (value) => {
        value = value.replace(/,/g, '').replace('.', '');
        if (this.state.couponCurrency === '%' && (value > 100 || value < 0)) {
            this.setState({ temp: { ...this.state.temp, errorMessage: `${getTranslatedPhrase(this.props.userLanguage, 'Discount value is invalid.')}` } });
            return;
        }
        if (this.state.couponCurrency !== '%' && (Number(value) < 0 || Number(value) > Number(this.props.initialPrice || 0))) {
            this.setState({ temp: { ...this.state.temp, errorMessage: `${getTranslatedPhrase(this.props.userLanguage, 'Discount value is invalid.')}` } });
            return;
        }
        this.setState({ couponValue: value, temp: { ...this.state.temp, couponSaved: false, errorMessage: '' } });
    }

    displayPriceValue = (value) => {
        if (isNaN(value) || value === '') return '';
        if (value === '0' || value === 0) return '';

        if (this.state.couponCurrency === '%') return value;
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }

    requiredFieldsAreFilled() {
        let res = true, _ = this.state;
        let _err = '';

        if (_.title === '') {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Coupon Code') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required');
            res = false;
        }
        if (!_.couponValue) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Coupon Value') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required');
            res = false;
        }
        if (Number(_.couponValue) === 0) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Coupon Value') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'must be greater than 0');
            res = false;
        }
        // if ( _.targetProducts === '' ) {
        //     _err = getTranslatedPhrase(this.props.userLanguage,'Product Name')+' '+getTranslatedPhrase(this.props.userLanguage,'is required');
        //     res = false;
        // }
        // if ( _.brandName === '' ) {
        //     _err = getTranslatedPhrase(this.props.userLanguage,'Brand Name')+' '+getTranslatedPhrase(this.props.userLanguage,'is required');
        //     res = false;
        // }

        // if ( !_.featuredImage ) {
        //     _err = getTranslatedPhrase(this.props.userLanguage,'You need to choose the main photo');
        //     res = false;
        // }
        // if ( !_.redeemURL ) {
        //     _err = getTranslatedPhrase(this.props.userLanguage,'Redeem URL')+' '+getTranslatedPhrase(this.props.userLanguage,'is required');
        //     res = false;
        // }
        if (!_.startDate) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Start Date') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required');
            res = false;
        }
        if (!_.endDate) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'End Date') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required');
            res = false;
        }
        if (res) {
            // Check the dates

            if (moment(_.endDate).isBefore(moment(_.startDate), 'days')) {
                _err = getTranslatedPhrase(this.props.userLanguage, 'Start date cannot be after end date');
                res = false;
            }
            if (moment(_.startDate).isBefore(moment(), 'days')) {
                _err = getTranslatedPhrase(this.props.userLanguage, 'Start date cannot be in the past');
                res = false;
            }

        }
        // if ( !_.category || _.category === 'select-category' ) {
        //     _err = getTranslatedPhrase(this.props.userLanguage,'Please select a valid category');
        //     res = false;
        // }
        if (_.temp.couponSaved && res) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Coupon') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is already saved');
            res = false;
        }
        this.setState({ temp: { ...this.state.temp, errorMessage: _err } });
        return res;
    }

    // onClearDraftHandler = (e) => {
    //     let c = this.state.categoriesSelect;
    //     this.setState(this.initialState);
    //     this.setState({ categoriesSelect: c });
    //     let d = this.state.fImageDivRef;
    //     if (d.hasChildNodes()) {
    //         for (let i=0;i<d.childNodes.length;i++) {
    //             d.removeChild(d.childNodes[i]); 
    //         }
    //     }
    // }


    onSaveCoupon = async () => {
        if (!this.requiredFieldsAreFilled()) {
            return;
        }
        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let nbd = Object.assign({}, this.state);
        nbd.isDeal = false;
        nbd.title = nbd.title.replace(/\W\W*/g, '').trim().toUpperCase();
        delete nbd.temp;
        delete nbd.fImageDivRef;
        delete nbd.openModal;
        delete nbd.categoriesSelect;

        let newCouponData = new FormData();
        for (let i in nbd) {
            newCouponData.append(i, nbd[i]);
        }
        let
            options = {
                url: `${hostNameAndPort}/api/coupons/update`,
                method: 'post',
                data: newCouponData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                nbd.itemId = data.itemId;
                nbd.active = true;
                this.props.onClose(nbd);
                this.setState({ itemId: data.itemId, temp: { ...this.state.temp, postListingPressed: false, couponSaved: true, errorMessage: '', mainTitle: 'EDIT COUPON CODE' } });
            }
            else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, couponSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFECRCUPMD01'), couponSaved: false } });
        }
    }

    render() {
        if (!this.props.active) {
            return null;
        }
        let couponCurrency = (!this.props.couponCurrency) ? '' : this.props.couponCurrency || (this.props.oldCoupon && (this.props.oldCoupon.couponCurrency)) || '%';




        return (
            <div className="modal-wrapper">
                <Backdrop show={this.props.active} clicked={(e) => this.props.onClose()} />
                <div className="modal">
                    <div className="modal_body">
                        <h4 className="mgt-sm">{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainTitle)}</h4>

                        <div className="modal_body_functions create-coupon-modal">
                            <br />

                            <div className="row">
                                <div className="col span-2-of-2">
                                    <input type="text" className="form-input-text form-input-full" value={this.state.title}
                                        onChange={(e) => this.onChangeCouponProp(e, 'title', 15, 'Coupon Code')}
                                        placeholder={getTranslatedPhrase(this.props.userLanguage, 'Coupon Code')} />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col span-6-of-9">
                                    <input type="number" className="form-input-text form-input-full" value={this.displayPriceValue(this.state.couponValue)}
                                        onChange={e => this.onChangeCouponValue(e.target.value)}
                                        placeholder={getTranslatedPhrase(this.props.userLanguage, 'Discount Value')} />
                                </div>


                                <div className="col span-3-of-9">
                                    <div className="form-input-select">
                                        <select className="form-input-full" value={this.state.couponCurrency} onChange={(e) => this.onChangeCouponProp(e, 'couponCurrency', 30, 'Discount Type')}>
                                            <option value="%">{getTranslatedPhrase(this.props.userLanguage, '%')}</option>
                                            {currencyList.map((v, i) => (
                                                <option key={v + i} value={v}>{v}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col span-3-of-6">
                                    <input type="date" className="form-input-text form-input-full" value={this.state.startDate}
                                        onChange={(e) => this.onChangeCouponProp(e, 'startDate', 30, 'Start Date')}
                                        placeholder={getTranslatedPhrase(this.props.userLanguage, 'Start Date')} />
                                </div>
                                <div className="col span-3-of-6">
                                    <input type="date" className="form-input-text form-input-full" value={this.state.endDate}
                                        onChange={(e) => this.onChangeCouponProp(e, 'endDate', 30, 'Expirations Date')}
                                        placeholder={getTranslatedPhrase(this.props.userLanguage, 'Expiration Date')} />
                                </div>
                            </div>

                            <br />

                            <FormFieldError>{this.state.temp.errorMessage} {/maximum number/.test(this.state.temp.errorMessage) && (<Link to="/subscription-settings">Click to upgrade your subscription.</Link>)}</FormFieldError>
                            <LoadingSvg active={this.state.temp.postListingPressed} />

                            <button
                                className="button button-regular button-small mgt-sm dsp-in-blk"
                                onClick={this.onSaveCoupon}>{getTranslatedPhrase(this.props.userLanguage, 'Save Coupon')}
                            </button>
                        </div>
                    </div>

                    <span onClick={(e) => this.props.onClose()} className="modal_close"><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateTocreateCouponModalProps = (
    state,
    props
) => {
    return {
        // active: state.createCouponModalActive,
        // modalMessage: state.createCouponModalMessage,
        // createCouponModalButtonLabel: state.createCouponModalButtonLabel,
        // createCouponModalRedirect: state.createCouponModalRedirect,
        userLanguage: state.userLanguage
    };
};

const mapDispatchTocreateCouponModalProps = (
    dispatch
) => {
    return {
        // onHidecreateCouponModal: () => {
        //     dispatch({type: actionType.ALERT_MODAL, createCouponModalActive: false, createCouponModalMessage: '', createCouponModalRedirect: '', alerModalButtonLabel: ''});
        // },
    };
};

export default connect(mapStateTocreateCouponModalProps, mapDispatchTocreateCouponModalProps)(createCouponModal);