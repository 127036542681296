import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {titleCase} from '../../../shared/util/string';
import {formatPriceAndCurrency} from '../../../shared/util/number';
import moment from 'moment';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';

class TravelFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
            <div className="featured-item featured-store-listing"> 

                <Link className="featured-store-listing_image" to={'/travel/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                    <img src={v.featuredImage} alt={v.title} />
                </Link>
                    
                <Link className="featured-store-listing_details" to={'/travel/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                    <span className="featured-store-listing_details_category"> {getTranslatedPhrase(v.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</span>
                    <h3> {v.title} </h3>
                    <span> {(v.state !== 'n/a')?(v.city+', '+v.state+', '+v.country):v.city+', '+v.country} </span>
                    <div className="featured-store-listing_details_specs"><span>{getTranslatedPhrase(v.userLanguage,'Duration')}:</span>{' '}
                        {moment(v.endDate).diff(moment(v.startDate), 'days')} days | <span>{getTranslatedPhrase(v.userLanguage,'Departure')}:</span>  {moment(v.startDate).format('LL')}</div>
                    <span className="featured-store-listing_details_price"> {formatPriceAndCurrency(v.price,v.currency,',')} </span>
                </Link>

            </div>
        );
    }
}

const mapStateToTravelFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToTravelFeaturedPreviewProps, null)(TravelFeaturedPreview);