import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../util/lang';
import { titleCase } from '../../util/string';
import { connect } from 'react-redux';
import Axios from 'axios';
import { hostNameAndPort } from '../../util/vars';

class PopularCategories extends Component {
    state = {
        categoryList: [],
        userLanguage: '',
        selectedCategory: "Category"
    }

    componentDidMount() {
        const windowLocationSearch = window.location.search;
        const urlParams = new URLSearchParams(windowLocationSearch);
        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        this.setState({ selectedCategory: cat })
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({
                userLanguage: v,
            });
        }
        let a = this.state.categoryList;
        let b = this.props.categoryList.filter(v => (!/^\s*-+/.test(v)));
        let isDifferent = false;
        if (a.length === b.length) {
            for (let i in a) {
                if (a[i] !== b[i]) {
                    isDifferent = true;
                    break;
                }
            }
        } else isDifferent = true;
        if (isDifferent)
            this.setState({ categoryList: [...b] });
    }


    onSubmitHandler = (e) => {
        if (e !== this.props.activeCategory) this.props.onChangeCategory(e);
    }



    render() {
        if (!this.props.categoryList) {
            return null;
        } else {
            return (
                <div className="main-sidebar_box main-sidebar_box-categories">

                    <form>
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.selectedCategory} onChange={(e) => this.onSubmitHandler(e.target.value)}>
                                <option value="" disabled="">Category</option>

                                {this.state.categoryList.map((v, i) => (
                                    <option key={i} value={v}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}

                            </select>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

const mapStateToPopularCategoriesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToPopularCategoriesProps, null)(PopularCategories);