import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { titleCase } from '../../../shared/util/string';
import { connect, useSelector } from 'react-redux';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import actionType from '../../../redux-store/action-type';
// import EngageBox from '../../../shared/uielements/Engage/EngageBox';


const NewsPreview = props => {
    const userLanguage = useSelector(state => state.userLanguage);
    const loggedInUser = useSelector(state => state.loggedInUser);
    let linkSlug = `/news/${props.category}/${props.subcategory || 'other'}/${props.slug}`;

    function onClickHandler() {
        if (props.onClick !== undefined) props.onClick();
        if (props.onToggleSidebar !== undefined) props.onToggleSidebar();
        if (!props.url) {
            props.history.push(linkSlug || '/news');
            props.pageShouldRefresh(linkSlug);
        } else {
            window.open(props.url, '_otherWindow');
        }
    }


    return (
        <div className="news-preview category-list_item">
            <button
                className="news-preview-img"
                onClick={onClickHandler}
                style={{backgroundImage: `url(${props.featuredImage})`}}
            >
                {/*<img src={} alt={props.title} />*/}
            </button>

            <div className="news-preview-content">
                <div className="news-preview-meta"><Link to={"/news" + props.category} className="bolder">{titleCase(props.category.replace(/-/g, ' '))}</Link></div>
                <h2>{(props.title.length > 45)?props.title.substring(0,45) + '...':props.title.substring(0,45)}</h2>

                {/*<EngageBox */}
                {/*    websiteSection={"news"}*/}
                {/*    itemId={props.itemId}*/}
                {/*    authorId={props.authorId}*/}
                {/*    withComments={false}*/}
                {/*    hideAllComments={true}*/}
                {/*/>*/}
                
            </div>
            
            <div className="blog-item-preview_stats">
                <p>{props.newsContent && props.newsContent.replace(/(<([^>]+)>)/g, "").replace(/&nbsp;/g,' ').substring(0,115)+"..."}</p>

                <div className="news-preview-author">by <Link to={"/"+(props.authorSlug || 'news')}>{props.authorName}</Link> on {moment(props.modifiedDate).format('MM/DD/YYYY')}</div>

                {!props.source?null:(
                <div className="news-preview-source">Source: <em>{props.source}</em></div>)}

                <span className="listing-preview-user-buttons">
                    <button className="button button-clear button-small dsp-in-blk" onClick={onClickHandler}>{getTranslatedPhrase(userLanguage,'Read More')}</button>
                    <ListingPreviewAdminPanel
                        itemType="news"
                        itemSlug={props.slug}
                        itemId={props.itemId}
                        userIsAuthorized={props.isAuthor || (loggedInUser && (loggedInUser.userLevel === 'admin' || loggedInUser.userLevel === 'customer-service'))}
                        />
                </span>

            </div>
        </div>
    )
}


const mapDispatchToNewsPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(null, mapDispatchToNewsPreviewProps)(withRouter(NewsPreview));