import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../util/vars';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../util/lang';
import Axios from 'axios';


class UserList extends Component {
    state = {
        size: 9,
        buttonLabel: 'Show All',
        userLanguage: '',
        users: []
    }

    componentDidMount() {
        let userListChanged = false;
        let a = this.state.users, b = this.props.userList;
        if (!b) {
            b = [];
        }
        if (a.length !== b.length) {
            userListChanged = true;
        } else {
            let c = {};
            for (let i = 0; i < b.length; i++)
                if (!this.props.listIsLoaded) c[b[i]] = 1;
                else c[b[i].userId] = 1;
            for (let i = 0; i < a.length; i++)
                c[a[i].userId] = 2;
            for (let i in c)
                if (c[i] === 1) {
                    userListChanged = true;
                    break;
                }
        }
        if (userListChanged) {
            if (!this.props.listIsLoaded) {
                this.getUserDetails();
            } else {
                this.setState({ users: b });
            }
        }
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({
                userLanguage: v,
                buttonLabel: getTranslatedPhrase(this.props.userLanguage, this.state.buttonLabel)
            });
        }
        let userListChanged = false;
        let a = this.state.users, b = this.props.userList;
        if (!b) {
            b = [];
        }
        if (a.length !== b.length) {
            userListChanged = true;
        } else {
            let c = {};
            for (let i = 0; i < b.length; i++)
                if (!this.props.listIsLoaded) c[b[i]] = 1;
                else c[b[i].userId] = 1;
            for (let i = 0; i < a.length; i++)
                c[a[i].userId] = 2;
            for (let i in c)
                if (c[i] === 1) {
                    userListChanged = true;
                    break;
                }
        }
        if (userListChanged) {
            if (!this.props.listIsLoaded) {
                this.getUserDetails();
            } else {
                this.setState({ users: b });
            }
        }
    }

    toggleSeeAll = () => {
        let n = this.state.size;
        let s;
        if (n === 9) {
            n = this.props.userList.length;
            s = getTranslatedPhrase(this.props.userLanguage, 'Show Less');
        } else {
            n = 9;
            s = getTranslatedPhrase(this.props.userLanguage, 'Show All');
        }
        this.setState({ size: n, buttonLabel: s });
    }

    getUserDetails = async () => {
        let k = this.props.userList;
        if (!k) {
            return;
        }
        let c = {};
        for (let i = 0; i < k.length; i++) c[k[i]] = 1;
        k = [...Object.keys(c)];
        let serviceListParams = new FormData();
        serviceListParams.append('users', JSON.stringify(k));
        let options = {
            url: `${hostNameAndPort}/api/user/user-list`,
            method: 'post',
            data: serviceListParams
        }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                if (!data.userList) {
                    data.userList = [];
                }
                this.setState({ users: data.userList });
            }
        } catch (err) {
            // Error
        }
    }

    addDefaultSingleSrc = (ev) => {
        ev.target.src = 'https://websitediag290.blob.core.windows.net/images/uploads/default/default-profile-image.jpg';
    }

    render() {
        if (!this.props.userList || !this.props.userList.length) {
            return null;
        }
        return (
            <div className="main-sidebar_box">
                <h4 className="h4-followers-number-title">{this.props.UserListTitle}</h4>

                <div className="main-sidebar_box_users">

                    {this.state.users.filter(v => (v.profileImage)).slice(0, this.state.size)
                        .map((v, i) => (<Link onClick={() => { this.props.pageShouldRefresh(v.slug); this.props.onToggleSidebar() }} to={'/' + v.slug} key={i}>
                            <img src={v.profileImage} onError={this.addDefaultSingleSrc} alt={v.userName} />
                            <span>{v.userName}</span>
                        </Link>))}

                </div>

                {(this.props.userList.length > 9) ? (
                    <button className="show-more-link" onClick={this.toggleSeeAll}>{this.state.buttonLabel}</button>
                ) : null}
            </div>
        );
    }
}

const mapStateToUserListProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToUserListProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToUserListProps, mapDispatchToUserListProps)(UserList);