import React, { useEffect } from 'react';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import useKeypress from 'react-use-keypress';

function ListingMediaModal(props) {

    useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
        if (event.key === 'ArrowLeft') {
            props.goBack();
        } else {
            props.goForward();
        }
    });

    if (!props.active) {
        return null;
    }

    return (<div className="modal-wrapper">
        <Backdrop show={props.active} clicked={props.onClickCloseHandler} />
        <div className="modal modal_wide listing-modal_wide">
            <div className="media-modal listing-media-modal">
                <div className="media-modal_box">

                    {!props.photoElement ? null : (
                        <div className="media-modal_box_image">
                            {props.photoElement}
                            {/* Previous Image */}
                            {(!props.showArrows) ? null : (
                                <button className="media-modal_box_image_left-arrow" onClick={props.goBack}><i className="fal fa-angle-left" /></button>)}
                            {/* Next Image */}
                            {(!props.showArrows) ? null : (
                                <button className="media-modal_box_image_right-arrow" onClick={props.goForward}><i className="fal fa-angle-right" /></button>)}
                        </div>)}

                    {!props.videoElement ? null : (
                        <div className="media-modal_box_video">
                            {props.videoElement}
                        </div>)}

                </div>

            </div>

            <button className="modal_close" onClick={props.onClickCloseHandler}><i className="fal fa-times" /></button>
        </div>
    </div>);
}

export default ListingMediaModal;