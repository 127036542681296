import React, { Component } from 'react';

class ProfileSettingsBox extends Component {
    render () {
        return (
            <div className="profile-settings_box">{this.props.children}</div>
        );
    }
}

export default ProfileSettingsBox; 