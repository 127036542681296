import React, { Component } from 'react';
import PaymentForm from './PaymentForm';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { hostNameAndPort } from '../../../util/vars';
import Axios from 'axios';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import { Link } from 'react-router-dom';

class SubscriptionModal extends Component {
    state = {
        paymentList: [],
        isUpdated: false,
        addNewCardClicked: false,
        chosenCard: -1,
        errorMessage: ''
    }

    componentDidUpdate() {
        if (this.state.isUpdated) {
            return;
        }
        if (this.props.active) {
            this.getPaymentList();
            this.setState({ isUpdated: true });
        }
    }

    getPaymentList = async () => {
        let url = `${hostNameAndPort}/api/pay/getpaymentforsubscriber`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({ paymentList: data.paymentList });
            }
        } catch (err) {
            // Network error
        }
    }

    onClickAddNewCard = () => {
        this.setState({ addNewCardClicked: true, errorMessage: '' });
    }

    onChooseSavedCardToPay = (i) => {
        this.setState({ chosenCard: i, errorMessage: '' });
    }

    onConfirmUseSelectedCard = () => {
        let g = this.state.chosenCard;
        if (g < 0 || g >= this.state.paymentList.length) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage, 'You need to choose an option first') });
            return;
        }
        this.props.onSubmitUserInfo({ stripeToken: '', gpaymentId: this.state.paymentList[this.state.chosenCard] });
        this.props.onMoveForward();
    }

    onCloseModalHandler = () => {
        this.setState({ addNewCardClicked: false, chosenCard: -1, errorMessage: '' });
        this.props.onCloseModal();
    }
    // {(!v.isPrimaryCard)?'': <i title="Primary Card" className="fas fa-credit-card-front"></i>}

    render() {
        if (!this.props.active) {
            return null;
        }
        return (<div className="modal-wrapper">
            <Backdrop show={this.props.active} clicked={this.onCloseModalHandler} />
            <div className="modal">

                <div className="step-three_payment">
                    <div className="modal_body">
                        {(this.state.paymentList.length && !this.state.addNewCardClicked && !this.props.addNewCardOnly) ? (
                            <div>
                                <h4>{getTranslatedPhrase(this.props.userLanguage, 'Payment')}</h4>
                                {this.state.paymentList.map((v, i) => (
                                    <div className="form-input-lists" key={'payment' + i}>
                                        <input type="radio" name="choosePaymentCardGroup" id={'payment' + i} onClick={(e) => this.onChooseSavedCardToPay(i)} />
                                        <label htmlFor={'payment' + i}><span><i className="fal fa-check" /></span> {v.brand} Card {getTranslatedPhrase(this.props.userLanguage, 'ending in')} {v.last4} </label>
                                    </div>
                                ))}
                                <FormFieldError>{this.state.errorMessage}</FormFieldError>
                                <br />
                                <p className="mgb-sm">By clicking "Pay", you agree to the <Link target="_blank" to="/terms-of-service">{getTranslatedPhrase(this.props.userLanguage, 'terms of service')}</Link></p>
                                <button className="button button-white" onClick={this.onClickAddNewCard}>{getTranslatedPhrase(this.props.userLanguage, 'Add New Card')}</button>
                        &nbsp;&nbsp;&nbsp;
                                <button className="button button-regular" onClick={this.onConfirmUseSelectedCard}>{getTranslatedPhrase(this.props.userLanguage, 'Pay') + ' $' + this.props.promoCharge}</button>
                            </div>)
                            : (<PaymentForm {...this.props} fontSize={18} />)}
                        <span className="modal_close" onClick={this.onCloseModalHandler}><i className="fal fa-times" /></span>
                    </div>
                </div>
            </div>
        </div>);
    }
}

// export default SubscriptionModal;
const mapStateToSubscriptionModalProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToSubscriptionModalProps, null)(SubscriptionModal);