import React, { Component } from 'react';
import { formatPriceAndCurrency } from '../../../shared/util/number';
import {titleCase} from '../../../shared/util/string';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';


class RealEstateFeaturedPreview extends Component {
    render() {
        let v = this.props;
    return (
        <div className="featured-item featured-store-listing">
            <Link className="featured-store-listing_image" to={'/realestate/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <img src={v.featuredImage} alt={v.authorName} />
            </Link>

            <Link className="featured-store-listing_details" to={'/realestate/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <h3>{v.title}</h3> 
                <span>{v.address+', '+titleCase(v.city)+', '+( (v.state !== 'n/a')?v.state:titleCase(v.country) )}</span>
                
                <span className="featured-store-listing_details_price">{formatPriceAndCurrency(v.price, v.currency, ',', v.rentOrSell==='rent'?getTranslatedPhrase(v.userLanguage,'Month'):'')}</span>
            </Link>
        </div>
        );
    }
}

const mapStateToRealEstateFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToRealEstateFeaturedPreviewProps, null)(RealEstateFeaturedPreview);
