import React from 'react';
import {countryList} from '../../../util/string';

function CountryDropDown(props) {
  return (
    <div className="form-input-select">
      <select className="form-input-full" value={props.country} onChange={props.onChangeCountry}>
      <option value={props.countryPlaceholder}>{props.countryPlaceholder}</option>
      {countryList.map( (v,i) => (<option key={v} value={v}>{v}</option>) )}
    </select>
    </div>
  );
}

export default CountryDropDown;