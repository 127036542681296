import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../util/vars';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../util/lang';
import { withRouter } from 'react-router-dom';

class accountLoginModal extends Component {
    state = {
        errorMessage: '',
        username: '',
        password: '',
        lastActive: false
    }

    componentDidMount() {
        let d = document.getElementById('emailInputBox');
        if (!!d) {
            d.focus();
        }
        // let userIsTryingToResetPass = /^https?:\/\/carverve.com\/(reset|forgot)-password/.test(window.location.href);
        // if (userIsTryingToResetPass) {
        //     this.props.onClickCloseHandler();
        // }
    }

    componentDidUpdate() {
        // let userIsTryingToResetPass = /^https?:\/\/carverve.com\/(reset|forgot)-password/.test(window.location.href);
        // if (userIsTryingToResetPass) {
        //     this.props.onClickCloseHandler();
        //     return;
        // }
        let a = this.props.active;
        if (this.state.lastActive !== a) {
            this.setState({ lastActive: a });
            if (a) {
                let d = document.getElementById('emailInputBox');
                if (!!d) {
                    d.focus();
                }
            }
        }
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        let u = this.state.username,
            p = this.state.password;
        if (u.match(/[><)(;:]/g) || p.match(/[><)(;:]/g)) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Your password can only contain letters and numbers.') });
            return;
        }

        let options = {
            url: `${hostNameAndPort}/login`,
            method: 'post',
            data: `username=${u}&password=${p}`,
        };
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    this.setState({ errorMessage: '', username: '', password: '' });
                    this.props.onLogin(data.user);
                    this.props.history.push(this.props.returnUrl);
                    this.props.refreshCurrentPage();
                    this.props.onClickCloseHandler();
                }
                else {
                    let emc = data.error;
                    // if (emc.indexOf('.') > -1) {
                    //     emc = emc.split('.');
                    //     emc = (<Box>{emc.map(v => (<Box>{v}<br /></Box>))}</Box>);
                    // }
                    this.setState({ errorMessage: emc });
                }
            })
            .catch(err => {
                //this.setState({errorMessage: 'Network error'});
                this.setState({ errorMessage: '' + err });
            });
    }

    emailChangeHandler = (e) => {
        let u = e.target.value;
        if (u.length < 80) {
            this.setState({ username: u });
        }
    }

    passwordChangeHandler = (e) => {
        let p = e.target.value;
        if (p.length < 60) {
            this.setState({ password: p });
        }
    }

    onActivateSignUpModal = (e) => {
        e.preventDefault();
        this.props.switchToSignUpModal();
    }

    render() {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="modal-wrapper" style={{zIndex: '99999'}}>
                <Backdrop show={this.props.active} clicked={() => null} />
                <div className="modal account-login-modal">
                    <div className="modal_body site-sign-in">
                        <form onSubmit={this.onSubmitHandler}>

                            <div className="site-sign-in-header">

                                {/* <div className="site-sign-in-header-cover"></div> */}
                                <p className="site-sign-in-header-title">{getTranslatedPhrase(this.props.userLanguage, 'Sign In')}</p>
                                <p className="site-sign-in-2ndcta">{getTranslatedPhrase(this.props.userLanguage, 'To get started sign in or register.')}</p>
                                {/* <p className="site-sign-in-header-cta"><strong>{getTranslatedPhrase(this.props.userLanguage, 'Buy/sell')}</strong> {getTranslatedPhrase(this.props.userLanguage, 'new and used products, cars, real-estate and more.')}
                                    <strong> {getTranslatedPhrase(this.props.userLanguage, 'Post/find')}</strong> {getTranslatedPhrase(this.props.userLanguage, 'jobs and services.')} <strong>{getTranslatedPhrase(this.props.userLanguage, 'Connect')}</strong> {getTranslatedPhrase(this.props.userLanguage, 'with family, friends and customers.')} </p> */}

                                {/* <div className="site-sign-in-header-iconlist">
                                    <i className="fal fa-blog"></i>
                                    <i className="fal fa-camera-retro"></i>
                                    <i className="fal fa-users"></i>
                                    <i className="fal fa-shopping-bag"></i>
                                    <i className="fal fa-car"></i>
                                    <i className="fal fa-briefcase"></i>
                                    <i className="fal fa-wrench"></i>
                                    <i className="fal fa-building"></i>
                                    + {getTranslatedPhrase(this.props.userLanguage, 'more')}
                                </div> */}

                            </div>



                            <div className="modal_body_functions modal_body_functions_log-in">
                                <input type="email" id="emailInputBox" placeholder={getTranslatedPhrase(this.props.userLanguage, "Email")} value={this.state.username} onChange={this.emailChangeHandler} />

                                <input type="password" placeholder={getTranslatedPhrase(this.props.userLanguage, "Password")} value={this.state.password} onChange={this.passwordChangeHandler} />


                                <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.state.errorMessage)}</FormFieldError>

                                <button
                                    className="button button-regular button-small"
                                    onClick={this.onSubmitHandler}>{getTranslatedPhrase(this.props.userLanguage, 'Sign In')}
                                </button>

                                <button
                                    className="button button-clear button-small"
                                    onClick={this.onActivateSignUpModal}>{getTranslatedPhrase(this.props.userLanguage, 'Register')}
                                </button>

                                <div className="txtc">
                                    <Link onClick={this.props.onClickCloseHandler} className="forgot-password" to="/forgot-password">{getTranslatedPhrase(this.props.userLanguage, 'Forgot Password')}</Link>
                                </div>



                            </div>
                        </form>

                        <span style={{ color: "#fff" }} className="modal_close" onClick={this.props.onClickCloseHandler}><i className="fal fa-times" /></span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToAccountLoginModalProps = (
    state,
    props
) => {
    return {
        active: state.accountLoginModalActive,
        userLanguage: state.userLanguage,
        returnUrl: state.returnUrl
    };
};

const mapDispatchToAccountLoginModalProps = (
    dispatch
) => {
    return {
        onLogin: (u) => {
            dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: true, loggedInUser: u });
        },
        onClickCloseHandler: () => {
            dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: false });
        },
        switchToSignUpModal: () => {
            dispatch({ type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: true });
            setTimeout(() => {
                dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: false });
            }, 50);
        },
        refreshCurrentPage: () => dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: 'new-login-' + Date.now() })
    };
};

export default connect(mapStateToAccountLoginModalProps, mapDispatchToAccountLoginModalProps)(withRouter(accountLoginModal));