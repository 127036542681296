import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';


class PhotosFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
        <div className="featured-item featured-photo-gallery">
            <Link className="featured-photo-gallery_image" to={'/photos/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <img src={v.featuredImage} alt={v.title} />
            </Link>

            <Link className="featured-photo-gallery_details" to={'/photos/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <span>{getTranslatedPhrase(v.userLanguage,'by')} {v.authorName}</span>
                <h3>{v.title}</h3>
            </Link>
        </div>
        ); 
    }
}


const mapStateToPhotosFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToPhotosFeaturedPreviewProps, null)(PhotosFeaturedPreview);