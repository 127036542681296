import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import { getTranslatedPhrase } from '../../../util/lang';

class accountTypeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signUpAccountType: 'personalAccountType',
            isThisTypeChecked: [1, 0, 0, 0, 0]
        };
    }

    onClickRadioButtonHandler = (e, j) => {
        let a = [0, 0, 0, 0, 0];
        a[j] = 1;
        this.setState({ signUpAccountType: e.target.id, isThisTypeChecked: a }, () => {
            this.onNext();
        });
    }

    resetThisModal = () => {
        this.setState({ isThisTypeChecked: [1, 0, 0, 0, 0] });
        this.props.onClickCloseHandler();
    }

    onNext = () => {
        this.props.onClickNextHandler(this.state.signUpAccountType.replace('AccountType', ''));
    }

    render() {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="modal-wrapper" style={{zIndex: '99999'}}>
                {/* <Backdrop show={this.props.active} clicked={this.resetThisModal} /> */}
                <Backdrop show={this.props.active} clicked={() => null} />
                <div className="modal account-type-modal">
                    <div className="modal_breadcrumb">
                        <span>{getTranslatedPhrase(this.props.userLanguage, 'Account Type')}</span> &nbsp;|&nbsp; {getTranslatedPhrase(this.props.userLanguage, 'Personal Information')}
                    </div>

                    <div className="modal_body">


                        <div className="modal_body_functions modal_body_functions_account-type">
                            <h3>{getTranslatedPhrase(this.props.userLanguage, 'Select Account Type')}</h3>

                            <div className="account-type-list">
                                <div className="form-input-lists"><input id="personalAccountType" checked={!!this.state.isThisTypeChecked[0]} type="radio" name="accountTypeSelection" onChange={(e) => this.onClickRadioButtonHandler(e, 0)} /> <label htmlFor="personalAccountType"><span><i className="fal fa-check" /></span> <div>{getTranslatedPhrase(this.props.userLanguage, 'Personal')}</div></label></div>
                                <div className="form-input-lists"><input id="businessAccountType" checked={!!this.state.isThisTypeChecked[1]} type="radio" name="accountTypeSelection" onChange={(e) => this.onClickRadioButtonHandler(e, 1)} /> <label htmlFor="businessAccountType"><span><i className="fal fa-check" /></span> <div>{getTranslatedPhrase(this.props.userLanguage, 'Business')}</div></label></div>
                                <div className="form-input-lists"><input id="organizationAccountType" checked={!!this.state.isThisTypeChecked[2]} type="radio" name="accountTypeSelection" onChange={(e) => this.onClickRadioButtonHandler(e, 2)} /> <label htmlFor="organizationAccountType"><span><i className="fal fa-check" /></span> <div>{getTranslatedPhrase(this.props.userLanguage, 'Organization')}</div></label></div>
                                <div className="form-input-lists"><input id="institutionAccountType" checked={!!this.state.isThisTypeChecked[3]} type="radio" name="accountTypeSelection" onChange={(e) => this.onClickRadioButtonHandler(e, 3)} /> <label htmlFor="institutionAccountType"><span><i className="fal fa-check" /></span> <div>{getTranslatedPhrase(this.props.userLanguage, 'Institution')}</div></label></div>
                                <div className="form-input-lists"><input id="public-figureAccountType" checked={!!this.state.isThisTypeChecked[4]} type="radio" name="accountTypeSelection" onChange={(e) => this.onClickRadioButtonHandler(e, 4)} /> <label htmlFor="public-figureAccountType"><span><i className="fal fa-check" /></span> <div>{getTranslatedPhrase(this.props.userLanguage, 'Public Figure')}</div></label></div>
                            </div>

                            <button className="button button-regular blk-ctr-align" onClick={this.onNext}>{getTranslatedPhrase(this.props.userLanguage, 'Next')}</button>
                            <button className="have-account-button" onClick={() => this.props.switchToLoginModal(this.props.returnUrl)} >{getTranslatedPhrase(this.props.userLanguage, 'Already have an Account?')}</button>
                        </div>
                    </div>

                    <span className="modal_close" onClick={this.resetThisModal}><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateToAccountTypeModalProps = (
    state,
    props
) => {
    return {
        active: state.accountTypeModalActive,
        userLanguage: state.userLanguage,
        returnUrl: state.returnUrl
    };
};

const mapDispatchToAccountTypeModalProps = (
    dispatch
) => {
    return {
        onClickCloseHandler: () => {
            dispatch({ type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: false });
        },
        onClickNextHandler: (t) => {
            dispatch({ type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: false });
            dispatch({ type: actionType.ACCOUNT_INFORMATION_MODAL, accountInformationModalActive: true, signUpAccountType: t });
        },
        switchToLoginModal: (r) => {
            dispatch({ type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: false });
            setTimeout(() => {
                dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: r });
            }, 50);
        }
    };
};

export default connect(mapStateToAccountTypeModalProps, mapDispatchToAccountTypeModalProps)(accountTypeModal);