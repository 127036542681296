import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { displayPriceValue } from '../../../util/number';
import { titleCase } from '../../../util/string';
import Modal from '../../../uielements/Modal/Modal';
import { populateCountries, populateStates } from '../../../util/countries';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';


const AdvRealEstateSearch = props => {
    const userLanguage = useSelector(state => state.userLanguage);
    const [category, setCategory] = useState('residential');
    const [subcategory, setSubcategory] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [priceAscending, setPriceAscending] = useState(0);
    const [viewsDescending, setViewsDescending] = useState(false);
    const [rating, setRating] = useState(0);
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [yearMin, setYearMin] = useState('');
    const [yearMax, setYearMax] = useState('');
    const [rentOrSell, setRentOrSell] = useState('sell');
    const [bedroomCount, setBedroomCount] = useState('');
    const [bathroomCount, setBathroomCount] = useState('');
    const [floorSizeMin, setFloorSizeMin] = useState('');
    const [floorSizeMax, setFloorSizeMax] = useState('');
    const [withCoupons, setWithCoupons] = useState(false);
    const [stateVar, setStateVar] = useState('');
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [countryElementId, setCountryElementId] = useState('');
    const [stateElementId, setStateElementId] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { section } = props;

    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const windowLocationSearch = window.location.search;

    useEffect(() => {
        setCategoryList(props.categoryList);
    }, [section]);

    const b1 = document.getElementById("stateElementForAdvRealEstate");
    const b2 = document.getElementById("countryElementForAdvRealEstate");
    const b2Len = (b2 && b2.options.length > 1) ? b2.options.length : 0;
    const b1Len = (b1 && b1.options.length) ? b1.options.length : 0;
    
    useEffect(() => {
        if (
            countryElementId
            && stateElementId
            && b1
            && b1Len
            && b2
            && b2Len
            //&& b2.selectedIndex
        ) {
            // populateCountries(countryElementId, stateElementId);
            let cc = b2;
            let i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === country) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
            populateStates(countryElementId, stateElementId);
            cc = document.getElementById("stateElementForAdvRealEstate");
            i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === stateVar) break;

            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
        }
    }, [b1, b2, b2Len, b1Len]);

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;
    
    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                subcategory,
                withCoupons,
                priceMin,
                priceMax,
                priceAscending,
                yearMin,
                yearMax,
                floorSizeMin,
                floorSizeMax,
                rentOrSell,
                // withCoupons,
                bedroomCount,
                bathroomCount,
                rating,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            if (!!props.category) props.history.push('/realestate');
        }

    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {

            const ts = {
                category,
                subcategory,
                withCoupons,
                priceMin,
                priceMax,
                priceAscending,
                viewsDescending,
                yearMin,
                yearMax,
                floorSizeMin,
                floorSizeMax,
                rentOrSell,
                // withCoupons,
                bedroomCount,
                bathroomCount,
                rating,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) {

                console.log(i);

                if (i === 'rentOrSell') {
                    onChangeItem(i, 'sell');
                } else {
                    onChangeItem(i, '');
                }

            } 

            setCountry(currentCountry);
            return;
        }

        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';
        if (category !== cat) setCategory(cat);



        if (subcategory !== subcat) setSubcategory(subcat);


        const ts = {
            category,
            subcategory,
            withCoupons,
            priceMin,
            priceMax,
            priceAscending,
            viewsDescending,
            yearMin,
            yearMax,
            floorSizeMin,
            floorSizeMax,
            rentOrSell,
            // withCoupons,
            bedroomCount,
            bathroomCount,
            rating,
            country,
            stateVar,
            city,
            zipCode
        };
        let changeState = false;

        const ns = {
            category: urlParams.get('category') || '',
            subcategory: urlParams.get('subcategory') || '',
            withCoupons: urlParams.get('withCoupons') || false,
            priceMin: urlParams.get('priceMin') || '',
            priceMax: urlParams.get('priceMax') || '',
            priceAscending: Number(urlParams.get('priceAscending')) || 0,
            viewsDescending: urlParams.get('viewsDescending') || false,
            yearMin: urlParams.get('yearBuiltMin'),
            yearMax: urlParams.get('yearBuiltMax'),
            floorSizeMin: urlParams.get('floorSizeMin') || '',
            floorSizeMax: urlParams.get('floorSizeMax') || '',
            rentOrSell: urlParams.get('rentOrSell') || 'sell',
            // withCoupons: urlParams.get('withCoupons'),
            bedroomCount: urlParams.get('bedroomCount'),
            bathroomCount: urlParams.get('bathroomCount'),
            rating: urlParams.get('rating') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || ''
        };


        if (!ns.viewsDescending || ns.viewsDescending === 'false' || ns.viewsDescending === 'null') ns.viewsDescending = false; else ns.viewsDescending = true;
        if (!ns.withCoupons || ns.withCoupons === 'false' || ns.withCoupons === 'null') ns.withCoupons = false; else ns.withCoupons = true;
        if (!ns.rating || ns.rating === 'false' || ns.rating === 'null') ns.rating = 0; else ns.rating = Number(ns.rating);
        if (!ns.yearMin || ns.yearMin === 'false' || ns.yearMin === 'null') ns.yearMin = ''; else ns.yearMin = Number(ns.yearMin);
        if (!ns.yearMax || ns.yearMax === 'false' || ns.yearMax === 'null') ns.yearMax = ''; else ns.yearMax = Number(ns.yearMax);
        if (ns.floorSizeMin === 'null') ns.floorSizeMin = '';
        if (ns.floorSizeMax === 'null') ns.floorSizeMax = '';

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        if (ns.address !== ts.address || ns.withCoupons !== ts.withCoupons || ts.priceMin !== ns.priceMin || ts.priceMax !== ns.priceMax || ts.priceAscending !== ns.priceAscending || ts.viewsDescending !== ns.viewsDescending ||
            ns.yearMin !== ts.yearMin || ns.yearMax !== ts.yearMax || ns.rentOrSell !== ts.rentOrSell || ns.bedroomCount !== ts.bedroomCount || ns.bathroomCount !== ts.bedroomCount ||
            ns.rating !== ts.rating || ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode || ns.floorSizeMin !== ts.floorSizeMin || ns.floorSizeMax !== ts.floorSizeMax)
            changeState = true;


        if (changeState) {
            for (let i of Object.keys(ns)) onChangeItem(i, ns[i]);
        }

        onChangeItem(rentOrSell, 'sell')
        
    }, [windowLocationSearch]);

    function onChangeItem(_prop, _value) {
        let a = {
            category: setCategory,
            subcategory: setSubcategory,
            priceAscending: setPriceAscending,
            viewsDescending: setViewsDescending,
            rating: setRating,
            priceMin: setPriceMin,
            priceMax: setPriceMax,
            yearMin: setYearMin,
            yearMax: setYearMax,
            floorSizeMin: setFloorSizeMin,
            floorSizeMax: setFloorSizeMax,
            rentOrSell: setRentOrSell,
            withCoupons: setWithCoupons,
            bedroomCount: setBedroomCount,
            bathroomCount: setBathroomCount,
            country: setCountry,
            stateVar: setStateVar,
            city: setCity,
            zipCode: setZipCode,
            address: setAddress
        };

        if (_prop === 'category') {

            if (!_value) {
                _value = 'residential';
            } else if (_value !== category) {
                setSubcategory('');
            }

            if (_value === 'business-opportunities') {
                setYearMax('');
                setYearMin('');
            }

            onChangeCategory(_value);
        }

        if (_prop === 'rentOrSell' && rentOrSell === _value) _value = 'sell';
        if (_prop === 'priceMin' || _prop === 'priceMax') _value = _value.replace(/,/g, '').replace('.', '');
        if (_prop === 'country') setStateVar('');

        if (a[_prop]) a[_prop](_value);

    }

    function onChangeCategory(c) {
        if (c === 'none' || !c) {
            setSubcategoryList([]);
            return;
        }
        let i = 0;
        let s = [];
        while (i < props.categoryList.length && props.categoryList[i] !== c) i++;
        for (i++; i < props.categoryList.length && /^\s*-+/.test(props.categoryList[i]); i++) s.push(props.categoryList[i].replace(/^\s*-+\s*/, ''));
        setSubcategoryList(s);
    }

    function onSubmitSearch(e) {
        e.preventDefault();
        let priceRange = false;
        if (Number(priceMin) || Number(priceMax)) priceRange = true;
        let residential = (category === 'residential') ? 1 : (category === 'commercial') ? 2 : (category === 'business-opportunities') ? 3 : 0;
        let f = "filters-" + Date.now();

        // let s_q = JSON.parse(searchQuery || '{}');
        let state = stateVar;
        // if (!state && country === s_q.country) state = s_q.region;
        if (!state && city) {
            setErrorMessage('Please select the state!');
            return;
        } else setErrorMessage('');

        let s = `/realestate/search/${f}?priceAscending=${priceAscending}&priceMin=${priceMin}&priceMax=${priceMax}&priceRange=${priceRange}&withCoupons=${withCoupons}&rentOrSell=${rentOrSell}&bathroomCount=${bathroomCount}&bedroomCount=${bedroomCount}&
residential=${residential}&floorSizeMin=${floorSizeMin}&floorSizeMax=${floorSizeMax}&yearBuiltMin=${yearMin}&yearBuiltMax=${yearMax}&address=${address}&country=${country}&state=${state}&city=${city}&zipCode=${zipCode}&category=${category.replace(/&/g, '%26')}&subcategory=${subcategory.replace(/&/g, '%26')}&keywords=${f}`;
        props.onSubmitHandler(e, s);
        props.closeModal();
    }


    return (
        <Modal
            title="Real Estate Advanced Search"
            closeModal={props.closeModal}
            modalClass="medium"
            modalBodyClass="adv-body"
        >
            <div className="adv-search">
                <div className="adv-search-content">
                    <div className="adv-search-content-box">
                        <p><strong>TYPE</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={category} onChange={e => onChangeItem('category', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Category')}</option>
                                {categoryList.filter(v => (!/^\s*-+/.test(v))).map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={subcategory} onChange={e => onChangeItem('subcategory', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Subcategory')}</option>
                                {subcategoryList.map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, v.replace(/_/g, '/'))}</option>
                                ))}
                            </select>
                        </div>

                        {category !== 'business-opportunities' && (
                            <>
                                <div className="form-input-lists form-input-lists_row">
                                    <input type="radio" name="transaction" id="buy" value="buy" checked={rentOrSell === 'sell'} onChange={() => null} />
                                    <label htmlFor="buy" onClick={e => onChangeItem('rentOrSell', 'sell')}><i className="fal fa-check" />Buy</label>
                                </div>
                                <div className="form-input-lists form-input-lists_row">
                                    <input type="radio" name="transaction" id="rent" value="rent" checked={rentOrSell === 'rent'} onChange={() => null} />
                                    <label htmlFor="rent" onClick={e => onChangeItem('rentOrSell', 'rent')}><i className="fal fa-check" />Rent/Lease</label>
                                </div>
                            </>
                        )}

                        <p className="mgt-md"><strong>PRICING</strong></p>

                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min $" value={displayPriceValue(priceMin)} onChange={e => onChangeItem('priceMin', e.target.value)} />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max $" value={displayPriceValue(priceMax)} onChange={e => onChangeItem('priceMax', e.target.value)} />
                        </div>

                        {/* <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="discount" id="discount" value="discount" checked={withCoupons} onChange={() => null} />
                            <label htmlFor="discount" onClick={e => onChangeItem('withCoupons', !withCoupons)}><i className="fal fa-check" />Deals</label>
                        </div> */}
                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>LOCATION</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="countryElementForAdvRealEstate"
                                ref={n => {
                                    if (!countryElementId) setCountryElementId("countryElementForAdvRealEstate");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={country}
                                onChange={e => onChangeItem('country', e.target.value)}>
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="stateElementForAdvRealEstate"
                                ref={n => {
                                    if (!stateElementId) setStateElementId("stateElementForAdvRealEstate");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={stateVar}
                                onChange={e => onChangeItem('stateVar', e.target.value)}>
                                <option value="">Select State *</option>
                            </select>
                        </div>

                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Zip/Postal Code" value={zipCode} onChange={e => onChangeItem('zipCode', e.target.value)} />

                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="City" value={city} onChange={e => onChangeItem('city', e.target.value)} />

                        {/* <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Street" value={address} onChange={e => onChangeItem('address', e.target.value)} /> */}
                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>OTHER OPTIONS</strong></p>

                        <p className="input-label">Floor Size:</p>
                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min SF" value={floorSizeMin} onChange={e => onChangeItem('floorSizeMin', e.target.value)} />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max SF" value={floorSizeMax} onChange={e => onChangeItem('floorSizeMax', e.target.value)} />
                        </div>


                        {category !== 'business-opportunities' &&

                            <>

                                <p className="input-label">Year Built:</p>
                                <div className="flex mgb-sm">
                                    <input type="text" className="form-input-text form-input-full" placeholder="Min" value={yearMin} onChange={e => onChangeItem('yearMin', e.target.value)} />
                                    <span>to</span>
                                    <input type="text" className="form-input-text form-input-full" placeholder="Max" value={yearMax} onChange={e => onChangeItem('yearMax', e.target.value)} />
                                </div>
                            </>
                        }

                        {category === 'residential' && (
                            <div className="form-input-select">
                                <select className="form-input-full mgb-sm" value={bedroomCount} onChange={e => onChangeItem('bedroomCount', e.target.value)}>
                                    <option value="">{getTranslatedPhrase(userLanguage, 'Bedroom Count')}</option>
                                    <option key={'item-bc1'} value="1">{getTranslatedPhrase(userLanguage, '1 Bedroom')}</option>
                                    <option key={'item-bc2'} value="2">{getTranslatedPhrase(userLanguage, '2 Bedrooms')}</option>
                                    <option key={'item-bc3'} value="3">{getTranslatedPhrase(userLanguage, '3 Bedrooms')}</option>
                                    <option key={'item-bc4'} value="4">{getTranslatedPhrase(userLanguage, '4 Bedrooms')}</option>
                                    <option key={'item-bc5'} value="5">{getTranslatedPhrase(userLanguage, '5 Bedrooms')}</option>
                                    <option key={'item-bc6'} value="6">{getTranslatedPhrase(userLanguage, '6 Bedrooms')}</option>
                                    <option key={'item-bc7'} value="7">{getTranslatedPhrase(userLanguage, '>6 Bedrooms')}</option>
                                </select>
                            </div>)}

                        {category === 'residential' && (
                            <div className="form-input-select">
                                <select className="form-input-full mgb-sm" value={bathroomCount} onChange={e => onChangeItem('bathroomCount', e.target.value)}>
                                    <option value="">{getTranslatedPhrase(userLanguage, 'Bathroom Count')}</option>
                                    <option key={'item-bd1'} value="1">{getTranslatedPhrase(userLanguage, '1 Bathroom')}</option>
                                    <option key={'item-bd2'} value="2">{getTranslatedPhrase(userLanguage, '2 Bathrooms')}</option>
                                    <option key={'item-bd3'} value="3">{getTranslatedPhrase(userLanguage, '3 Bathrooms')}</option>
                                    <option key={'item-bd4'} value="4">{getTranslatedPhrase(userLanguage, '>3 Bathrooms')}</option>
                                </select>
                            </div>)}


                    </div>

                </div>
                <div><FormFieldError> {errorMessage} </FormFieldError></div>
                <div className="adv-search-buttons">
                    <button className="button button-regular" onClick={onSubmitSearch}>Search</button>
                    <button className="button button-white" onClick={props.closeModal}>Cancel Search</button>
                </div>
            </div>
        </Modal>
    )
}

export default connect(null, null)(AdvRealEstateSearch);