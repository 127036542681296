import React from "react";
import ListingPage from "../../../../shared/templates/ListingTemplate/listing-edit";
import AutosEditFields from "./AutosEditFields";
import moment from 'moment';

function AutosEdit(props) {
  function checkReqs(item) {
    let m = "";

    if (!item.title) {
      m = "Auto listing title is required.";
    }
    if (!item.description.trim()) {
      m = "Description is required.";
    }
    if (!item.callForPricing && !item.price) {
      m = "Price is required.";
    }
    if (item.addDiscount === "how-about-discount") {
      m = "Does your listing have a discount?";
    }
    if (item.addDiscount === "add-discount") {

      if (moment(item.temp.startDate).isAfter(moment(item.temp.endDate))) {
        m = "Discount end date should be after the start date."
      }

      if (moment(item.temp.endDate).isBefore(moment().subtract(1, 'days'))) {
        m = "Discount end date can't be a past date."
      }

      if (moment(item.temp.startDate).year() > 3000 || moment(item.temp.endDate).year() > 3000) {
        m = "Please enter a valid year."
      }

      if (!item.temp.discountType || item.temp.discountType === 'discount-type' ||
        (item.temp.discountType === 'discount-deal' && (!Number(item.temp.discountValue) || !item.temp.availability || item.temp.availability === 'set-availability' || ((!item.temp.startDate || !item.temp.endDate) && item.temp.availability !== 'no-expiration-date'))) ||
        (item.temp.discountType === 'discount-coupon' && (!item.temp.selectWhichCoupon || item.temp.selectWhichCoupon === 'select-coupon')))
        m = 'You need to complete the discount information';
    }
    if (!item.productCondition || item.productCondition === 'choose-condition') {
      m = "Listing condition is required.";
    }
    if (
      (!item.callForPricing && !Number(item.price)) ||
      Number(item.price) < 0
    ) {
      m = "Price is not valid.";
    }

    if (!item.category || item.category === "select-category") {
      m = "Select a valid category.";
    }

    // if(item.category === "other" || item.category === "rVs-&-Campers") {
    //   if (item.subcategory === "select-subcategory") {
    //     m = "Select a valid subcategory.";
    //   }
    // }

    if (!item.seller) {
      m = "Select a valid seller.";
    }
    if (item.newAddress === "new-address" && item.pickUp !== 'sell-online') {
      // if (!item.address) {
      //   m = "Address is required.";
      // }
      if (!item.city) {
        m = "Please add your city.";
      }
      if (!item.state || item.state === "choose-state") {
        m = "State is required.";
      }
      if (!item.country || item.country === "choose-country" || item.country === '-1') {
        m = "Please select your country.";
      }
      if (!item.zipCode) {
        m = "Zip Code is required.";
      }
    }
    if (item.category === "cars") {
      if (!item.autoYear || item.autoYear === "select-year") {
        if (!item.autoMake || item.autoMake === "select-make") {
          m = "Choose a valid auto make.";
        }
        if (!item.autoModel || item.autoModel === "select-model") {
          m = "Choose a valid auto model.";
        }
        m = "Choose a valid auto year.";
      }


    }
    if (!item.featuredImage) {
      m = "You need to choose the main photo.";
    }
    if (
      item.pickUp !== "sell-online" &&
      (!item.newAddress || item.newAddress === "choose-where-to-buy")
    ) {
      m = "Choose Auto Listing Location";
    }
    if (!item.callForPricing && String(item.price).indexOf('.') >= 0 && String(item.price).length > 2 && String(item.price).indexOf('.') < String(item.price).length - 2) {
      m = 'Warning! Price cannot include comma(,). Also the dot(.) means decimals follow.'
    }

    return m;
  }

  return (
    <ListingPage
      section="autos"
      editListingUrl="/edit-auto-listing"
      itemType="Auto Listing"
      mainSubtitle="You're one step closer to success."
      checkRequirements={checkReqs}
      ItemFields={AutosEditFields}
    />
  );
}

export default AutosEdit;
