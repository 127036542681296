import React from 'react';

function ReviewsTab(props) {
    return (
        <div className="theReview">

            <div className="theReviewRating">
                {props.children}
            </div>

            <div className="theReviewText">
                {props.theReviewText}
            </div>

            <div className="theReviewMeta">
                by {props.theReviewAuthor} &nbsp;/&nbsp; {props.theReviewDate}
            </div>

        </div>
    );
}

export default ReviewsTab;