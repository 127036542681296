import React, { useState, useEffect } from 'react';
import LayoutSideBar from '../../../shared/util/layout-sidebar';

import CategoriesCatMobile from '../../../shared/components/CategoryMobile/CategoriesCatMobile';
import SubcategoriesCatMobile from '../../../shared/components/CategoryMobile/SubcategoriesCatMobile';
import SearchCatMobile from '../../../shared/components/CategoryMobile/SearchCatMobile';
import CreateNewCatMobile from '../../../shared/components/CategoryMobile/CreateNewCatMobile';

import Axios from 'axios';
import { hostNameAndPort } from '../../../shared/util/vars';

import { withRouter } from 'react-router';
// import ZSidebar from './zsidebar';

function SideBar(props) {
  const [oldCategoryList, setOldCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [activeCategory, setCategory] = useState('');
  const [activeSection, setSection] = useState('');
  const [subcategory, setSubcategory] = useState('');

  let { section, category, slug, isProfilePage } = props;

  let loc = props.location.pathname;
  if (loc) {
    loc = loc.split('/');
    if (!section) section = loc[1];
    if (loc[2]) category = loc[2];
    if (loc[3] && subcategory !== loc[3]) setSubcategory(loc[3]);
  }

  let categoryListChanged = false;
  if (categoryList.length !== oldCategoryList.length) categoryListChanged = true;
  else for (let i = 0; i < categoryList.length; i++)
    if (oldCategoryList[i] !== categoryList[i]) {
      categoryListChanged = true;
      break;
    }

  async function getCategories() {
    let url = `${hostNameAndPort}/api/${section}/categories`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setOldCategoryList(categoryList);
        setCategoryList(data.categories);
        if (props.onLoadCategories) props.onLoadCategories(data.categories);
      }
    } catch (err) {
      // server, network error
    }
  }

  useEffect(() => {
    if (section && section !== activeSection) getCategories();
  }, [section]);



  useEffect(() => {
    if (section !== activeSection || category !== activeCategory) {
      // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
      // setCategory(category);
      // setSection(section);
    }
  }, [section, category]);

  useEffect(() => {
    if (section !== activeSection || category !== activeCategory || categoryListChanged) {
      setSection(section);
      // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
      setCategory(category);
      setOldCategoryList(categoryList);
      setSubcategory('');
      changeSubcategories(category);
    }
  }, [section, category, categoryListChanged]);

  function onSubmitSearchHandler(q) {
    if (props.onSubmitSearch) props.onSubmitSearch(q);
  }

  function onChangeCategory(...args) {
    // setCategory(...args);
    changeSubcategories(...args);
    if (props.onChangeCategory) props.onChangeCategory(...args);
  }

  function changeSubcategories(category) {
    let i = 0;
    while (i < categoryList.length && categoryList[i] !== category) i++;
    let a = [];
    for (let j = i + 1; j < categoryList.length && /^\s*-+/.test(categoryList[j]); j++)
      a.push(categoryList[j].replace(/^\s*-+\s*/, ''));
    setSubcategoryList(a);
  }



  function onChangeSubcategory(v) {
    setSubcategory(v);
    if (props.onChangeSubcategory) props.onChangeSubcategory(activeCategory, v);// props.category,v);
  }

  const {
    searchTitle = 'Search',
    filterList = null,
    createNewTitle = 'Listing'
  } = props;



  return (<div className="category-mobile-sidebar">


    {/* {!isProfilePage && (props.section === 'realestate') &&
      <>
        <CategoriesCatMobile
          activeCategory={category}
          onChangeCategory={onChangeCategory}
          categoryList={categoryList}
          section={section}
        />

        <SubcategoriesCatMobile
          activeSubcategory={subcategory}
          onChangeSubcategory={onChangeSubcategory}
          subcategoryList={subcategoryList}
          section={section}
        />
      </>
    } */}

    <SearchCatMobile
      title={searchTitle}
      section={section}
      category={category}
      subcategory={subcategory}
      searchFilterList={filterList}
      onSubmitSearch={onSubmitSearchHandler}
      onChangeCategory={onChangeCategory}
      onChangeSubcategory={onChangeSubcategory}
      isProfilePage={isProfilePage}
      slug={slug}
    />


    <CreateNewCatMobile
      section={section}
      title={createNewTitle}
    />


  </div>);
}

export default withRouter(SideBar);