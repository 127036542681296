import actionType, { actions } from './action-type';

const initialState = {
    loggedInUser: null,
    userIsAuthenticated: false,
    deleteOperationConfirmed: false,
    confirmDeleteOperationModalActive: false,
    confirmDeleteOperationModalMessage: '',
    accountLoginModalActive: false,
    returnUrl: '/',
    accountInformationModalActive: false,
    signUpAccountType: 'personal',
    accountTypeModalActive: false,
    welcomeModalActive: false,
    mediaLightboxModalActive: false,
    alertModalActive: false,
    alertModalMessage: '',
    alertModalButtonLabel: '',
    alertModalRedirect: '',
    advertiseModalActive: false,
    advertiseItemType: '',
    advertiseItemId: '',
    advertiseIsFromCarsDB: false,
    advertiseIsFromRealestateDB: false,
    messagesDefaultMessage: '',
    messagesDefaultUser: null,
    pageRefresh: '',
    searchQuery: JSON.stringify({keywords:'',country:'USA',countryUpdated:'USA'}),
    userLanguage: 'en_EN',
    currentGeolocation: {},
    payperviewRedirect: false,
    referredBy: '',
    clientLongitude: 0,
    clientLatitude: 0,
    reason: '',
    windowLocation: '/',
    isRealestateAgency: false,
    signInRefresh: 0,

// from Messenger
    gotNewMessageFlag: false,
    currentConversation: 0,
    conversationTitle: {},
    lastMessage: [],
    seenMessage: [],
    conversationsHaveChangedOrder: false,
    conversationStatus: [],
    conversationListClassname: 'active'
};

function mainReducer(state = initialState, action) {
    switch (action.type) {
        case actionType.NEW_ACCOUNT_CREATED:
            return Object.assign({}, state, {
                accountInformationModalActive: action.accountInformationModalActive,
                accountLoginModalActive: action.accountLoginModalActive
            });
        case actionType.USER_LOGIN:
            return Object.assign({}, state, {
                userIsAuthenticated: action.userIsAuthenticated,
                loggedInUser: (!action.loggedInUser)?null:action.loggedInUser
            });
        case actionType.LOGIN_MODAL:
            return Object.assign({}, state, {
                accountLoginModalActive: action.accountLoginModalActive,
                returnUrl: action.returnUrl || '/'
            });
        case actionType.ACCOUNT_INFORMATION_MODAL:
            return Object.assign({}, state, {
                accountInformationModalActive: action.accountInformationModalActive,
                signUpAccountType: action.signUpAccountType || 'personal'
            });
        case actionType.ACCOUNT_TYPE_MODAL:
            return Object.assign({}, state, {
                accountTypeModalActive: action.accountTypeModalActive
            });
        case actionType.WELCOME_MODAL:
            return Object.assign({}, state, {
                welcomeModalActive: action.welcomeModalActive
            });
        case actionType.SIGN_IN_REFRESH:
            return Object.assign({}, state, {
                signInRefresh: action.signInRefresh
            });
        case actionType.ADVERTISE_MODAL:
            return Object.assign({}, state, {
                advertiseModalActive: action.advertiseModalActive,
                advertiseItemType: action.advertiseItemType,
                advertiseItemId: action.advertiseItemId,
                advertiseIsFromCarsDB: action.advertiseIsFromCarsDB,
                advertiseIsFromRealestateDB: action.advertiseIsFromRealestateDB
            });
        case actionType.CONFIRM_DELETE_OPERATION_MODAL:
            return Object.assign({}, state, {
                confirmDeleteOperationModalActive: action.confirmDeleteOperationModalActive,
                confirmDeleteOperationModalMessage: action.confirmDeleteOperationModalMessage,
            });
        case actionType.DELETE_OPERATION:
            return Object.assign({}, state, {
                deleteOperationConfirmed: action.deleteOperationConfirmed
            });
        case actionType.ALERT_MODAL:
            return Object.assign({}, state, {
                alertModalActive: action.alertModalActive,
                alertModalMessage: action.alertModalMessage,
                alertModalButtonLabel: action.alertModalButtonLabel,
                alertModalRedirect: action.alertModalRedirect,
            });
        case actionType.MEDIA_LIGHTBOX_MODAL:
            return Object.assign({}, state, {
                mediaLightboxModalActive: action.mediaLightboxModalActive
            });
        case actionType.VIEW_SEARCH_RESULTS:
            return Object.assign({}, state, {
                searchQuery: action.searchQuery
            });
        case actionType.PAGE_REFRESH:
            return Object.assign({}, state, {
                pageRefresh: action.pageRefresh
            });
        case actionType.MESSAGES_PAGE:
            return Object.assign({}, state, {
                messagesDefaultUser: action.messagesDefaultUser,
                messagesDefaultMessage: action.messagesDefaultMessage,
            });
        case actionType.UPDATE_USER_LANGUAGE:
            return Object.assign({}, state, {
                userLanguage: action.userLanguage
            });
        case actionType.CURRENT_GEOLOCATION:
            return Object.assign({}, state, {
                clientLongitude: action.clientLongitude,
                clientLatitude: action.clientLatitude
            });
        case actionType.PAYPERVIEW_REDIRECT:
            return Object.assign({}, state, {
                payperviewRedirect: action.payperviewRedirect
            });
        case actionType.REFERRED_BY:
            return Object.assign({}, state, {
                referredBy: action.referredBy,
            });
        case actionType.SELECT_REASON:
            return Object.assign({}, state, {
                reason: action.reason,
            });
        case actionType.UPDATE_LOCATION:
            return Object.assign({}, state, {
                windowLocation: action.windowLocation,
            });
        case actionType.REALESTATE_AGENCY:
            return Object.assign({}, state, {
                isRealestateAgency: action.isRealestateAgency,
            });
        
        // from Messenger
        case actions.NEW_MESSAGE_FLAG: 
            return Object.assign({}, state, {
                gotNewMessageFlag: action.gotNewMessageFlag
            })
        case actions.ACTIVE_CONVERSATION: 
            return Object.assign({}, state, {
                currentConversation: action.currentConversation
            })
        case actions.CONVERSATION_TITLE: 
            return Object.assign({}, state, {
                conversationTitle: action.conversationTitle
            })
        case actions.LAST_MESSAGE: 
            return Object.assign({}, state, {
                lastMessage: action.lastMessage
            })
        case actions.UPDATE_SEEN_MESSAGES: 
            return Object.assign({}, state, {
                seenMessage: action.seenMessage,
            })
        case actions.CONVERSATIONS_CHANGED_ORDER: 
            return Object.assign({}, state, {
                conversationsHaveChangedOrder: action.conversationsHaveChangedOrder
            })
        case actions.CONVERSATION_STATUS: 
            return Object.assign({}, state, {
                conversationStatus: action.conversationStatus
            })
        case actions.TOGGLE_HIDE_CONVERSATION_LIST:
            return Object.assign({}, state, {
                conversationListClassname: action.conversationListClassname
            })
        
        default:
            return state;
    }
};

export default mainReducer;
