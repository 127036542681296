import Axios from 'axios';
import {hostNameAndPort} from './vars';
import {isEqual} from './compare.js';
const DELAY_MIN = 1000;
const COUNT_MAX = 20;

/**
 * Class for loading elements asynchronously from an API point
 * @param cb user function called when done loading
 * @param options.delay wait time between requests
 * @param options.count number of items loaded in one request
 * @param options.request options for the request, including _url_, _method_, and _formParams_
 */
class ListLoader {
  constructor({
    cb = null,
    options = {
      delay: 3000,
      count: 20,
      request: {
        url: '',
        method: 'post',
        formParams: {}
      }
    }
  }) {
    if (!options.count) options.count = 20;
    if (options.count > COUNT_MAX) throw Error(`ListLoader: options.count cannot be greater than ${COUNT_MAX}.`);
    this.count = options.count;
    if (!options.delay) options.delay = 3000;
    if (options.delay < DELAY_MIN) throw Error(`ListLoader: options.delay cannot be smaller than ${DELAY_MIN}.`);
    this.delay = options.delay;
    if (!options.request.url) throw Error(`ListLoader: options.request.url cannot be an empty string.`);
    this.request = options.request;
    if (!this.request.method) this.request.method = 'post';
    if (!this.request.formParams) this.request.formParams = {};
    this.$items = null;
    this.waitId = 0;
    this.currentItem = 0;
    this.cb = cb;
  }
  
  get items() {
    return this.$items;
  }
  
  updateFormParams(formParams = {}) {
    if (isEqual(formParams, this.formParams)) {
      if (this.cb) this.cb();
      return;
    }
    this.request.formParams = formParams;
    this.$items = null;
    this.reset(true);
    this.init();
  }
  
  init() {
    if (this.waitId) this.reset();
    this.initLoading();
    this.waitId = setInterval(() => this.initLoading(), this.delay);
  }

  initLoading() {
    this.getNextItems()
      .then(result => {
        let { newItems } = result;
        if (!newItems) this.reset();
        else {
          if (Array.isArray(newItems)) {
            if (!this.$items) this.$items = [];
            this.$items.push(...newItems);
            if (this.cb)  this.cb();
          }
          else if (typeof newItems === 'object') {
            // For Search component we receive an object with arrays
            if (!this.$items) this.$items = {};
            for (let i in newItems) {
              if (!this.$items[i]) this.$items[i] = [];
              this.$items[i].push( ...newItems[i] );
            }
            if (this.cb)  this.cb();
          }
        }
      })
      .catch(err => {
        if (process.env.NODE_ENV === 'development') console.log(err);
        this.reset();
      });
  }

  getNextItems() {
    return new Promise((resolve, reject) => {
      let _data = new FormData();
      _data.append('start', this.currentItem);
      _data.append('count', this.count);
      for (let i in this.request.formParams) _data.append(i, this.request.formParams[i]);

      this.currentItem += this.count;

      let opts = {
        method: this.request.method,
        url: hostNameAndPort+this.request.url,
        data: _data
      };
      Axios(opts)
      .then(result => {
        let { data } = result;
        // console.log('list loader',data)
        if (!data.error) {
          if (isEmpty(data.items)) resolve({ newItems: null });
          else resolve({ newItems: data.items });
        }
        else resolve({ newItems: null });
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  reset = (isUpdate=false) => {
    clearInterval(this.waitId);
    this.currentItem = 0;
    this.waitId = 0;
    if (this.cb && !isUpdate) this.cb();
  }

  get isLoadingStill() {
    return !!this.waitId;
  }
}

function isEmpty(m) {
  if (Array.isArray(m) && m.length) return false;
  else if (typeof m === 'object') {
    for (let i in m) {
      if (Array.isArray(m[i]) && m[i].length) return false;
    }
  }
  return true;
}

export default ListLoader;