import React, { Component } from "react";
import Swiper from "react-id-swiper";
import ListingMediaModal from "../../../components/Modals/Media/ListingMediaModal";
import ReactTooltip from "react-tooltip";
import moment from 'moment';
import ListingMeta from './ListingMeta';

import { formatPriceAndCurrency, displayNumber } from "../../../util/number";
import AutoExteriorColor from "../../../../pages/Listings/Autos/Assets/auto-exterior-color.svg";
import EngageBox from "../../../../shared/uielements/Engage/EngageBox";
import { titleCase } from "../../../util/string";
import { getTranslatedPhrase } from "../../../util/lang";
import DefaultImage from "../../../../assets/default/default-events-listing-preview.jpg";
import DefaultAutoImage from "../../../../assets/default/default-auto-listing-preview.jpg";
import RealEstateFloorSize from "../../../../pages/Listings/RealEstate/Assets/realestate-surface.svg";
import CBALogo from '../../../../pages/Listings/RealEstate/Assets/cba-logo-white.png';
import { toHttps } from "../../../util/toHttps";

class ListingHeader extends Component {
    state = { 
        openModal: false,
        currentPhoto: -1,
        photos: [],
        a: ''
    };

    componentDidMount() {

        let a = this.props.address.trim();

        if (!a) {
            a = "0"; //Empire State Building';
        }

        a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#).?\s?\d+/g, "");
        a = a.replace(/\s\s*/g, "+");
        a = a.replace(/&/g, "and");

        const photoList = [...this.props.photos];
        const cleanPhotos = [];

        photoList.forEach(el => {
            cleanPhotos.push(el.replace(/\\/g, ''));
        })

        this.setState({ photos: cleanPhotos, a: a });
    }

    componentDidUpdate() {
        if (this.state.photos[0] !== this.props.photos[0])
            this.setState({ photos: this.props.photos });
    }

    onClickPhoto = (i) => {
        this.setState({
            openModal: true,
            currentPhoto: i
        });
    };

    onCloseMediaModal = () => {
        this.setState({
            openModal: false
        });
    };

    goBack = () => {
        let c = this.state.currentPhoto;
        if (!c) {
            c = this.state.photos.length - 1;
        } else {
            c = c - 1;
        }

        this.setState({
            currentPhoto: c
        });
    };

    goForward = () => {
        let c = this.state.currentPhoto + 1;
        if (c >= this.state.photos.length) {
            c = 0;
        }

        this.setState({
            currentPhoto: c
        });
    };

    addDefaultSrc = (ev) => {
        // const cleanPhotos = this.state.photos.filter(img => img !== ev.target.src);
        // this.setState({ photos: cleanPhotos });

        ev.target.parentElement.remove();
    };

    addDefaultSingleSrc = (ev) => {
        if (this.props.websiteSection === "autos" || this.props.websiteSection === "cars") {

            if (this.props.carPhotoUrl) {
                ev.target.src = toHttps(this.props.carPhotoUrl);
                this.props.photos[0] = toHttps(this.props.carPhotoUrl);
            } else {
                ev.target.src = DefaultAutoImage;
                this.props.photos[0] = DefaultAutoImage;
            }

        } else {
            ev.target.src = DefaultImage;
            this.props.photos[0] = DefaultImage;
        }
    }

    render() {
        const params = {
            slidesPerView: "auto",
            initialSlide: 0,
            keyboard: {
                enabled: !this.state.openModal,
                onlyInViewport: false
            },
            loop: true,
            rebuildOnUpdate: true,
            freeMode: false,
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        };



        if (!this.props.photos || !this.props.photos.length) {
            return null;
        }

        let timeOnMarket;

        if (this.props.listedDate) {
            timeOnMarket = moment().diff(moment(this.props.listedDate), 'days');
        }

        if (this.props.websiteSection === "cars") {
            if (this.props.carPhotoUrl) {
                this.props.photos[0] = toHttps(this.props.carPhotoUrl);
            } else {
                this.props.photos[0] = DefaultAutoImage;
            }
        }

        return (

            <>
                <div style={{ display: 'none' }}>
                    <ReactTooltip place="bottom" type="info" effect="solid" backgroundColor="#555555" />
                </div>

                <div className={`ProductDetailHeader ${this.props.pickUp === 'sell-online' ? 'ProductDetailHeader-SellOnline' : 'null'}`}>
                    <div className={`ProductDetailHeaderBox ${this.props.pickUp === 'sell-online' ? 'ProductDetailHeaderBox-Photo' : 'null'}`}>
                        <div
                            className={`product-listing-header-image ${this.props.websiteSection === "jobs" ? 'product-listing-header-image-jobs' : ''}`}>
                            {this.props.photos.length === 1 ?
                                <div key='first-photo' className="ProductDetailHeaderBoxinBox">
                                    <img onError={this.addDefaultSingleSrc} src={toHttps(this.props.photos[0])}
                                        alt="234093091" onClick={() => this.onClickPhoto(0)} />
                                </div> :
                                <Swiper {...params}>
                                    {this.state.photos.map((v, i) => (
                                        <div key={i}>
                                            <img onError={this.addDefaultSrc} src={toHttps(v)} alt={"Photo " + i}
                                                onClick={() => this.onClickPhoto(i)} />
                                        </div>
                                    ))}
                                </Swiper>
                            }
                        </div>


                            
                        
                    
                        


                        


                        <div className="listing-engage-box listing-header-engage-box">
                            <EngageBox
                                websiteSection={this.props.websiteSection}
                                itemId={this.props.itemId}
                                favoriteItemId={this.props.favoriteItemId}
                                authorId={this.props.authorId}
                                withComments={false}>
                                {/*<Rate noStars={this.props.productStars} noReviews={this.props.reviewCount} />*/}
                                <button className="print-button" onClick={window.print}><i
                                    className="fal fa-print" /></button>
                            </EngageBox>
                        </div>


                    </div>

                    {this.props.pickUp !== 'sell-online' && this.state.a && 
                        <div className="ProductDetailHeaderBox">
                            <iframe
                                frameBorder="0"
                                style={{ border: "0" }}
                                title="locationMap"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC3sPCT7W11tTP8LCANvOBoKe0dK08AYoI&q=${this.state.a}`}
                                allowFullScreen
                            />
                        </div>
                    }

                    {this.props.pickUp === 'sell-online' &&
                        <div className="ProductDetailHeaderBox ProductDetailHeaderBox-ListingMeta">
                            <ListingMeta
                                websiteSection={this.props.websiteSection}
                                shareItemId={this.props.shareItemId}
                                itemIsShared={this.props.itemIsShared}
                                shareCount={this.props.shareCount}
                                viewCount={this.props.viewCount}
                                authorId={this.props.authorId}
                                productDetailTitle={this.props.productDetailTitle}
                                brandName={this.props.brandName}
                                authorSlug={this.props.authorSlug}
                                authorName={this.props.authorName}
                                authorProfileImage={this.props.authorProfileImage}
                                categoryName={this.props.category}
                                subcategory={this.props.subcategory || 'other'}
                                productCost={this.props.price}
                                productRepeat={this.props.subcategory}
                                afterDiscountPrice={this.props.afterDiscountPrice}
                                PropertyDetailAddress={this.props.PropertyDetailAddress}
                                productCurrency={this.props.productCurrency}
                                callForPricing={this.props.callForPricing}
                                noLove={this.props.noLove}
                                productIsLoved={this.props.productIsLoved}
                                productStars={this.props.productStars}
                                reviewCount={this.props.reviewCount}
                                sellerPhone={this.props.sellerPhone}
                                sellerEmail={this.props.sellerEmail}
                                userIsAuthenticated={this.props.userIsAuthenticated}
                                pickUpLocation={this.props.pickUpLocation}
                                ticketSaleURL={this.props.ticketSaleURL}
                                buttonLabel="Buy now"
                                numberToCall={this.props.numberToCall}
                                onChangeTab={this.props.onChangeTab}
                                pickUp={this.props.pickUp}
                            />
                        </div>
                    }

                    {(this.state.currentPhoto === -1) ? null : (
                        <ListingMediaModal
                            active={this.state.openModal}
                            onClickCloseHandler={this.onCloseMediaModal}
                            photoElement={<img src={this.state.photos[this.state.currentPhoto]}
                                alt={this.state.photos[this.state.currentPhoto]} />}
                            showArrows={(this.state.photos.length > 1)}
                            goBack={this.goBack}
                            goForward={this.goForward}
                        // goBackWithArrowLeft={this.goBackWithArrowLeft}
                        />)}
                </div>
            </>
        );
    }

}

export default ListingHeader;
