import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import { connect } from 'react-redux';
import Axios from 'axios';
import { hostNameAndPort } from '../../../util/vars';

class PopularCategories extends Component {
    state = {
        showMoreOrLess: 'Show More',
        categoryCount: 10,
        categoryList: [],
        userLanguage: '',
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({
                userLanguage: v,
                showMoreOrLess: getTranslatedPhrase(this.props.userLanguage, this.state.showMoreOrLess)
            });
        }
        let a = this.state.categoryList;
        let b = this.props.categoryList.filter(v => (!/^\s*-+/.test(v)));
        let isDifferent = false;
        if (a.length === b.length) {
            for (let i in a) {
                if (a[i] !== b[i]) {
                    isDifferent = true;
                    break;
                }
            }
        } else isDifferent = true;
        if (isDifferent)
            this.setState({ categoryList: [...b] });
    }

    onClickMore = () => {
        let s = this.state.showMoreOrLess;
        if (s === getTranslatedPhrase(this.props.userLanguage, 'Show More')) {
            s = getTranslatedPhrase(this.props.userLanguage, 'Show Less');
        } else {
            s = getTranslatedPhrase(this.props.userLanguage, 'Show More');
        }
        let n = this.state.categoryCount;
        let a;
        if (!this.props.categoryList) {
            a = 10;
        } else {
            a = this.props.categoryList.length;
        }

        if (n === a) {
            n = 10;
        } else {
            n = a;
        }
        this.setState({
            showMoreOrLess: s,
            categoryCount: n,
        });
    }

    onClickHandler = (v) => {
        if (v !== this.props.activeCategory) this.props.onChangeCategory(v);
    }

    getClassNameFor = (v) => {
        if (v !== this.props.activeCategory) return 'main-sidebar_category-active';
        return 'main-sidebar_category-inactive';
    }

    editCategories = () => {
        let popup = window.open('about:blank', '_blank', 'height=650,width=300');
        let title = document.createElement('h2');
        title.innerText = 'Edit ' + this.props.section + ' categories';
        popup.document.body.append(title);
        let txtArea = document.createElement('textarea');
        txtArea.cols = 30;
        txtArea.rows = 30;
        this.props.categoryList.forEach((v, i) => {
            txtArea.value = txtArea.value + v + '\n';
        });
        popup.document.body.append(txtArea);
        let btn = document.createElement('button');
        btn.innerHTML = 'Submit';
        btn.onclick = (e) => {
            let _data = new FormData();
            _data.append('categories', txtArea.value);
            let options = {
                url: `${hostNameAndPort}/api/admin/edit-categories/${this.props.section}`,
                method: 'post',
                data: _data
            };
            Axios(options)
                .then(result => {
                    let { data } = result;
                    if (!data.error) {
                        popup.close();
                    } else console.log(data.error);
                    return 0;
                })
                .catch(err => {
                    if (process.env.NODE_ENV === 'development') console.log(err);
                });
        };
        popup.document.body.append(btn);
        let btn2 = document.createElement('button');
        btn2.innerHTML = 'Cancel';
        btn2.onclick = (e) => {
            popup.close();
        };
        popup.document.body.append(btn2);
        let div1 = document.createElement('div');
        div1.innerText = '\r\nTo add a category, write it on a new line. To add a subcategory, put a dash in front of the text. For example: - subcategoryName.';
        popup.document.body.append(div1);
    }

    render() {
        if (!this.props.categoryList) {
            return null;
        }
        else {
            return (
                <div className="main-sidebar_box main-sidebar_box-categories">
                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Popular Categories')}</h4>

                    <ul>
                        {this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin') && (<li><button className="main-sidebar_category-inactive" style={{ color: '#EE6565' }} onClick={this.editCategories}><i className="fal fa-wrench" /> Edit Categories</button></li>)}
                        <li><button className={this.getClassNameFor('')} onClick={() => this.props.onChangeCategory('')}>{getTranslatedPhrase(this.props.userLanguage, 'All Categories')}</button></li>
                        {this.state.categoryList.slice(0, this.state.categoryCount).map((v, i) => (<li key={i}>
                            <button className={this.getClassNameFor(v)} onClick={() => this.onClickHandler(v)}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ')))}</button>
                        </li>))}
                    </ul>

                    {!!this.state.categoryList && this.state.categoryList.length > 10 && (
                        <button onClick={this.onClickMore} className="show-more-link">{this.state.showMoreOrLess}</button>
                    )}
                </div>
            );
        }
    }
}

const mapStateToPopularCategoriesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToPopularCategoriesProps, null)(PopularCategories);