import React, { Component } from 'react';
import ProfileSettingsBox from '../ProfileSettingsBox';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Box from '../../../../shared/util/box';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import ListLoader from '../../../../shared/util/list-loader';
import SettingsMenu from "../SettingsMenu";

class PurchaseHistory extends Component {
    state = {
        transactionList: [],
    }

    componentDidMount() {
        setTimeout( () => {
            this.initLoader({ filter: JSON.stringify({}) });
      },500);
    }

    initLoader = (formParams) => {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 3000,
                count: 20,
                request: {
                url: '/api/pay/purchases',
                method: 'post',
                formParams
                }
            }
        });
        Loader.init();
        this.setState({ Loader });
    }

    onLoad = () => {
        if (!this.state.Loader || !this.state.Loader.items) return;
        this.setState({ transactionList: this.state.Loader.items });
    }

    tryToTranslate = (s,h) => {
        if (!s) {
            return '';
        }
        if (s.indexOf('subscription')>=0) {
            return getTranslatedPhrase(h,s);
        }
        if (s === 'Undetermined') {
            return getTranslatedPhrase(h,s);
        }
        if (s.indexOf('Cancelled')>=0) {
            return getTranslatedPhrase(h,'Cancelled')+s.slice(9);
        }
        return s;
    }

    render () {
        return (
          <SettingsMenu PurchaseHistoryLiActive="active">

                
                <ProfileSettingsBox>
                
                <h4>{getTranslatedPhrase(this.props.userLanguage, "Purchase History")}</h4>

                <div className="posts-history-section purchase-history-section_manage-listings">

                    {(!this.state.transactionList.length)?(<p>{getTranslatedPhrase(this.props.userLanguage,'You have not purchased any services.')}</p>):

                    (<Box>
                        <h4>{getTranslatedPhrase(this.props.userLanguage,'Subscriptions')}</h4>
                        <table><tbody>
                        <tr className="tableHeader">
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Order ID')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'From')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Until')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Description')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Order Date')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Amount')}</td>
                        </tr>

                        {this.state.transactionList.map( (v,i) => (/subscription/.test(v.description))?(<tr key={'transaction-'+i}>
                            <td className="txtr">{v.orderId}</td>
                            <td className="txtr">{v.startDate}</td>
                            <td>{this.tryToTranslate(v.endDate, this.props.userLanguage)}</td>
                            <td>{this.tryToTranslate(v.description, this.props.userLanguage)}</td>
                            <td className="txtr">{v.creationDate}</td>
                            <td className="txtr">${v.amount}</td>
                        </tr>):null  )}
                    </tbody>   
                    </table>
                    
                    <br /><br />
                    <h4>{getTranslatedPhrase(this.props.userLanguage,'Ads')}</h4>
                    <table><tbody>
                        <tr className="tableHeader">
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Order ID')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'From')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Until')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Description')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Order Date')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Amount')}</td>
                        </tr>

                        {this.state.transactionList.map( (v,i) => (/subscription/.test(v.description))?null:(<tr key={'transaction-'+i}>
                            <td className="txtr">{v.orderId}</td>
                            <td className="txtr">{v.startDate}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,v.endDate)}</td>
                            <td>{this.tryToTranslate(v.description, this.props.userLanguage)}</td>
                            <td className="txtr">{v.creationDate}</td>
                            <td className="txtr">${v.amount}</td>
                        </tr>)  )}
                    </tbody>   
                    </table>

                    <LoadingSvg active={this.state.waitForNewPostsIntervalId} />

                    </Box>)}
                </div>
                </ProfileSettingsBox>


          </SettingsMenu>
        );
    }
}


const mapStateToPurchaseHistoryProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToPurchaseHistoryProps, null)(PurchaseHistory);