import React, { Component } from 'react';
// import bbbLogo from './badges/bbb-logo.png';
// import nortonLogo from './badges/norton-secured-logo.png';

class footerBadges extends Component {
    state = {
        // scriptSrc: "https://seal.websecurity.norton.com/getseal?host_name=www.followone.com&amp;size=L&amp;use_flash=NO&amp;use_transparent=No&amp;lang=en",
        // scriptParent: null,
        // scriptInserted: false,
    }

    componentDidMount() {
        let dn = "followone.com";
        let lang = "en";
        let tpt = "opaque";
        // let vrsn_style = "WW";
        let splash_url = "https://trustsealinfo.websecurity.norton.com";
        let sslcenter_url = "https://www.websecurity.symantec.com/security-topics/what-is-ssl-tls-https";
        let seal_url = "https://seal.websecurity.norton.com";
        let u1 = splash_url + "/splash?form_file=fdf/splash.fdf&dn=" + dn + "&lang=" + lang;
        let u2 = seal_url + "/getseal?at=0&sealid=0&dn=" + dn + "&lang=" + lang + "&tpt=" + tpt;

        this.setState({
            splash_url: splash_url,
            sslcenter_url: sslcenter_url,
            seal_url: seal_url,
            u1: u1,
            u2: u2,
            u5: sslcenter_url,
            sopener: false
        });
    }

    symcBuySSL = () => {
        let win = window.open(this.state.u5, '_blank');
        win.focus();
    }

    vrsn_splash = () => {
        if (this.state.sopener && !this.state.sopener.closed) {
            this.state.sopener.focus();
        } else {
            let tbar = "location=yes,status=yes,resizable=yes,scrollbars=yes,width=560,height=500";
            let sw = window.open(this.state.u1, 'VRSN_Splash', tbar);
            if (sw) {
                sw.focus();
                this.setState({ sopener: sw });
            }
        }
    }

    render() {
        /* <br /><a href="https://www.websecurity.symantec.com/ssl-certificate" target="_blank"  style={{color:"#000000", textDecoration:"none", font:"bold 10px verdana,sans-serif", letterSpacing: ".5px", textAlign: "center", margin: "0px", padding: "0px"}}> ABOUT SSL CERTIFICATES</a> */
        return (
            <>
                <div className="clb" />
                <div className="footer-badges">
                    {/* <a href="https://www.bbb.org/northwest/business-reviews/social-media-marketing/followone-inc-in-kirkland-wa-90008442/#sealclick" id="bbblink" className="ruhzbum" target="_blank" rel="nofollow noopener noreferrer"><img src="https://seal-boise.bbb.org/logo/ruhzbum/bbb-90008442.png" style={{ border: 0 }} alt="Followone, Inc BBB Business Review" /></a> */}

                    {/*<table width="135" border="0" cellPadding="2" cellSpacing="0" title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications.">*/}
                    {/*    <tbody><tr>*/}
                    {/*        <td width="135" align="center" valign="top">*/}
                    {/*            <img name="seal" src={this.state.u2} onContextMenu={() => false} border="0" useMap="#sealmap_large" alt="" />*/}
                    {/*            <map name="sealmap_large" id="sealmap_large" >*/}
                    {/*                <area alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" onClick={this.vrsn_splash} shape="rect" coords="0,0,130,65" tabIndex="-1" style={{ outline: "none" }} />*/}
                    {/*                <area alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" onClick={this.vrsn_splash} shape="rect" coords="0,65,71,88" tabIndex="-1" style={{ outline: "none" }} />*/}
                    {/*                <area alt="" title="" onClick={this.symcBuySSL} shape="rect" coords="71,65,130,88" style={{ outline: "none" }} />*/}
                    {/*            </map>*/}
                    {/*        </td>*/}
                    {/*    </tr></tbody></table>*/}
                </div>
            </>
        );
    }
}

export default footerBadges;