import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { withRouter } from 'react-router-dom';
import { getTranslatedPhrase } from '../../util/lang';


class CreateNewCatMobile extends Component {
    onClickHandler = () => {
        if (!this.props.userIsAuthenticated) {
            this.props.onClickLoginButtonHandler();
            return;
        }
        let x = this.props.section;
        switch (x) {
            case 'store': x = '/store/new-product'; break;
            case 'food': x = '/food/new-food-listing'; break;
            case 'autos': x = '/autos/new-auto-listing'; break;
            case 'cars': x = '/autos/new-auto-listing'; break;
            case 'realestate': x = '/realestate/new-property'; break;
            case 'jobs': x = '/jobs/new-job'; break;
            case 'services': x = '/services/new-service'; break;
            case 'events': x = '/events/new-event'; break;
            case 'travel': x = '/travel/new-travel'; break;
            case 'blogs': x = '/blogs/new-blog'; break;
            case 'photos': x = '/photos/new-photo'; break;
            case 'videos': x = '/videos/new-video'; break;
            case 'groups': x = '/groups/new-group'; break;
            case 'news': x = '/news/new-news'; break;
            default: x = ''; break;
        }
        if (x) this.props.history.push(x);
        if (this.props.onClickHandler) this.props.onClickHandler();
    }

    render() {
        return (
            <button onClick={this.onClickHandler}><i className="fal fa-plus" /></button>
        );
    }
}

const mapStateToCreateNewButtonProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated
    };
};

const mapDispatchToCreateNewButtonProps = (
    dispatch
) => {
    return {
        onClickLoginButtonHandler: () => {
            dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname });
        },
    };
};

export default connect(mapStateToCreateNewButtonProps, mapDispatchToCreateNewButtonProps)(withRouter(CreateNewCatMobile));