import React from 'react';
import Modal from '../../../uielements/Modal/Modal';

const AdvTravel = props => {
    return (
        <Modal
            title="Travel Advanced Search"
            closeModal={props.closeModal}
            modalClass="medium"
            modalBodyClass="adv-body"
        >
            <div className="adv-search">
                <div className="adv-search-content">
                    <div className="adv-search-content-box">
                        <p><strong>TYPE</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">Select Category</option>
                                {/* Generate Categories from DB */}
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">Select Subcategory</option>
                                {/* Generate Subcategories from DB */}
                            </select>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="transaction" id="buy" value="buy" />
                            <label htmlFor="buy"><i className="fal fa-check" />Buy</label>
                        </div>

                        {/* Show if cateogry is residential. */}
                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="transaction" id="rent" value="rent" />
                            <label htmlFor="rent"><i className="fal fa-check" />Rent</label>
                        </div>

                        {/* Show if cateogry is commercial. */}
                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="transaction" id="lease" value="lease" />
                            <label htmlFor="lease"><i className="fal fa-check" />Lease</label>
                        </div>

                        <p className="mgt-md"><strong>PRICING</strong></p>

                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min $" />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max $" />
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="discount" id="discount" value="discount" />
                            <label htmlFor="discount"><i className="fal fa-check" />Deals</label>
                        </div>
                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>LOCATION</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">Country </option>
                                {/* Get Countries from JS */}
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">State/Province </option>
                                {/* Get States/Provinces from JS */}
                            </select>
                        </div>

                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Zip/Postal Code" />

                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="City" />

                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Street" />
                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>OTHER OPTIONS</strong></p>

                        <p className="input-label">Floor Size:</p>
                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min SF" />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max SF" />
                        </div>

                        <p className="input-label">Year Built:</p>
                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min" />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max" />
                        </div>

                        {/* Show if cateogry is residential. */}
                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">Bedroom Count</option>
                                {/* Generate Subcategories from DB */}
                            </select>
                        </div>

                        {/* Show if cateogry is residential. */}
                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" disabled="">Bathroom Count</option>
                                {/* Generate Subcategories from DB */}
                            </select>
                        </div>
                    </div>

                </div>
                <div className="adv-search-buttons">
                    <button className="button button-regular">Search</button>
                    <button className="button button-white" onClick={props.closeModal}>Cancel Search</button>
                </div>
            </div>
        </Modal >
    )
}

export default AdvTravel;