import React from 'react';
import SideBarMobile from './category-sidebar-mobile';

function RealEstateSideBarMobile(props) {

  let searchLabel = '';

  if (props.isProfilePage) {
    searchLabel = 'Enter Keywords'
  } else {
    searchLabel = 'Enter Keywords or Agency Name'
  }

  return (<SideBarMobile {...props}
    section="realestate"
    searchTitle="Search Properties"
    createNewTitle="New Property"
    filterList={[
      { name: 'keywords', value: searchLabel },
    ]}
  />);
}

export default RealEstateSideBarMobile;