import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getTranslatedPhrase } from '../../../shared/util/lang';

import actionType from '../../../redux-store/action-type';
import { connect } from 'react-redux';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';
import { Link } from 'react-router-dom';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';


class VideoPreview extends Component {
    onClickHandler = () => {
        let urlSlug = `/videos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;
        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push(urlSlug || '/videos');
        this.props.pageShouldRefresh(urlSlug);
    }

    render() {
        if (!this.props.slug) {
            return null;
        }
        let { onClickOnFavorite = () => null } = this.props;

        let linkSlug = `/videos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;

        return (
            <div className="category-list_item video-item-preview">


                <span className="resp-video-cat">
                    <iframe title={this.props.title} src={this.props.videoURL} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                    <Link to={linkSlug} onClick={this.onClickHandler}><button className="video-cat-modal-link" /></Link>
                    {/* <button className="video-cat-modal-link" onClick={this.onClickHandler}></button> */}
                </span>

                <div className="slp mgt-md">
                    <p className="slp-title">{this.props.title}</p>
                    <p className="slp-brand">
                        <span>{getTranslatedPhrase(this.props.userLanguage,'by')}</span> {this.props.authorName}
                    </p>

                    <div className="slp-buttons">
                        <Link className="button button-clear button-small dsp-in-blk" to={linkSlug} onClick={this.onClickHandler}>{getTranslatedPhrase(this.props.userLanguage, 'See Video')}</Link>
                        <ListingPreviewAdminPanel
                          itemType="videos"
                          itemSlug={this.props.slug}
                          itemId={this.props.itemId}
                          userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                        />
                    </div>

                    <div className="slp-social">
                        <EngageBox
                          websiteSection="videos"
                          itemId={this.props.itemId}
                          authorId={this.props.authorId}
                          hideAllComments={true}
                          withComments={true}
                          getClickToCommentEvent={true}
                          onLeaveComment={this.onClickHandler}
                          onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToVideoPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};


const mapDispatchToVideoPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToVideoPreviewProps, mapDispatchToVideoPreviewProps)(withRouter(VideoPreview));