import React from 'react';
import shortFormNumber from './ShortFormNumber';
// const defaultClick = () => null;

const ViewsButton = (props) => {
    let e = (!props.isClicked)?'l':'s';
    // if (!props.isDisabled) {
    //     return (
    //         <button className="engage_buttons_views" onClick={props.onClick || defaultClick}>
    //         {shortFormNumber(props.noViews)} <i className={`fa${e} fa-eye`}></i>
    //         </button>);
    // }
    return null; 
    // (
        // <span className="engage_buttons_views">
        //     <i className={`fa${e} fa-eye`} /> <span className="engage_button_numbers"> {shortFormNumber(props.noViews)} </span>
        // </span>
        // );
}

export default ViewsButton;