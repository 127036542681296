import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { hostNameAndPort } from '../../util/vars';
import Axios from 'axios';

export default function FeaturedItems(props) {
    let [featuredItems, setFeaturedItems] = useState([]);
    let [activeCategory, setCategory] = useState('-');
    let [activeSection, setSection] = useState('-');
    const { section = 'all', category = 'all' } = props;

    useEffect(() => {
        if (section !== activeSection || category !== activeCategory) {
            if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
            setCategory(category);
            setSection(section);
            getFeatured();
        }
    }, [category]);

    async function getFeatured(k) {
        let travelListParams = new FormData();
        travelListParams.append('section', section);
        travelListParams.append('category', category);
        travelListParams.append('count', 6);
        let options = {
            url: `${hostNameAndPort}/api/adv/featured`,
            method: 'post',
            data: travelListParams
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setFeaturedItems(data.items);
            }
        } catch (err) {
            // Error
        }
    }

    const params = {
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 20,
        initialSlide: 1,
        loop: false,
        freeMode: false,
        pagination: {
            el: '.swiper-pagination'
        }
    };

    if (!featuredItems || !featuredItems.length) {
        return null;
    }

    let featuredClassName = "main-featured-slider featured-slider-photo";

    if (section === "blogs") {
        featuredClassName = "featured-slider";
    }

    return (
        <div className={featuredClassName}>
            <Swiper {...params}>
                {featuredItems.map((v, i) => (<div key={'featureditem-' + i}><props.FeaturedItemPreview {...v} /></div>))}
            </Swiper>
        </div>
    );
}
