import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function ServicesSideBar(props) {
  return (<SideBar {...props} 
    section="services"
    searchTitle="Search Services"
    createNewTitle="New Service"
    filterList={[
      { name:'keywords', value: 'Enter Keywords' }, 
    ]}
  />);
}

export default ServicesSideBar;