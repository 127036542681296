import React, {Component} from 'react';
import {formatPriceAndCurrency} from '../../../shared/util/number';
import EngageButtons from '../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import Rate from '../../../shared/uielements/Engage/EngageButtons/RateButton';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';

class StoreFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
            <div className="featured-item featured-store-listing">
                
                <Link className="featured-store-listing_image" to={`/store/${v.category}/${v.subcategory || 'other'}/${v.slug}`}>
                    <img src={v.featuredImage} alt={v.title} /> 
                </Link>
                
                
                <Link className="featured-store-listing_details" to={`/store/${v.category}/${v.subcategory || 'other'}/${v.slug}`}>
                    <h3>{v.title}</h3>
                    <span>{getTranslatedPhrase(v.userLanguage,'by')} {v.authorName}</span>
                    
                    <EngageButtons>
                        <Rate noStars={v.averageRating} />
                    </EngageButtons>
                    
                    <span className="featured-store-listing_details_price">{formatPriceAndCurrency(v.price, v.currency, ',' )}</span>
                </Link>
            </div>
        );
    }
}

const mapStateToStoreFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToStoreFeaturedPreviewProps, null)(StoreFeaturedPreview);