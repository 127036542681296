import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../shared/util/lang';

class MemberTile extends Component {
    render () {
        return (
            <div className="ServicesTile">
                <div className="ServicesTile__FeaturedImage">
                    {this.props.MemberFeaturedImage}
                </div>

                <div className="ServicesTile__BrandLogo">
                    {this.props.MemberBrandLogo}
                </div>

                <h4>{this.props.MemberBusinessName}</h4>

                <div className="ServicesTile__BusinessRating">
                    {this.props.MemberBusinessRating}
                </div>

                {/* <div className="ServicesTile__BusinessDescription">
                    {this.props.MemberBusinessDescription}
                </div> */}

                <div className="ServicesTile__BusinessDetails">
                    {this.props.children}
                    <div className="ServicesTile__LearnMoreBtn">
                        <Link to={this.props.MemberURL}>
                            <button className="button button-clear">{this.props.ButtonLabel || getTranslatedPhrase(this.props.userLanguage,'View Profile')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToMemberTileProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToMemberTileProps, null)(MemberTile);
