import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function StoreSideBarMobile(props) {
  return (<SideBarMobile {...props}
    section="store"
    searchTitle="Search Products"
    createNewTitle="New Store Listing"
    filterList={[
      { name: 'keywords', value: 'Enter Keywords' },
    ]}
  />);
}

export default StoreSideBarMobile;