import Axios from 'axios';
import {isEqual} from './compare.js';
import {hostNameAndPort} from './vars.js';

const COUNT_MAX = 20;

/**
 * Generic class for loading a section page
 */
class SectionLoader {
  constructor({
    section = '',
    cb = null,
    options = {
      page: 1,
      pageCount: 18,
      noItems: false,
      noCount: false,
      formParams: {}
    }
  }) {
    if (!section) throw Error('SectionLoader: section cannot be an empty string.');
    this.$section = section;
    if (!options.page) options.page = 1;
    if (options.page < 1) throw Error('SectionLoader: options.page cannot be smaller than 1.');
    this.$page = options.page;
    if (!options.pageCount) options.pageCount = 18;
    if (options.pageCount > COUNT_MAX) throw Error(`SectionLoader: options.pageCount cannot be greater than ${COUNT_MAX}.`);
    this.$pageCount = options.pageCount;
    this.formParams = options.formParams;
    this.$items = [];
    this.$totalCount = 0;
    this.cb = cb;
    this.$noItems = options.noItems || false;
    this.$noCount = options.noCount || false;
    this.counted = false;
    this.loaded = false;
    if (!this.$noCount) this.getCount(); else this.counted = true;
    if (!this.$noItems) this.getItems(); else this.loaded = true;
    if (this.counted && this.loaded && this.cb) this.cb({ error: 'SectionLoader: No items.' });
  }

  get totalCount() {
    return this.$totalCount;
  }

  get items() {
    return this.$items;
  }

  updatePage(value = 1, force = false) {
    if (value < 1) throw Error('SectionLoader: page number cannot be smaller than 1.');
    if (value === this.$page && !force) {
      if (this.cb) this.cb({ error: 'SectionLoader: Same page.' });
      return;
    }
    if ((value-1) * this.$pageCount > this.$totalCount)
      throw Error(`SectionLoader: page number cannot be greater than ${Math.ceil(this.$totalCount / this.$pageCount)+1}.`);
    this.$page = value;
    this.$items = [];
    this.counted = true;
    this.getItems();
  }

  updateSection(section = '', force = false) {
    if (!section) throw Error('SectionLoader: section cannot be an empty string.');
    if (section === this.$section && !force) {
      if (this.cb) this.cb({ error: 'SectionLoader: Same section.' });
      return;
    }
    this.$page = 1;
    this.$section = section;
    this.$items = [];
    this.$totalCount = 0;
    this.counted = false;
    this.loaded = false;
    if (!this.$noCount) this.getCount(); else this.counted = true;
    if (!this.$noItems) this.getItems(); else this.loaded = true;
    if (this.counted && this.loaded && this.cb) {
      this.cb({ error: 'SectionLoader: No items.' });
    }
  }

  async updateFormParams(formParams = {}, force = false) {
    if (isEqual(formParams, this.formParams) && !force) {
      if (this.cb) this.cb({ error: 'SectionLoader: Same form parameters.' });
      return;
    }
    this.$page = formParams.page || 1;
    this.formParams = formParams;
    this.counted = false;
    this.loaded = false;
    this.$noItems = formParams.noItems || false;
    this.$noCount = formParams.noCount || false;
    if (!this.$noCount) this.getCount(); else this.counted = true;
    if (!this.$noItems) this.getItems(); else this.loaded = true;
    if (this.counted && this.loaded && this.cb) this.cb({ error: 'SectionLoader: No items.' });
  }

  set pageCount(value = 1) {
    if (value > COUNT_MAX) throw Error(`SectionLoader: pageCount cannot be greater than ${COUNT_MAX}.`);
    if (value < 1) throw Error('SectionLoader: pageCount cannot be smaller than 1');
    this.$pageCount = value;
  }

  async getCount() {
    try {
      let result = await this.$getSection('count');
      this.$totalCount = result;
      if (this.cb && this.loaded) {
        this.counted = false;
        this.loaded = false;
        this.cb({ totalCount: result, items: this.$items });
      } else {
        this.counted = true;
      }
      return result;
    } catch(e) {
      if (this.cb) this.cb({ error: e });
    }
  }

  async getItems() {
    try {
      let result = await this.$getSection('items');
      this.$items = result;
      if (this.counted && !this.$totalCount) {
        if (Array.isArray(this.$items) && this.$items.length) this.counted = false;
        if (typeof this.$items === 'object') {
          let a = Object.keys(this.$items);
          for (let i in a)
            if (a[i].length) {
              this.counted = false;
              break;
            }
        }
      }
      
      if (this.cb && this.counted) {
        this.loaded = false;
        this.counted = false;
        this.cb({ items: result, totalCount: this.$totalCount });
      } else {
        this.loaded = true;
      }
      return result;
    } catch(e) {
      if (this.cb) this.cb({ error: e });
    }
  }

  $getSection(t) {
    if (!t) throw Error('SectionLoader: you must choose between count or items.');
    return new Promise((resolve, reject) => {
      let _data = new FormData();
      if (t === 'items') {
        _data.append('start', (this.$page-1) * this.$pageCount);
        _data.append('count', this.$pageCount);
      }
      for (let i in this.formParams) {
        // console.log(i,this.formParams[i])
        _data.append(i, this.formParams[i]);
      }
      
      let url = hostNameAndPort+'/api/'+this.$section+'/'+t;
      let opts = {
        method: 'post',
        url,
        data: _data
      };
      Axios(opts)
      .then(result => {
        let { data } = result;
        // console.log((this.$page-1) * this.$pageCount, data)
        if (!data.error) resolve(data[t]);
        else reject(data.error);
      })
      .catch(err => {
        reject(err);
      });
    });
  }    
}

export default SectionLoader;