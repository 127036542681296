import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';
import { Link } from 'react-router-dom';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';
import EngageButtons from '../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import FavoritesButton from '../../../shared/uielements/Engage/EngageButtons/FavoritesButton';
import LoveButton from '../../../shared/uielements/Engage/EngageButtons/LoveButton';
import ViewsButton from '../../../shared/uielements/Engage/EngageButtons/ViewsButton';


class PhotoGalleryPreview extends Component {
    onClickHandler = () => {
        let urlSlug = `/photos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;
        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push(urlSlug || '/photos');
        this.props.pageShouldRefresh(urlSlug);
    }

    render() {
        if (!this.props.slug) {
            return null;
        }
        let { onClickOnFavorite = () => null } = this.props;
        let linkSlug = `/photos/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;

        return (
            <div className="media-gallery-category_listing">

                {/* <button onClick={this.onClickHandler}>
                    <img src={`${this.props.featuredImage}`} alt={this.props.title} />
                </button> */}

                <Link to={linkSlug} onClick={this.onClickHandler} className="photo-preview-img-header" style={{ backgroundImage: `url(${this.props.featuredImage})` }}>
                </Link>

                <div className="slp mgt-md">
                    <p className="slp-title">{this.props.title}</p>
                    <p className="slp-brand">
                        <span>{getTranslatedPhrase(this.props.userLanguage, 'by')}</span> {this.props.authorName}
                    </p>

                    <div className="slp-buttons">
                        <Link className="button button-clear button-small dsp-in-blk" to={linkSlug} onClick={this.onClickHandler}>{getTranslatedPhrase(this.props.userLanguage, 'See Gallery')}</Link>
                        <ListingPreviewAdminPanel
                            itemType="photos"
                            itemSlug={this.props.slug}
                            itemId={this.props.itemId}
                            userIsAuthorized={this.props.description !== 'Profile Photos' && (this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service')))}
                        />
                    </div>


                    <div className="slp-social">
                        <EngageBox
                            websiteSection={"photos"}
                            itemId={this.props.itemId}
                            authorId={this.props.authorId}
                            withComments={true}
                            hideAllComments={true}
                            getClickToCommentEvent={true}
                            onLeaveComment={this.onClickHandler}
                            onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>


                    {/* {!this.props.onlyFavoritesButton?(
                      <div className="slp-social">
                      <EngageBox
                        websiteSection={"photos"}
                        itemId={this.props.itemId}
                        authorId={this.props.authorId}
                        withComments={true}
                        hideAllComments={true}
                        getClickToCommentEvent={true}
                        onLeaveComment={this.onClickHandler}
                        onClickOnFavorite={onClickOnFavorite}
                      /></div>
                    ):(<div className="slp-social"><EngageButtons>
                        <LoveButton
                          isDisabled={true}
                          noLove={this.props.likeCount}
                          isClicked={this.props.itemIsLiked}
                          onClick={() => null}
                        />
                        <ViewsButton
                          isDisabled={true}
                          noViews={this.props.viewCount}
                          isClicked={false}
                        />
                        <FavoritesButton
                          isDisabled={false}
                          isClicked={true}
                          onClick={onClickOnFavorite}
                        />
                    </EngageButtons></div>)} */}
                </div>
            </div>
        );
    }
}

const mapStateToPhotoGalleryPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToPhotoGalleryPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToPhotoGalleryPreviewProps, mapDispatchToPhotoGalleryPreviewProps)(withRouter(PhotoGalleryPreview));