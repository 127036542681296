import React, { useState, useEffect } from "react";
// import ProfileSettingsNav from "../ProfileNav/ProfileSettingsNav";
import ProfileSettingsBox from "../ProfileSettingsBox";
import DeleteAccountImage from "../../../../assets/images/delete-account.jpg";
import { connect, useSelector } from "react-redux";
import actionType from "../../../../redux-store/action-type";
import { hostNameAndPort } from "../../../../shared/util/vars";
import Axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import SettingsMenu from "../SettingsMenu";


function DeleteAccount(props) {
    const loggedInUser = useSelector(state => state.loggedInUser);
    const deleteOperationConfirmed = useSelector(state => state.deleteOperationConfirmed);
    const userLanguage = useSelector(state => state.userLanguage);

    const [waitingForDeleteConfirmation, setWaitingForDeleteConfirmation] = useState(false);
    const [deleteOperationConfirmedLocal, setDeleteOperationConfirmed] = useState(false);
    const history = useHistory();



    useEffect(() => {
        if (deleteOperationConfirmedLocal === deleteOperationConfirmed) {
            return;
        }
        if (!deleteOperationConfirmed) {
            setWaitingForDeleteConfirmation(false);
            setDeleteOperationConfirmed(false);
            return;
        } else {
            if (!!waitingForDeleteConfirmation) {
                onDeleteConfirmedNow();
                setWaitingForDeleteConfirmation(false);
                setDeleteOperationConfirmed(false);
            }
            props.resetDeleteOperationConfirmation();
        }
    }, [deleteOperationConfirmed, waitingForDeleteConfirmation]);

    function onClickDeleteHandler(e) {
        e.preventDefault();
        setWaitingForDeleteConfirmation(true);
        let s = getTranslatedPhrase(userLanguage, "Are you sure you want to delete this account?");
        props.onShowConfirmDeleteModal(s);
    }

    async function onDeleteConfirmedNow() {
        try {
            let _data;
            _data = new FormData();
            _data.append("userId", loggedInUser.userId);
            let options = {
                url: `${hostNameAndPort}/api/admin/delete-account`,
                method: "post",
                data: _data
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                onLogOut();
            }
        } catch (err) {
            alert('Network error for deleting the account. Try again later. ' + err);
        }
    }

    async function onDisableAccount() {
        let _data;
        _data = new FormData();
        _data.append("user", loggedInUser.slug);
        let options = {
            url: `${hostNameAndPort}/api/admin/disable-account`,
            method: "post",
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                onLogOut();
            }
        } catch (err) {
            alert('Network error for disabling the account. Try again later.' + err);
        }
    };

    function onLogOut() {
        let url = `${hostNameAndPort}/logout`;
        Axios(url)
          .then(result => {
              let { data } = result;
              if (!data.error) {
                  history.push("/");
                  props.onEndAuthentication();
              }
          });
    }

    return (
        <SettingsMenu DeleteLiActive="active">
            <form>


                    <ProfileSettingsBox>

                        <div className="DeleteAccount">
                            <img src={DeleteAccountImage} alt="Delete Account" />

                            <h4>{getTranslatedPhrase(userLanguage, "WE'LL MISS YOU!")}</h4>

                            <p
                            className="goodByeText">{getTranslatedPhrase(userLanguage, "We hate goodbyes, but we'll always be here! Maybe you just need a break. For the times you need to write a blog, sell something, or upload that occasional photo or video, we’ll be waiting to have you back. If you would like to leave us some feedback, please feel free to do so as it helps us improve!")}</p>

                            <p>{getTranslatedPhrase(userLanguage, "Hope to see you again soon")}!</p>

                            <button type="button" className="button button-regular" onClick={onDisableAccount}>{getTranslatedPhrase(userLanguage, "I'LL TAKE A BREAK")}</button>
                            <button type="button" className="button button-regular" onClick={onClickDeleteHandler}>{getTranslatedPhrase(userLanguage, "DELETE MY ACCOUNT")}</button>
                        </div>

                    </ProfileSettingsBox>


            </form>
        </SettingsMenu>
    );
}


const mapDispatchToDeleteAccountProps = (
  dispatch
) => {
    return {
        onShowConfirmDeleteModal: (s) => dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true, confirmDeleteOperationModalMessage: s }),
        resetDeleteOperationConfirmation: () => dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false }),
        onEndAuthentication: () => dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: false, loggedInUser: null })
    };
};

export default connect(null, mapDispatchToDeleteAccountProps)(withRouter(DeleteAccount));