import React, { Component } from 'react';
import ListLoader from '../../../../shared/util/list-loader';
import { titleCase } from '../../../../shared/util/string';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import CreateCouponModal from '../../../../shared/components/Modals/Account/CreateCouponModal';
import moment from 'moment';
import { currencyList } from '../../../../shared/util/string';
import { populateMakes, populateModels } from '../../../../shared/util/car-models';

// import Box from '../../../../shared/util/box';
// import { hostNameAndPort } from '../../../../shared/util/vars';
// import Axios from 'axios';
// import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
// const MORE_SPECS = ['Fuel Type','Fuel Economy','Cylinders','Doors', 'Weight', 'Wheels', 'Seller Type', 'Vehicle History', 'Previous Owners', 'Features'];
const MORE_SPECS = ['VIN', 'Stock #', 'MSRP', 'Miles', 'Drivetrain', 'Gas Type', 'Highway MPG', 'City MPG', 'Engine', 'Transmission', 'Doors', 'Exterior Color', 'Interior Color'];

export default class AutosEditFields extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            title: '',
            brandName: '',
            seller: '',
            ticketSaleURL: '',
            category: 'select-category',
            subcategory: '',
            features: '',
            featureList: {},
            options: '',
            optionList: {},
            subcategoriesSelect: [],
            pickUp: 'sell-local',
            description: '',
            productCondition: '',
            price: '',
            currency: 'USD',
            callForPricing: '',
            moreSpecs: [...MORE_SPECS],
            specList: [
                { specName: 'Exterior Color', specValue: '' },
                { specName: 'Interior Color', specValue: '' },
                { specName: 'Mileage', specValue: '' },
                { specName: 'VIN', specValue: '' },
                { specName: 'Car Title', specValue: '' },
                { specName: 'Drive', specValue: 'Front Wheel Drive' },
                { specName: 'Transmission', specValue: 'Automatic' },
                { specName: 'Fuel Type', specValue: 'Unleaded' },
                { specName: 'Miles / Gallon', specValue: '' },
                { specName: 'Miles / Gallon', specValue: '' },
                { specName: 'Doors', specValue: '' },
                { specName: 'Cylinders', specValue: '' }
            ],
            couponList: [],
            addDiscount: 'how-about-discount',
            discountId: '',
            alreadyLoadedItem: false,
            autoMakeList: [],
            autoModelList: [],
            autoTrimList: [],
            autoMake: '',
            autoModel: '',
            autoYear: '',
            autoTrim: '',
            makeElementId: null,
            modelElementId: null,
            makesLoaded: false,
            temp: {
                specName: '',
                specValue: '',
                selectWhichCoupon: '',
                discountType: '',
                discountValue: '',
                discountCurrency: '%',
                availability: '',
                endDate: moment().format('YYYY-MM-DD'),
                startDate: moment().format('YYYY-MM-DD'),
                showCouponModal: false
            }
        }
    }

    componentDidMount() {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 3000,
                count: 20,
                request: {
                    url: '/api/coupons/items',
                    method: 'post',
                    formParams: { filter: JSON.stringify({ author: '', isDeal: false }) }
                }
            }
        });
        Loader.init();
        this.setState({ Loader });
    }

    componentDidUpdate() {
        if (!this.state.alreadyLoadedItem && this.props.loadedItem.title && this.props.categoriesSelect.length) {
            const carMake = this.props.loadedItem.autoMake;
            const carModel = this.props.loadedItem.autoModel;
            setTimeout(() => {
                const b1 = document.getElementById("modelElementForAutoEditFields");
                const b2 = document.getElementById("makeElementForAutoEditFields");
                const b2Len = (b2 && b2.options.length > 1) ? b2.options.length : 0;
                const b1Len = (b1 && b1.options.length) ? b1.options.length : 0;
                if (
                    this.state.makeElementId
                    && this.state.modelElementId
                    && b1
                    && b1Len
                    && b2
                    && b2Len
                ) {
                    let cc = b2;
                    let i = 0;
                    for (; i < cc.options.length; i++)
                        if (cc.options[i].value === carMake) break;
                    if (i < cc.options.length) cc.selectedIndex = i;
                    else cc.selectedIndex = 0;
                    populateModels(this.state.makeElementId, this.state.modelElementId);
                    cc = document.getElementById("modelElementForAutoEditFields");
                    i = 0;
                    for (; i < cc.options.length; i++)
                        if (cc.options[i].value === carModel) break;

                    if (i < cc.options.length) cc.selectedIndex = i;
                    else cc.selectedIndex = 0;
                }
            }, 500);
            let s = { ...this.state };
            let { Loader, couponList } = this.state;
            for (let i in s) {
                if (s.hasOwnProperty(i))
                    s[i] = this.props.loadedItem[i];
            }
            s.Loader = Loader;
            s.couponList = couponList;
            if (!s.specList || !s.specList.length || s.specList.length < 12)
                s.specList = [
                    { specName: 'Exterior Color', specValue: '' },
                    { specName: 'Interior Color', specValue: '' },
                    { specName: 'Mileage', specValue: '' },
                    { specName: 'VIN', specValue: '' },
                    { specName: 'Car Title', specValue: '' },
                    { specName: 'Drive', specValue: 'Front Wheel Drive' },
                    { specName: 'Transmission', specValue: 'Automatic' },
                    { specName: 'Fuel Type', specValue: 'Unleaded' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Doors', specValue: '' },
                    { specName: 'Cylinders', specValue: '' }
                ];
            if (!s.autoMakeList || !s.autoMakeList.length) s.autoMakeList = [];
            if (!s.autoModelList || !s.autoModelList.length) s.autoModelList = [];
            if (!s.autoTrimList || !s.autoTrimList.length) s.autoTrimList = [];
            if (!!s.features) {
                let featureList = {};
                s.features.split('|').forEach(v => (featureList[v] = true));
                s.featureList = featureList;
                delete s.features;
                this.onChangeItem(featureList, 'featureList');
            }
            if (!!s.options) {
                let optionList = {};
                s.options.split('|').forEach(v => (optionList[v] = true));
                s.optionList = optionList;
                delete s.options;
                this.onChangeItem(optionList, 'optionList');
            }
            s.alreadyLoadedItem = true;



            // Subcategories
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === s.category) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            s.subcategoriesSelect = [...b];

            i = 0;
            while (i < s.moreSpecs.length)
                if (s.specList.map(v => v.specName).indexOf(s.moreSpecs[i]) >= 0) s.moreSpecs.shift();
                else i++;

            this.setState(s);
            // this.updateAutoMakeList(s.autoYear);
            // this.updateAutoModelList(s.autoYear, s.autoMake);
            // this.updateAutoTrimList(s.autoYear, s.autoMake.toLowerCase().replace(/ /g, '-'), s.autoModel);
        }
        if (this.props.loadedItem.clearAllFields !== this.state.clearAllFields) {
            this.setState({ clearAllFields: this.props.loadedItem.clearAllFields });
            let a = this.initialState;
            a.moreSpecs = [...MORE_SPECS];
            a.specList = a.specList.slice(0, 12);
            this.setState(a);

            for (let i in a.temp) {
                this.props.onUpdateTempItem(a.temp[i], i);
            }
            for (let i in a) {
                this.props.onUpdateItem(a[i], i);
            }
        }
    }

    onLoad = () => {
        if (!this.state.Loader) return;
        let { items } = this.state.Loader;
        if (!items) items = [];
        this.setState({ couponList: items });
    }



    onChangeDiscountProperty = (e, _propName, _sizeLimit, _propMsg) => {
        let newValue = e.target.value;
        if (newValue.length > _sizeLimit) return;
        if (_propName === 'discountValue') {
            newValue = newValue.replace(/,/g, '').replace('.', '');
            newValue = Number(newValue);
            if (Number.isNaN(newValue)) newValue = 0;
            if (this.state.temp.discountCurrency === '%' && (newValue > 100 || newValue < 0)) return;
            if (this.state.temp.discountCurrency === this.state.currency && newValue > Number(this.state.price)) return;
        }
        if (_propName === 'selectWhichCoupon' && newValue === 'add-new-coupon') {
            this.setState({ temp: { ...this.state.temp, showCouponModal: true } });
        } else {
            if (!this.props.onUpdateTempState) return;
            let nextTempState = Object.assign({}, this.state.temp);
            nextTempState[_propName] = newValue;

            // Copy to all listings
            if (_propName === 'selectWhichCoupon') {
                const foundCoupon = this.state.couponList.filter(v => v.itemId === newValue)

                if (foundCoupon.length) {
                    nextTempState.discountValue = foundCoupon[0].couponValue;
                    nextTempState.discountCurrency = foundCoupon[0].couponCurrency;
                }
            }


            if (_propName === 'discountType') {
                nextTempState.discountValue = '';
                nextTempState.discountCurrency = '%';
                nextTempState.availability = '';
            }
            if (_propName === 'discountCurrency') nextTempState.discountValue = '';
            this.props.onUpdateTempState(nextTempState);
            this.setState({ temp: nextTempState });
        }
    }


    onCloseCouponModal = _newCoupon => {
        let a = this.state.couponList;
        if (!!_newCoupon) {
            a.unshift(_newCoupon);
            if (!this.props.onUpdateTempState) return;


            // Copy to all listings
            let result =
                this.props.onUpdateTempState({
                    ...this.state.temp,
                    discountValue: _newCoupon.couponValue,
                    discountCurrency: _newCoupon.couponCurrency,
                    selectWhichCoupon: _newCoupon.itemId,
                })

            if (result) {

                this.setState({
                    couponList: a,
                    temp: {
                        ...this.state.temp,
                        discountValue: _newCoupon.couponValue,
                        discountCurrency: _newCoupon.couponCurrency,
                        selectWhichCoupon: _newCoupon.itemId,
                        showCouponModal: false
                    }
                });
            }
        } else {
            this.setState({ temp: { ...this.state.temp, showCouponModal: false } });
        }
    };



    // onAddSpecSubmit = (e) => {
    //     e.preventDefault();
    //     this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue });
    //     this.state.temp.specFocusedInput.focus();
    // }

    // onAddSpec = ({ newSpecName, newSpecValue }) => {
    //     if (!newSpecValue) return;
    //     let moreSpecs = [];
    //     for (let i in this.state.moreSpecs) {
    //         if (this.state.moreSpecs[i] === this.state.temp.specName) continue;
    //         else moreSpecs.push(this.state.moreSpecs[i]);
    //     }
    //     if (this.state.moreSpecs.length === moreSpecs.length) {
    //         newSpecName = moreSpecs.shift();
    //     }
    //     if (!newSpecName) return;
    //     this.setState({ moreSpecs });

    //     let _specList = this.state.specList;
    //     _specList.push({ specName: newSpecName, specValue: newSpecValue });
    //     if (!this.props.onUpdateItem) return;
    //     let result = this.props.onUpdateItem(_specList, 'specList');
    //     if (result) this.setState({ specList: _specList, temp: { ...this.state.temp, specName: '', specValue: '' } });
    // }

    // onRemoveSpec = (k) => {
    //     let moreSpecs = this.state.moreSpecs;
    //     let _specList = this.state.specList.filter((v, i) => { if (i === k) { moreSpecs.unshift(v.specName); return false; } else return true; });
    //     if (!this.props.onUpdateItem) return;
    //     let result = this.props.onUpdateItem(_specList, 'specList');
    //     if (result) this.setState({ specList: _specList, moreSpecs });
    // }

    onChangeSpecNameHandler = (e) => {
        if (e.target.value.length > 100) return;
        this.setState({ temp: { ...this.state.temp, specName: e.target.value } });
    }

    onChangeSpecValueHandler = (e) => {
        if (e.target.value.length > 100) return;
        let specValue = e.target.value;
        let specValueNames = ['Weight', 'Height']
        specValueNames = specValueNames.map(v => getTranslatedPhrase(this.props.userLanguage, v));
        if (specValueNames.indexOf(this.state.temp.specName)) {
            if (!/[0-9, .]*/.test(specValue)) return;
        }
        this.setState({ temp: { ...this.state.temp, specValue: e.target.value } });
    }

    onChangeDefaultSpecName = (e, i) => {
        if (e.target.value.length > 100) {
            this.setState({ temp: { ...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'The spec name must be smaller than 100 characters') } });
            return;
        }

        let _specList = this.state.specList;
        _specList[i].specName = e.target.value;
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(_specList, 'specList');
        if (result) this.setState({ specList: _specList });
    }

    onChangeDefaultSpecValue = (e, i) => {
        if (e.target.value.length > 100) {
            this.setState({ temp: { ...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'The spec value must be smaller than 100 characters') } });
            return;
        }

        let _specList = this.state.specList;
        _specList[i].specValue = e.target.value;
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(_specList, 'specList');
        if (result) this.setState({ specList: _specList });
    }

    onChangeItem = (value, propName, limit, message) => {
        if (!this.props.onUpdateItem) return;
        if (propName === 'callForPricing' && value) {
            this.onChangeItem('no-discount', 'addDiscount', 50, 'Add Discount');
        }
        if ((propName === 'addDiscount' && value === 'no-discount') || (propName === 'discountType')) {
            this.onChangeDiscountProperty({ target: { value: '' } }, 'discountType', 50, 'Discount Type');
        }
        if (propName === 'pickUp' && value === 'sell-online') this.props.onUpdateItem('no-address-needed', 'newAddress', 50, 'Address');
        if (propName === 'category' && value !== 'select-category') {
            this.setState({ makesLoaded: false });

            let specList = [];
            if (value === 'cars') {
                specList = [
                    { specName: 'Exterior Color', specValue: '' },
                    { specName: 'Interior Color', specValue: '' },
                    { specName: 'Mileage', specValue: '' },
                    { specName: 'VIN', specValue: '' },
                    { specName: 'Car Title', specValue: '' },
                    { specName: 'Drive', specValue: 'Front Wheel Drive' },
                    { specName: 'Transmission', specValue: 'Automatic' },
                    { specName: 'Fuel Type', specValue: 'Unleaded' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Doors', specValue: '' },
                    { specName: 'Cylinders', specValue: '' }
                ];
            } else if (value === 'motorcycles') {
                specList = [
                    { specName: 'Color', specValue: '' },
                    { specName: 'Interior Color', specValue: '' },
                    { specName: 'Mileage', specValue: '' },
                    { specName: 'VIN', specValue: '' },
                    { specName: 'Title', specValue: '' },
                    { specName: 'Drive Mode', specValue: 'Shaft Drive' },
                    { specName: 'Transmission', specValue: 'Automatic' },
                    { specName: 'Fuel Type', specValue: 'Unleaded' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Miles / Gallon', specValue: '' },
                    { specName: 'Cubic Capacity cc', specValue: '' },
                    { specName: 'Cylinders', specValue: '' }
                ];
            }
            this.setState({ specList });
            this.props.onUpdateItem(specList, 'specList');

            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ subcategoriesSelect: [...b] })
            this.props.onUpdateItem('', 'subcategory', 50, 'Subcategory');
            // if (value === 'cars' || value === 'commercial') this.updateAutoMakeList(new Date().getFullYear());
        }
        let result = this.props.onUpdateItem(value, propName, limit, message);
        if (result) this.setState({ [propName]: value });
    }

    onChangePriceItem = (value) => {
        value = value.replace(/,/g, '').replace('.', '');
        if (String(value).length > 12) return;
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(value, 'price', 20, 'Price');
        if (result) this.setState({ price: value });
    }

    displayPriceValue = (value, propName) => {
        if (isNaN(value) || value === '') return '';
        if (value === '0' || value === 0) return '0';
        if (this.state.temp.discountCurrency === '%' && propName === 'discountValue') return value;
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }

    onChangeMoreItems = (x) => {
        if (typeof x !== 'object') return false;
        if (!this.props.onUpdateItem) return;
        let result = true;
        for (let i in x) {
            result = result && this.props.onUpdateItem(x[i], i);
        }
        if (result) this.setState(x);
    }

    onChangeAutoYear = (e) => {
        let v = e.target.value;
        if (v.length > 10) {
            // this.setState({temp: {...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage,'The auto year must be smaller than 10 characters.')} });
            return;
        }
        // this.updateAutoMakeList(v);
        this.onChangeMoreItems({ autoYear: v, autoMake: '', autoModel: '', autoTrim: '' });
    }

    onChangeAutoMake = (e) => {
        let v = e.target.value;
        if (v.length > 150) {
            // this.setState({temp: {...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage,'The auto make must be smaller than 150 characters.')} });
            return;
        }
        // this.updateAutoModelList(this.state.autoYear, v);
        this.onChangeMoreItems({ autoMake: v, autoModel: '', autoTrim: '' });
    }

    onChangeAutoModel = (e) => {
        let v = e.target.value;
        if (v.length > 150) {
            // this.setState({temp: {...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage,'The auto model must be smaller than 150 characters.')} });
            return;
        }
        // this.updateAutoTrimList(this.state.autoYear, this.state.autoMake.toLowerCase().replace(/ /g, '-'), v);
        this.onChangeMoreItems({ autoModel: v, autoTrim: '' });
    }

    onChangeAutoTrim = (e) => {
        let v = e.target.value;
        if (v.length > 150) {
            // this.setState({temp: {...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage,'The auto model must be smaller than 150 characters.')} });
            return;
        }
        // if (v === 'Regular') v='';
        this.updateAutoMoreSpecs(this.state.autoYear, this.state.autoMake, this.state.autoModel, v);
        this.onChangeItem(v, 'autoTrim');
    }

    // updateAutoMakeList = async (y) => {
    //     let _data = new FormData();
    //     _data.append('autoYear', y);
    //     let options = {
    //         url: `${hostNameAndPort}/api/autos/car-make`,
    //         method: 'post',
    //         data: _data
    //     };
    //     try {
    //         this.setState({ autoMakeListIsLoading: true });
    //         let result = await Axios(options);
    //         let { data } = result;
    //         if (!data.error) {
    //             this.setState({ autoMakeListIsLoading: false })
    //             let a = [];
    //             if (!!data.autoMakeList) {
    //                 a = [...data.autoMakeList];
    //             }
    //             this.setState({ autoMakeList: a });
    //         }
    //     } catch (err) {
    //         // server, network error
    //         this.setState({ autoMakeListIsLoading: false })
    //     }
    // }

    // updateAutoModelList = async (y, z) => {
    //     let _data = new FormData();
    //     _data.append('autoYear', y);
    //     _data.append('autoMake', z);
    //     let options = {
    //         url: `${hostNameAndPort}/api/autos/car-models`,
    //         method: 'post',
    //         data: _data
    //     };
    //     try {
    //         let result = await Axios(options);
    //         let { data } = result;
    //         if (!data.error) {
    //             let a = [];
    //             if (!!data.autoModelList) {
    //                 a = [...data.autoModelList];
    //             }
    //             this.setState({ autoModelList: a });
    //         }
    //     } catch (err) {
    //         // server, network error
    //     }
    // }

    // updateAutoTrimList = async (y, z, w) => {
    //     let _data = new FormData();
    //     _data.append('autoYear', y);
    //     _data.append('autoMake', z);
    //     _data.append('autoModel', w);
    //     let options = {
    //         url: `${hostNameAndPort}/api/autos/car-trims`,
    //         method: 'post',
    //         data: _data
    //     };
    //     try {
    //         let result = await Axios(options);
    //         let { data } = result;
    //         if (!data.error) {
    //             let a = [];
    //             if (!!data.autoTrimList) {
    //                 a = [...data.autoTrimList];
    //             }
    //             if (!a.length) a = ['Regular'];
    //             this.setState({ autoTrimList: a });
    //             if (!a.length) {
    //                 this.updateAutoMoreSpecs(this.state.autoYear, this.state.autoMake.toLowerCase().replace(/ /g, '-'), this.state.autoModel, '');
    //             }
    //         } else this.setState({ autoTrimList: ['Regular'] });
    //     } catch (err) {
    //         // server, network error
    //         this.setState({ autoTrimList: ['Regular'] });
    //     }
    // }

    // updateAutoMoreSpecs = async (y, z, w, t) => {
    //     let _data = new FormData();
    //     _data.append('autoYear', y);
    //     _data.append('autoMake', z);
    //     _data.append('autoModel', w);
    //     _data.append('autoTrim', t);
    //     let options = {
    //         url: `${hostNameAndPort}/api/autos/car-specs`,
    //         method: 'post',
    //         data: _data
    //     };
    //     try {
    //         let result = await Axios(options);
    //         let { data } = result;
    //         if (!data.error) {
    //             if (!!data.autoMoreSpecs) {
    //                 let a = [...this.state.specList.slice(0, 5), ...data.autoMoreSpecs];
    //                 if (!this.props.onUpdateItem) return;
    //                 let result = this.props.onUpdateItem(a, 'specList');
    //                 if (result) this.setState({ specList: a });
    //             }
    //         }
    //     } catch (err) {
    //         // server, network error
    //     }
    // }

    onChangeFeature = (value, propName) => {
        let featureList = Object.assign({}, this.state.featureList);
        featureList[propName] = value;
        this.onChangeItem(featureList, 'featureList');
    }

    onChangeOption = (value, propName) => {
        let optionList = Object.assign({}, this.state.optionList);
        optionList[propName] = value;
        this.onChangeItem(optionList, 'optionList');
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    render() {
        let categoriesSelect = this.props.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' '))) })).sort((a, b) => ((a.text > b.text) ? 1 : -1));

        return (<>


            {/* Main Info */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Main Info</p>
                </div>

                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <input
                            className="form-input-text form-input-full"
                            type="text"
                            placeholder={getTranslatedPhrase(this.props.userLanguage, 'Auto Listing Title') + ' *'}
                            value={this.state.title}
                            onChange={(e) => this.onChangeItem(e.target.value, 'title', 50, 'Auto Listing Title')} />
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select
                                className="form-input-full"
                                value={this.state.productCondition}
                                onChange={(e) => this.onChangeItem(e.target.value, 'productCondition', 10, 'Condition')}>
                                <option value="choose-condition">{getTranslatedPhrase(this.props.userLanguage, 'Condition')} *</option>
                                <option value="New">{getTranslatedPhrase(this.props.userLanguage, 'New')}</option>
                                <option value="Used">{getTranslatedPhrase(this.props.userLanguage, 'Used')}</option>
                            </select>
                        </div>
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select
                                className="form-input-full"
                                value={this.state.seller}
                                onChange={(e) => this.onChangeItem(e.target.value, 'seller', 10, 'Seller')}>
                                <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Sold By')} *</option>
                                <option value="owner">{getTranslatedPhrase(this.props.userLanguage, 'Private')}</option>
                                <option value="dealer">{getTranslatedPhrase(this.props.userLanguage, 'Dealer')}</option>
                            </select>
                        </div>
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select
                                className="form-input-full"
                                value={this.state.category}
                                onChange={(e) => this.onChangeItem(e.target.value, 'category', 50, 'Category')}>
                                <option value="select-category">{getTranslatedPhrase(this.props.userLanguage, 'Select Category')} *</option>
                                {categoriesSelect.map((v, i) => (<option value={v.value} key={i}>{v.text}</option>))}
                            </select>
                        </div>
                    </div>
                    {!!this.state.subcategoriesSelect && !!this.state.subcategoriesSelect.length && (
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select
                                    className="form-input-full"
                                    value={this.state.subcategory}
                                    onChange={(e) => this.onChangeItem(e.target.value, 'subcategory', 50, 'Subcategory')}>
                                    <option value="select-subcategory">{getTranslatedPhrase(this.props.userLanguage, 'Select Subcategory')}</option>
                                    {this.state.subcategoriesSelect.map((v, i) => (<option value={v} key={'sub' + i}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ').trim()))}</option>))}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </div>



            {/* Car Select */}
            {(this.state.category !== 'cars') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Make & Model</p>
                    </div>

                    <div className="new-edit-page_section-content">
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select
                                    className="form-input-full"
                                    value={this.state.autoYear}
                                    onChange={this.onChangeAutoYear} >
                                    <option value="select-year">{getTranslatedPhrase(this.props.userLanguage, 'Select Year')} *</option>
                                    {Array(100).fill(1).map((v, i) => {
                                        let n = new Date().getFullYear() - i + 1;
                                        if (n < 1936) {
                                            return null;
                                        }
                                        return (<option value={n} key={'key-' + n}>{n}</option>);
                                    }
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full"
                                    id="makeElementForAutoEditFields"
                                    ref={n => {
                                        if (!this.state.makeElementId) this.setState({ makeElementId: "makeElementForAutoEditFields" });
                                        if (this.state.makeElementId && this.state.modelElementId && !this.state.makesLoaded) {
                                            populateMakes(this.state.makeElementId, this.state.modelElementId);
                                            this.setState({ makesLoaded: true });
                                        }
                                    }}
                                    value={this.state.autoMake}
                                    onChange={this.onChangeAutoMake}
                                />
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full"
                                    id="modelElementForAutoEditFields"
                                    ref={n => {
                                        if (!this.state.modelElementId) this.setState({ modelElementId: "modelElementForAutoEditFields" });
                                        if (this.state.makeElementId && this.state.modelElementId && !this.state.makesLoaded) {
                                            populateModels(this.state.makeElementId, this.state.modelElementId);
                                            this.setState({ makesLoaded: true });
                                        }
                                    }}
                                    value={this.state.autoModel}
                                    onChange={this.onChangeAutoModel}>
                                </select>
                            </div>
                        </div>

                        {/* <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.autoTrim} onChange={this.onChangeAutoTrim}>
                                    <option value="select-trim">{getTranslatedPhrase(this.props.userLanguage, 'Select Trim')} *</option>
                                    {this.state.autoTrimList.map((v, i) => (<option key={'item-trim-' + i} value={v}>{titleCase(v)}</option>))}
                                </select>
                            </div>
                        </div> */}

                    </div>
                </div>
            )}

            {(this.state.category !== 'commercial' && this.state.category !== 'motorcycles' && this.state.category !== 'rVs-&-Campers' && this.state.category !== 'other') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>{getTranslatedPhrase(this.props.userLanguage, 'Make & Model')}</p>
                    </div>

                    <div className="new-edit-page_section-content">
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select
                                    className="form-input-full"
                                    value={this.state.autoYear}
                                    onChange={this.onChangeAutoYear} >
                                    <option value="select-year">{getTranslatedPhrase(this.props.userLanguage, 'Select Year')} *</option>
                                    {Array(100).fill(1).map((v, i) => {
                                        let n = new Date().getFullYear() - i + 1;
                                        if (n < 1936) { return null }
                                        return (<option value={n} key={'key-' + n}>{n}</option>);
                                    }
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <input className="form-input-text form-input-full" value={this.state.autoMake} onChange={this.onChangeAutoMake} type="text" placeholder="Make" maxLength='20' />
                        </div>

                        <div className="edit-page-field">
                            <input className="form-input-text form-input-full" value={this.state.autoModel} onChange={this.onChangeAutoModel} type="text" placeholder="Model" maxLength='20' />
                        </div>


                    </div>
                </div>
            )}

            {/* Specifications */}
            {(this.state.category !== 'cars') ? null : (
                <div className="new-edit-page_section">

                    <div className="new-edit-page_section-title">
                        <p>Specifications</p>
                    </div>

                    <div className="new-edit-page_section-specs">
                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>
                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'VIN')} disabled
                                            />

                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder="ex: 1ZF15258DDD887"
                                                value={this.state.specList[3].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 3)}
                                                maxLength='17' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Exterior Color')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            {/* <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: black')}
                                                value={this.state.specList[0].specValue} onChange={}
                                                maxLength='15' /> */}

                                            <div className="form-input-select mgb-sm mgt-sm">
                                                <select className="form-input-full" value={this.state.specList[0].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 0)}>
                                                    <option value="">{getTranslatedPhrase(this.props.userLanguage, 'Select Exterior Color')}</option>
                                                    <option key={'item-b0'} value="Beige">{getTranslatedPhrase(this.props.userLanguage, 'Beige')}</option>
                                                    <option key={'item-b1'} value="Black">{getTranslatedPhrase(this.props.userLanguage, 'Black')}</option>
                                                    <option key={'item-b2'} value="Blue">{getTranslatedPhrase(this.props.userLanguage, 'Blue')}</option>
                                                    <option key={'item-b3'} value="Brown">{getTranslatedPhrase(this.props.userLanguage, 'Brown')}</option>
                                                    <option key={'item-b3a'} value="Gold">{getTranslatedPhrase(this.props.userLanguage, 'Gold')}</option>
                                                    <option key={'item-b4'} value="Green">{getTranslatedPhrase(this.props.userLanguage, 'Green')}</option>
                                                    <option key={'item-b5'} value="Gray">{getTranslatedPhrase(this.props.userLanguage, 'Gray')}</option>
                                                    <option key={'item-b6'} value="Orange">{getTranslatedPhrase(this.props.userLanguage, 'Orange')}</option>
                                                    <option key={'item-b6a'} value="Red">{getTranslatedPhrase(this.props.userLanguage, 'Red')}</option>
                                                    <option key={'item-b6b'} value="Pink">{getTranslatedPhrase(this.props.userLanguage, 'Pink')}</option>
                                                    <option key={'item-b6c'} value="Purple">{getTranslatedPhrase(this.props.userLanguage, 'Purple')}</option>
                                                    <option key={'item-b7'} value="Silver">{getTranslatedPhrase(this.props.userLanguage, 'Silver')}</option>
                                                    <option key={'item-b8'} value="White">{getTranslatedPhrase(this.props.userLanguage, 'White')}</option>
                                                    <option key={'item-b9'} value="Yellow">{getTranslatedPhrase(this.props.userLanguage, 'Yellow')}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Interior Color')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: black')}
                                                value={this.state.specList[1].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 1)}
                                                maxLength='15' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[2].specName}
                                                    onChange={e => this.onChangeDefaultSpecName(e, 2)}>
                                                    <option value="Mileage">Mileage</option>
                                                    <option value="Kilometers">Kilometers</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 12,000')}
                                                value={this.state.specList[2].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 2)}
                                                maxLength='7' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Drive')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[5].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 5)}>
                                                    <option value="All Wheel Drive">All Wheel Drive</option>
                                                    <option value="Front Wheel Drive">Front Wheel Drive</option>
                                                    <option value="Rear Wheel Drive">Rear Wheel Drive</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Transmission')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[6].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 6)}>
                                                    <option value="Automatic">Automatic</option>
                                                    <option value="Manual">Manual</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>
                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Fuel Type')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[7].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 7)}>
                                                    <option value="Biodiesel">Biodiesel</option>
                                                    <option value="Compressed Natural Gas">Compressed Natural Gas</option>
                                                    <option value="Diesel">Diesel</option>
                                                    <option value="E85">E85</option>
                                                    <option value="Electric">Electric</option>
                                                    <option value="Hydrogen">Hydrogen</option>
                                                    <option value="Lpg">Lpg</option>
                                                    <option value="Natural Gas">Natural Gas</option>
                                                    <option value="Premium Unleaded">Premium Unleaded</option>
                                                    <option value="Unleaded">Unleaded</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>


                                    <tr>
                                        <td colSpan="2"><label>Fuel Efficiency / City</label></td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[8].specName} onChange={e => this.onChangeDefaultSpecName(e, 8)}>
                                                    <option value="Miles / Gallon">Miles / Gallon</option>
                                                    <option value="Liters / 100 Km">Liters / 100 Km</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 24')}
                                                value={this.state.specList[8].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 8)}
                                                maxLength='5' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td colSpan="2"><label>Fuel Efficiency / Highway</label></td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[9].specName} onChange={e => this.onChangeDefaultSpecName(e, 9)}>
                                                    <option value="Miles / Gallon">Miles / Gallon</option>
                                                    <option value="Liters / 100 Km">Liters / 100 Km</option>
                                                </select>
                                            </div>
                                        </td>

                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 30')}
                                                value={this.state.specList[9].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 9)}
                                                maxLength='5' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Doors')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder="ex: 5"
                                                value={this.state.specList[10].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 10)}
                                                maxLength="2" />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Engine')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder="ex: V8"
                                                value={this.state.specList[11].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 11)}
                                                maxLength='20' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}


            {(this.state.category !== 'motorcycles') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Specifications</p>
                    </div>

                    <div className="new-edit-page_section-specs">
                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>
                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'VIN')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder="ex: 1ZF15258DDD887"
                                                value={this.state.specList[3].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 3)}
                                                maxLength='17' />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Color')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: black')}
                                                value={this.state.specList[0].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 0)}
                                                maxLength='15' />
                                        </td>
                                        <td className="addRemoveValue" />
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[2].specName}
                                                    onChange={e => this.onChangeDefaultSpecName(e, 2)}>
                                                    <option value="Mileage">Mileage</option>
                                                    <option value="Kilometers">Kilometers</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 12,000')}
                                                value={this.state.specList[2].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 2)}
                                                maxLength='7' />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Drive Mode')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[5].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 5)}>
                                                    <option value="Shaft Drive">Shaft Drive</option>
                                                    <option value="Chain Drive">Chain Drive</option>
                                                    <option value="Belt Drive">Belt Drive</option>

                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Transmission')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[6].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 6)}>
                                                    <option value="Automatic">Automatic</option>
                                                    <option value="Semi-Automatic">Semi-Automatic</option>
                                                    <option value="Manual">Manual</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>
                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Fuel Type')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[7].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 7)}>
                                                    <option value="Biodiesel">Biodiesel</option>
                                                    <option value="Compressed Natural Gas">Compressed Natural Gas</option>
                                                    <option value="Diesel">Diesel</option>
                                                    <option value="E85">E85</option>
                                                    <option value="Electric">Electric</option>
                                                    <option value="Hydrogen">Hydrogen</option>
                                                    <option value="Lpg">Lpg</option>
                                                    <option value="Natural Gas">Natural Gas</option>
                                                    <option value="Premium Unleaded">Premium Unleaded</option>
                                                    <option value="Unleaded">Unleaded</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>


                                    <tr>
                                        <td colSpan="2"><label>Fuel Efficiency / City</label></td>
                                    </tr>
                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[5].specName} onChange={e => this.onChangeDefaultSpecName(e, 5)}>
                                                    <option value="Miles / Gallon">Miles / Gallon</option>
                                                    <option value="Liters / 100 Km">Liters / 100 Km</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 24')}
                                                value={this.state.specList[8].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 8)}
                                                maxLength='3' />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>


                                    <tr>
                                        <td colSpan="2"><label>Fuel Efficiency / Highway</label></td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select" >
                                                <select className="form-input-full" value={this.state.specList[5].specName} onChange={e => this.onChangeDefaultSpecName(e, 5)}>
                                                    <option value="Miles / Gallon">Miles / Gallon</option>
                                                    <option value="Liters / 100 Km">Liters / 100 Km</option>
                                                </select>
                                            </div>
                                        </td>

                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                placeholder={getTranslatedPhrase(this.props.userLanguage, 'ex: 30')}
                                                value={this.state.specList[9].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 9)}
                                                maxLength='3' />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="text"
                                                value={getTranslatedPhrase(this.props.userLanguage, 'Cubic Capacity cc')} disabled />
                                        </td>
                                        <td className="addSpecValue">
                                            <input
                                                className="form-input-text form-input-full"
                                                type="number"
                                                placeholder="ex: 750"
                                                value={this.state.specList[10].specValue} onChange={e => this.onChangeDefaultSpecValue(e, 10)}
                                                onInput={this.maxLengthCheck}
                                                maxLength='4' />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            )}

            {/* Options */}
            {(this.state.category !== 'cars') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Car Options</p>
                    </div>

                    <div className="new-edit-page_section-content">
                        <div className="edit-page-field">
                            <p><strong>Exterior</strong></p>

                            <div className="form-input-lists">
                                <input type="checkbox" name="keylessEntry" checked={this.state.optionList['Keyless Entry']} onChange={() => null} />
                                <label htmlFor="keylessEntry" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Keyless Entry'], 'Keyless Entry')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Keyless Entry')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="roofRack" checked={this.state.optionList['Roof Rack']} onChange={() => null} />
                                <label htmlFor="roofRack" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Roof Rack'], 'Roof Rack')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Roof Rack')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="premiumWheels" checked={this.state.optionList['Premium Wheels']} onChange={() => null} />
                                <label htmlFor="premiumWheels" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Premium Wheels'], 'Premium Wheels')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Premium Wheels')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="dualRearWheels" checked={this.state.optionList['Dual Rear Wheels']} onChange={() => null} />
                                <label htmlFor="dualRearWheels" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Dual Rear Wheels'], 'Dual Rear Wheels')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Dual Rear Wheels')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="towingPackage" checked={this.state.optionList['Towing Package']} onChange={() => null} />
                                <label htmlFor="towingPackage" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Towing Package'], 'Towing Package')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Towing Package')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="adaptiveEquipment" checked={this.state.optionList['Adaptive Equipment']} onChange={() => null} />
                                <label htmlFor="adaptiveEquipment" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Adaptive Equipment'], 'Adaptive Equipment')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Adaptive Equipment')}
                                </label>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <p><strong>Interior</strong></p>

                            <div className="form-input-lists">
                                <input type="checkbox" name="3rdRowSeats" checked={this.state.optionList['3rd Row Seats']} onChange={() => null} />
                                <label htmlFor="3rdRowSeats" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['3rd Row Seats'], '3rd Row Seats')} /></span>{getTranslatedPhrase(this.props.userLanguage, '3rd Row Seats')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="heatedSeats" checked={this.state.optionList['Heated Seats']} onChange={() => null} />
                                <label htmlFor="heatedSeats" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Heated Seats'], 'Heated Seats')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Heated Seats')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="leatherSeats" checked={this.state.optionList['Leather Seats']} onChange={() => null} />
                                <label htmlFor="leatherSeats" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Leather Seats'], 'Leather Seats')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Leather Seats')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="sunroof" checked={this.state.optionList['Sunroof']} onChange={() => null} />
                                <label htmlFor="sunroof" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Sunroof'], 'Sunroof')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Sunroof')}
                                </label>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <p><strong>Vehicle History</strong></p>

                            <div className="form-input-lists">
                                <input type="checkbox" name="cleanTitle" checked={this.state.optionList['Clean Title']} onChange={() => null} />
                                <label htmlFor="cleanTitle" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Clean Title'], 'Clean Title')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Clean Title')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="rebuiltTitle" checked={this.state.optionList['Rebuilt Title']} onChange={() => null} />
                                <label htmlFor="rebuiltTitle" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Rebuilt Title'], 'Rebuilt Title')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Rebuilt Title')}
                                </label>
                            </div>

                            <div className="form-input-lists">
                                <input type="checkbox" name="noAccidents" checked={this.state.optionList['No Accidents']} onChange={() => null} />
                                <label htmlFor="noAccidents" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['No Accidents'], 'No Accidents')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'No Accidents')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="singleOwner" checked={this.state.optionList['Single Owner']} onChange={() => null} />
                                <label htmlFor="singleOwner" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Single Owner'], 'Single Owner')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Single Owner')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="fullServiceHistory" checked={this.state.optionList['Full Service History']} onChange={() => null} />
                                <label htmlFor="fullServiceHistory" >
                                    <span><i className="fal fa-check" onClick={(e) => this.onChangeOption(!this.state.optionList['Full Service History'], 'Full Service History')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Full Service History')}
                                </label>
                            </div>

                        </div>

                    </div>
                </div>
            )}



            {/* Features */}
            {(this.state.category !== 'cars') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Car Features</p>
                    </div>

                    <div className="new-edit-page_section-content new-edit-page_section-content-list">
                        <div className="form-input-lists">
                            <input type="checkbox" name="appleCarPlay" checked={this.state.featureList['Apple CarPlay']} onChange={() => null} />
                            <label htmlFor="appleCarPlay" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Apple CarPlay'], 'Apple CarPlay')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Apple CarPlay')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="androidAuto" checked={this.state.featureList['Android Auto']} onChange={() => null} />
                            <label htmlFor="androidAuto" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Android Auto'], 'Android Auto')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Android Auto')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="bluetooth" checked={this.state.featureList['Bluetooth']} onChange={() => null} />
                            <label htmlFor="bluetooth" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Bluetooth'], 'Bluetooth')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Bluetooth')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="audioConnection" checked={this.state.featureList['Audio Connection']} onChange={() => null} />
                            <label htmlFor="audioConnection" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Audio Connection'], 'Audio Connection')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Audio Connection')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="premiumAudio" checked={this.state.featureList['Premium Audio']} onChange={() => null} />
                            <label htmlFor="premiumAudio" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Premium Audio'], 'Premium Audio')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Premium Audio')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="satelliteRadio" checked={this.state.featureList['Satellite Radio']} onChange={() => null} />
                            <label htmlFor="satelliteRadio" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Satellite Radio'], 'Satellite Radio')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Satellite Radio')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="DVDPlayer" checked={this.state.featureList['DVD Player']} onChange={() => null} />
                            <label htmlFor="DVDPlayer" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['DVD Player'], 'DVD Player')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'DVD Player')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="navigation" checked={this.state.featureList['Navigation']} onChange={() => null} />
                            <label htmlFor="navigation" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Navigation'], 'Navigation')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Navigation')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="steeringWheelControls" checked={this.state.featureList['Steering Wheel Controls']} onChange={() => null} />
                            <label htmlFor="steeringWheelControls" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Steering Wheel Controls'], 'Steering Wheel Controls')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Steering Wheel Controls')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="cruiseControl" checked={this.state.featureList['Cruise Control']} onChange={() => null} />
                            <label htmlFor="cruiseControl" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Cruise Control'], 'Cruise Control')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Cruise Control')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="backupCamera" checked={this.state.featureList['Backup Camera']} onChange={() => null} />
                            <label htmlFor="backupCamera" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Backup Camera'], 'Backup Camera')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Backup Camera')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="securitySystem" checked={this.state.featureList['Security System']} onChange={() => null} />
                            <label htmlFor="securitySystem" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Security System'], 'Security System')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Security System')}
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {(this.state.category !== 'motorcycles') ? null : (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Motorcycle Features</p>
                    </div>

                    <div className="new-edit-page_section-content new-edit-page_section-content-list">
                        <div className="form-input-lists">
                            <input type="checkbox" name="ABS" checked={this.state.featureList['ABS']} onChange={() => null} />
                            <label htmlFor="ABS" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['ABS'], 'ABS')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'ABS')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="box" checked={this.state.featureList['Box']} onChange={() => null} />
                            <label htmlFor="box" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Box'], 'Box')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Box')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="catalyticConverter" checked={this.state.featureList['Catalytic Converter']} onChange={() => null} />
                            <label htmlFor="catalyticConverter" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Catalytic Converter'], 'Catalytic Converter')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Catalytic Converter')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="electricStarter" checked={this.state.featureList['Electric Starter']} onChange={() => null} />
                            <label htmlFor="electricStarter" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Electric Starter'], 'Electric Starter')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Electric Starter')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="kickstarter" checked={this.state.featureList['Kickstarter']} onChange={() => null} />
                            <label htmlFor="kickstarter" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Kickstarter'], 'Kickstarter')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Kickstarter')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="roll-overBar" checked={this.state.featureList['Roll-over Bar']} onChange={() => null} />
                            <label htmlFor="roll-overBar" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Roll-over Bar'], 'Roll-over Bar')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Roll-over Bar')}
                            </label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="windshield" checked={this.state.featureList['Windshield']} onChange={() => null} />
                            <label htmlFor="windshield" >
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeFeature(!this.state.featureList['Windshield'], 'Windshield')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Windshield')}
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {/* Description */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Description</p>
                </div>


                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <textarea
                            className="form-input-textarea form-input-full"
                            placeholder={getTranslatedPhrase(this.props.userLanguage, 'Write your auto listing description. *')}
                            value={this.state.description}
                            onChange={(e) => this.onChangeItem(e.target.value, 'description', 10000, 'Description')} >
                        </textarea>
                    </div>
                </div>
            </div>


            {/* Pricing & Discount */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Pricing & Discount</p>
                </div>


                <div className="new-edit-page_section-content">
                    {!this.state.callForPricing && (<>
                        <div className="edit-page-field">
                            <input
                                className="form-input-text form-input-full"
                                type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Price') + ' *'}
                                value={this.displayPriceValue(this.state.price, 'price')}
                                onChange={(e) => this.onChangePriceItem(e.target.value)} />
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select
                                    className="form-input-full"
                                    value={this.state.currency}
                                    onChange={(e) => this.onChangeItem(e.target.value, 'currency', 10, 'Currency')} >
                                    {currencyList.map(v => (
                                        <option key={v} value={v}>{v}</option>)
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select
                                    className="form-input-full"
                                    value={this.state.addDiscount}
                                    onChange={(e) => this.onChangeItem(e.target.value, 'addDiscount', 50, 'Add Discount')}>
                                    <option value="how-about-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add Discount?')} *</option>
                                    <option value="no-discount">{getTranslatedPhrase(this.props.userLanguage, 'No Discount')}</option>
                                    <option value="add-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add New Discount')}</option>
                                </select>
                            </div>
                        </div>

                        {this.state.addDiscount === 'add-discount' && (
                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select
                                        className="form-input-full"
                                        value={this.state.temp.discountType}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'discountType', 50, 'Discount Type')}>
                                        <option value="discount-type">{getTranslatedPhrase(this.props.userLanguage, 'Discount Type *')}</option>
                                        <option value="discount-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Coupon')}</option>
                                        <option value="discount-deal">{getTranslatedPhrase(this.props.userLanguage, 'Deal')}</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        {this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-coupon' && (
                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select
                                        className="form-input-full"
                                        value={this.state.temp.selectWhichCoupon}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'selectWhichCoupon', 50, 'Select Coupon')}>
                                        <option value="select-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Select Coupon')} *</option>
                                        <option value="add-new-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Add New')}</option>
                                        {this.state.couponList.map((v, i) => (
                                            <option key={'coupon-' + i} value={v.itemId}>{v.title}</option>)
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}

                        {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal') && (
                            <>
                                <div className="edit-page-field">
                                    <input
                                        className="form-input-text form-input-full"
                                        type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Discount Value *')}
                                        value={this.displayPriceValue(this.state.temp.discountValue, 'discountValue')}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'discountValue', 20, 'Discount Value')} />
                                </div>

                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select
                                            className="form-input-full"
                                            value={this.state.temp.discountCurrency}
                                            onChange={(e) => this.onChangeDiscountProperty(e, 'discountCurrency', 50, 'Currency')}>
                                            <option value="%">{getTranslatedPhrase(this.props.userLanguage, '%')}</option>
                                            <option value={this.state.currency}>{this.state.currency}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select
                                            className="form-input-full"
                                            value={this.state.temp.availability}
                                            onChange={(e) => this.onChangeDiscountProperty(e, 'availability', 50, 'Set Availability')}>
                                            <option value="set-availability">{getTranslatedPhrase(this.props.userLanguage, 'Set Availability')} *</option>
                                            <option value="choose-dates">{getTranslatedPhrase(this.props.userLanguage, 'Choose Dates')}</option>
                                            <option value="no-expiration-date">{getTranslatedPhrase(this.props.userLanguage, 'No Expiration Date')}</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}

                        {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal' && this.state.temp.availability === 'choose-dates') && (
                            <>
                                <div className="edit-page-field">
                                    <input
                                        className="form-input-text form-input-full"
                                        type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Start Date')}
                                        value={this.state.temp.startDate}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'startDate', 20, 'Start Date')} />
                                </div>

                                <div className="edit-page-field">
                                    <input
                                        className="form-input-text form-input-full"
                                        type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Expiration Date')}
                                        value={this.state.temp.endDate}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'endDate', 20, 'Expiration Date')} />
                                </div>
                            </>
                        )}
                    </>)}

                    {/* <div className="edit-page-field edit-page-field-full">
                        <div className="form-input-lists">
                            <input type="checkbox" name="displayCallForPricing" checked={this.state.callForPricing} onChange={() => null} style={{ display: 'none' }} />
                            <label htmlFor="displayCallForPricing" style={{ paddingLeft: "16px", width: '100%' }}>
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.callForPricing, 'callForPricing')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}
                            </label>
                        </div>
                    </div> */}
                </div>
            </div>



            <CreateCouponModal
                active={this.state.temp.showCouponModal}
                onClose={this.onCloseCouponModal}
                oldCoupon={null}
                initialPrice={this.state.price}
                couponCurrency={this.state.currency}
            />
        </>);
    }
}
