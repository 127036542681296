import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';

import { hostNameAndPort } from '../../../shared/util/vars';

import ProfileHeader from './Components/ProfileHeader';
import ProfileNavigation from './Components/ProfileNavigation';
import ProfileContent from './Components/ProfileContent';
import ProfileSidebar from './Components/ProfileSidebar';

const ProfilePage = (props) => {

    const [slug, setSlug] = useState('');
    const [category, setCategory] = useState('');
    const [user, setUser] = useState({});
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [postList, setPostList] = useState([]);
    const [recentPostList, setRecentPostList] = useState([])
    const [followers, setFollowers] = useState([]);
    const [follow, setFollow] = useState([]);
    const [stats, setStats] = useState({});
    const [allCount, setAllCount] = useState(0);
    const [howManyTimesProfilePage, setHowManyTimesProfilePage] = useState(0);
    const [isRealEstateAgency, setIsRealestateAgency] = useState(false);
    const [allFavorites, setAllFavorites] = useState(0);

    const history = useHistory();
    const p = history.location.pathname.split('/');

    const authChange = props.userIsAuthenticated;

    useEffect(() => {
        setUserIsAuthenticated(props.userIsAuthenticated);

        return () => {
            setIsRealestateAgency(false);
        }
    }, [])

    useEffect(() => {

        if (p.length > 1) {
            setCategory(p[2] || '');
        }

        if (p[2] === '') {
            console.log('null')
        }

        setSlug(p[1]);

    }, [p])



    useEffect(() => {

        let s = window.location.pathname;

        let pathSize = s.split('/').length;

        if (pathSize > 2) {
            s = s.split('/')[1];

        } else if (pathSize <= 2) {
            s = s.slice(1);
        }


        if ((userIsAuthenticated !== props.userIsAuthenticated) || (slug !== s)) {
            // console.log(userIsAuthenticated, props.userIsAuthenticated)
            window.scrollTo(0, 0);
            setUserIsAuthenticated(props.userIsAuthenticated);
            setPostList([]);
            setFollowers([]);
            setFollow([]);
            setStats({});
            setSlug(s);
        }

        if (howManyTimesProfilePage < 1) {
            getUserProfileDataFromServer(s);
            getTotalPostCount(s);
        }

    }, [slug, authChange])





    const getTotalPostCount = async (u) => {
        let _data = new FormData();
        _data.append('user', u);

        let options = {
            url: `${hostNameAndPort}/api/user/profile/allcount`,
            method: 'post',
            data: _data
        };

        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setAllCount(data.itemsCount);
                setAllFavorites(data.favoritesCount);
            } else {
                console.log(data.error);
            }
        } catch (err) {
            console.log(err)
        }
    }




    const getUserProfileDataFromServer = async (u) => {

        // if (slug) this.setState(this.initialState);

        let _data = new FormData();
        _data.append('user', u);

        let options = {
            url: `${hostNameAndPort}/api/user/profile`,
            method: 'post',
            data: _data
        };

        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                data.user.coverImage = data.user.coverImage || this.state.coverImage;
                data.user.profileImage = data.user.profileImage || this.state.profileImage;
                if (!!data.user.joined) {
                    data.user.joined = (new Date(data.user.joined)).getFullYear();
                }
                else {
                    data.user.joined = '';
                }
                if (data.user.birthdate) {
                    data.user.age = (new Date()).getFullYear() - (new Date(data.user.birthdate)).getFullYear();
                }
                data.user.howManyTimesProfilePage = 0;
                if (!data.user.dealer_id) data.user.dealer_id = '';
                if (data.user.category === 'real-estate' && !!data.user.PrimaryOfficeID) setIsRealestateAgency(true);
                setUser(data.user);
            } else {
                console.log(data.error);
                setIsRealestateAgency(false);
                history.push('/404');
                setHowManyTimesProfilePage(0);
            }
        } catch (err) {
            // Network error
            console.log(err);
            setIsRealestateAgency(false);
            history.push('/404');
            setHowManyTimesProfilePage(0);
        }
    }

    const updateTotalFavorites = () => {
        setAllFavorites(allFavorites - 1);
    }

    // const onPostNewStatus = (t) => {
    //     if (!!t) {
    //         setRecentPostList([t, ...recentPostList])
    //     }
    // }

    if (!user || !user.stats) return null;

    // if (category === '') {
    //     history.push('/' + slug + '/about');
    // }

    // console.log('profile cat is ...:', category);

    // console.log('authentication', userIsAuthenticated, user.isProfileOwner, user.isFollower)


    // console.log(user.isProfileOwner);

    return (
        <div className="user-profile">
            <ProfileHeader
                coverImage={user.coverImage}
                profileImage={user.profileImage}
                userName={user.userName}
                accountType={user.accountType}
                reviews={user.reviews}
                userId={user.userId}
                dealerId={user.dealer_id || ''}
                realestateAgency={user.PrimaryOfficeID || user.ListOfficeMlsId || ''}
                isFollower={user.isFollower}
                stats={user.stats}
                isProfileOwner={user.isProfileOwner}
                requestIsSent={user.requestIsSent}
                slug={slug}
                shareLink={'https://carverve.com/' + slug}
            />

            <ProfileNavigation
                isProfileOwner={user.isProfileOwner}
                userIsAuthenticated={userIsAuthenticated}
                accountType={user.accountType}
                stats={user.stats}
                slug={slug}
                userId={user.userId}
                dealerId={user.dealer_id || ''}
                allFavCount={allFavorites}
                realestateAgency={user.PrimaryOfficeID || user.ListOfficeMlsId || ''}
            />

            <div className={`profile-content ${(category === '' || category === 'about' || category === 'reviews') ? 'profile-content-limited' : ''}`}>

            {(category === 'about' || category === 'reviews' || category === '') &&
                    <ProfileSidebar
                        userToMessage={{
                            userId: user.userId,
                            userName: user.userName,
                            profileImage: user.profileImage,
                            slug: slug
                        }}

                        allCount={allCount}

                        userName={user.userName}
                        userId={user.userId}
                        totalPosts={user.stats.totalPosts}
                        totalViews={user.stats.noViews}

                        isFollower={!!user.isFollower}
                        isProfileOwner={user.isProfileOwner}

                        requestIsSent={user.requestIsSent}
                    />
                }

                <ProfileContent
                    // About
                    businessAboutContent={user.aboutMe}
                    userName={user.userName}
                    isAccredited={user.subscription !== 'free'}

                    // Reviews
                    reviewsList={user.reviews}
                    userIsAuthenticated={userIsAuthenticated}
                    itemId={user.userId}
                    authorId={user.userId}


                    // Favorites
                    totalCount={user.stats.totalFavorites}
                    updateTotalFavorites={updateTotalFavorites}
                    slug={slug}

                    // Category
                    category={category}
                    userId={user.userId}
                    dealerId={user.dealer_id || ''}
                    realestateAgency={user.PrimaryOfficeID || user.ListOfficeMlsId || ''}
                />

               
            </div>
        </div>
    )
}

const mapStateToPersonalProfileProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToPersonalProfileProps = (
    dispatch
) => {
    return {
        setIsRealestateAgency: (isRealestateAgency) => dispatch({ type: actionType.REALESTATE_AGENCY, isRealestateAgency })
    };
};


export default connect(mapStateToPersonalProfileProps, mapDispatchToPersonalProfileProps)(ProfilePage);