import React, { useState } from 'react';
import { getTranslatedPhrase } from '../../../util/lang';

function AddCommentReply(props) {
    const [comment, setComment] = useState('');

    const onChangeComment = (e) => {
        let c = e.target.value;
        if (c.length <= 500) {
            setComment(c);
        }
    }

    const onSubmitComment = () => {
        props.onSubmitComment(comment);
        setComment('');
    }

    const onDiscardComment = () => {
        setComment('');
        props.onCloseCommentBox();
    }

    if (!props.active) {
        return null;
    }
    return (
        <div className="engage_the-comment">


            <div className="engage_the-comment_content">
                
                <div className="engage_the-comment_content_user">
                    {props.children}
                    <span>{props.PostCommentUserName}</span>
                </div> 

                <div className="engage_the-comment_content_the-comment">
                    <textarea 
                        value={comment}
                        onChange={onChangeComment}
                        placeholder={getTranslatedPhrase(props.userLanguage,'Type your comment')+'...'}>
                    </textarea>
                </div>

                <div className="engage_the-comment_content_engage engage_the-comment_content_engage-send">
                    <div className="left">
                        <button onClick={onSubmitComment}><i className="fal fa-paper-plane" /> {getTranslatedPhrase(props.userLanguage,'Post Comment')}</button>
                    </div>
                    {!props.firstCommentReplyBox && (
                    <div className="right">
                        <button onClick={onDiscardComment}><i className="fal fa-times" /></button>
                    </div>
                    )}
                    <div className="clb" />
                </div>

                <div className="engage_the-comment_content_arrow" />
            </div> 

            <div className="clb" />
        
        </div>
    );   
}


export default AddCommentReply;