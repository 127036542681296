import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { hostNameAndPort } from '../../../util/vars';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../util/lang';


class ListingPreviewAdminPanel extends Component {
    state = {
        waitingForDeleteConfirmation: false,
        deleteOperationConfirmed: false,
    }

    componentDidUpdate() {
        if (this.state.deleteOperationConfirmed === this.props.deleteOperationConfirmed) {
            return;
        }
        if (!this.props.deleteOperationConfirmed) {
            this.setState({
                waitingForDeleteConfirmation: false,
                deleteOperationConfirmed: false
            });
            return;
        }
        else {
            if (!!this.state.waitingForDeleteConfirmation) {
                this.onDeleteConfirmedNow();
                this.setState({
                    waitingForDeleteConfirmation: false,
                    deleteOperationConfirmed: false
                });
            }
            this.props.resetDeleteOperationConfirmation();
        }
    }

    onClickDeleteHandler = () => {
        this.setState({ waitingForDeleteConfirmation: true });
        this.props.onShowConfirmDeleteModal();
    }

    onDeleteConfirmedNow = async () => {
        try {
            let options = {
                url: `${hostNameAndPort}/api/${this.props.itemType}/${this.props.itemSlug}`,
                method: 'delete'
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                //this.props.history.push('/'+this.props.itemType);
                window.location.reload();
            }
        } catch (err) {
            alert(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFRADPN01'));
        }
    }

    onClickEditHandler = () => {
        let a = '/';
        let z = this.props.itemType;
        switch (z) {
            case 'blogs':
            case 'photos':
            case 'videos':
            case 'events':
            case 'groups':
            case 'services':
            case 'jobs':
                a = a + z + '/edit-' + z.slice(0, -1);
                break;
            case 'store':
                a = '/store/edit-product';
                break;
            case 'news':
                a = '/news/edit-news';
                break;
            case 'food':
                a = '/food/edit-food-listing';
                break;
            case 'autos':
                a = '/autos/edit-auto-listing';
                break;
            case 'realestate':
            case 'realestate-old':
                a = '/realestate/edit-property';
                break;
            case 'travel':
                a = '/travel/edit-travel';
                break;
            default:
                break;
        }

        if (z === 'cars' || z === 'realestate')
            this.props.showFollowoneAlert(getTranslatedPhrase(this.props.userLanguage, 'Feature not available for this listing!'))
        else {
            let s = '?q=' + this.props.itemSlug;
            window.open(a + s, '_new' + Date.now())
            // this.props.history.push({ pathname: a, search: s});
        }

    }

    onResetListingDate = async () => {
        try {

            let itemType = this.props.itemType;

            let options = {
                url: `${hostNameAndPort}/api/${itemType}/reset-creation-date/${this.props.itemSlug}`,
                method: 'patch'
            };
            let result = await Axios(options);
            let { data } = result;

            // if (!data.error) {
            //     window.location.reload();
            // }
        } catch (err) {
            alert(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFRADPN01'));
        }
    }

    render() {
        if (!this.props.userIsAuthorized || this.props.itemType === 'cars' || this.props.itemType === 'realestate') {
            return null;
        }

        return (
            <div className="hide-on-m">

                {(this.props.itemType === 'autos' || this.props.itemType === 'jobs') &&
                    <button className="button button-clear button-small2" onClick={this.onResetListingDate}>
                        <i className="fal fa-calendar" />
                    </button>
                }

                <button className="button button-clear button-small2" onClick={this.onClickEditHandler}>
                    <i className="fal fa-pencil-alt" />
                </button>

                {/* <button className="button button-clear button-small2" onClick={this.onClickDeleteHandler}>
                        <i className="fal fa-trash-alt"></i>
                    </button>

                    <button className='button button-clear button-small2' onClick={this.onPromoteItem}>
                        <i className="fal fa-bullhorn"></i>
                    </button> */}

            </div>
        );
    }
}

const mapStateToListingPreviewAdminPanelProps = (
    state,
    props
) => {
    return {
        deleteOperationConfirmed: state.deleteOperationConfirmed,
        userLanguage: state.userLanguage
    };
};

const mapDispatchToListingPreviewAdminPanelProps = (
    dispatch
) => {
    return {
        onShowConfirmDeleteModal: () => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true });
        },
        showFollowoneAlert: (u) => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        resetDeleteOperationConfirmation: () => {
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        }
    };
};

export default connect(mapStateToListingPreviewAdminPanelProps, mapDispatchToListingPreviewAdminPanelProps)(withRouter(ListingPreviewAdminPanel));