import React from 'react';
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { displayNumber, formatPriceAndCurrency } from "../../../../shared/util/number";
import { titleCase } from "../../../../shared/util/string";
import RealEstateFloorSize from "../Assets/realestate-surface.svg";
import { connect } from "react-redux";

const RealEstatePreviewInfo = (props) => {
    let label = '';
    let labelCategory = null;
    if (props.rentOrSell === 'sell' || props.category === 'Residential' || /sale/i.test(props.listingType) || /(sale|farm)/i.test(props.PropertyType)) label = 'For Sale';
    else if ((props.rentOrSell === 'rent' && /commercial/i.test(props.category)) || /commercial lease/i.test(props.category) || props.listingType === "For Lease") label = 'For Lease';
    else if (props.rentOrSell === 'rent' || /residential lease/i.test(props.category) || /storage/i.test(props.subcategory)) label = 'For Rent';
    if (props.isMlsBiz || props.subcategory === 'Business Opportunity') {
        label = '';
        labelCategory = <span>Business<br />Opportunity</span>;
    }
    return (
        <div className="slp-pricing-header">
            <div className="slp-pricing-header-price">
                <p>
                    {label}
                    {labelCategory}
                </p>


                {(!!props.callForPricing && props.callForPricing === 'call-for-pricing')
                    ? (
                        <p className="store-listing-preview_price store-listing-preview_price-call">{getTranslatedPhrase(props.userLanguage, 'Call For Pricing')}</p>)
                    : (!props.addDiscount)
                        ? (
                            <p className="store-listing-preview_price">{formatPriceAndCurrency(props.price, props.currency, ',')}
                                <span className="store-listing-preview_price-attr">
                                    {(props.rentOrSell && props.rentOrSell === 'rent')
                                        ? ' / ' + getTranslatedPhrase(props.userLanguage, 'month')
                                        : ''}
                                    {(!props.rateType)
                                        ? ''
                                        : ' / ' + getTranslatedPhrase(props.userLanguage, titleCase(props.rateType))
                                    }
                                    {props.isMlsCom && props.listingType === "For Lease" &&
                                        <span>/SF/YR</span>}
                                </span>
                            </p>)
                        : (
                            <p className="store-listing-preview_price">
                                {!props.afterDiscountPrice
                                    ? getTranslatedPhrase(props.userLanguage, 'FREE')
                                    : (<>
                                        {props.afterDiscountPrice}
                                        <span className="store-listing-preview_price-attr">
                                            {(props.rentOrSell && props.rentOrSell === 'rent')
                                                ? ' / ' + getTranslatedPhrase(props.userLanguage, 'month')
                                                : ''}
                                            {(!props.rateType)
                                                ? ''
                                                : ' / ' + getTranslatedPhrase(props.userLanguage, titleCase(props.rateType))
                                            } </span></>)} {' '}
                                <span className="deals-was-price">
                                    {formatPriceAndCurrency(props.price, props.currency, ',')}

                                </span>
                            </p>
                        )}
            </div>

            <div className="slp-divider" />

            <div className="slp-pricing-header-option">
                <img src={RealEstateFloorSize} alt="floor size" />
                <p>Floor Size <br />
                    <strong className="capitalize">
                        {props.floorSize ? `${displayNumber(props.floorSize)} ${props.floorSizeUnit === 'Floor Size SQ Meters' ? 'Sq M' : 'SF'}` : 'Call Agent'}
                    </strong>
                </p>
            </div>

            <div className="slp-divider" />

            <div className="slp-pricing-header-option">
                <i className="fal fa-calendar-alt" />
                <p>Time on Market <br /><strong className="capitalize">Call Agent</strong></p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { userLanguage: state.userLanguage, };
};

export default connect(mapStateToProps)(RealEstatePreviewInfo);