import React, { useState, useEffect } from "react";
import BackgroundImage from "../../../assets/home/home-search-services.jpg";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import actionType from "../../../redux-store/action-type";
import AdvServices from "../../../shared/components/Modals/Search/AdvServices";
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { hostNameAndPort } from "../../../shared/util/vars";
import Axios from "axios";
import Autosuggest from "react-autosuggest";


const ServicesSearch = props => {
    const [category, setCategory] = useState("");
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateVar, setStateVar] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [cityMissing, setCityMissing] = useState(false);

    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);
    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const windowLocationSearch = window.location.search;

    function checkLogin() {
        if (!loggedInUser) props.onClickLoginButtonHandler("/services/new-service");
        else props.history.push("/services/new-service");
    }

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;
    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                title,
                address,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            if (!!props.category) props.history.push('/services');
        }
    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {
            const ts = {
                category,
                title,
                address,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            return;
        }
        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        if (category !== cat) setCategory(cat);

        const ts = {
            title,
            address,
            country,
            stateVar,
            city,
            zipCode
        };
        let changeState = false;

        const ns = {
            title: urlParams.get('keywords') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || ''
        };

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;

        let keywordsParts = ns.title.split('-');
        if (keywordsParts.length > 1) {
            keywordsParts.shift();
            keywordsParts.pop();
        }
        ns.title = keywordsParts.join('-');

        if (ns.title !== ts.title || ns.address !== ts.address || ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode)
            changeState = true;

        if (changeState) {
            for (let i of Object.keys(ns)) onChangeItem(ns[i], i);
            if (!!ns.city || !!ns.stateVar) onChangeItem(ns.city + ', ' + ns.stateVar, 'address');
        }
    }, [windowLocationSearch]);

    function onChangeItem(_value, _prop) {
        if (_prop === "category") {
            if (category === _value) setCategory("");
            else setCategory(_value);
            return;
        }

        if (_prop === "title" && _value.length < 200) {
            setTitle(_value);
        }


        if (_prop === "address" && _value.length < 500) onChangeAddress(null, { newValue: _value });
        switch (_prop) {
            case 'country':
                setCountry(_value);
                break;
            case 'stateVar':
                setStateVar(_value);
                break;
            case 'city':
                setCity(_value);
                break;
            case 'zipCode':
                setZipCode(_value);
                break;
            // Andrei Check - Add Default Case
        }
    }

    function onChangeAddress(event, { newValue }) {
        if (/\\/g.test(newValue)) return;
        if (/^ +/.test(newValue)) newValue = newValue.replace(/^ +/, '');
        if (address.length < 2 && newValue.length >= 1 && !cityList.length && !/^[0-9]+/.test(newValue)) getCityList(newValue.substring(0, 2));
        if (newValue.length < 2) {
            if (cityList.length)
                setCityList([]);
            setCityMissing(false);
        }
        if (/^, ?/.test(newValue)) {
            setCityMissing(true);
            newValue = newValue.replace(/^, ?/, '');
        }
        setAddress(newValue);
    }

    async function getCityList(value) {

        try {

            let localCountry;

            if (!country) {
                if (document.cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
                    let data = document.cookie.match(/fo_userCountry=([^;]*)(;|$)/);
                    localCountry = data[1] || 'USA';
                }
            } else {
                localCountry = country;
            }

            let result = await Axios(hostNameAndPort + '/api/cities/options-for/' + localCountry + '/' + value);
            let { data } = result;
            if (!data.error) {
                setCityList(data.items);
            } else setCityList([]);
        } catch (error) {
            setCityList([]);
        }
    }

    function getSuggestions(value = '') {
        if (/\\/g.test(value)) return cityList;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        let a = cityList;
        let r;
        if (value.length && value[value.length - 1] === ' ') {
            let v = value.substring(0, value.length - 1);
            r = new RegExp('^' + v + ',?', 'i');
        } else r = new RegExp('^' + value, 'i');
        a = a.filter(v => r.test(v));

        return inputLength === 0 ? [] : a;
    }

    function getSuggestionValue(suggestion) {
        return suggestion;
    }

    function renderSuggestion(suggestion) {
        return (
            <div>
                {suggestion}
            </div>
        );
    }

    function onSuggestionsFetchRequested({ value }) {
        setSuggestions(getSuggestions(value));
    }

    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function onSubmitServicesForm(e, s) {
        e.preventDefault();
        let f;
        if (!title) f = "filters-" + Date.now();
        else f = "filters-" + title + "-" + Date.now();

        let a = address;
        if (cityMissing) {
            a = ', ' + a;
        }
        a = a.split(',');
        let city = "";
        let zipCode = "";
        let state = "";
        if (a.length > 1) {
            city = a[0].trim();
            state = a[1].trim();
        } else {
            a = a[0].trim();
            if (/^[-a-z' ]+$/.test(a.toLowerCase())) city = a;
            else if (/^[-a-z0-9]+$/.test(a.toLowerCase())) zipCode = a;
        }
        let c = category.replace(/&/g, "%26");
        let s_q = JSON.parse(searchQuery || '{}');
        if (!state && country === s_q.country) state = s_q.region;


        if (!s) props.history.push(`/services/search/${f}?priceAscending=0&priceMin=&priceMax=&viewsDescending=false&priceRange=false&withCoupons=false&rating=0&rateType=&zipCode=${zipCode}&address=&state=${state}&city=${city}&country=${country}&category=${c}&keywords=${f}&subcategory=`);
        else {
            // s = s.replace(/filters-[0-9]*/g, f);
            props.history.push(s);
        }
    }

    const advancedSearchHandler = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const inputProps = {
        placeholder: getTranslatedPhrase(userLanguage, 'Type a city, state.'),
        value: address,
        onChange: onChangeAddress
    };

    return (
        <>
            {modalOpen && <AdvServices closeModal={closeModal} {...props} onSubmitHandler={onSubmitServicesForm} />}
            <div className="home-search-box" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="hsb-background" />
                <div className="hsb-container">
                    <h2>{getTranslatedPhrase(userLanguage, 'Services')}</h2>
                    <form onSubmit={onSubmitServicesForm}>
                        <div className="hsb-filter">
                            <input type="radio" name="services" id="servicesall" checked={category === ""} onChange={e => null} /> <label htmlFor="servicesall" onClick={e => onChangeItem("", "category")}>{getTranslatedPhrase(userLanguage, 'All')}</label>
                            <input type="radio" name="services" id="construction" checked={category === "construction-&-remodeling"} onChange={e => null} /> <label htmlFor="construction"
                                onClick={e => onChangeItem("construction-&-remodeling", "category")}>{getTranslatedPhrase(userLanguage, 'Construction')}</label>
                            <input type="radio" name="services" id="fitness" checked={category === "sports-&-fitness"} onChange={e => null} /> <label className="mobile-hide" htmlFor="fitness"
                                onClick={e => onChangeItem("sports-&-fitness", "category")}>{getTranslatedPhrase(userLanguage, 'Fitness')}</label>
                            <input type="radio" name="services" id="it" checked={category === "programming-&-tech"} onChange={e => null} /> <label htmlFor="it" onClick={e => onChangeItem("programming-&-tech", "category")}>IT</label>
                        </div>
                        <div className="hsb-input hsb-input-services">
                            <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Find pros for your next project.")} value={title} onChange={e => onChangeItem(e.target.value, "title")} />
                        </div>
                        <div className="hsb-input hsb-input-location">
                            {/* <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Location: City or ZIP Code.")} value={address} onChange={e => onChangeItem(e.target.value, "address")} /> */}
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                        </div>
                        <div className="hsb-buttons">
                            <div>
                                <button className="button button-white" type="submit" onSubmit={onSubmitServicesForm}>{getTranslatedPhrase(userLanguage, 'Search')}</button>
                                {props.category && <button className="button button-white" type="button" onClick={advancedSearchHandler}>{getTranslatedPhrase(userLanguage, 'Advanced Search')}</button>}
                            </div>
                            <div>
                                <Link to="/services">{props.category ? getTranslatedPhrase(userLanguage, "Services Home") : getTranslatedPhrase(userLanguage, "Home")}</Link> /
                                <button style={{ fontSize: "1.4rem", color: "#fff", marginLeft: "1rem" }} onClick={checkLogin}>{getTranslatedPhrase(userLanguage, 'Post Services')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};


const mapDispatchToServicesSearchProps = dispatch => {
    return {
        onClickLoginButtonHandler: (returnUrl) => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl })
    };
};

export default connect(null, mapDispatchToServicesSearchProps)(withRouter(ServicesSearch));