import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageNumbers from '../../../shared/uielements/Pagination/page-numbers';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { titleCase } from '../../../shared/util/string';
import SectionLoader from '../../../shared/util/section-loader';
import { Helmet } from "react-helmet";
import CategoryLoader from "../../../shared/templates/CategoryTemplate/category-loader";

const MAX_TIME = 15000;
const REQUEST_IS_SENT_TIMEOUT = 3000;
var requestIsSentOnRealEstateCategoryPage = false;

class CategoryPage extends Component {
  state = {
    filter: '',
    value: '',
    subcategory: '',
    currentPage: 1,
    itemsPerPage: 20,
    loadedOld: false,
    loadedNew: false,
    pageRefresh: '',
    totalCountRealestateOld: 0,
    totalCountRealestate: 0,
    itemsRealestateOld: [],
    itemsRealestate: [],
    LoaderRealestateOld: null,
    LoaderRealestate: null,
    // userCountry: 'No Country',
    isProfilePageConfirmed: false,
  }

  componentDidMount() {
    // Trigger the update
    this.setState({
      // filter: 'category',
      value: 'all',
      subcategory: '',
      category: this.props.section,
      startTimePassed: Date.now(),
      isProfilePageConfirmed: this.props.isProfilePage,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    try {

      // PROFILE PAGE
      if ((this.state.Loader && !this.state.isProfilePageConfirmed && this.props.isProfilePage) || this.props.section !== this.state.category) {

        this.state.Loader.updateSection(this.props.section, true)
        this.setState({ isProfilePageConfirmed: true })

        if (this.props.section !== this.state.category) {
          this.setState({
            isProfilePageConfirmed: false,
            category: this.props.section
          })
        }
        return;
      }


      // Check for latitude and longitude
      // let timePassed = Date.now() - this.state.startTimePassed;
      // if (this.props.clientLatitude === 0 && this.props.clientLongitude === 0 && timePassed < 5000) {
      //   return;
      // }

      if (this.state.itemsRealestateOld.length !== this.state.totalCountRealestateOld && this.state.LoaderRealestateOld.items.length === this.state.totalCountRealestateOld) {
        this.setState({ itemsRealestateOld: this.state.LoaderRealestateOld.items });
        return;
      }

      let s_q = JSON.parse(this.props.searchQuery || '{}');
      // let countryUpdated = s_q.countryUpdated;
      let currentCountry = s_q.country;
      let currentRegion = s_q.region;


      // Get the current page
      const urlParams = new URLSearchParams(window.location.search);
      const currentPage = Number(urlParams.get('page')) || 1;

      let m = this.props.match, formParams = {}, formParams_Realestate = {};

      // When the category changes
      let localCategory, localSubcategory;
      if (typeof m.params.storeCategory !== 'undefined') {
        localCategory = m.params.storeCategory;
      } else {
        localCategory = '';
      }

      // When the subcategory changes
      if (typeof m.params.storeSubcategory !== 'undefined') {
        localSubcategory = m.params.storeSubcategory;
      } else {
        localSubcategory = '';
      }
      let categoryChanged = (m.url.indexOf('/realestate/search/') < 0) && ((this.state.value !== localCategory) || (this.state.subcategory !== localSubcategory));

      // When the search query changes
      let q = '';
      if (typeof m.params.storeQuery !== 'undefined') {
        q = m.params.storeQuery;
      }
      let nextState = {};

      if (categoryChanged) {
        nextState = {
          filter: 'category',
          value: localCategory,
          subcategory: localSubcategory,
          // userCountry: countryUpdated
        };

        this.setState(nextState);
        setTimeout(() => {
          this.setState({ loadedOld: true, loadedNew: true });
        }, MAX_TIME);

        let f = {
          category: localCategory,
          subcategory: localSubcategory,
          // country: countryUpdated,
        };

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
            f.realestateAgency = this.props.realestateAgency;
            f.author = this.props.userId;
            f.noOldDocuments = true;
          } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
            f.author = this.props.userId;
            f.isFo = true;
          }
        }

        let f_Realestate = {
          showInactive: true,
          category: localCategory,
          subcategory: localSubcategory,
          // country: countryUpdated
        };

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
            f_Realestate.realestateAgency = this.props.realestateAgency;
            f_Realestate.noOldDocuments = true;
            f_Realestate.category = null;
          } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
            f_Realestate.author = this.props.userId;
            f_Realestate.category = null;
            f_Realestate.isFo = true;
          }
        }





        // if (currentCountry === countryUpdated) {
        //   f.state = currentRegion;
        //   f_Realestate.state = currentRegion;
        // }

        for (const [key, value] of urlParams.entries()) {
          f[key] = value;
          f_Realestate[key] = value;
        }


        // if (f_Realestate.country === 'USA') delete f_Realestate.country;

        formParams = {
          filter: JSON.stringify(f),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        };
        formParams_Realestate = {
          filter: JSON.stringify(f_Realestate),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude }),
          // noCount: true
        };

        if (!this.state.LoaderRealestateOld && !requestIsSentOnRealEstateCategoryPage) {
          let nextState = {
            loadedOld: false,
          };
          // if (f.country === 'USA') 
          nextState.loadedNew = false;
          this.setState(nextState);
          // console.log('new cat init loader')
          this.initLoader(currentPage, formParams, formParams_Realestate, false);
          requestIsSentOnRealEstateCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnRealEstateCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
          return;
        }

        if (this.state.LoaderRealestateOld && this.state.LoaderRealestate) {
          // if (f.country === 'USA') {
          // this.state.LoaderRealestateOld.pageCount = 5;
          // this.state.LoaderRealestate.pageCount = this.state.itemsPerPage - 5;

          // } else {
          //   this.state.LoaderRealestateOld.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderRealestate.pageCount = 0;
          // }

          this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
        }

        if (!requestIsSentOnRealEstateCategoryPage) {
          let nextState = {
            loadedOld: false,
          };
          // if (f.country === 'USA') 
          nextState.loadedNew = false;
          this.setState(nextState);
          // console.log('new cat update form params')

          this.state.LoaderRealestate.updateFormParams(formParams_Realestate);
          this.state.LoaderRealestateOld.updateFormParams(formParams); // UN-COMMENT
          requestIsSentOnRealEstateCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnRealEstateCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }
      }

      else if (q && (this.state.value !== q)) {
        nextState = {
          filter: 'keywords',
          value: q,
          // userCountry: countryUpdated,
          totalCountRealestateOld: 0,
          totalCountRealestate: 0,
          itemsRealestateOld: [],
          itemsRealestate: [],
          // loadedOld: false
        };
        setTimeout(() => {
          this.setState({ loadedOld: true, loadedNew: true });
        }, MAX_TIME);
        let f = {
          //keywords: q,
          // country: countryUpdated,

        };
        let f_Realestate = {
          showInactive: true,
          // country: countryUpdated
        };
        for (const [key, value] of urlParams.entries()) {
          f[key] = value;
          f_Realestate[key] = value;
        }
        if (f_Realestate.country === 'USA') {
          // delete f_Realestate.country; 
          nextState.loadedNew = false;
        }
        this.setState(nextState);
        // if (currentCountry === f.country && !f.state) {
        //   f.state = currentRegion;
        //   f_Realestate.state = currentRegion;
        // }


        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
            f.realestateAgency = this.props.realestateAgency;
            f.noOldDocuments = true;
          } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
            f.author = this.props.userId;
            f.isFo = true;
          }
        }


        // if (currentCountry === f.country && !f.city && !f.state && !f.zipCode) {
        //   delete f.country;
        //   delete f_Realestate.country;
        // }
        formParams = {
          filter: JSON.stringify(f),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        };


        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
            f_Realestate.realestateAgency = this.props.realestateAgency;
            f_Realestate.noOldDocuments = true;
          } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
            f_Realestate.author = this.props.userId;
            f_Realestate.isFo = true;
          }
        }


        formParams_Realestate = {
          filter: JSON.stringify(f_Realestate),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude }),
          // noCount: true
        };
        if (!this.state.LoaderRealestateOld && !requestIsSentOnRealEstateCategoryPage) {
          // console.log('new search init loader')
          this.initLoader(currentPage, formParams, formParams_Realestate, f.country === 'USA');
          requestIsSentOnRealEstateCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnRealEstateCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
          return;
        }
        if (this.state.LoaderRealestateOld && this.state.LoaderRealestate) {
          // if (f.country === 'USA') {
          //   // this.state.LoaderRealestateOld.pageCount = 5;
          //   // this.state.LoaderRealestate.pageCount = this.state.itemsPerPage - 5;
          //   this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
          // } else {
          //   this.state.LoaderRealestateOld.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderRealestate.pageCount = 0;
          // }

          this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
        }
        if (!requestIsSentOnRealEstateCategoryPage) {
          let nextState = {
            loadedOld: false
          };
          // if (f.country === 'USA') 
          nextState.loadedNew = false;
          this.setState(nextState);
          // console.log('new search update form params')

          this.state.LoaderRealestate.updateFormParams(formParams_Realestate);
          this.state.LoaderRealestateOld.updateFormParams(formParams); // UN-COMMENT
          requestIsSentOnRealEstateCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnRealEstateCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }
      }

      else if (currentPage !== this.state.currentPage) {
        if (currentPage < this.state.currentPage && this.state.LoaderRealestateOld && this.state.LoaderRealestate) {
          // if (countryUpdated === 'USA') {
          //   // this.state.LoaderRealestateOld.pageCount = 5;
          //   // this.state.LoaderRealestate.pageCount = this.state.itemsPerPage - 5;
          //   this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
          // } else {
          //   this.state.LoaderRealestateOld.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderRealestate.pageCount = 0;
          // }

          this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
        }
        // let currentSearchCountry = countryUpdated;
        // for (const [key, value] of urlParams.entries()) {
        //   if (key === 'country') currentSearchCountry = value;
        // }
        let nextState = {
          loadedOld: false,
          loadedNew: false,
          currentPage
        };
        // if (currentSearchCountry !== 'USA') 
        nextState.loadedNew = true;
        this.setState(nextState);
        setTimeout(() => {
          this.setState({ loadedOld: true, loadedNew: true });
        }, MAX_TIME);
        if (!requestIsSentOnRealEstateCategoryPage) {
          setTimeout(() => {
            this.setState({
              loadedOld: true,
              loadedNew: true
            });
          }, MAX_TIME);
          // console.log('new page update form params')
          this.state.LoaderRealestate.updatePage(currentPage);
          // this.state.LoaderRealestateOld.updatePage(currentPage); // UN-COMMENT
          requestIsSentOnRealEstateCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnRealEstateCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }
      }

      // else if (this.state.userCountry !== countryUpdated && this.state.loadedOld && this.state.loadedNew) {

      //   // WHEN THE USER CHANGES THE COUNTRY FROM THE HEADER

      //   this.setState({
      //     userCountry: countryUpdated
      //   });
      //   setTimeout(() => {
      //     this.setState({ loadedOld: true, loadedNew: true });
      //   }, MAX_TIME);

      //   let f = {
      //     filter: 'category',
      //     country: countryUpdated,
      //     category: localCategory,
      //     subcategory: localSubcategory
      //   };

      //   // PROFILE PAGE
      //   if (this.state.isProfilePageConfirmed) {
      //     if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
      //       f.realestateAgency = this.props.realestateAgency;
      //       f.noOldDocuments = true;
      //       f.category = null;
      //     } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
      //       f.author = this.props.userId;
      //       f.category = null;
      //     }
      //   }

      //   let f_Realestate = {
      //     showInactive: true,
      //     filter: 'category',
      //     country: countryUpdated,
      //     category: localCategory,
      //     subcategory: localSubcategory
      //   };


      //   // PROFILE PAGE
      //   // if (this.props.realestateAgency && this.state.isProfilePageConfirmed) {
      //   //   console.log('d2')
      //   //   f_Realestate.realestateAgency = this.props.realestateAgency;
      //   // } 

      //   if (this.state.isProfilePageConfirmed) {
      //     if (this.props.section === 'realestate' && this.props.realestateAgency !== '') {
      //       f_Realestate.realestateAgency = this.props.realestateAgency;
      //       f_Realestate.noOldDocuments = true;
      //       f_Realestate.category = null;
      //     } else if (this.props.section === 'realestate' && !this.props.realestateAgency) {
      //       f_Realestate.author = this.props.userId;
      //       f_Realestate.category = null;
      //     }
      //   }


      //   // if (f_Realestate.country === 'USA') delete f_Realestate.country;
      //   for (const [key, value] of urlParams.entries())
      //     if (key !== 'country') {
      //       f[key] = value;
      //       f_Realestate[key] = value;
      //     }
      //   if (currentCountry === f.country && !f.state) {
      //     f.state = currentRegion;
      //     f_Realestate.state = currentRegion;
      //   }
      //   // if (currentCountry === countryUpdated && !f.city && !f.state && !f.zipCode) {
      //   //   delete f.country;
      //   //   delete f_Realestate.country;
      //   // }

      //   formParams = {
      //     filter: JSON.stringify(f),
      //     page: currentPage,
      //     geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
      //   };

      //   formParams_Realestate = {
      //     filter: JSON.stringify(f_Realestate),
      //     page: currentPage,
      //     geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude }),
      //     // noCount: true
      //   };
      //   if (!this.state.LoaderRealestateOld && !requestIsSentOnRealEstateCategoryPage) {
      //     // console.log('new country init loader')
      //     this.initLoader(currentPage, formParams, formParams_Realestate, f.country === 'USA');
      //     requestIsSentOnRealEstateCategoryPage = true;
      //     setTimeout(() => {
      //       requestIsSentOnRealEstateCategoryPage = false;
      //     }, REQUEST_IS_SENT_TIMEOUT);
      //     return;
      //   }
      //   if (this.state.LoaderRealestateOld && this.state.LoaderRealestate) {
      //     if (f.country === 'USA') {
      //       // this.state.LoaderRealestateOld.pageCount = 5;
      //       // this.state.LoaderRealestate.pageCount = this.state.itemsPerPage - 5;
      //       this.state.LoaderRealestate.pageCount = this.state.itemsPerPage;
      //     } else {
      //       this.state.LoaderRealestateOld.pageCount = this.state.itemsPerPage;
      //       this.state.LoaderRealestate.pageCount = 0;
      //     }
      //   }
      //   if (!requestIsSentOnRealEstateCategoryPage) {
      //     let nextState = {
      //       loadedOld: false
      //     };
      //     if (f_Realestate.country !== 'USA') nextState.loadedNew = true;
      //     this.setState(nextState);
      //     // console.log('new country update form params')
      //     if ((f_Realestate.country && f_Realestate.country === 'USA') || !f_Realestate.country)
      //       this.state.LoaderRealestate.updateFormParams(formParams_Realestate, true);
      //     this.state.LoaderRealestateOld.updateFormParams(formParams, true); // UN-COMMENT
      //     requestIsSentOnRealEstateCategoryPage = true;
      //     setTimeout(() => {
      //       requestIsSentOnRealEstateCategoryPage = false;
      //     }, REQUEST_IS_SENT_TIMEOUT);
      //   }
      // }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.log(err);
    }
  }





  initLoader = (page, formParams_RealestateOld, formParams_Realestate, fromUSA) => {
    let itemsPerPage = this.state.itemsPerPage;
    let pageCount = this.state.itemsPerPage; // 0 
    // if (!fromUSA) {
    //   pageCount = itemsPerPage;
    // } else {
    //   pageCount = 5;
    // }
    let nextState = {};
    if (!fromUSA) {
      nextState = {
        // LoaderRealestateOld: new SectionLoader({
        //   section: 'realestate-old',
        //   cb: this.onLoadRealestateOld,
        //   options: {
        //     page,
        //     pageCount: itemsPerPage,
        //     formParams: formParams_RealestateOld
        //   }
        // }),
        LoaderRealestate: new SectionLoader({
          section: 'realestate',
          cb: this.onLoadRealestate,
          options: {
            page,
            // noItems: true,
            // noCount: true,
            pageCount: itemsPerPage,
            formParams: formParams_Realestate
          }
        })
      }
    } else {
      nextState = {
        // LoaderRealestateOld: new SectionLoader({
        //   section: 'realestate-old',
        //   cb: this.onLoadRealestateOld,
        //   options: {
        //     page,
        //     // noItems: true, // REMOVE
        //     // noCount: true, // REMOVE
        //     pageCount,
        //     formParams: formParams_RealestateOld
        //   }
        // }),
        LoaderRealestate: new SectionLoader({
          section: 'realestate',
          cb: this.onLoadRealestate,
          options: {
            page,
            // pageCount: itemsPerPage, // REMOVE
            // noCount: true, // REMOVE
            pageCount: itemsPerPage - pageCount, // UN-COMMENT
            formParams: formParams_Realestate
          }
        })
      }
    }
    this.setState(nextState);
  }

  onLoadRealestateOld = (err) => {
    return;
    if (err && (process.env.NODE_ENV === 'development')) console.log(err);
    // window.scrollTo(0, 0);
    let nextState;
    if (!this.state.LoaderRealestateOld)
      nextState = {
        totalCountRealestateOld: 0,
        itemsRealestateOld: [],
        // loadedOld: true
      };
    else nextState = {
      totalCountRealestateOld: this.state.LoaderRealestateOld.totalCount,
      itemsRealestateOld: this.state.LoaderRealestateOld.items,
      loadedOld: true
    };
    this.setState(nextState);
  }

  onLoadRealestate = (err) => {
    if (err && (process.env.NODE_ENV === 'development')) console.log(err);

    if (this.props.history.location.hash === "#top") {
      window.scrollTo(0, 0);
    }

    let nextState, totalCountRealestate = 0;
    // if (this.state.LoaderRealestate && this.state.LoaderRealestate.items.length) totalCountRealestate = 500;
    if (!this.state.LoaderRealestate)
      nextState = {
        totalCountRealestate: 0,
        itemsRealestate: [],
        // loadedNew: false
      };
    else nextState = {
      totalCountRealestate: this.state.LoaderRealestate.totalCount,
      itemsRealestate: this.state.LoaderRealestate.items,
      loadedNew: true
    };


    this.setState(nextState);
  }

  onUpdatePageNumber = (n = 1) => {
    const urlParams = new URLSearchParams(window.location.search);
    let s = 'page=' + n + '&';
    for (const [key, value] of urlParams.entries())
      if (key === 'category' || key === 'subcategory') s = s + key + '=' + value.replace(/&/g, '%26') + '&';
      else if (key !== 'page') s = s + key + '=' + value + '&';
    s = s.substring(0, s.length - 1);



    // PROFILE PAGE
    if (this.state.isProfilePageConfirmed) {
      this.props.history.push(this.props.match.url + '?' + encodeURI(s));
    } else {
      this.props.history.push(this.props.match.url + '?' + encodeURI(s));
    }

    window.scrollTo(0, 0);
  }

  onUpdateCategory = (value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const PARAMS = ['country', 'state'];
    let s = '';
    for (const [key, value] of urlParams.entries())
      if (PARAMS.indexOf(key) >= 0) s = s + key + '=' + value + '&';

    s = s + `category=${value.replace(/&/g, '%26')}&subcategory=`;

    if (!value || value === 'all') {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}?` + encodeURI(s));
      }

    } else {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + value + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${value}?` + encodeURI(s));
      }
    }

    window.scrollTo(0, 0);
  }

  onUpdateSubcategory = (category, value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const PARAMS = ['country', 'state'];
    let s = '';
    for (const [key, value] of urlParams.entries())
      if (PARAMS.indexOf(key) >= 0) s = s + key + '=' + value + '&';

    s = s + `category=${category.replace(/&/g, '%26')}&subcategory=${value.replace(/&/g, '%26')}`;

    if (!value || value === 'all') {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + category + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${category}?` + encodeURI(s));
      }
    } else {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + category + '/' + value + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${category}/${value}?` + encodeURI(s));
      }
    }

    window.scrollTo(0, 0);
  }

  onSubmitSearchHandler = (q) => {
    if (!q || !q.keywords) return;
    let s = '';
    for (let i in q) s = s + i + '=' + q[i] + '&';
    s = s.substring(0, s.length - 1);


    // PROFILE PAGE
    if (this.state.isProfilePageConfirmed) {
      this.props.history.push('/' + this.props.slug + '/realestate/search/' + encodeURI(q.keywords) + '?' + encodeURI(s));
    } else {
      this.props.history.push('/realestate/search/' + encodeURI(q.keywords) + '?' + encodeURI(s));
    }
  }

  getPageTitle = () => {
    let s = this.state.value;
    if (!s || s === 'all') {
      s = 'Latest Properties';
    }
    if (this.state.filter === 'keywords') {
      if (s.startsWith('filters-')) s = s.replace(/^filters-/, '').replace(/-?[0-9]+$/, '');
      let a = ['🚗', '🚲', '🏍'];
      if (!s) s = 'Latest Properties';
      else s = getTranslatedPhrase(this.props.userLanguage, 'Search for') + ' "' + s + '"';
    }
    return s;
  }

  render() {
    // let totalCount = this.state.totalCountRealestateOld + this.state.totalCountRealestate;
    // let items = [...this.state.itemsRealestate, ...this.state.itemsRealestateOld];



    let totalCount = this.state.totalCountRealestate;
    let items = this.state.itemsRealestate;

    // if (totalCount < items.length) totalCount = items.length;

    // console.log(items.length, this.state.itemsPerPage, totalCount, this.state.itemsPerPage * (this.state.currentPage - 1) + items.length, this.state.itemsRealestate.length, this.state.LoaderRealestate && this.state.LoaderRealestate.pageCount)

    // if (items.length < this.state.itemsPerPage && this.state.LoaderRealestate && this.state.itemsRealestate.length < this.state.LoaderRealestate.pageCount && this.state.itemsRealestate.length !== this.state.LoaderRealestate.pageCount * (this.state.currentPage - 1) + this.state.itemsRealestate.length) {
    //   totalCount = this.state.itemsPerPage * this.state.currentPage + 1;
    // }

    // if (this.state.LoaderRealestate && this.state.itemsRealestate.length < this.state.LoaderRealestate.pageCount) {
    //   totalCount = this.state.itemsPerPage * (this.state.currentPage - 1) + 1;
    // }

    // console.log(totalCount)

    // if (this.state.LoaderRealestateOld && this.state.LoaderRealestate && this.state.totalCountRealestateOld >= this.state.LoaderRealestateOld.pageCount && this.state.totalCountRealestate <= this.state.LoaderRealestate.pageCount) {
    //   totalCount = this.state.itemsPerPage * this.state.currentPage + 1;
    // }

    // let loaded = this.state.loadedOld && this.state.loadedNew;
    let loaded = this.state.loadedNew;

    let filter = this.state.filter;
    let filterValue = this.state.value;
    let pageTitleParts = ['No properties that match this criteria were found.'];
    if (filter === 'category' && filterValue && filterValue !== 'all') pageTitleParts = ['No properties that match this criteria (category: ', titleCase(filterValue.replace(/-/g, ' ')), ') were found.'];
    if (filter === 'keywords') {
      let a = filterValue.split('-');
      let count = a.length;
      if (count >= 3) {
        filterValue = '';
        for (let i = 1; i < count - 1; i++) filterValue = filterValue + ' ' + a[i];
        filterValue = a[1].trim().replace(/[\(\)]/g, '').replace(/(.\*)/g, ' ');
        pageTitleParts = ['No properties that match this criteria (keywords: ', titleCase(filterValue), ') were found.'];
      } else if (count === 2) {
        pageTitleParts = ['No properties that match your filters were found.'];
      }
    }

    let isCategoryHome = true;
    if (filter !== 'category') {
      const urlParams = new URLSearchParams(window.location.search);
      for (const [key, value] of urlParams.entries()) {
        if (key !== 'keywords') {
          if ((key === 'priceAscending' && value === '0') || (key === 'viewsDescending' && value === 'false') ||
            (key === 'priceRange' && value === 'false') || (key === 'withCoupons' && value === 'false') || (key === 'rating' && value === '0')) continue;
          if ((key === 'page' && value && value !== '1') || (key === 'category' && value && value !== 'null' && value !== 'all') || (key !== 'category' && value && value !== 'null' && value !== 'undefined')) {
            isCategoryHome = false;
            break;
          }
        } else {
          let a = value.split('-');
          if (a.length === 3) {
            isCategoryHome = false;
            break;
          }
        }
      }
    } else if (filterValue && filterValue !== 'all' && filterValue !== 'undefined') isCategoryHome = false;

    // console.log('render',this.props.searchQuery)
    // console.log(Math.ceil(totalCount / this.state.itemsPerPage))


    return (<>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Real Estate | Followone</title>
        <meta name="description" content={this.props.description} />
        <link rel="canonical" href="https://followone.com/realestate" />
      </Helmet>

      {/* <this.props.SideBarMobile
        section={'realestate'}
        category={this.state.value}
        onSubmitSearch={this.onSubmitSearchHandler}
        onChangeCategory={this.onUpdateCategory}
        onChangeSubcategory={this.onUpdateSubcategory}

        isProfilePage={this.props.isProfilePage}
        slug={this.props.slug}
        section={this.props.section}
      /> */}


      <div className="category-title">
        <h2 className="capitalize">{getTranslatedPhrase(this.props.userLanguage, this.getPageTitle())}</h2>
      </div>

      <div className="category-body">
        {!loaded
          ? (<div className="main-container store-home">
            <CategoryLoader />
          </div>)
          : (<div className="main-container store-home">
            {/* {(!totalCount && !items.length && loaded) ? isCategoryHome ? getTranslatedPhrase(this.props.userLanguage, 'No properties were found in your area. Use Advanced Search to look for properties in other areas.') : pageTitleParts.map(v => (getTranslatedPhrase(this.props.userLanguage, v))) : null} */}
            {(!totalCount && !items.length && loaded) ? isCategoryHome ? getTranslatedPhrase(this.props.userLanguage, 'Use Search/Advanced Search to look for more properties.') : pageTitleParts.map(v => (getTranslatedPhrase(this.props.userLanguage, v))) : null}
            <div className="category-list">
              {items.map((v, i) => (<this.props.ItemPreview itemType={(this.state.itemType === 'realestate' && !!this.state.id) ? 'realestate' : 'realestate-old'} key={'realestate/' + v.title + '-' + i} {...v} />))}
            </div>

            <PageNumbers
              count={Math.ceil(totalCount / this.state.itemsPerPage)}
              activePage={this.state.currentPage}
              onClick={this.onUpdatePageNumber} />
          </div>)
        }



        <this.props.SideBar
          section={'realestate'}
          category={this.state.value}
          onSubmitSearch={this.onSubmitSearchHandler}
          onChangeCategory={this.onUpdateCategory}
          onChangeSubcategory={this.onUpdateSubcategory}

          isProfilePage={this.props.isProfilePage}
          slug={this.props.slug}
          section={this.props.section}
        />

      </div>

    </>);
  }
}

const mapStateToRealEstateCategoryProps = (
  state,
  props
) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    currentGeolocation: state.currentGeolocation,
    searchQuery: state.searchQuery,
    clientLongitude: state.clientLongitude,
    clientLatitude: state.clientLatitude
  };
};


export default connect(mapStateToRealEstateCategoryProps, null)(withRouter(CategoryPage));