import React from 'react';

const ErrorMessages = (props) => {
    if (!props.show) {
        return null;
    }
    return (<div className={props.errorMessageClass}><strong>{props.errorMessageTitle}</strong> 
        <br /> {props.errorMessageBody}
        <br /> {props.children}
        </div>);
}

export default ErrorMessages;