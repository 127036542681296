import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import Axios from 'axios';
import { hostNameAndPort } from '../../../../shared/util/vars';
import SpecialItem from '../../../../shared/components/Advertising/SpecialItem/SpecialItem';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { withRouter } from 'react-router-dom';
import ListLoader from '../../../../shared/util/list-loader';


class Favorites extends Component {
    state = {
        items: null,
        Loader: null,
        tabs: ['Auto Listings'],
        tabActive: {},
        tabNumbers: {},
        hash: '-',
        slug: '',
    }

    componentDidMount() {
        this.initTabs();
        this.updateTabs().then(r => this.initLoader());
        this.setState({ slug: this.props.slug });
    }

    componentDidUpdate() {
        let { hash, pathname } = window.location;

        hash = decodeURI(hash.slice(1));
        if (pathname === `/${this.state.slug}/favorites` && hash && hash !== this.state.hash) {
            this.changeTab(hash);
            this.setState({ hash });
        }
    }

    initLoader = () => {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 2000,
                count: 20,
                request: {
                    url: `/api/engage/favorites/items`,
                    method: 'post',
                    formParams: {}
                }
            }
        });
        Loader.init();

        this.setState({ Loader });
    }

    onLoad = () => {
        if (!this.state.Loader) return;
        let items = this.state.Loader.items;
        this.setState({ items });
        // for (let i in items) {
        //     if (items[i].length) {
        //         this.changeTab(i);
        //         break;
        //     }
        // }
        let { hash, pathname } = window.location;

        if (pathname === `/${this.state.slug}/favorites` && hash) {
            hash = decodeURI(hash.slice(1));
            this.changeTab(hash);
        }
    }

    initTabs() {
        let _tabs = this.state.tabs;
        let a = {};
        let b = {};
        for (let i in _tabs) {
            a[_tabs[i]] = false;
            b[_tabs[i]] = 0;
        }

        this.setState({
            tabActive: a,
            tabNumbers: b,
        });
    }

    changeTab = (t) => {
        let section = ['autos'];
        let label = ['Auto Listings'];

        let a = { ...this.state.tabActive };
        if (label.indexOf(t) >= 0) {
            if (this.state.tabActive[t]) return;
            for (let i in a) a[i] = false;
            a[t] = true;
            for (let i in label)
                if (t === label[i]) t = section[i];
        } else if (section.indexOf(t) >= 0) {
            let c = t;
            for (let i in section)
                if (c === section[i]) c = label[i];
            if (this.state.tabActive[c]) return;
            for (let i in a) a[i] = false;
            a[c] = true;
            if (this.state.tabActive[c]) return;
        }
        else return;

        let { hash, pathname } = window.location;
        if (pathname === `/${this.state.slug}/favorites` && hash) {
            hash = decodeURI(hash.slice(1));
            if (hash !== t) {
                this.props.history.push(`#${t}`);
            }
        } else {
            this.props.history.push(`#${t}`);
        }

        this.setState({ tabActive: a });
    }

    updateTabs = async () => {
        try {
            let url = hostNameAndPort + '/api/engage/favorites/count';
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                let section = ['autos'];
                let label = ['Auto Listings'];
                let activeTab = '';
                let { hash, pathname } = window.location;

                if (pathname === `/${this.state.slug}/favorites` && hash) {
                    hash = decodeURI(hash.slice(1));
                    let pos = section.indexOf(hash);
                    if (pos >= 0) activeTab = label[pos];
                }
                if (!data.count[activeTab])
                    for (let i in data.count) {
                        if (data.count[i]) {
                            activeTab = i;
                            break;
                        }
                    }
                this.changeTab(activeTab);
                this.setState({ tabNumbers: data.count });
            }
        } catch (err) {
            if (process.env.NODE_ENV) console.log(err);
        }
    }

    onUpdateList = async (itemType, i) => {
        let a = [...this.state.items[itemType]];

        let item = a[i];
        let itemId = (!item.favoriteItemId) ? item.itemId : item.favoriteItemId;
        let mlsProp = item.mls_prop_name || '';
        // a = a.filter( (v,j) => (i !== j) );
        a.splice(i, 1);
        let tabNumbers = { ...this.state.tabNumbers };
        let activeTab = '';
        let allSections = ['autos'];
        let the = ['Auto Listings'];
        for (let label in allSections)
            if (itemType === allSections[label]) activeTab = the[label];

        if (activeTab) tabNumbers[activeTab] = tabNumbers[activeTab] - 1;
        if (tabNumbers[activeTab] < 0) tabNumbers[activeTab] = 0;
        let { items } = this.state;
        items[itemType] = a;
        this.setState({ items: { ...this.state.items }, tabNumbers });
        if (!!item) {
            if (itemType === 'realestate' && !item.ListingID && !item.ListingId) itemType = 'realestate-old';
            if (itemType === 'autos' && !!item.id) itemType = 'cars';
            this.props.onClickOnFavorite();
            await this.onClickToRemoveFavorite(itemType, itemId, mlsProp);
        }

        this.props.updateTotalFavorites();
    }

    onClickToRemoveFavorite = async (w, itemId, mlsProp) => {
        let favoriteData = new FormData();
        favoriteData.append('itemId', itemId);
        favoriteData.append('mlsProp', mlsProp);
        let options = {
            url: `${hostNameAndPort}/api/engage/removefavorite/${w}`,
            method: 'post',
            data: favoriteData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                //
            }
        } catch (err) {
            //console.log(err)
            //console.log('Network error');
        }
    }

    render() {
        let currentTabClass = {
            'true': 'active',
            'false': ''
        };

        let { items, tabNumbers, tabActive } = this.state;
        const itemsProps = ['autos'];
        const tabsProps = ['Autos'];

        if (!items) {
            items = {};
            for (let i of itemsProps) items[i] = [];
        } else {
            for (let i of itemsProps)
                if (!items[i]) items[i] = [];
        }
        let activeTabFound = 0;
        for (let i = 0; i < itemsProps.length; i++) {
            tabNumbers[tabsProps[i]] = items[itemsProps[i]].length;
            if (tabActive[tabsProps[i]] && !tabNumbers[tabsProps[i]]) {
                tabActive[tabsProps[i]] = false;
                activeTabFound = 1;
            }
        }
        for (let i = 0; i < itemsProps.length; i++) {
            if (tabNumbers[tabsProps[i]] && activeTabFound === 1) {
                tabActive[tabsProps[i]] = true;
                break;
            }
        }


        let stillWaiting = !this.state.Loader || (this.state.Loader && this.state.Loader.isLoadingStill);


        // ({this.state.tabNumbers['Products']})

        return (
            <div className="profile-favorites">
                <h4>Favorites</h4>

                <div className="profile-favorites-main">
                    {tabNumbers['Auto Listings'] === 0 ? <p className="no-items">{getTranslatedPhrase(this.props.userLanguage, 'You have no favorite items.')}</p> : (
                        <div className="profile-favorites-main-nav">
                            <div className="manage-listings_nav">
                                <span className="manage-listings_nav_header">{getTranslatedPhrase(this.props.userLanguage, 'Select Listing Type')}</span>
                                <div className="manage-listings_nav_items">
                                    {/* {(!tabNumbers['Products']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Products']]} key={0} onClick={e => this.changeTab('Products')}>{tabNumbers.Products} {getTranslatedPhrase(this.props.userLanguage, 'Products')}</button>)} */}
                                    {/* {(!tabNumbers['Foods']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Foods']]} key={12} onClick={e => this.changeTab('Foods')}>{tabNumbers.Foods} {getTranslatedPhrase(this.props.userLanguage, 'Foods')}</button>)} */}
                                    {(!tabNumbers['Auto Listings']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Auto Listings']]} key={'a0'} onClick={e => this.changeTab('Auto Listings')}>{tabNumbers['Auto Listings']} {getTranslatedPhrase(this.props.userLanguage, 'Auto Listings')}</button>)}
                                    {/* {(!tabNumbers['Properties']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Properties']]} key={1} onClick={e => this.changeTab('Properties')}>{tabNumbers.Properties} {getTranslatedPhrase(this.props.userLanguage, 'Properties')}</button>)} */}
                                    {/* {(!tabNumbers['Jobs']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Jobs']]} key={2} onClick={e => this.changeTab('Jobs')}>{tabNumbers.Jobs} {getTranslatedPhrase(this.props.userLanguage, 'Jobs')}</button>)} */}
                                    {/* {(!tabNumbers['Services']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Services']]} key={3} onClick={e => this.changeTab('Services')}>{tabNumbers.Services} {getTranslatedPhrase(this.props.userLanguage, 'Services')}</button>)} */}
                                    {/* {(!tabNumbers['Travel']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Travel']]} key={7} onClick={e => this.changeTab('Travel')}>{tabNumbers.Travel} {getTranslatedPhrase(this.props.userLanguage, 'Travel')}</button>)} */}
                                    {/* {(!tabNumbers['Blogs']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Blogs']]} key={4} onClick={e => this.changeTab('Blogs')}>{tabNumbers.Blogs} {getTranslatedPhrase(this.props.userLanguage, 'Blogs')}</button>)} */}
                                    {/* {(!tabNumbers['Photo Galleries']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Photo Galleries']]} key={5} onClick={e => this.changeTab('Photo Galleries')}>{tabNumbers['Photo Galleries']} {getTranslatedPhrase(this.props.userLanguage, 'Photo Galleries')}</button>)} */}
                                    {/* {(!tabNumbers['Videos']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Videos']]} key={8} onClick={e => this.changeTab('Videos')}>{tabNumbers.Videos} {getTranslatedPhrase(this.props.userLanguage, 'Videos')}</button>)} */}
                                    {/* {(!tabNumbers['Events']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Events']]} key={9} onClick={e => this.changeTab('Events')}>{tabNumbers.Events} {getTranslatedPhrase(this.props.userLanguage, 'Events')}</button>)} */}
                                    {/* {(!tabNumbers['Groups']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Groups']]} key={10} onClick={e => this.changeTab('Groups')}>{tabNumbers.Groups} {getTranslatedPhrase(this.props.userLanguage, 'Groups')}</button>)} */}
                                </div>
                            </div>

                        </div>)}



                    {(!this.props.totalCount) ? null : (<div className="profile-favorites-main-content">

                        <LoadingSvg active={stillWaiting} />

                        {/* {(!tabActive['Properties']) ? null : (<div className="real-estate_category">
                            <div className="category-list">
                                {(!items.realestate.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No properties that match this criteria were found') : null}
                                {items.realestate.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('realestate', i)} itemType={!v.ListingID && !v.ListingId ? "realestate-old" : "realestate"} userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Products']) ? null : (<div className="store-home">
                            <div className="category-list">
                                {(!items.store.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No products that match this criteria were found') : null}
                                {items.store.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('store', i)} itemType="store" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Foods']) ? null : (<div className="store-home">
                            <div className="category-list">
                                {(!items.food.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No foods that match this criteria were found') : null}
                                {items.food.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('food', i)} itemType="food" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {(!tabActive['Auto Listings']) ? null : (<div id="Auto Listings" className="store-home">
                            <div className="category-list">
                                {(!items.autos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No auto listings that match this criteria were found') : null}
                                {items.autos.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('autos', i)} itemType={!v.id ? "autos" : "cars"} userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)}

                        {/* {(!tabActive['Jobs']) ? null : (<div className="jobs-category">
                            <div className="category-list">
                                {(!items.jobs.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No jobs that match this criteria were found') : null}
                                {items.jobs.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('jobs', i)} itemType="jobs" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}
{/* 
                        {(!tabActive['Services']) ? null : (<div className="service-category">
                            <div className="category-list">
                                {(!items.services.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No services that match this criteria were found') : null}
                                {items.services.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('services', i)} itemType="services" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Blogs']) ? null : (<div className="store-home">
                            <div className="category-list">
                                {(!items.blogs.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No blogs that match this criteria were found') : null}
                                {items.blogs.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('blogs', i)} itemType="blogs" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Photo Galleries']) ? null : (<div id="Photo Galleries" className="photo-gallery-category">
                            <div className="category-list">
                                {(!items.photos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No photo galleries that match this criteria were found') : null}
                                {items.photos.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('photos', i)} itemType="photos" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Videos']) ? null : (<div className="video-gallery-category">
                            <div className="category-list">
                                {(!items.videos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No videos that match this criteria were found') : null}
                                {items.videos.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('videos', i)} itemType="videos" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Travel']) ? null : (<div className="photo-gallery-category">
                            <div className="category-list">
                                {(!items.travel.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No travel listings that match this criteria were found') : null}
                                {items.travel.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('travel', i)} itemType="travel" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}

                        {/* {(!tabActive['Events']) ? null : (<div className="photo-gallery-category">
                            <div className="category-list">
                                {(!items.events.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No events that match this criteria were found') : null}
                                {items.events.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('events', i)} itemType="events" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}
{/* 
                        {(!tabActive['Groups']) ? null : (<div className="video-gallery-category">
                            <div className="category-list">
                                {(!items.groups.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No groups that match this criteria were found') : null}
                                {items.groups.map((v, i) => (<SpecialItem key={i} {...v} onClickOnFavorite={() => this.onUpdateList('groups', i)} itemType="groups" userLanguage={this.props.userLanguage} onClick={() => null} onlyFavoritesButton />))}
                            </div>
                        </div>)} */}


                    </div>)}

                </div>
            </div>

        );
    }
}

const mapStateToFavoritesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToFavoritesProps, null)(withRouter(Favorites));