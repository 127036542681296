import React, { Component } from 'react';
import LayoutSideBar from '../../shared/util/layout-sidebar';
import CouponsSidebar from '../../shared/components/SideBar/CouponsSideBar/CouponsSideBar';
import SearchBox from '../../shared/components/SideBar/Search/Search';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../shared/util/lang';
import actionType from '../../redux-store/action-type';

class SearchSidebar extends Component {
    state = {
        filterList: [
            { name: 'keywords', value: 'Keywords' },
            { name: 'city', value: 'The City' },
            { name: 'state', value: 'The State' },
            { name: 'zipCode', value: 'The Zip Code' },
            { name: 'country', value: 'The Country' },
        ],
    }

    onSubmitSearch = (s) => {
        let errorMessage = '';
        // if (!s.city && !s.zipCode && !s.state) errorMessage = 'Please fill in state, city or code.';
        if (!s.country) errorMessage = 'Please select your country.';
        this.setState({ errorMessage });
        if (errorMessage) return;
        this.props.onSubmitSearchQuery(JSON.stringify(s));
    }

    render () {
        return (
            <LayoutSideBar>
                <SearchBox SearchBoxTitle={getTranslatedPhrase(this.props.userLanguage, 'Advanced Search')}
                    section="search"
                    searchFilterList={this.state.filterList} 
                    errorMessage={this.state.errorMessage}
                    onSubmitSearch={this.onSubmitSearch} />

                <CouponsSidebar section="store" category="all" />
            </LayoutSideBar>
        );
    }
}


const mapStateToSearchSidebarProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        searchQuery: state.searchQuery,
    };
};

const mapDispatchTosearchSidebarProps = (
    dispatch
) => {
    return {
        onSubmitSearchQuery: (s) => {
            dispatch({type: actionType.VIEW_SEARCH_RESULTS, searchQuery: s});
        }
    };
};

export default connect(mapStateToSearchSidebarProps, mapDispatchTosearchSidebarProps)(SearchSidebar);