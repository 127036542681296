import React, {Component} from 'react';
import {hostNameAndPort} from '../../../shared/util/vars';
import { titleCase} from '../../../shared/util/string';
import { Link } from 'react-router-dom'; 
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';


class ServicesFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
        <div className="featured-item featured-store-listing">
            <Link className="featured-store-listing_image" to={'/services/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <img src={hostNameAndPort+v.featuredImage} alt={v.title} />    
            </Link>

            <Link className="featured-store-listing_details" to={'/services/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>

                <span className="featured-store-listing_category">{getTranslatedPhrase(v.userLanguage,v.category.replace(/-/g, ' ')).toUpperCase()}</span>
                <h3>{v.title}</h3>
                <span>{(v.state !== 'n/a')?titleCase(v.city)+', '+v.state:titleCase(v.city)+', '+titleCase(v.country)}</span>
                    
            </Link>
        </div>
        ); 
    }
}


const mapStateToServicesFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToServicesFeaturedPreviewProps, null)(ServicesFeaturedPreview);
