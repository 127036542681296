import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { hostNameAndPort } from '../../../../util/vars';

function PostComment(props) {
    const [commentIsLiked, setCommentIsLiked] = useState(0);
    const [commentLikeCount, setCommentLikeCount] = useState(0);
    const heartClasses = [ 'fal fa-heart', 'fas fa-heart'];
    const [commentIsDeleted, setCommentIsDeleted] = useState(false);

    useEffect(() => {
        let a = Number(props.PostCommentIsLiked);
        let b = props.PostCommentNoLikes;
        setCommentIsLiked(a || 0);
        setCommentLikeCount(b);
    },[]);

    const onClickLike = () => {
        if (!props.userIsAuthenticated) {
            return;
        }
        let a = Number(!commentIsLiked);
        let b = commentLikeCount;
        if (!a) {
            b = b - 1;
        } else {
            b = b + 1;
        }
        setCommentIsLiked(a)
        setCommentLikeCount(b);
        props.onLikeComment(!a);
    }

    const onRemoveComment = async () => {
        try { 
            let _data = new FormData();
            _data.append('itemCommentId', props.PostCommentId);

            let options = {
                    url: `${hostNameAndPort}/api/engage/removecomment/any`,
                    method: 'post',
                    data: _data
                };
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setCommentIsDeleted(true);
                props.onRemoveComment();
            }
        } catch(err) {            
            // Network error
        }
    }

    if (commentIsDeleted) {
        return null;
    }

    
    return (
        <div className="engage_the-comment">

            <div className="engage_the-comment_content">

                <div className="engage_the-comment_content_user">
                    {props.children}
                    <span>{props.PostCommentUserName}</span>
                    {props.PostCommentEditable && (
                    <button onClick={onRemoveComment}><i className="fal fa-trash-alt" /></button>)}
                </div> 

                <div className="engage_the-comment_content_the-comment">
                    {props.PostTheComment}
                </div>

                <div className="engage_the-comment_content_engage">
                    <div className="left">
                        {commentLikeCount}&nbsp; <button onClick={onClickLike}><i className={heartClasses[commentIsLiked]} /> </button>
                        <button onClick={props.onReplyToComment}><i className="fal fa-reply" /></button>
                    </div>
                    <div className="right">
                        {props.PostCommentDateStamp}
                    </div>
                    <div className="clb" />
                </div>

                <div className="engage_the-comment_content_arrow" />
            </div> 

            <div className="clb" />
        
        </div>
    );   
}

export default PostComment;