import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function VideosSideBar(props) {
  return (<SideBar {...props} 
    section="videos"
    searchTitle="Search Videos"
    createNewTitle="New Video"
    filterList={[
      { name:'keywords', value: 'Enter Keywords' }, 
    ]}
  />);
}

export default VideosSideBar;