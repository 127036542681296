import React from 'react';
import shortFormNumber from './ShortFormNumber';

const EngageButtonsRate = (props) => {
    let _noStars = Number(props.noStars) || 0;
    _noStars = Math.floor(_noStars);
    if ( (_noStars < 0) || (_noStars > 5) ) {
        _noStars = 0;
    }
    
    let a = Array(5).fill('fal fa-star');
    let j = 0;
    while (j < _noStars) {
        a[j++] = 'fas fa-star';
    }

    return (<span className="engage_buttons_rating">
        {a.map( (v,j) => (<i key={'engage-rating'+j} className={v} />) )}
        <span className="engage_buttons_rating_number">{!props.withoutNumber ? shortFormNumber(0 || props.noReviews) : null}</span>
        
    </span>);
}

export default EngageButtonsRate;