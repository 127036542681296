import React, { useEffect, useState } from 'react';

function LayoutSideBar(props) {
  let [sdBarClassName, setSdBarClassName] = useState('main-sidebar');
  let [buttonLabel, setButtonLabel] = useState(<i className="fal fa-bars" />);

  function toggleSideBar() {
    if (sdBarClassName === 'main-sidebar') {
      setSdBarClassName('main-sidebar main-sidebar-active');
      // setButtonLabel(<i className="fal fa-hand-point-right" />);
      setButtonLabel(<i className="fal fa-times" />);
    }
    else {
      setSdBarClassName('main-sidebar');
      // setButtonLabel(<i className="fal fa-hand-point-left" />);
      setButtonLabel(<i className="fal fa-bars" />);
    }
  }

  const { toggleSidebar } = props;

  useEffect(() => {
    if (sdBarClassName !== 'main-sidebar') {
      if (toggleSidebar) toggleSideBar();
    }
  }, [toggleSidebar]);

  return (<>
    <div className={sdBarClassName}>{props.children}</div>
    <button className="toggle-sidebar" onClick={toggleSideBar}>{buttonLabel}</button>
  </>);
}

export default LayoutSideBar;