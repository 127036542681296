import React, {Component} from 'react';
import FollowoneAppIcon from './icons/carverve-app-icon.png';
import AppstoreAppIcon from './icons/appstore-app-icon.png';
import PlaystoreAppIcon from './icons/playstore-app-icon.png';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

class footerAppDownload extends Component {
    render() {
        return (
        <div className="footer-app-download">
            <h4>{getTranslatedPhrase(this.props.userLanguage,'Get the App')}</h4>

            <div>
                {/* <img src={FollowoneAppIcon} alt="CarVerve App Icon" style={{borderRadius: '4px'}} /> */}
                <a href="https://apps.apple.com/us/app/carverve/id1617156389" target="_blank" rel="noopener noreferrer"><img src={AppstoreAppIcon} className="MiddleImage" alt="Apple Appstore Icon" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.brommko.android.carverve&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer"><img src={PlaystoreAppIcon} alt="Google Playstore Icon" /></a>
            </div>
        </div>
        ); 
    }
}

const mapStateToFooterAppDownloadProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToFooterAppDownloadProps, null)(footerAppDownload);