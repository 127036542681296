import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    ViberShareButton,
    // EmailShareButton,
  } from 'react-share';

import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import SettingsMenu from "../SettingsMenu";

class InviteFriends extends Component {
    state = {
        emails: '',
        success: false,
        errorMessage: '',
        user: {profileImage: '/assets/email/images/email-invite-friends-2.jpg'}
    }


    onChangeEmails = (e) => {
        if (e.target.value.length > 1000) {
            return;
        }
        this.setState({ emails: e.target.value });
        if (!!this.state.errorMessage) {
            this.setState({ errorMessage: '' });
        }
    }

    sendInviteHandler = async (e) => {
        let _data = new FormData();
        _data.append('emails', this.state.emails);

        let options = {
            url: `${hostNameAndPort}/api/mail/sendinvite`,
            method: 'post',
            data: _data, 
        } 
        try { let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({ emails: '', success: true, errorMessage: '' });
            }
        } catch(err) {
            console.log(err);
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage,'Network error ERRFEINVFRD01') });
        }
    }

    render () {

        let shareLink = 'https://www.followone.com/downloadapp';

        return (
          <SettingsMenu InviteLiActive="active">

              <div className="profile-update-settings">

                <ProfileSettingsBox>
                    <div className="invite-friends-options">
                            <h4>{getTranslatedPhrase(this.props.userLanguage, "INVITE FRIENDS USING YOUR SOCIAL MEDIA ACCOUNTS")}</h4>
                            <div className="ras-url">                             
                                <CopyToClipboard text={shareLink} onCopy={() => this.setState({copied: true})}>
                                    <button className="button button-regular button-copy"><i className="fal fa-copy" /> {this.state.copied ? <span>Copied!</span> : null}</button>
                                </CopyToClipboard>

                                <FacebookShareButton url={shareLink} >
                                    <button className="button button-regular button-copy"><i className="fab fa-facebook-f" /></button>
                                </FacebookShareButton>
                                <TwitterShareButton url={shareLink} >
                                    <button className="button button-regular button-copy"><i className="fab fa-twitter" /></button>
                                </TwitterShareButton>
                                <PinterestShareButton url={shareLink} media={this.state.user.profileImage}>
                                    <button className="button button-regular button-copy"><i className="fab fa-pinterest-p" /></button>
                                </PinterestShareButton>
                                <WhatsappShareButton url={shareLink}>
                                    <button className="button button-regular button-copy"><i className="fab fa-whatsapp" /></button>
                                </WhatsappShareButton>
                                <ViberShareButton url={shareLink}>
                                    <button className="button button-regular button-copy"><i className="fab fa-viber" /></button>
                                </ViberShareButton>
                        </div>




                    </div>
                </ProfileSettingsBox>

                  <ProfileSettingsBox>
                      <div className="invite-friends-options">
                          <h4>{getTranslatedPhrase(this.props.userLanguage,'INVITE FRIENDS VIA EMAIL')}</h4>

                          <label>{getTranslatedPhrase(this.props.userLanguage,'Enter email addresses sepparated by commas')}:</label>
                          <textarea className="form-input-textarea form-input-full mgt-sm" placeholder="email@one.com, email@two.com" value={this.state.emails}
                                    onChange={this.onChangeEmails} />

                          <FormFieldError>{this.state.errorMessage}</FormFieldError>
                          <button className="button button-regular mgt-md" onClick={this.sendInviteHandler}>{getTranslatedPhrase(this.props.userLanguage,'Send Invite')}</button>
                          <ErrorMessages
                            errorMessageClass={"error-message error-message-info"}
                            errorMessageTitle={getTranslatedPhrase(this.props.userLanguage,'Success')}
                            errorMessageBody={getTranslatedPhrase(this.props.userLanguage,'Your invitation has been sent')} show={this.state.success}/>
                      </div>
                  </ProfileSettingsBox>
              </div>

          </SettingsMenu>
        );
    }
}


const mapStateToInviteFriendsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToInviteFriendsProps, null)(InviteFriends);
