import React from 'react';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import Box from '../../../../shared/util/box';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import {Redirect} from 'react-router-dom';
import actionType from '../../../../redux-store/action-type';
import { Helmet } from "react-helmet";

class ForgotPassword extends React.Component {
    state = { 
        errorMessage: '',
        emailSent: false,
        email: '',
        expectedResult: Math.random() * 10,
        questionText: '1+1=',
        userAnswer: ''
    }

    componentDidMount() {
        this.genRandQuestion();
        // if (this.props.accountLoginModalActive) this.props.hideLoginModal();
    }

    componentDidUpdate() {
        this.genRandQuestion();
        // if (this.props.accountLoginModalActive) this.props.hideLoginModal();
    }

    onClickResetPasswordHandler = async () => {
        try {
            let em = this.state.email.toLowerCase();
            let re = /^[-_._a-z0-9]+@[a-z][a-z0-9.]*.[a-z]{2,10}/;            
            if ( !em || !re.test(em) ) {
                this.setState({ errorMessage: 'Please choose a different email address' });
                return;
            }
            if ( this.state.expectedResult !== Number(this.state.userAnswer) ) {
                this.setState({ errorMessage: 'Wrong answer' });
                return;
            }
            let _data = new FormData();
            _data.append('email', em);
            let options = {
                url: `${hostNameAndPort}/api/mail/sendresetpasslink`,
                method: 'post',
                data: _data
            };
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({ emailSent: true, errorMessage: '', email: '' });
            } else {
                this.setState({ errorMessage: data.error });
            }
        } catch (err) {
            this.setState({ errorMessage: err });
        }

    }
 
    onChangeSecretQuestion = (e) => {
        this.setState({ secretQuestion: e.target.value });
    }

    onChangeSecretAnswer = (e) => {
        this.setState({ secretAnswer: e.target.value });
    }

    onChangeEmail = (e) => {
        let a = e.target.value;
        if (a.length < 80) {
            this.setState({ email: a });
        }
    }

    genRandQuestion = () => {
        let op1, op2;
        op1 = Math.ceil( Math.random() * 10 );
        op2 = Math.ceil( Math.random() * 10 );
        let c = document.getElementById('myCanvasForgotPassword');
        if (!c) return;
        if (this.state.questionText !== '1+1=') return;
        let ctx = c.getContext('2d');
        ctx.font = '14px Arial';
        ctx.fillStyle = '#ffffff';
        ctx.fillText(op1 + '+' + op2 + '=', 10, 60);

        this.setState({ questionText: op1 + '+' + op2 + '=', expectedResult: op1 + op2 });
    }

    onChangeExpectedResult = (e) => {
        let a = e.target.value;
        if (a.length < 10) {
            this.setState({ userAnswer: a });
        }
    }

    render() {
        if (this.props.userIsAuthenticated) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="main-100 forgot-password">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Forgot Password | CarVerve</title>
                    <meta name="description" content="If you forgot your password CarVerve has you covered." />
                    <link rel="canonical" href="https://carverve.com/forgot-password" />
                </Helmet>

                <div className="home-box">

                    <div className="visitor-header">
                        <div className="visitor-header_box visitor-header_box_sign-in">
                            <h1>{getTranslatedPhrase(this.props.userLanguage,'Forgot Password')}</h1>
             
                            <p className="white-text dsp-in-blk mgt-md">{getTranslatedPhrase(this.props.userLanguage,'Write the email you use to log in, and we will send you a link to reset your password.')}</p>

                            {!this.state.emailSent? (<Box>

                            <input type="email" placeholder="your@email.com" className="form-input-text" value={this.state.email} onChange={this.onChangeEmail}/>

                            <canvas id="myCanvasForgotPassword" height="60" width="100" style={{border: "0px"}} />
                            <input type="number" className="form-input-text" value={this.state.userAnswer} onChange={this.onChangeExpectedResult}/>

                            <div className="visitor-header_box_footer">
                                <div><FormFieldError>{getTranslatedPhrase(this.props.userLanguage,this.state.errorMessage)}</FormFieldError></div>
                                <button className="button button-white mgt-md" onClick={this.onClickResetPasswordHandler} >{getTranslatedPhrase(this.props.userLanguage,'Submit')}</button>
                                <div>&nbsp;</div>
                            </div>

                            </Box>):(
                                <ErrorMessages errorMessageClass={"error-message error-message-info"} 
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage,'Success')} 
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage,'We sent an email with a link to reset your password. Didn\'t receive the email? Check your SPAM folder as well.')} show={true} />
                            )}
                        </div>
                    </div>

                </div>
            </div>);
    }
}

const mapStateToForgotPasswordProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated,
        accountLoginModalActive: state.accountLoginModalActive
    };
};

const mapDispatchToForgotPasswordProps = (
    dispatch
) => {
    return {
        hideLoginModal: () => {
            dispatch({type: actionType.LOGIN_MODAL, accountLoginModalActive: false});
        },
    }
};

export default connect(mapStateToForgotPasswordProps, mapDispatchToForgotPasswordProps)(ForgotPassword);