import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function AutosSideBarMobile(props) {

  let searchLabel = '';

  if (props.isProfilePage) {
    searchLabel = 'Enter Keywords'
  } else {
    searchLabel = 'Enter Keywords or Dealer Name'
  }

  return (<SideBarMobile {...props}
    section="autos"
    searchTitle="Search Auto"
    createNewTitle="New Auto Listing"
    filterList={[
      { name: 'keywords', value: searchLabel },
    ]}
  />);
}

export default AutosSideBarMobile;