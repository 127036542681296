import React, { Component } from 'react';
import moment from 'moment';
import { formatPriceAndCurrency, formatPrice } from '../../../../../shared/util/number';

class SponsoredPromoPreview extends Component {
    state = {
        showMore: false,
        showMoreLabel: { 'true': 'Hide Details', 'false': 'Show Details' },
        arrowIcon: { 'true': 'fal fa-angle-up', 'false': 'fal fa-angle-down' },
        runningButtons: { 'true': ' mp-button-active', 'false': '' },
        active: true
    }

    componentDidMount() {
        this.setState({ active: this.props.active });
    }

    toggleViewMore = () => {
        this.setState({ showMore: !this.state.showMore });
    }

    onClickRun = () => {
        this.setState({ active: true });
    }

    onClickStop = () => {
        this.setState({ active: false });
    }

    render() {
        return (
            <div className="mp-container-box mp-container-box-featured">
                <img src={(!this.props.campaignPhoto) ? `/uploads/default/default-product-image.jpg` : `${this.props.campaignPhoto}`} alt="Campaign Name" />

                <p className="mp-container-box-title">{this.props.title || 'Sponsored Campaign'} {(moment(this.props.endDate).isBefore(moment(), 'day')) ? (<span className="gppv-end-text">Campaign Ended</span>) : null}</p>
                <p className="mp-container-box-id">Type: Sponsored Campaign</p>
                <p className="mp-container-box-date">{moment(this.props.startDate).format('L')} - {moment(this.props.endDate).format('L')}</p>

                { this.state.showMore && (<div className="mp-container-box-audience">
                    <p className="mp-container-box-info-title">Details</p>

                    <table>
                        <tbody>
                            <tr>
                                <td>Country</td>
                                <td><span>USA</span></td>
                            </tr>

                            <tr>
                                <td>Price</td>
                                <td><span>{!this.props.budget ? '-' : formatPriceAndCurrency(this.props.budget * (this.props.pricePerView || 1), 'USD', ',')}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>)}


                <button onClick={this.toggleViewMore} className="mp-container-box-showmore">{this.state.showMoreLabel[this.state.showMore]}<i className={this.state.arrowIcon[this.state.showMore]} /></button>
                {/* When open text should be hide details and arrow should point up <i className="fal fa-angle-up"></i> */}


                <div className="mp-container-box-stats">
                    <p className="mp-container-box-info-title">Views Stats</p>

                    <table>
                        <tbody>
                            <tr>
                                <td>Paid</td>
                                <td>Organic</td>
                                <td>Total</td>
                            </tr>

                            <tr>
                                <td>{formatPrice(this.props.paidViews, ',')}</td>
                                <td>{formatPrice(this.props.organicViews, ',')}</td>
                                <td>{formatPrice(Number(this.props.paidViews || '0') + Number(this.props.organicViews || '0'), ',')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="mp-container-box-buttons">
                    {/* <button onClick={this.onClickRun} className={"mp-button" + this.state.runningButtons[!!this.state.active]}>Running</button>  */}
                    {/* when active change label to Running, when paused change to Start also add class active */}
                    {/* when paused add class mp-button-inactive and change label to pauased */}
                    {/* <button onClick={this.onClickStop} className={"mp-button" + this.state.runningButtons[!this.state.active]}>Stop</button> */}

                    {/* !!!! INFO: when campaign has ended make start, pause and end campaign buttons inactive */}
                </div>
            </div>
        );
    }
}

export default SponsoredPromoPreview;