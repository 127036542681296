import React, { useEffect, useState } from 'react';
import ProfileAbout from '../Pages/ProfileAbout';
import ProfileStore from '../Pages/ProfileStore';
import ProfileSocial from '../Pages/ProfileSocial';
import ProfileReviews from '../Pages/ProfileReviews';
import ProfileFavorites from '../Pages/ProfileFavorites';

const ProfileContent = (props) => {

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');

    useEffect(() => {
        setCategory(props.category);
    })

    useEffect(() => {
        switch (category) {
            case 'about':
                setTitle('About');
                break;
            case 'reviews':
                setTitle('Reviews');
                break;
            case 'favorites':
                setTitle('Favorites');
                break;
            case 'store':
                setTitle('Store');
                break;
            case 'autos':
                setTitle('Autos');
                break;
            case 'food':
                setTitle('Food');
                break;
            case 'realestate':
                setTitle('Properties');
                break;
            case 'jobs':
                setTitle('Jobs');
                break;
            case 'services':
                setTitle('Services');
                break;
            case 'travel':
                setTitle('Travel Packages');
                break;
            case 'blogs':
                setTitle('Blogs');
                break;
            case 'photos':
                setTitle('Photo Galleries');
                break;
            case 'videos':
                setTitle('Videos');
                break;
            case 'events':
                setTitle('Events');
                break;
            default:
                setTitle('');
        }
    }, [category])

    return (
        <>
            {/* <h2 className="profile-content-title">{props.category ? title : ''}</h2> */}

            {(category === 'about' || category === '') && <ProfileAbout
                userId={props.userId}
                businessAboutContent={props.businessAboutContent}
                userName={props.userName}
                isAccredited={props.isAccredited}
                // descriptionLabel={getTranslatedPhrase(this.props.userLanguage, 'Description')}
                descriptionLabel='Description'
            />}

            {category === 'reviews' && <ProfileReviews
                reviewsList={props.reviewsList}
                userIsAuthenticated={props.userIsAuthenticated}
                itemId={props.itemId}
                authorId={props.authorId}
                userId={props.userId}
            />}

            {category === 'favorites' && <ProfileFavorites updateTotalFavorites={props.updateTotalFavorites} totalCount={props.totalCount} slug={props.slug} />}

            {(category === 'store' || category === 'autos' || category === 'food' || category === 'realestate' || category === 'jobs' || category === 'services' || category === 'travel') &&
                <ProfileStore
                    category={category}
                    userId={props.userId}
                    dealerId={props.dealerId}
                    realestateAgency={props.realestateAgency}
                    slug={props.slug}
                />}

            {(category === 'blogs' || category === 'photos' || category === 'videos' || category === 'events') && <ProfileSocial
                category={category}
                userId={props.userId}
                dealerId={props.dealerId}
                realestateAgency={props.realestateAgency}
                slug={props.slug}
            />}
        </>
    )
}

export default ProfileContent;