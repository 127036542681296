import React, { useEffect, useState } from 'react';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PageNumbers from '../../../uielements/Pagination/page-numbers';
import SectionLoader from '../../../util/section-loader';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from "react-router-dom";
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase, getCountryFilter } from '../../../util/lang';
import SpecialItem from '../SpecialItem/SpecialItem';
import Helmet from 'react-helmet';


function Specials(props) {
  const userLanguage = useSelector(state => state.userLanguage);
  const userIsAuthenticated = useSelector(state => state.userIsAuthenticated);
  const searchQuery = useSelector(state => state.searchQuery);

  // const [filter, setFilter] = useState('')
  // const [value, setValue] = useState('')
  const [currentPageLocal, setCurrentPage] = useState(1)
  const [loaded, setLoaded] = useState(true)
  // const [pageRefresh, setPageRefresh] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [items, setItems] = useState([])
  const [Loader, setLoader] = useState(null)
  const itemsPerPage = 18
  const history = useHistory()

  let formParams1 = {};
  let random = Math.random();

  useEffect(() => {
    // Trigger the update
    setCurrentPage(0);
    if (!userIsAuthenticated) props.onShowLoginModal();
  }, [userIsAuthenticated]);


  // Get the current page
  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = Number(urlParams.get('page')) || 1;

  useEffect(() => {
    let formParams = {};
    if (currentPage !== currentPageLocal) {
      setCurrentPage(currentPage);
      setLoaded(false);
      let f = {
        country: getCountryFilter(searchQuery)
      };
      formParams = {
        filter: JSON.stringify(f),
        geolocation: JSON.stringify(props.currentGeolocation)
      };
      if (!Loader) {

        // console.log('stuff', currentPage, JSON.stringify(formParams));

        formParams1 = formParams;

        initLoader(currentPage, formParams);
        return;
      }
      Loader.updatePage(currentPage);
    }
  }, [currentPage, random]);

  function initLoader(page, formParams) {

    let section = 'campaigns/specials';

    if (!props.userIsAffiliate) section = 'campaigns/specials';



    setLoader(new SectionLoader({
      section,
      cb: onLoad,
      options: {
        page,
        pageCount: itemsPerPage,
        formParams
      }
    })
    );
  }

  function onLoad(err) {
    if (err && (process.env.NODE_ENV === 'development')) console.log(err);
    window.scrollTo(0, 0);
    setLoaded(true)
    // setTotalCount(Loader.totalCount);
    // setItems(Loader.items);
  }

  useEffect(() => {
    if (Loader) {
      setTotalCount(Loader.totalCount);
      setItems(Loader.items);
    }
  }, [loaded])

  function onUpdatePageNumber(n = 1) {
    history.push(props.match.url + '?page=' + n);
  }

  function handleClick(v) {
    props.addPayperviewRedirect(v.campaignId);
    // history.push(`/${v.section}/${v.category}/${v.subcategory || 'other'}/${v.slug}`);
  }




  if (!userIsAuthenticated)
    return (<>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Specials | Followone</title>
        <meta name="description" content="Gathered from all over the place just for you!" />
        <link rel="canonical" href="https://followone.com/specials" />
      </Helmet>
      <div className="appv">
        <div className="main-100-header">
          <h1>Specials</h1>
          <p>{getTranslatedPhrase(userLanguage, 'Gathered from all over the place just for you!')}</p>
        </div>

        <div className="category-title">
          <h2 className="capitalize">{getTranslatedPhrase(userLanguage, 'Specials will appear after you log in.')}</h2>
        </div>

      </div></>);
  else if (!props.userIsAffiliate) return (<>

    <Helmet>
      <meta charSet="utf-8" />
      <title>Specials | Followone</title>
      <meta name="description" content="Gathered from all over the place just for you!" />
      <link rel="canonical" href="https://followone.com/specials" />
    </Helmet>

    <div className="appv">

      <div className="main-100-header">
        <h1>Specials</h1>
        <p>{getTranslatedPhrase(userLanguage, 'Gathered from all over the place just for you!')}</p>
      </div>

      <div className="category-title">
        <h2 className="capitalize">{!items.length && loaded ? getTranslatedPhrase(userLanguage, 'There are no specials.') : null}</h2>
      </div>

      <div className="paid-notifications-list">
        <LoadingSvg active={!loaded} />
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} gutter="10px" >
          <Masonry>
            {items.map((v, i) => (<SpecialItem key={i} {...v} userLanguage={userLanguage} onClick={() => handleClick(v)} />))}

          </Masonry>
        </ResponsiveMasonry>

        <PageNumbers
          count={Math.ceil(totalCount / itemsPerPage)}
          activePage={currentPage}
          onClick={onUpdatePageNumber} />

      </div>

    </div></>
  );
  else return (<>

    <Helmet>
      <meta charSet="utf-8" />
      <title>Specials | Followone</title>
      <meta name="description" content="Gathered from all over the place just for you!" />
      <link rel="canonical" href="https://followone.com/specials" />
    </Helmet>

    <div className="notifications-page_content_box">

      {props.children}

      {!items.length && loaded ? (
        <div className="notifications-page_content_box_header">
          <h4>{getTranslatedPhrase(userLanguage, 'There are no cash-in ads.')}</h4>
          <Link className="button button-clear button-small" to="/cash-in">Dashboard</Link>
        </div>) : null}

      {items.length !== 0 ? (
        <div className="paid-notifications-list">
          <LoadingSvg active={!loaded} />
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} gutter="10px" >
            <Masonry>
              {items.map((v, i) => (<SpecialItem key={i} {...v} userLanguage={userLanguage} onClick={() => handleClick(v)} />))}

            </Masonry>
          </ResponsiveMasonry>

          <div className="paid-notifications-pagination">
            <PageNumbers
              count={Math.ceil(totalCount / itemsPerPage)}
              activePage={currentPage}
              onClick={onUpdatePageNumber} />
          </div>

        </div>) : (loaded) ? null : (<div className="paid-notifications-list">
          <LoadingSvg active={!loaded} /></div>)}

    </div></>);
}


const mapDispatchToSpecialsProps = (
  dispatch
) => {
  return {
    addPayperviewRedirect: (c) => dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: c }),
    onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname })
  };
};

export default connect(null, mapDispatchToSpecialsProps)(withRouter(Specials));