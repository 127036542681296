import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import Modal from '../../../uielements/Modal/Modal';
import { displayPriceValue } from '../../../util/number';
import { populateCountries, populateStates } from "../../../util/countries";
import { populateMakes, populateModels } from '../../../util/car-models';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';



const AdvAuto = props => {
    const userLanguage = useSelector(state => state.userLanguage);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [productCondition, setProductCondition] = useState('');
    const [seller, setSeller] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [carMake, setCarMake] = useState('');
    const [carModel, setCarModel] = useState('');
    const [yearMin, setYearMin] = useState('');
    const [yearMax, setYearMax] = useState('');
    const [address, setAddress] = useState('');
    const [keywords, setKeywords] = useState('');
    const [fuelType, setFuelType] = useState('');
    const [withCoupons, setWithCoupons] = useState(false);
    const [exteriorColor, setExteriorColor] = useState('');
    const [makeElementId, setMakeElementId] = useState('');
    const [modelElementId, setModelElementId] = useState('');
    const [makesLoaded, setMakesLoaded] = useState(false);
    const [priceRange, setPriceRange] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const searchQuery = useSelector(state => state.searchQuery);

    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const [stateVar, setStateVar] = useState('');
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [countryElementId, setCountryElementId] = useState('');
    const [stateElementId, setStateElementId] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const { section } = props;
    const windowLocationSearch = window.location.search;

    useEffect(() => {
        setCategoryList(props.categoryList);
    }, [section]);

    const b1 = document.getElementById("stateElementForAdvAutos");
    const b2 = document.getElementById("countryElementForAdvAutos");
    const b2Len = (b2 && b2.options.length > 1) ? b2.options.length : 0;
    const b1Len = (b1 && b1.options.length) ? b1.options.length : 0;
    useEffect(() => {
        if (
            countryElementId
            && stateElementId
            && b1
            && b1Len
            && b2
            && b2Len
        ) {
            let cc = b2;
            let i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === country) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
            populateStates(countryElementId, stateElementId);
            cc = document.getElementById("stateElementForAdvAutos");
            i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === stateVar) break;

            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
        }
    }, [b1, b2, b2Len, b1Len]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';
        setCategory(cat);
        setSubcategory(subcat);

        const ts = {
            category,
            subcategory,
            priceMin,
            priceMax,
            priceRange,
            productCondition,
            seller,
            carMake,
            carModel,
            yearMin,
            yearMax,
            fuelType,
            exteriorColor,
            address,
            country,
            stateVar,
            city,
            zipCode,
            keywords
        };

        const ns = {
            category: urlParams.get('category') || '',
            subcategory: urlParams.get('subcategory') || '',
            priceMin: urlParams.get('priceMin') || '',
            priceMax: urlParams.get('priceMax') || '',
            priceRange: urlParams.get('priceRange') || false,
            productCondition: urlParams.get('condition') || '',
            seller: urlParams.get('seller') || '',
            fuelType: urlParams.get('fuelType') || '',
            carMake: urlParams.get('carMake') || '',
            carModel: urlParams.get('carModel') || '',
            yearMin: urlParams.get('yearMin') || '',
            yearMax: urlParams.get('yearMax') || '',
            exteriorColor: urlParams.get('exteriorColor') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || '',
            keywords: urlParams.get('keywords') || ''
        };

        if (ns.keywords.startsWith('filters-')) {
            let s1 = ns.keywords.substring(0, ns.keywords.indexOf('-') + 1);
            ns.keywords = ns.keywords.replace(s1, '');
            let startSubstring = ns.keywords.lastIndexOf('-');
            if (startSubstring < 0) startSubstring = 0;
            let s2 = ns.keywords.substring(startSubstring, ns.keywords.length);
            ns.keywords = ns.keywords.replace(s2, '');
        }

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        let changeState = false;
        if (ns.priceMin !== ts.priceMin || ns.priceMax !== ts.priceMax || ns.seller !== ts.seller || ns.carMake !== ts.carMake || ns.carModel !== ts.carModel ||
            ns.yearMin !== ts.yearMin || ns.yearMax !== ts.yearMax || ns.productCondition !== ts.productCondition || ns.exteriorColor !== ts.exteriorColor ||
            ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode || ns.keywords !== ts.keywords || ns.fuelType !== ts.fuelType)
            changeState = true;


        if (changeState) {
            for (let i of Object.keys(ns)) {
                if (i !== 'address' && i !== 'stateVar' && i !== 'city' && i !== 'zipCode') onChangeItem(i, ns[i]);
            }
            if (!ns.address) {
                onChangeItem('stateVar', ns.stateVar);
                onChangeItem('city', ns.city);
                onChangeItem('zipCode', ns.zipCode);
                onChangeItem('address', ns.address);
            } else {
                let newCity = '', newState = '', newZipCode = '';
                let a = ns.address.split(',');
                if (a.length < 2) {
                    if (/[-A-Za-z0-9]*/i.test(a)) newZipCode = a[0].trim();
                    else newCity = a[0].trim();
                } else {
                    newCity = a[0].trim();
                    newState = a[1].trim();
                }
                onChangeItem('stateVar', newState);
                onChangeItem('city', newCity);
                onChangeItem('zipCode', newZipCode);
                onChangeItem('address', ns.address);
            }
        }
    }, [windowLocationSearch]);

    function onChangeItem(_prop, _value) {
        let a = {
            category: setCategory,
            subcategory: setSubcategory,
            productCondition: setProductCondition,
            seller: setSeller,
            priceMin: setPriceMin,
            priceMax: setPriceMax,
            priceRange: setPriceRange,
            carMake: setCarMake,
            carModel: setCarModel,
            fuelType: setFuelType,
            yearMin: setYearMin,
            yearMax: setYearMax,
            withCoupons: setWithCoupons,
            exteriorColor: setExteriorColor,
            address: setAddress,
            country: setCountry,
            stateVar: setStateVar,
            city: setCity,
            zipCode: setZipCode,
            keywords: setKeywords
        };
        if (_prop === 'category') {
            setSubcategory('');
            onChangeCategory(_value);
        }
        if (_prop === 'productCondition' && productCondition === _value) _value = '';
        if (_prop === 'seller' && seller === _value) _value = '';
        if (_prop === 'priceMin' || _prop === 'priceMax') _value = _value.replace(/,/g, '').replace('.', '');
        if (_prop === 'country') {
            setStateVar('');
            setCity('');
            setZipCode('');
            setAddress(city);
        }
        if (_prop === 'city') {
            if (zipCode !== '') setZipCode('');
            setAddress(_value + ', ' + stateVar);
        }
        if (_prop === 'stateVar') {
            setAddress(city + ', ' + _value);
            setCity('');
            setZipCode('');
        }
        if (_prop === 'carMake') setCarModel('');

        if (a[_prop]) a[_prop](_value);
        if (!priceMin && !priceMax) setPriceRange('');
        else setPriceRange('1');

    }

    function onChangeCategory(c) {
        if (c === 'none' || !c) {
            setSubcategoryList([]);
            return;
        }
        let i = 0;
        let s = [];
        while (i < props.categoryList.length && props.categoryList[i] !== c) i++;
        for (i++; i < props.categoryList.length && /^\s*-+/.test(props.categoryList[i]); i++) s.push(props.categoryList[i].replace(/^\s*-+\s*/, ''));
        setSubcategoryList(s);
    }

    function onSubmitSearch(e) {
        e.preventDefault();
        let priceRange = false;
        if (Number(priceMin) || Number(priceMax)) priceRange = true;
        let f;
        if (!keywords) f = "filters-" + Date.now();
        else f = "filters-" + keywords + '-' + Date.now();

        // let s_q = JSON.parse(searchQuery || '{}');
        let state = stateVar;
        // if (!state && country === s_q.country) state = s_q.region;
        if (!state && city) {
            setErrorMessage('Please select the state!');
            return;
        } else setErrorMessage('');

        let s = `/autos/search/${f}?priceMin=${priceMin}&priceMax=${priceMax}&priceRange=${priceRange}&withCoupons=${withCoupons}&exteriorColor=${exteriorColor}&carMake=${carMake}&carModel=${carModel}&seller=${seller}&fuelType=${fuelType}
&condition=${productCondition}&yearMin=${yearMin}&yearMax=${yearMax}&address=${address}&country=${country}&state=${state}&city=${city}&zipCode=${zipCode}&category=${category.replace(/&/g, '%26')}&subcategory=${subcategory.replace(/&/g, '%26')}&keywords=${f}`;
        props.onSubmitHandler(e, s);
        props.closeModal();
    }

    // console.log('render',country,stateVar,countryElementId,stateElementId,b2,b1)
    return (
        <Modal
            title="Cars Advanced Search"
            closeModal={props.closeModal}
            modalClass="medium"
            modalBodyClass="adv-body"
        >
            <div className="adv-search">
                <div className="adv-search-content">
                    <div className="adv-search-content-box">

                        <p><strong>KEYWORDS</strong></p>
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Keywords or Dealer Name" value={keywords} onChange={e => onChangeItem('keywords', e.target.value)} />

                        <p><strong>MAKE / MODEL</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="makeElementForAutoSearch"
                                ref={n => {
                                    if (!makeElementId) setMakeElementId("makeElementForAutoSearch");
                                    if (makeElementId && modelElementId && !makesLoaded) {
                                        populateMakes(makeElementId, modelElementId);
                                        setMakesLoaded(true);
                                    }
                                }}
                                value={carMake}
                                onChange={e => onChangeItem("carMake", e.target.value)}
                            />
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="modelElementForAutoSearch"
                                ref={n => {
                                    if (!modelElementId) setModelElementId("modelElementForAutoSearch");
                                    if (makeElementId && modelElementId && !makesLoaded) {
                                        populateModels(makeElementId, modelElementId);
                                        setMakesLoaded(true);
                                    }
                                }}
                                value={carModel}
                                onChange={e => onChangeItem('carModel', e.target.value)}>
                            </select>
                        </div>

                        <p><strong>TYPE</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={category} onChange={e => onChangeItem('category', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Category')}</option>
                                {categoryList.filter(v => (!/^\s*-+/.test(v))).map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={subcategory} onChange={e => onChangeItem('subcategory', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Subcategory')}</option>
                                {subcategoryList.map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>
                        <p><strong>CONDITION</strong></p>
                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="newused" id="new" value="new" checked={productCondition === "New"} onChange={() => null} />
                            <label htmlFor="new" onClick={e => onChangeItem('productCondition', 'New')}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'New')}</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="newused" id="used" value="used" checked={productCondition === "Used"} onChange={() => null} />
                            <label htmlFor="used" onClick={e => onChangeItem('productCondition', 'Used')}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Used')}</label>
                        </div>


                    </div>

                    <div className="adv-search-content-box">

                        <p><strong>SOLD BY</strong></p>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="seller" id="dealer" value="dealer" checked={seller === "dealer"} onChange={() => null} />
                            <label htmlFor="dealer" onClick={e => onChangeItem('seller', 'dealer')}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Dealer')}</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="seller" id="owner" value="owner" checked={seller === "owner"} onChange={() => null} />
                            <label htmlFor="owner" onClick={e => onChangeItem('seller', 'owner')}><i className="fal fa-check" />{getTranslatedPhrase(userLanguage, 'Owner')}</label>
                        </div>

                        <p className="mgt-md"><strong>PRICING</strong></p>

                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min $" value={displayPriceValue(priceMin)} onChange={e => onChangeItem('priceMin', e.target.value)} />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max $" value={displayPriceValue(priceMax)} onChange={e => onChangeItem('priceMax', e.target.value)} />
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="discount" id="discount" value="discount" checked={withCoupons} onChange={() => null} />
                            <label htmlFor="discount" onClick={e => onChangeItem('withCoupons', !withCoupons)}><i className="fal fa-check" />Deals</label>
                        </div>
                        <p className="mgt-md"><strong>FUEL TYPE</strong></p>
                        <div className="form-input-select" style={{ marginTop: '10px' }}>
                            <select className="form-input-full mgb-sm" value={fuelType} onChange={e => onChangeItem('fuelType', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Fuel Type')}</option>
                                <option value="Biodiesel">Biodiesel</option>
                                <option value="Compressed Natural Gas">Compressed Natural Gas</option>
                                <option value="Diesel">Diesel</option>
                                <option value="E85">E85</option>
                                <option value="Electric">Electric</option>
                                <option value="Hydrogen">Hydrogen</option>
                                <option value="Lpg">Lpg</option>
                                <option value="Natural Gas">Natural Gas</option>
                                <option value="Premium Unleaded">Premium Unleaded</option>
                                <option value="Unleaded">Unleaded</option>
                            </select>
                        </div>



                        {/* <p className="input-label mgt-md">Sort by Price:</p>
                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="sortprice" id="lowtohigh" value="lowtohigh" checked={priceAscending === 1} onChange={() => null}/>
                            <label  htmlFor="lowtohigh" onClick={e => onChangeItem('priceAscending', 1)}><i className="fal fa-check" />Low to High</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="sortprice" id="hightolow" value="hightolow" checked={priceAscending === 2} onChange={() => null}/>
                            <label htmlFor="hightolow" onClick={e => onChangeItem('priceAscending', 2)}><i className="fal fa-check" />High to Low</label>
                        </div> */}

                        <p className="input-label mgt-md"><strong>YEAR</strong></p>
                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Year Min." value={yearMin} onChange={e => onChangeItem('yearMin', e.target.value)} />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Year Max." value={yearMax} onChange={e => onChangeItem('yearMax', e.target.value)} />
                        </div>



                        {/* <p className="input-label mgt-md">Sort by Most Views:</p>
                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="mostviews" id="mostviews" value="mostviews" />
                            <label htmlFor="mostviews" onClick={e => onChangeItem('viewsDescending', !viewsDescending)}><i className="fal fa-check" />Most Views</label>
                        </div>

                        <p className="input-label mgt-md">Rating:</p>
                        <div className="engage_buttons mgt-sm">
                            <span className="engage_buttons_rating">
                                {ratingClass.map((v, j) => (<button key={'rating-a-' + j}
                                        onClick={(e) => onChangeRatingHandler(e, j + 1)}>
                                        <i className={v}></i>
                                    </button>)
                                    )}
                            </span>
                            <div style={{fontSize: "1.5rem"}}>&amp; Up</div>
                        </div> */}
                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>CAR OPTIONS</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={exteriorColor} onChange={(e) => onChangeItem('exteriorColor', e.target.value)}>
                                <option value="" disabled="">Select Exterior Color</option>
                                <option key={'item-au1'} value="Beige">{getTranslatedPhrase(userLanguage, 'Beige')}</option>
                                <option key={'item-au2'} value="Black">{getTranslatedPhrase(userLanguage, 'Black')}</option>
                                <option key={'item-au3'} value="Blue">{getTranslatedPhrase(userLanguage, 'Blue')}</option>
                                <option key={'item-au4'} value="Brown">{getTranslatedPhrase(userLanguage, 'Brown')}</option>
                                <option key={'item-au5'} value="Gold">{getTranslatedPhrase(userLanguage, 'Gold')}</option>
                                <option key={'item-au6'} value="Green">{getTranslatedPhrase(userLanguage, 'Green')}</option>
                                <option key={'item-au7'} value="Gray">{getTranslatedPhrase(userLanguage, 'Gray')}</option>
                                <option key={'item-au8'} value="Orange">{getTranslatedPhrase(userLanguage, 'Orange')}</option>
                                <option key={'item-au9'} value="Red">{getTranslatedPhrase(userLanguage, 'Red')}</option>
                                <option key={'item-au10'} value="Pink">{getTranslatedPhrase(userLanguage, 'Pink')}</option>
                                <option key={'item-au11'} value="Purple">{getTranslatedPhrase(userLanguage, 'Purple')}</option>
                                <option key={'item-au12'} value="Silver">{getTranslatedPhrase(userLanguage, 'Silver')}</option>
                                <option key={'item-au13'} value="White">{getTranslatedPhrase(userLanguage, 'White')}</option>
                                <option key={'item-au14'} value="Yellow">{getTranslatedPhrase(userLanguage, 'Yellow')}</option>
                            </select>
                        </div>

                        <p className="mgt-md"><strong>LOCATION</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="countryElementForAdvAutos"
                                ref={n => {
                                    if (!countryElementId) setCountryElementId("countryElementForAdvAutos");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={country}
                                onChange={e => onChangeItem('country', e.target.value)}>
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="stateElementForAdvAutos"
                                ref={n => {
                                    if (!stateElementId) setStateElementId("stateElementForAdvAutos");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={stateVar}
                                onChange={e => onChangeItem('stateVar', e.target.value)}>
                                <option value="">Select State *</option>
                            </select>
                        </div>

                        {/*<input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Zip/Postal Code" value={zipCode} onChange={e => onChangeItem('zipCode', e.target.value)}/>*/}
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="City" value={city} onChange={e => onChangeItem('city', e.target.value)} />
                    </div>
                </div>

                <div><FormFieldError> {errorMessage} </FormFieldError></div>

                <div className="adv-search-buttons">

                    <button className="button button-regular" onClick={onSubmitSearch}>Search</button>
                    <button className="button button-white" onClick={props.closeModal}>Cancel Search</button>
                </div>
            </div>
        </Modal >
    )
}

export default connect(null, null)(AdvAuto);
