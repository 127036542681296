import Axios from 'axios';
import { isEqual } from './compare.js';
import { hostNameAndPort } from './vars.js';

const COUNT_MAX = 40;

/**
 * Generic class for loading a section page
 */
class SectionLoader {
  constructor({
    section = '',
    cb = null,
    options = {
      page: 1,
      pageCount: 20,
      noItems: false,
      noCount: false,
      formParams: {}
    }
  }) {
    if (!section) throw Error('SectionLoader: section cannot be an empty string.');
    this.$section = section;
    if (!options.page) options.page = 1;
    if (options.page < 1) throw Error('SectionLoader: options.page cannot be smaller than 1.');
    this.$page = options.page;
    if (!options.pageCount) options.pageCount = 20;
    if (options.pageCount > COUNT_MAX) throw Error(`SectionLoader: options.pageCount cannot be greater than ${COUNT_MAX}.`);
    this.$pageCount = options.pageCount;
    this.formParams = options.formParams;
    this.$items = [];
    this.$totalCount = 0;
    this.cb = cb;
    this.counted = false;
    this.loaded = false;
    this.$noCount = options.noCount || false;
    this.$noItems = options.noItems || false;

    let sendData = this.$noCount || this.$noItems;
    if (!options.noCount) this.getCount(); else this.counted = true;
    if (!options.noItems) this.getItems(); else this.loaded = true;
    if (sendData && this.cb) this.cb();
  }

  get totalCount() {
    return this.$totalCount;
  }

  get items() {
    return this.$items;
  }

  updatePage(value = 1, force = false) {
    if (value < 1) throw Error('SectionLoader: page number cannot be smaller than 1.');
    if (value === this.$page && !force) {
      if (this.cb) this.cb();
      return;
    }
    // DOESN'T WORK ON AUTOS PAGE
    // if ((value-1) * this.$pageCount > this.$totalCount)
    //   throw Error(`SectionLoader: page number cannot be greater than ${Math.ceil(this.$totalCount / this.$pageCount)+1}.`);
    this.$page = value;
    this.$items = [];
    this.counted = true;
    this.getItems();
  }

  updateSection(section = '', force = false) {
    if (!section) throw Error('SectionLoader: section cannot be an empty string.');
    if (section === this.$section && !force) {
      if (this.cb) this.cb();
      return;
    }
    this.$page = 1;
    this.$section = section;
    this.$items = [];
    this.$totalCount = 0;
    this.getCount();
    this.getItems();
  }

  async updateFormParams(formParams = {}, force = false) {
    if (isEqual(formParams, this.formParams) && !force) {
      if (this.cb) this.cb();
      return;
    }
    this.$page = formParams.page || 1;
    this.formParams = formParams;
    this.$items = [];
    this.$totalCount = 0;
    this.$noCount = formParams.noCount || false;
    this.$noItems = formParams.noItems || false;

    let sendData = this.$noCount || this.$noItems;
    if (!this.$noCount) await this.getCount(); else this.counted = true;
    if (!this.$noItems) await this.getItems(); else this.loaded = true;
    if (sendData && this.cb) this.cb();
  }

  get pageCount() {
    return this.$pageCount;
  }

  set pageCount(value = 1) {
    if (value > COUNT_MAX) throw Error(`SectionLoader: pageCount cannot be greater than ${COUNT_MAX}.`);
    if (value < 1) value = 0; // throw Error('SectionLoader: pageCount cannot be smaller than 1');
    this.$pageCount = value;
  }

  async getCount() {
    try {
      let result = await this.$getSection('count');
      this.$totalCount = result;
      if (this.cb && this.loaded) {
        this.counted = false;
        this.loaded = false;
        this.cb();
      } else {
        this.counted = true;
      }
      return result;
    } catch (e) {
      if (this.cb) this.cb(e);
    }
  }

  async getItems() {
    try {
      let result = await this.$getSection('items');
      // this.$items = result;
      // console.log('this.$items', this.$items)
      if (this.counted && !this.$totalCount && this.$section !== 'cars' && this.$section !== 'realestate') {
        if (Array.isArray(this.$items) && this.$items.length) this.counted = false;
        if (typeof this.$items === 'object') {
          let a = Object.keys(this.$items);
          for (let i in a)
            if (a[i].length) {
              this.counted = false;
              break;
            }
        }
      }
      if (this.cb && (this.$section === 'cars' || this.$section === 'realestate')) {
        // console.log('first cb')
        this.cb();// null, this.$items || [], this.$totalCount || 0);
        // console.log('after first cb')
      } else if (this.cb && this.counted) {
        this.loaded = false;
        this.counted = false;
        this.cb();// null, this.$items || [], this.$totalCount || 0);
      } else {
        this.loaded = true;
      }
      return result;
    } catch (e) {
      if (this.cb) this.cb(e);
    }
  }

  $getSection(t) {
    if (!t) throw Error('SectionLoader: you must choose between count or items.');
    return new Promise((resolve, reject) => {
      if (!this.$pageCount)
        if (t === 'items') resolve([]);
        else resolve(0);
      let _data = new FormData();
      if (t === 'items') {

        // console.log('page start:', (this.$page - 1) * 20, this.$pageCount);

        // _data.append('start', (this.$page - 1) * this.$pageCount);
        _data.append('start', (this.$page - 1) * 20);
        _data.append('count', this.$pageCount);
      }
      for (let i in this.formParams) {
        // console.log(i, this.formParams[i])
        _data.append(i, this.formParams[i]);
      }



      let url = hostNameAndPort + '/api/' + this.$section + '/' + t;
      let opts = {
        method: 'post',
        url,
        data: _data
      };

      Axios(opts)
        .then(result => {
          let { data } = result;
          // console.log('section', this.$section, data)
          if (!data.error) {
            if (t === 'items') this.$items = data[t];
            else this.$totalCount = data[t];
            resolve(data[t]);
          } else reject(data.error);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default SectionLoader;