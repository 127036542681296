import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function AutosSideBar(props) {

  let searchLabel = '';

  if (props.isProfilePage) {
    searchLabel = 'Enter Keywords'
  } else {
    searchLabel = 'Enter Keywords or Dealer Name'
  }

  return (<SideBar {...props}
    section="autos"
    searchTitle="Search"
    createNewTitle="New Auto Listing"
    filterList={[
      { name: 'keywords', value: searchLabel },
    ]}
  />);
}

export default AutosSideBar;