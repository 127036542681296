import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import { Link } from 'react-router-dom';
import { getTranslatedPhrase } from '../../../util/lang';

class alertModal extends Component {
    render() {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="modal-wrapper" style={{zIndex: '99999'}}>
                <Backdrop show={this.props.active} clicked={this.props.onHideAlertModal} />
                <div className="modal">
                    <div className="modal_body">
                        <h4 className="mgt-sm">{getTranslatedPhrase(this.props.userLanguage, 'MESSAGE')}</h4>

                        <div className="modal_body_functions">
                            <p>{this.props.modalMessage}</p>
                            <br />
                            {(!this.props.alertModalRedirect) ? (<button
                                className="button button-regular mgt-sm dsp-in-blk"
                                onClick={this.props.onHideAlertModal}>{this.props.alertModalButtonLabel || 'OK'}
                            </button>) : (
                                    <Link to={this.props.alertModalRedirect}
                                        className="button button-regular mgt-sm dsp-in-blk"
                                        onClick={this.props.onHideAlertModal}>{this.props.alertModalButtonLabel || 'OK'}</Link>
                                )}
                            &nbsp;&nbsp;&nbsp;

                        </div>
                    </div>

                    <span onClick={this.props.onHideAlertModal} className="modal_close"><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateToAlertModalProps = (
    state,
    props
) => {
    return {
        active: state.alertModalActive,
        modalMessage: state.alertModalMessage,
        alertModalButtonLabel: state.alertModalButtonLabel,
        alertModalRedirect: state.alertModalRedirect,
        userLanguage: state.userLanguage
    };
};

const mapDispatchToAlertModalProps = (
    dispatch
) => {
    return {
        onHideAlertModal: () => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: false, alertModalMessage: '', alertModalRedirect: '', alerModalButtonLabel: '' });
        },
    };
};

export default connect(mapStateToAlertModalProps, mapDispatchToAlertModalProps)(alertModal);