var makesArray = ["--- Popular Makes ---",
"Acura",
"Alfa Romeo",
"Aston Martin",
"Audi",
"Bentley",
"BMW",
"Bugatti",
"Buick",
"Cadillac",
"Chevrolet",
"Chrysler",
"Dodge",
"Ferrari",
"Fiat",
"Ford",
"Genesis",
"GMC",
"Honda",
"Hummer",
"Hyundai",
"Infiniti",
"Isuzu",
"Jaguar",
"Jeep",
"Kia",
"Lamborghini",
"Land Rover",
"Lexus",
"Lincoln",
"Maserati",
"Maybach",
"Mazda",
"Mclaren",
"Mercedes-Benz",
"Mini",
"Mitsubishi",
"Nissan",
"Porsche",
"Rolls Royce",
"Smart",
"Subaru",
"Suzuki",
"Tesla",
"Toyota",
"Volkswagen",
"Volvo",
"Citroen",
"Dacia",
"Opel",
"Peugeot",
"Renault",
"Seat",
"Skoda",
"--- All Makes ---",
"Abarth",
"Ac",
"Acura",
"Adler",
"Alfa Romeo",
"Allard",
"Alpina",
"Alpine",
"Alvis",
"Am General",
"AMC",
"Apal",
"Ariel",
"Armstrong Siddeley",
"Aro",
"Ascari",
"Asia",
"Aston Martin",
"Audi",
"Aurus",
"Austin Healey",
"Autobianchi",
"Autocam",
"Auverland",
"Avanti",
"Baic",
"Bajaj",
"Baltijas Dzips",
"Batmobile",
"Beijing",
"Bentley",
"Berkeley",
"Bertone",
"Bilenkin",
"Bitter",
"Bizzarrini",
"BMW",
"Borgward",
"Brabus",
"Brilliance",
"Bristol",
"Bufori",
"Bugatti",
"Buick",
"Byd",
"Byvin",
"Cadillac",
"Callaway",
"Carbodies",
"Caterham",
"Chana",
"Changan",
"Changfeng",
"Changhe",
"Checker",
"Chery",
"Chevrolet",
"Chrysler",
"Citroen",
"Cizeta",
"Coggiola",
"Combat",
"Cord",
"Dacia",
"Dadi",
"Daewoo",
"Daf",
"Daihatsu",
"Daimler",
"Dallara",
"Dallas",
"Datsun",
"De Tomaso",
"Delage",
"Delorean",
"Derways",
"Desoto",
"Dkw",
"Dodge",
"Dongfeng",
"Doninvest",
"Donkervoort",
"Ds",
"Dw Hower",
"E Car",
"Eagle",
"Efini",
"Excalibur",
"Fairthorpe",
"Faw",
"Ferrari",
"Fiat",
"Fisker",
"Flanker",
"Ford",
"Foton",
"Fso",
"Fuqi",
"Gac",
"Gaz",
"Geely",
"Genesis",
"Geo",
"Ginetta",
"GMC",
"Gonow",
"Gordon",
"Gp",
"Great Wall",
"Groz",
"Hafei",
"Haima",
"Hanomag",
"Haval",
"Hawtai",
"Hindustan",
"Hispano Suiza",
"Holden",
"Honda",
"Horch",
"Huanghai",
"Hudson",
"Humber",
"Hummer",
"Hurtan",
"Hyundai",
"Infiniti",
"Innocenti",
"International",
"Invicta",
"Iran Khodro",
"Isdera",
"Isuzu",
"Italdesign",
"Iveco",
"Izh",
"Jac",
"Jaguar",
"Jeep",
"Jensen",
"Jiangnan",
"Jinbei",
"JMC",
"Kanonir",
"Kia",
"Koenigsegg",
"Ktm Ag",
"Lada",
"Lamborghini",
"Lancia",
"Land Rover",
"Landwind",
"Lexus",
"Liebao Motor",
"Lifan",
"Ligier",
"Lincoln",
"Logem",
"Lotec",
"Lotus",
"Lti",
"Luaz",
"Lucid",
"Luxgen",
"Mahindra",
"Marcos",
"Marlin",
"Marussia",
"Maruti",
"Maserati",
"Matra Simca",
"Maybach",
"Mazda",
"Mcc",
"Mclaren",
"Mega",
"Mercedes-Benz",
"Mercury",
"Metrocab",
"MG",
"Microcar",
"Minelli",
"Mini",
"Mitsubishi",
"Mitsuoka",
"Monte Carlo",
"Monteverdi",
"Moretti",
"Morgan",
"Morris",
"Moskvich",
"Nash",
"Nissan",
"Noble",
"NSU",
"Nysa",
"Oldsmobile",
"Opel",
"Osca",
"Packard",
"Pagani",
"Panoz",
"Paykan",
"Perodua",
"Peugeot",
"PGO",
"Piaggio",
"Pininfarina",
"Plymouth",
"Pontiac",
"Porsche",
"Premier",
"Proton",
"Puch",
"Puma",
"Qoros",
"Qvale",
"Ram",
"Rambler",
"Ravon",
"Reliant",
"Renaissance",
"Renault",
"Renault Samsung",
"Rezvani",
"Riley",
"Rimac",
"Rinspeed",
"Rolls Royce",
"Ronart",
"Rover",
"Saab",
"Saipa",
"Saleen",
"Samsung",
"Santana",
"Saturn",
"Scion",
"Seat",
"Shanghai Maple",
"Shifeng",
"Shuanghuan",
"Simca",
"Singer",
"Skoda",
"Sma",
"Smart",
"Smz",
"Soueast",
"Spectre",
"Spyker",
"Ssangyong",
"Ssc",
"Steyr",
"Studebaker",
"Subaru",
"Sunbeam",
"Suzuki",
"Tagaz",
"Talbot",
"Tata",
"Tatra",
"Tazzari",
"Tesla",
"Think",
"Tianma",
"Tianye",
"Tofas",
"Toyota",
"Trabant",
"Tramontana",
"Triumph",
"TVR",
"UAZ",
"Ultima",
"Vauxhall",
"Vaz (Lada)",
"Vector",
"Venturi",
"Volkswagen",
"Volvo",
"Vortex",
"W Motors",
"Wanderer",
"Wartburg",
"Westfield",
"Wiesmann",
"Willys",
"Willys Overland",
"Wuling",
"Xedos",
"Xin Kai",
"Yo Mobil",
"Zagato",
"Zastava",
"Zaz",
"Zenos",
"Zenvo",
"Zibar",
"Zil",
"Zis",
"Zotye",
"Zx",
];
var modelsArray = [];
modelsArray[0] = "";
modelsArray[1] = "";
modelsArray[2] = "ILX|ILX Hybrid|MDX|NSX|RDX|RL|RLX|RSX|TL|TLX|TSX|--- Other Models ---|CL|CL-X|CSX|EL|Integra|Legend|SLX|Vigor|ZDX";
modelsArray[3] = "Giulia|Stelvio|--- Other Models ---|105/115|145|146|147|155|156|159|164|166|175|1750|179|1900|2000|2300|2600|33|33 Race|33 Stradale|33 Tt|4C|6|6C|75|8C Competizione|90|AR 51|Alfa 6|Alfasud|Alfetta|Arna|Bella|Berlina|Brera|Caimano|Carabo|Centauri|Coupe|Crosswagon|Cuneo|Dardo|Dauphine|Disco Volante|Eagle|GP 158|GP 159|GT|GTA|GTA Coupe|GTV|GTV6|Giulietta|Graduate|Junior|Kamal|MiTo|Milano|Montreal|Navajo|Nuvola|Proteo|RZ|S.Z./R.Z.|SZ|Scarabeo|Scighera|Spider|Sportiva|Sportut|Sportwagon|Sprint";
modelsArray[4] = "DBX|DB11|DBS|Vantage|V8 Vantage|V12 Vantage|--- Other Models ---|15|2-Litre|Atom|Bulldog|Cygnet|DB AR1|DB2|DB2/4|DB3|DB4|DB5|DB6|DB7|DB9|DBR2|Lagonda|One-77|Project Vantage|Rapide|Rapide S|Tickford Capri|V12 Zagato|V8 Saloon|V8 Volante|V8 Zagato|Vanquish|Virage";
modelsArray[5] = "A3|A4|A4 allroad|A5|A6|A6 allroad|A7|A8|Q2|Q3|Q3 Sportback|Q5|Q7|Q8|Q8 I|R8|R8 LMP|R8R|RS 2|RS 3|RS 4|RS 5|RS 6|RS 7|RS Q3|RS Q3 Sportback|RS Q8|S3|S4|S5|S6|S7|S8|SQ5|SQ7|SQ8|TT|TT RS|TTS|--- Other Models ---|100|200|4000|50|5000|80|90|920|A1|A2|A3 e-tron|AD|AL2|allroad|Asso|Avantissimo|Avus|F103|Fox|LeMans|NSU RO 80|Quattro|Rosemeyer|Sport|Steppenwolf|Super 90|Typ R|UR|V8|e-tron|e-tron Sportback";
modelsArray[6] = "Bentayga|Continental|Continental GT|Continental GTC|Continental Supersports|Continental T|Flying Spur|Mulsanne|--- Other Models ---|Arnage|Azure|Brooklands|Continental Flying Spur|Eight|Exp Speed 8|Hunaudieres|Java|MK V|Mark VI|R Type|R Type Continental|S1|S2|State Limousine|T|T2|Turbo|Turbo R";
modelsArray[7] = "1 Series|2 Series|3 Series|3 Series Gran Turismo|4 Series|5 Series|5 Series Gran Turismo|6 Series|7 Series|8 Series|M2|M3|M4|M5|M6|M8|X1|X2|X3|X3 M|X4|X4 M|X5|X5 M|X6|X6 M|X7|Z3|Z3 M|Z4|Z4 M|i3|i8|--- Other Models ---|116|118|120|123|125|128|130|135|1500|1502|1600|1602|1800|1M|2 Series Active Tourer|2 Series Grand Tourer|2000|2000 C/CS|2002|2004|2800|315|316|317|318|320|3200|3200 CS|321|323|324|325|326|327|328|330|332|333|335|340|4 Series Gran Coupe|501|502|503|507|518|520|523|524|525|528|529|530|535|538|540|545|550|6 Series Gran Coupe|600|628|630|633|635|640|645|650|700|725|728|729|730|732|735|740|745|748|750|760|840|845|850|854|856|ALPINA B6 Gran Coupe|ALPINA B7|ActiveHybrid 3|ActiveHybrid 5|ActiveHybrid 7|Alpina|B7|Breyton|E3|E9|FW 27|Formula FB02|Formula One|Isetta|Just 4|Karmann Asso di Quadri|L7|LMR|M|M1|M12|M28i|M6 Gran Coupe|X Activity|X Coupe|Z1|Z18|Z22|Z8|Z9|Z9 Gran Turismo Convertible";
modelsArray[8] = "Chiron|Veyron|EB 18-3 Chiron|EB 18-4 Veyron|EB Veyron 16.4|--- Other Models ---|251|Divo|EB 110|EB 112|EB 118|EB 218|ID 90|Type 101|Type 55|Type 57|Type 68|Type 73";
modelsArray[9] = "Century|Enclave|Encore|Envision|LaCrosse|LeSabre|Lucerne|Park Avenue|Regal|Rendezvous|Verano|--- Other Models ---|40|70|Blackhawk|Centieme|Centurion|Cielo|Electra|Estate|Estate Wagon|Excelle|GL8|GS|Invicta|Rainier|Reatta|Riviera|Roadmaster|Signia|Skyhawk|Skylark|Special|Super|Terraza|Wildcat|XP2000";
modelsArray[10] = "ATS|ATS Coupe|CT4|CT4-V|CT5|CT5-V|CT6|CTS|CTS Coupe|CTS-V|CTS-V Coupe|CTS-V Wagon|DTS|DeVille|Eldorado|Escalade|Escalade ESV|SRX|STS|STS-V|XT4|XT5|XT6|XTS|--- Other Models ---|60|61|62|6239D|ATS-V|Allante|Aurora|BLS|Biarritz|Brougham|CTS Wagon|Calais|Catera|Cimarron|ELR|Fleetwood|Imaj|LMP|LSE|Le Mans|Park Avenue|SLS|Series 61|Series 62|Series 70|Seville|Sixty|Sixty Special|Solitaire|V Sixteen|Vizon|XLR";
modelsArray[11] = "Avalanche|Aveo|Bel Air|Blazer|Blazer K5|Bolt|Camaro|Captiva|Cavalier|Chevelle|City Express|Cobalt|Colorado|Corvette|Cruze|El Camino|Equinox|Express|Express Cargo|HHR|Impala|Malibu|Monte Carlo|S-10|S-10 Pickup|Silverado 1500|Silverado 2500HD|Silverado 3500HD|Sonic|Spark|Spark EV|Suburban|Tahoe|Trailblazer|Traverse|Trax|Uplander|Volt|--- Other Models ---|150|1700|210|2103|400|APV|Adventure|Aerovette|Agile|Alero|Astra|Astro|Astrovette|Beat|Beretta|Biscayne|C-10|C/K|CSV CR8|Calibra|Caprice|Celebrity|Celta|Chevette|Chevy II|Cheyenne|Citation|Citation II|Classic|Commodore|Comodoro|Corsa|Corsica|Corvair|Corvette Stingray|D20|DeLuxe|DeVille|Delray|Deluxe|Enjoy|Epica|Evanda|Fleetline|Fleetmaster|Forester|Grand Blazer|Grand Vitara|Intimidator|Ipanema|Jimny|Journey|K-20|Kadett|Kalos|Kodiak|Kommando|LUV D-MAX|Lacetti|Lanos|Lova|Lumina|Lumina APV|MW|Master|Matiz|Meriva|Metro|Montana|Monza|N200|N300|Niva|Nomad|Nova|Nubira|Omega|Onix|Opala|Optra|Orlando|Pickup|Prisma|Prizm|Relsamo|Rezzo|SS|SSR|Sabia|Sail|Samurai|Senator|Silverado|Sonora|Special DeLuxe|Spectrum|Spin|Sprint|Starcraft|Stylemaster|Swift|Tacuma|Tandem 2000|Task Force|Tavera|Tornado|Tracker|Triax|Trooper|Vectra|Vega|Venture|Veraneio|Vitara|Viva|Vivant|XP 882|XP 897 GT|XP 898|Zafira";
modelsArray[12] = "200|200 CONVERTIBLE|300|Aspen|Crossfire|Pacifica|PT Cruiser|PT Dream Cruiser|Sebring|Town & Country|Voyager|--- Other Models ---|1609|1610|180|300 Letter Series|300C|300M|Airflite|Alpine|Atlantic|Avenger|Aviat|C|CCV|Centura|Charger|Chronos|Cirrus|Concorde|Conquest|Cordoba|Dart|Daytona|Delta|Drifter|Dynasty|E|ES|ESX 3|Executive|Expresso|Fifth Avenue|GS|Grand Voyager|Horizon|Howler|Hunter|Imperial|Imperial Crown|Intrepid|Java|LHS|Lancer|Laser|LeBaron|Limousine|Millenium|Nassau|Neon|New Yorker|Newport|Panel Cruiser|Phaeton|Prowler|Saratoga|Stratus|Sunbeam|Thunderbolt|Valiant|Valiant Galant|Viper|Vision|Windsor|Ypsilon160";
modelsArray[13] = "Avenger|Caliber|Caravan|Challenger|Charger|Dakota|Dart|Durango|Grand Caravan|Journey|Magnum|Neon|Nitro|RAM 1500|RAM 2500|RAM 3500|Stratus|Viper|--- Other Models ---|330|400|440|600|A100|Aries|Aspen|Attitude|Brisa|Charger Daytona|Charger RT Concept|Colt|Conquest|Copperhead|Coronet|Custom|Custom Royal|D/W Series|D8|Daytona|Demon|Diplomat|Dynasty|Forza|Hemi Super 8|Intrepid|Kahuna|Lancer|M80|Matador|Maxx Cab|Mayfair|Meadowbrook|Mirada|Monaco|Omni|Phoenix|Polara|Power Box|Power Wagon|Prowler|Raider|Ram|Ram 50|Ram Van|Ramcharger|Rampage|Razor|Royal|SE|SRT Viper|Shadow|Sidewinder|Spirit|Sprinter|St. Regis|Stealth|T-Rex 6x6|Venom";
modelsArray[14] = "360|458 Italia|488|California|F 12 Berlinetta|F355|F430|--- Other Models ---|500 F2|500 Mondial|500 Superfast|500 Testarossa|512 BB|512 BBi|512 BBi Turbo|512 F1|512 M|512 S|512 TR|550|553 F1|553 F2|575|575 Superamerica|575M|575M Maranello|599|599 GTB Fiorano|612|612 Scaglietti|625 F1|735|750|801 F1|812|812 Superfast|850|Berlinetta Boxer|C62|D 50|Dino|Dino 206 GT|Dino 208/308 GT4|Dino 246 GT|Enzo|F 2005|F1 156|F1 2000|F1 86|F1 88|F1 89|F1 90|F1 93|F310|F333|F399|F40|F50|F512 M|F55|F550|F8|F8 Tributo|FF|FF HELE|FXX K|GTC4Lusso|GTO|KS 360 Modena|LaFerrari|Maranello|Mondial|Monza SP|Mythos|P2|P5|Pinin|Portofino|Roma|Rossa|SF90 Stradale|Superamerica|Testarossa|125|125 F1|125S|126|156|158|159S|166|195|196|206|208|212|225|246|250|250 GT|250 GTE|250 GTO|250 LM|250 Mille Miglia|250 Testarossa|255 S|256 F1|275|288 GTO|306|308|312|328|328 GTB|328 GTS|330|330GT|340 America|340 F1|340 MM|340 Mexico|340 Spider|342 America|348|348 TS Targa|355 Spider|355 TS|365|365 BB|365 California|365 GT|365 GT4|365 GTB|365 GTC|365 GTC 4|365 GTS|375 America|375 Indy|375 Mille Miglia|3Z|400|400 Superamerica|410 Superamerica|412|412 GT|412 MI|412 T2|456|456M";
modelsArray[15] = "124 Spider|500|500L|500X|500e|--- Other Models ---|508|600|750|850|8V|900T|Abarth|Albea|Argenta|Armadillo|Barchetta|Berline|Brava|Bravo|Brio|Cabriolet|Campagnola|Cinquecento|Coupe|Croma|Dino|Doblo|Ducato|Duna|ESV|Ecobasic|Ecobasis|Elba|Fiorino|Freemont|Fullback|Fullback I|Grand Break|Grande Punto|Idea|Legram|Linea|Marea|Marea Weekend|Marengo|Mille|Mirafiori|Multipla|OT|Palio|Palio II|Panda|Panorama|Perla|Premio|Punto|Qubo|Regata|Ritmo|Scudo|Sedici|Seicento|Siena|Spazio|Stilo|Strada|Talento|Tempra|Tipo|Tucan|Ulysse|Uno|V8|Viaggio|Vivace|X 1/9|X1-9|X1/9|1100|1200|124|124 Sport Spider|125|126|127|128|130|1300|1300/1500|131|132|133|1400|147|1500|1600|1800|1800/2100|1900|2100|2300|238";
modelsArray[16] = "Bronco|Bronco II|C-Max Energi|C-Max Hybrid|Crown Victoria|E-Series|EcoSport|Edge|Escape|Excursion|Expedition|Explorer|Explorer Sport Trac|F-Series|F-150|F-250|F-250 Super Duty|F-350|F-350 Super Duty|F-450|F-450 Super Duty|F-550|Fiesta|Fiesta ST|Five Hundred|Flex|Focus|Focus RS|Focus ST|Freestar|Freestyle|Fusion|Fusion Energi|Fusion Hybrid|GT|Model A|Mustang|Mustang Mach-E|Ranger|Taurus|Thunderbird|Transit Connect|Transit Van|Transit Wagon|Windstar|--- Other Models ---|021 C|12 M|17|17M|2000|24.7|427|49|Aerostar|Anglia|Artic|Aspire|B-MAX|Bantam|C 100|C-Max|Capri|Coin|Comete|Consul|Contour|Corcel|Corsair|Cortina|Cougar|Courier|Crestline|Del Rey|Desert Excursion|Durango|E-150|E-250|E-350|Elite|Equator|Escort|Everest|Extreme EX|F|FPV BF GT|Fairlane|Fairmont|Falcon|Festiva|Figo|Freda|GPA|GPW|GT 40|GT 500|GT 70|GT40|Galaxie|Galaxy|Gran Torino|Granada|Husky|Ikon|Indigo|Ixion|Ka|Kuga|LTD|LTD Crown Victoria|LTD II|Laser|Libre|Limited|Lobo|Lotus Cortina|Lynx|M151|Mainline|Maverick|Megastar II|Meteor|Model T|Model U|Monarch|Mondeo|Mondeo ST|O21 C|Orion|Pampa|Pilot|Pinto|Popular|Prefect|Probe|Puma|RS 200|Ranchero|Royale|S-Max|ST 460|Saetta|Scorpio|Shelby|Shelby GR-1 Concept|Shelby GT 500|Shelby GT500|Sierra|Skyliner|Spectron|Station Wagon|Street KA|Synergy 2010|TE-50|TL-50|TS-50|Taunus|Taurus X|Telstar|Tempo|Territory|Think|Think Neighbor|Tonka|Torino|Tourneo|Tourneo Connect|Tourneo Courier|Tourneo Custom|Tracer|Triton|Urban Explorer|V8|Vedette|Verona|Versailles|XR 8 Xplod|Zephyr|Zodiac|e-Ka";
modelsArray[17] = "G70|G80|G90";
modelsArray[18] = "Acadia|Canyon|Envoy|Savana|Sierra 1500|Sierra 2500HD|Sierra 3500HD|Sonoma|Terrain|Yukon|Yukon XL|--- Other Models ---|Firebird|Jimmy|Safari|Sierra|Syclone|Terra 4|Terracross|Terradyne|Typhoon|Vandura";
modelsArray[19] = "Accord|Accord Crosstour|Accord Hybrid|CR-V|CR-Z|Civic|Crosstour|Element|Fit|HR-V|Insight|Odyssey|Passport|Pilot|Ridgeline|S2000|--- Other Models ---|1300|145|Acty|Airwave|Amaze|Argento Viva|Ascot|Ascot Innova|Avancier|Ballade|Beat|Brio|CR-X|Capa|City|Civic Del Sol|Civic Ferio|Civic Type R|Concerto|Crider|Crossroad|Domani|EV|Edix|Elysion|F1|FCX|FCX Clarity|FR-V|Fit Aria|Fit Shuttle|Freed|Fuya Jo|Grace|HP-X|Horizon|Inspire|Integra|Integra SJ|J-VX|Jade|Jazz|Lagreat|Legend|Life|Logo|MDX|MR-V|Mobilio|Mobilio Spike|Model X|N III|N-BOX|N-One|N-Series|N-WGN|N360|NSX|Orthia|Partner|Prelude|Quint|Quintet|RafagaS500|S600|S660|S800|SSM|Saber|Shuttle|Spirior|Step Wagon|Stepwgn|Stream|Street|That|That's|Today|Torneo|Vamos|Vezel|Vigor|Z|Zest";
modelsArray[20] = "H1|H2|H3";
modelsArray[21] = "Accent|Azera|Elantra|Elantra GT|Equus|Genesis|Genesis Coupe|Ioniq|Kona|Palisade|Santa Fe|Santa Fe Sport|Sonata|Sonata Hybrid|Tucson|Veloster|Venue|Veracruz|--- Other Models ---|Amica|Aslan|Atos|Avante|Avega|Centennial|Click|Clix|Creta|Dynasty|Entourage|Eon|Euro 1|Excel|Galloper|Getz|Grace|Grand Starex|Grandeur|H1|H100|H200|HB20|HCD-7|HCD-III|HED-5|HED-6|Langdong|Lantra|Lavita|Marcia|Matrix|Maxcruz|Neos|Panel Van|Pony|Portico|Presto|Rohens|S-Coupe|Santamo|Santro|Santro Xing|Satellite|Scoupe|Solaris|Sonica|Starex|Stellar|TB|TQ|Terracan|Tiburon|Tipper|Trajet|Tuscani|Verna|XG|XG 300|XG 350|i10|i20|i25|i30|i30 N|i40|i45|ix20|ix35|ix55";
modelsArray[22] = "EX|FX|G25|G35|G37|JX|M|Q50|Q60|Q70|QX30|QX4|QX50|QX56|QX60|QX70|QX80|--- Other Models ---|G|G20|I|I30|I35|IPL|J|J30|Q|Q30|Q40|Q45|Q60 Convertible|Q60 Coupe|QX";
modelsArray[23] = "117|3.1|4200|Alterra|Amigo|Ascender|Aska|Axiom|Bellel|Bellett|Bighorn|Campo|Como|Crosswind|D-Max|Fargo|Fargo Filly|Filly|Florian|Frontera|Fuego|Gemini|Hombre|I-280|I-290|I-350|I-Mark|Impulse|KB|Kai|MU|MU-7|MU-X|Midi|Minx|New Bellel|Oasis|Panther|Piazza|Rodeo|Statesman de Ville|Stylus|TF (Pickup)|Trooper|VX-02|VX4|VehiCross|Vehicross|WFR|Wasp|Wizard|ZXS|i-Series";
modelsArray[24] = "E-Pace|F-Pace|F-Type|I-Pace|S-Type|XE|XF|XJ|XK|--- Other Models ---|XK8|XKA|XKR|XS|220|240|240/340|3.8|420|420G|C-Type|Concept Eight|E-Type|Kensington|MK 10|MK II|MK IV|MK IX|MK V|MK VII|MK VIII|MK X|Mark|Mark 2|Mark IV|Mark IX|R Coupe|R-D6|SS|Sovereign|Type E|Type S|Type-C|Vanden Plas|X-300|X-Type|X400|XFR|XJ-13|XJ220|XJ6|XJ8|XJR|XJR-11|XJR-15|XJS";
modelsArray[25] = "Cherokee|Commander|Compass|Gladiator|Grand Cherokee|Liberty|Patriot|Renegade|Wrangler|--- Other Models ---|2500|CJ|CJ2|CJ2A|CJ3A|CJ5|CJ7|Comanche|Commando|Dakar|Grand Cherokee SRT|Grand Wagoneer|Icon|Jeepster|MB|Station Wagon|Varsity|Wagoneer|Willys|Willys 2";
modelsArray[26] = "Cadenza|Forte|K900|Niro|Optima|Optima Hybrid|Rio|Rondo|Sedona|Seltos|Sorento|Soul|Soul EV|Spectra|Sportage|Stinger|Telluride|--- Other Models ---|Avella|Besta|Borrego|Brisa|Capital|Carens|Carnival|Carstar|Ceed|Ceed GT|Cerato|Clarus|Concord|Credos|Elan|Enterprise|Joice|K2700|K3|K5|K7|K9|KCV-4|Lotze|Magentis|Mentor|Mohave|Morning|Opirus|Parktown|Picanto|Potentia|Pregio|Pride|Pride Wagon|ProCeed|Quanlima|Quoris|Ray|Retona|Sephia|Sephia II|Shuma|Shuma II|Spectra5|Stonic|Towner|Venga|Visto|X-Trek|XCeed|XedosAmanti";
modelsArray[27] = "Aventador|Gallardo|Huracan|Urus|--- Other Models ---|350 GT|350/400 GT|350GT|400|400GT||Bravo|Cala|Centenario|Concept S|Countach|Diablo|Egoista|Egoista I|Espada|Flying Star|Islero|Jalpa|Jarama|LM|LM001|LM002|Marco Polo|Marzal|Miura|Murcielago|Portofino|Project P140|Reventon|Sesto Elemento|Silhouette|Urraco|Veneno";
modelsArray[28] = "Defender|Discovery|Discovery Sport|Freelander|LR2|LR3|LR4|Range Rover|Range Rover Evoque|Range Rover Sport|Range Rover Velar|--- Other Models ---|88|A 109|Discovery 3|I|Range Rover Evoque Convertible|Series I|Series II|Series III109";
modelsArray[29] = "CT|ES|ES 300h|ES 350|GS 350|GS F|GX|GX 470|HS|IS|IS 250|IS 350|LC|LX|LX 570|NX|NX 200t|RC|RC 350|RC F|RX|RX 350|RX 450h|SC|UX|--- Other Models ---|CT 200h|Daytona|FLV|GS|GS 450h|IS 250 C|IS 350 C|IS F|LF-C|LFA|LM|LS|LS 460|LS 600h L|Minority Report|NX 300h";
modelsArray[30] = "Aviator|Continental|Corsair|LS|MKC|MKS|MKT|MKX|MKZ|Nautilus|Navigator|Town Car|--- Other Models ---|Blackwood|Capri|Continental Mark Series|Cosmopolitan|Custom|K-Series|MK 9|Mark III|Mark IV|Mark LT|Mark VI|Mark VII|Mark VIII|Mark X|Premiere|Sentinel|Versailles|Zephyr";
modelsArray[31] = "Ghibli|GranTurismo|Levante|Quattroporte|--- Other Models ---|124|150S|151|228|250|300|3200 GT|3500|3500 GT|350S|4|420|4200 GT|420M|430|450|4CL|4CLT|5000 GT|6C|8|8CL|8CLT|A6|A6G|A6GCM|A6GCS|Auge|Barchetta Straale|Barchetta Stradale|Bi Turbo|Biturbo|Boomerang|Bora|Chubasco|Coupe|GT|GT 3500|GranTurismo 3500|GranCabrio|GranSport|GranTurismo Convertible|Indy|Karif|Khamsin|Kubang|Kyalami|MC12|Merak|Mexico|Mistral|Racing|Royale|Sebring|Shamal|Simun|Spider 3500|Spyder|Tipo 60|Tipo 61|Tipo 64|Tipo 65|V8 GranSport";
modelsArray[32] = "57|62|--- Other Models ---|Exelero|Landaulet|SW|Zeppelin";
modelsArray[33] = "CX-3|CX-30|CX-5|CX-7|CX-9|Mazda2|Mazda3|Mazda5|Mazda6|MazdaSpeed3|MX-5 Miata|RX-8|Tribute|--- Other Models ---|Capella|Carol|Chante|Chantez|Clef|Cosmo|Cronos|Demio|Drifter|DrifterX|Efini MS-6|Efini MS-8|Efini MS-9|Etude|Eunos|Eunos 100|Eunos 300|Eunos 500|Eunos 800|Eunos Cosmo|Familia|Flair|Flair Crossover|Flair Wagon|GLC|Kusabi|Lantis|Laputa|Levante|Luce|MPV|MS-6|MS-8|MS-9|MX|MX-3|MX-5|MX-6|MX-Flexa|Marathon|Miata|Midge|Millenia|Montrose|Navajo|Persona|Porter|Porter Cab|Premacy|Proceed|Proceed Levante|Proceed Marvie|Protege|R 360|R-100|R100|R360|RX 4 Coupe|RX Evolv|RX-01|RX-2|RX-3|RX-4|RX-5|RX-7|RX-Evolv|Revue|Roadpacer AP|Roadster|Rustler|SW-X|Scrum|Sentia|Spiano|Sport Wagon|Verisa|Washu|Xedos 6|Xedos 9|1000|110 S|121|1300|3 MPS|323|6 MPS|616|626|787|808|818|929|AZ|AZ-1|AZ-Offroad|AZ-Wagon|Activehicle|Allegro|Atenza|Autozam AZ-3|Axela|B-Series|B2300|B2500|B3000|B4000|BT-50|Biante|Bongo|Bongo Brawny|Bongo Friendee|Bravo|Brown Collection Verisa|CU-X";
modelsArray[34] = "570S|600LT|720S|GT|--- Other Models ---|540C|570GT|650S|650S Coupe|650S Spider|675LT|F1|F1 GTR|F1 LM|M10|M14|M19|M1C|M21|M23|M8E|MP4|MP4-12C|P1|Senna";
modelsArray[35] = "A-Class|AMG GT|C-Class|CL-Class|CLA-Class|CLK-Class|CLS-Class|E-Class|G-Class|GL-Class|GLA-Class|GLB-Claass|GLC-Class|GLE-Class|GLK-Class|GLS-Class|M-Class|R-Class|S-Class|SL-Class|SLC-Class|SLK-Class|Sprinter|--- Other Models ---|170 V|170S|180|190|190 (W201)|190 SL|200|219|220|230|230.4|240|250|260|280|300|300B|300D|300S|320|350|380|400|420|450|500|560|600|770|B-Class|Bionic Car Concept|CLC-Class|CLK|CW|Citan|F200|F300 Life Jet|MCC|ML|Ponton|SC|SE|SLR McLaren|SLS|SLS AMG|SLS AMG GT|Simplex|Studie|Swatch|T|T V-12|Type 300|V-Class|Vaneo|Version Longue|Viano|Vision|Vito|W 110|W 111|W 123 Coupe|W 136|W 196|W100|W105|W108|W110|W111|W114|W115|W120|W121|W123|W124|W128|W136|W142|W186|W188|W189|W191|W29|X-Class";
modelsArray[36] = "Cooper|Cooper Clubman|Cooper Countryman|--- Other Models ---|Cooper Coupe|Cooper Paceman|Cooper Roadster|Cooper S|MK I|MK II|MK III|MK IV|MK V|MK VI|MK VII|One";
modelsArray[37] = "Eclipse|Eclipse Cross|Endeavor|Galant|Lancer|Lancer Evolution|Mirage|Outlander|Outlander Sport|--- Other Models ---|3000 GT|3000GT|380|500|A10|ASX|Adventure|Airtrek|Aspire|Attrage|Bravo|Carisma|Celeste|Challenger|Chariot|Colt|Cordia|Debonair|Delica|Delica D:2|Delica D:3|Delica D:5|Delica Space Gear|Diamante|Dignity|Dingo|Dion|EK Wagon|Emeraude|Eterna|Expo|FTO|Field Guard|Freeca|Fuzion|GTO|Galant Fortis|Gaus|Grandis|Grunder|HSR-V|Jeep|Jeep J|L 200|L100|L200|L300|L400|Lancer Cargo|Lancer Ralliart|Lancer Sportback|Legnum|Libero|Magna|Magnum|Maven|Mighty Max|Minica|Minicab|Montero|Montero Sport|Montero iO|Mum 500|Nessie|Nimbus|Pajero|Pajero Junior|Pajero Mini|Pajero Pinin|Pajero Sport|Pajero iO|Pistachio|Precis|Proudia|RPM 7000|RVR|Raider|SSS|SST|SSU|SUP|SUW|Sapporo|Savrin|Scorpion|Shogun|Shogun Pinin|Shogun Sport|Sigma|Space Gear|Space Liner|Space Runner|Space Star|Space Wagon|Starion|Strada|Toppo|Town BOX|Towny|Tredia|Triton|Valley|Verada|Warrior|XPANDER|Zinger|eK|eK Active|eK Classic|eK Custom|eK Space|eK Sport|i|i-MiEV";
modelsArray[38] = "350Z|370Z|Altima|Armada|Cube|Frontier|GT-R|Juke|Kicks|Leaf|Maxima|Murano|NV|NV200|Pathfinder|Quest|Rogue|Sentra|Titan|Xterra|--- Other Models ---|100 NX|100NX|110|1400|180SX|200 SX Silvia|200SX|211|240 C|240SX|260 ZX|270 R|280ZX|300 ZX|300ZX|400 R|70|AA-X|AD|AXY|AZ-1|Almera|Almera Classic|Almera Tino|Alpha|Altra|Aprio|Auster|Avenir|Axxess|Bassara|Be-1|Bluebird|Bluebird Maxima|Bluebird Sylphy|C 52|CQ-X|Caravan|Cedric|Cefiro|Chappo|Cherry|Cherry Cab|Cima|Clipper|Clipper Rio|Commercial|Crew|DS-2|Datsun|Dayz|Dayz Roox|Dualis|E20|El Grand|Elgrand|Exa|Expert|Fairlady|Fairlady Z|Figaro|Fuga|Fusion|Gazelle|Gloria|Grand Livina|Hardbody|Hikari|Homy|Hustler|HyperMini|Hypermini|Ideo|Interstar|Juke Nismo|Junior|Kix|Kubistar|Lafesta|Langley|Largo|Latio|Laurel|Laurel Spirit|Leopard|Liberta Villa|Liberty|Livina|Lucino|M|MM|March|Micra|Mid4|Mistral|Moco|Multi|Murano CrossCabriolet|NP200|NP300|NV100 Clipper|NV350|NV350 Caravan|NX Coupe|Navara|Navara (Frontier)|Note|Otti|Paladin|Pao|Patrol|Pickup|Pino|Pintara|Pixo|Platina|Prairie|Presage|Presea|President|Primastar|Primera|Pulsar|Qashqai|Qashqai+2|R 390 GT1|R 391|R'nessa|Rasheen|Roox|SUT|Safari|Santana|Sedan|Trailrunner|Tsuru|Urvan|Vanette|Versa|Versa Note|Violet|Violet Liberta|Wingroad|X-Trail|XVL|Z|Zaroot";
modelsArray[39] = "718|718 Spyder|911|Boxster|Carrera GT|Cayenne|Cayman|Cayman GT4|Macan|Panamera|Taycan|--- Other Models ---|1300|3400|356|550 A|804|904|906|907|908|910|911 GT2|911 GT3|911 R|912|914|917|918|918 Spyder|924|928|930|935|936|944|959|962|964|965|968|993|996|DP|GT2|GT3|Karisma|Kremer 935 K3|Mega Cabriolet Biturbo|Pan Americana|RGT|Tapiro";
modelsArray[40] = "Cullinan|Dawn|Ghost|Ghost Series II|Phantom|Wraith|--- Other Models ---|100|20/25|Camargue|Corniche|Flying Spur|Park Ward|Phantom Drophead Coupe|Silver Cloud|Silver Cloud II|Silver Dawn|Silver Ghost|Silver Seraph|Silver Shadow|Silver Spirit|Silver Spirit II|Silver Spur|Silver Wraith";
modelsArray[41] = "Forfour|Fortwo|Roadster";
modelsArray[42] = "Ascent|B9 Tribeca|BRZ|Forester|Impreza|Impreza WRX|Impreza WRX STi|Legacy|Outback|Outback Sport|Tribeca|WRX|WRX STi|XV Crosstrek|--- Other Models ---|1000|1400|1500|360|Alcyone|Alfa|BRAT|Baja|Bighorn|Bistro|Coupe|DL|Dex|Dias Wagon|Domingo|Estratto|Exiga|Exiga Crossover 7|FF-1|Fiori|G3X|HM-01|Justy|K 111|Legacy Lancaster|Leone|Levorg|Libero|Loyale|Lucra|Mini Jumbo|Pleo|R-2|R1|R2|Rex|STX|SVX|Sambar|Stella|Traviq|Trezia|Vivio|Vortex|WX 01|XT|XV";
modelsArray[43] = "Grand Vitara|SX4|XL-7|--- Other Models ---|Aerio|Alto|Alto Lapin|Baleno|Beidouxing|C2|Cappuccino|Cara|Carry|Celerio|Cervo|Ciaz|Covie|Cultus|Cultus Crescent|EV Sport|Equator|Ertiga|Escudo|Esteem|Every|F1|Forenza|Forsa|Fronte|Fun|GSX R4|Grand Escudo|Grand Nomade|Hustler|Ignis|Jimny|Karimun Estilo|Kei|Khyber|Kizashi|LJ 20|LJ 50|Landy|Lapin|Liana|Light|MR Wagon|Margalla|Mehran|Mighty Boy|Palette|Potohar|Reno|SC 100 GX|SC100|SJ 410|SJ 413|SX|Samurai|Santana|Sea Forenza Wagon|Sidekick|Solio|Spacia|Splash|Swift|Twin|Verona|Vitara|Wagon R|Wagon R+|X-90|X2|X90|XL6";
modelsArray[44] = "Cybertruck|Model 3|Model S|Model X|Model Y|Roadster";
modelsArray[45] = "4Runner|Avalon|Avalon Hybrid|C-HR|Camry|Camry Hybrid|Camry Solara|Celica|Corolla|FJ Cruiser|Highlander|Highlander Hybrid|Land Cruiser|Matrix|Mirai|Prius|Prius C|Prius Plug-In|Prius Plus|Prius V|RAV4|Sequoia|Sera|Sienna|Supra|Tacoma|Tundra|Venza|Yaris|--- Other Models ---|1000|105|2000GT|AA|AB|AC|AE|Agya|Allex|Allion|Alphard|Altezza|Aqua|Aristo|Ateva|Aurion|Auris|Avanza|Avensis|Avensis T27|Avensis Verso|Aygo|BB|Bandeirante|Belta|Blade|Blizzard|Brevis|Briska|COMS|Caldina|Cami|Carina|Carina E|Carina ED|Carri|Caserta|Cavalier|Celsior|Century|Chaser|Classic|Coaster|Comfort|Condor|Conquest|Corolla Ceres|Corolla II|Corolla Levin|Corolla Rumion|Corolla Spacio|Corolla Verso|Corona|Corona EXiV|Corsa|Cressida|Cresta|Crown|Crown Majesta|Curren|Cynos|Duet|Dyna|ES 3|Echo|Esquire|Estima|Etios|F-1|FCHV 5|FXS|Fortuner|Fun Cargo|FunCargo|GT 86|GT1|GT86|Gaia|Grand HiAce|Granvia|HMV|Harrier|Hi-Ace|Hiace|Hilux|Hilux Surf|Innova|Ipsum|Isis|Ist|Kijang|Kluger|Land Cruiser Prado|Lexcen|Lite Ace|MR-S|MR2|MRJ|Mark II|Mark X|Mark X Zio|Master|Master RR|MasterAce|MasterAce Surf|Mega Cruiser|MiniAce|Model F|Nadia|Noah|Opa|Origin|Paseo|Passo|Passo Sette|Picnic|Pixis|Pixis Epoch|Pixis Joy|Pixis Mega|Pixis Space|Pixis Van|Platz|Pod|Porte|Premio|Previa|ProAce|Probox|Progres|Progress NC 300|Pronard|Publica|Quantum|RSC|Ractis|Raize|Raum|Regius|RegiusAce|Reiz|Retro Cruiser|Rukus|RunX|Rush|SA|Sai|Scepter|Sienta|Soarer|Solara|Soluna|Space Cruiser|Spade|Sparky|Sport 800|Sports 800|Sprinter|Sprinter Carib|Sprinter Marino|Sprinter Trueno|Stallion|Starlet|Stout|Succeed|Super|T100|Tamaraw FX|Tank|Tarago|Tazz|Tercel|Tiara|Touring HiAce|Town Ace|Unser|Urban Cruiser|Vanguard|Vellfire|Venture|Verossa|Verso|Verso-S|Vienta|Vios|Vista|Vitz|Voltz|Voxy|WiLL|Will|Will Cypha|Will VS|Will Vi|Windom|Wish|XYR|Yaris Verso|Zace|Zelas|bB|iQ";
modelsArray[46] = "Amarok|Arteon|Atlas|Beetle|e-Golf|Eos|Golf|Golf GTI|Golf R|Golf SportWagen|Jetta|Jetta GLI|Jetta Hybrid|Jetta SportWagen|Passat|Passat CC|Rabbit|Routan|Tiguan|Touareg|--- Other Models ---|1 Litre|1302|1303|1500|1600|181|411|AAC|Beetle Convertible|Bora|Brasilia|Caddy|California|Caravelle|Citi|Citi Golf|Clasico|Commercial|Concept C|Concept T|Corrado|Country Buggy|Derby|Dune Buggy|EuroVan|Eurovan|Fox|Fusca|GLI|GTD|GTI|Gol|Golf Country|Golf Plus|Golf R32|Golf Sportsvan|Hac|ID.3|Iltis|K 70|K70|Karmann Ghia|Karmann-Ghia|Kombi|LT 35 HR Panel Van|Lavida|Lupo|Lupo GTI|Magellan|Magotan|Microbus|Multivan|New Beetle|Parati|Passat Variant|Phaeton|Pickup|Pointer|Polo|Polo GTI|Polo R WRC|Quantum|SP|SP2|Sagitar|Santana|Saveiro|Schwimmwagen|Scirocco|Scirocco R|Sedan|Sharan|Suran|T-Cross|T-Roc|T5|Taro|Tayron|Teramont|Tharu|Touran|Transporter|Type 1|Type 2|Type 3|Type 3 Squareback|Type 4|Up|Variant VII|Vento|Voyage|W12|XL1";
modelsArray[47] = "C70|S40|S60|S80|S90|V60|V70|V90|XC40|XC60|XC70|XC90|--- Other Models ---|120|120 Series|122|140|140 Series|144|145|164|1800|220|240|240 Series|242|244|245|260|260 Series|264|265|300 Series|340|343|360|440|460|480|66|740|760|780|850|940|960|Amazon|C30|Duett|Hatric|Laplander|P 1800|P 1900|P 210 Duett|P1800|P1900|PV|PV 544 1.8|PV 60|PV 801-10|PV444/544|PV60|S60 Cross Country|S70|SCC|V40|V40 Cross Country|V50|V60 Cross Country|V90 Cross Country|X670|YCC";
modelsArray[48] = "Berlingo|C-Elysee|C3|C3 AirCross|C4|C4 AirCross|C5|C5 AirCross|Jumpy|Jumper|--- Other Models ---|1.6|11|15|2 CV|2CV|7|7A|AX|Activa|Ak 350|Ami|Aventure|Axel|BX||Bijou|Bx|C|C 15|C-Airdream|C-Crosser|C-Quatre|C-Triomphe|C-Zero|C1|C15|C2|C3 Picasso|C35|C4 Cactus|C4 Picasso|C4 Spacetourer|C6|C8|CX|Chanson|DS|DS3|DS4|DS5|Dispatch|Dyane|Dyane 4|Dyane 6|E-Mehari|Eco 2000|Eole|Evasion|Fukang|GS|GSA|ID|ID 19|Karin|LN|LNA|Mehari|Mini-Zup|Multispace|Nemo|Osee|Picasso|Pluriel|Rally Raid|SM|Saxo|SpaceTourer|Synergie|Traction|Traction Avant|Visa|XM|Xanae|Xantia|Xsara|Xsara Picasso|ZX";
modelsArray[49] = "Dokker|Duster|Lodgy|Logan|Sandero|--- Other Models ---|1100|1300|1310|1320|1325|1325 Liberta|1410|2000|500|D6|Denem||Gamma|Nova|Pick-Up|Shifter|Solenza|Supernova";
modelsArray[50] = "Astra|Corsa|Crossland X|Grandland X|Insignia|Mokka|Vivaro|Zafira|Zafira Life|--- Other Models ---|Adam|Admiral|Agila|Ampera|Antara|Arena|Ascona|Astra OPC|Bedford Blitz|Blazer|Cabrio|Calais|Calibra|Campo|Cascada|Combo|Commodore|Concept M|Corsa OPC|Diplomat|Filo|Frogster|Frontera|GT|Insignia OPC|Kadett|Kapitan|Karl|Kraftfahrzeug|Lotus Omega|M|Manta|Meriva|Meriva OPC|Monterey|Monza|Movano|Olympia|Omega|P 1200|P4|Record|Rekord|Senator|Signum|Sintra|Speedster|Super 6|Super Six|Tigra|Trixx|Vectra|Vectra OPC|Vita|Zafira OPC";
modelsArray[51] = "108|208|308|508|2008|3008|5008|--- Other Models ---|1007|104|106|107|201|202|203|204|205|205 GTi|206|207|208 GTi|3008|301|302|304|305|306|307|308 GTi|309|4007|4008|401|402|403|404|405|406|407|408|504|504D|505|604|605|607|806|807|907|Bipper|Boxer|Escapade|Expert|H2O|Hoggar|Kart Up|Nautilus|Oxia|Pars|Partner|Persia|Peugette|Promethee|Proxima|Quasar|RC|RCZ|Rifter|Roa|Sesame|Touareg|Traveller|Tulip|Type 202|Vroomster|iOn";
modelsArray[52] = "Clio|Captur|Kadjar|Megane|Megane RS|Trafic|Zoe|--- Other Models ---|10|11|12|14|15|16|17|18|19|20|21|25|3|30|4|4CV|5|6|7|8|9|Alaskan|Alliance|Alpine|Argos|Arkana|Avantime|Be Bop|Caravelle|Celtaquatre|Clio RS|Clio V6|Colorale|Dauphine|Dauphine Gordini|Dokker|Duster|Ellypse|Espace|Espider|Etoile|Express|Fifties|Floride|Fluence|Fregate|Fuego|Grand Espace|Grand Scenic|Helem|Juva|Juvaquatre|Kangoo|Kaptur|Koleos|Kwid|Laguna|Latitude|Le Car|Lodgy|Logan|Ludo|Modus|Monaquatre|P55|Primaquatre|Pulse|R5|RE|RS 11|Racoon|Rodeo|Safrane|Sandero|Sandero RS|Scala|Scenic|Scenic II|Siete|Spider|Sport Spider|Super 5|Symbol|TXE|Talisman|Thalia|Torino|Twingo|Twizy|Vel Satis|Vivastella|Wind|Zo|Zoom";
modelsArray[53] = "Alhambra|Arona|Ateca|Ibiza|Leon|Tarraco|--- Other Models ---|1200/1430 Sport|124|127|128|131|132|133|1400|1430|1500|600|600/800|850|Altea|Arosa|Bolero|Cordoba|Exeo|Formula|Fura|Ibiza Cupra|Inca|Inca Kombi|Leon Cupra|Malaga|Marbella|Mii|Panda|Ritmo|Ronda|Salsa|Tango|Toledo";
modelsArray[54] = "Citigo|Fabia|Kamiq|Karoq|Kodiaq|Octavia|Scala|Superb|--- Other Models ---|100|100 Series|1000 MB|105 120|105/120/130|110 L|1200|1201|1202|440|440/445/450|Fabia RS|Favorit|Felicia|Forman|Garde|Kodiaq RS|L|Montreux|Octavia RS|Polular 995|Popular|Praktik|Rapid|Rapid R|Roomster|S 110 R|VOS|Yeti";
modelsArray[55] = "---";
modelsArray[56] = "1000|1000 Bialbero|1000 GT|1000 SP|1000 TC Corsa|103 GT|124|124 GT|124 Spider|124 Spider Turismo|1300|131 Rally|1500|1600|2000|2000 Pininfarina|2000 SE027|205|207|207A Boano Spyder Corsa|208|209|210|2200|2400|500|500 Pogea Racing Ares|595|595 Competizione|595 Pista|595 Turismo|600|695 Biposto|695 Rivale|695 XSR Yamaha|700|750|750 GT Zagato|800 Scorpione Coupe Allemano|850|A 112|Bialbero|Coupe|Grande Punto|Lancia 037|Lancia Delta S4 Stradale (SE038)|Lancia Rally 037 Stradale|Mono 1000|Monomille|Monotipo|OT|Renault|Simca|Spider Riviera|Stola";
modelsArray[57] = "2-Litre|378 GT Zagato|427|428|ACE|Ace|Aceca|Aceca-Bristol|Cobra|Greyhound";
modelsArray[58] = "ILX|ILX Hybrid|MDX|NSX|RDX|RL|RLX|RSX|TL|TLX|TSX|CL|CL-X|CSX|EL|Integra|Legend|SLX|Vigor|ZDX";
modelsArray[59] = "Diplomat|Trumpf|Trumpf Junior";
modelsArray[60] = "Giulia|Stelvio|105/115|145|146|147|155|156|159|164|166|175|1750|179|1900|2000|2300|2600|33|33 Race|33 Stradale|33 Tt|4C|6|6C|75|8C Competizione|90|AR 51|Alfa 6|Alfasud|Alfetta|Arna|Bella|Berlina|Brera|Caimano|Carabo|Centauri|Coupe|Crosswagon|Cuneo|Dardo|Dauphine|Disco Volante|Eagle|GP 158|GP 159|GT|GTA|GTA Coupe|GTV|GTV6|Giulietta|Graduate|Junior|Kamal|MiTo|Milano|Montreal|Navajo|Nuvola|Proteo|RZ|S.Z./R.Z.|SZ|Scarabeo|Scighera|Spider|Sportiva|Sportut|Sportwagon|Sprint";
modelsArray[61] = "GT|J|J1|J2|J2R|J2X|K1|K2|K3|M1|P1|Palm Beach|Safari";
modelsArray[62] = "2002|3|B|B 10|B 12|B10|B11|B12|B3|B4|B5|B6|B7|B8|B9|C1|C2|D10|D3|D4|D5|Roadster|Roadster S|Roadster V8|XD3|XD4";
modelsArray[63] = "A|A 106|A 108|A 110|A 310|A 442|A 610|A110|A310|A610|GTA|V6";
modelsArray[64] = "TA 14|TA 21|TB 14|TB 21|TC|TC 108 G|TC 21|TD|TF";
modelsArray[65] = "HMMWV (Humvee)";
modelsArray[66] = "AMX|AMX III|Ambassador|Eagle|Gremlin|Hornet|Javelin|Matador|Pacer";
modelsArray[67] = "21541 Stalker";
modelsArray[68] = "Atom";
modelsArray[69] = "16|Sapphire|Star Sapphire|Whitley";
modelsArray[70] = "10|24|Spartana";
modelsArray[71] = "A10|Ecosse|FG-T|KZ1";
modelsArray[72] = "Retona|Rocsta|Topic";
modelsArray[73] = "DBX|DB11|DBS|Vantage|V8 Vantage|V12 Vantage|15|2-Litre|Atom|Bulldog|Cygnet|DB AR1|DB2|DB2/4|DB3|DB4|DB5|DB6|DB7|DB9|DBR2|Lagonda|One-77|Project Vantage|Rapide|Rapide S|Tickford Capri|V12 Zagato|V8 Saloon|V8 Volante|V8 Zagato|Vanquish|Virage";
modelsArray[74] = "A3|A4|A4 allroad|A5|A6|A6 allroad|A7|A8|Q2|Q3|Q3 Sportback|Q5|Q7|Q8|Q8 I|R8|R8 LMP|R8R|RS 2|RS 3|RS 4|RS 5|RS 6|RS 7|RS Q3|RS Q3 Sportback|RS Q8|S3|S4|S5|S6|S7|S8|SQ5|SQ7|SQ8|TT|TT RS|TTS|100|200|4000|50|5000|80|90|920|A1|A2|A3 e-tron|AD|AL2|allroad|Asso|Avantissimo|Avus|F103|Fox|LeMans|NSU RO 80|Quattro|Rosemeyer|Sport|Steppenwolf|Super 90|Typ R|UR|V8|e-tron|e-tron Sportback";
modelsArray[75] = "Senat";
modelsArray[76] = "10 HP|100|1300|16|1800|2200|3|3-Litre|3000|3000 Mk II|3000 Mk III|7|8|A|A 110|A 125|A 135|A 30|A 35|A 40|A 55|A 60|A 70|A 90|A 95|A 99|A135|Allegro|Ambassador|FL2|Maestro|Marina|Maxi|Metro|Mini|Mini Clubman|Mini Cooper|Mini Metro|Mini Sky|Montego|Princess|Sports Convertible|Sprite|Tickford";
modelsArray[77] = "A 112|Bianchina|Primula|Stellina";
modelsArray[78] = "2160|2163|3101";
modelsArray[79] = "A3|A4";
modelsArray[80] = "Avanti|Sport Convertible|Studebaker";
modelsArray[81] = "A1|BJ2020|BJ212|Beijing BJ20|Beijing BJ80|ES 210|EU260|Huansu H2|Huansu S2|Huansu S3|Huansu S5|Jeep Cherokee 2500|Luba (XB624)|Senova";
modelsArray[82] = "Qute";
modelsArray[83] = "BD-1322";
modelsArray[84] = "1989|2018";
modelsArray[85] = "BJ 2020|BJ 2021|BJ 212";
modelsArray[86] = "Bentayga|Continental|Continental GT|Continental GTC|Continental Supersports|Continental T|Flying Spur|Mulsanne|Arnage|Azure|Brooklands|Continental Flying Spur|Eight|Exp Speed 8|Hunaudieres|Java|MK V|Mark VI|R Type|R Type Continental|S1|S2|State Limousine|T|T2|Turbo|Turbo R";
modelsArray[87] = "B|Foursome|QB|Sports|Twosome";
modelsArray[88] = "Freeclimber";
modelsArray[89] = "Vintage";
modelsArray[90] = "CD|Diplomat|SC|Type 3|Type III";
modelsArray[91] = "A3C|BZ-2001|GT|GTS|Manta";
modelsArray[92] = "1 Series|2 Series|3 Series|3 Series Gran Turismo|4 Series|5 Series|5 Series Gran Turismo|6 Series|7 Series|8 Series|M2|M3|M4|M5|M6|M8|X1|X2|X3|X3 M|X4|X4 M|X5|X5 M|X6|X6 M|X7|Z3|Z3 M|Z4|Z4 M|i3|i8|116|118|120|123|125|128|130|135|1500|1502|1600|1602|1800|1M|2 Series Active Tourer|2 Series Grand Tourer|2000|2000 C/CS|2002|2004|2800|315|316|317|318|320|3200|3200 CS|321|323|324|325|326|327|328|330|332|333|335|340|4 Series Gran Coupe|501|502|503|507|518|520|523|524|525|528|529|530|535|538|540|545|550|6 Series Gran Coupe|600|628|630|633|635|640|645|650|700|725|728|729|730|732|735|740|745|748|750|760|840|845|850|854|856|ALPINA B6 Gran Coupe|ALPINA B7|ActiveHybrid 3|ActiveHybrid 5|ActiveHybrid 7|Alpina|B7|Breyton|E3|E9|FW 27|Formula FB02|Formula One|Isetta|Just 4|Karmann Asso di Quadri|L7|LMR|M|M1|M12|M28i|M6 Gran Coupe|X Activity|X Coupe|Z1|Z18|Z22|Z8|Z9|Z9 Gran Turismo Convertible";
modelsArray[93] = "2000|BX5|BX7|Hansa 1100";
modelsArray[94] = "7.3S|M V12|ML 63 Biturbo|SV12";
modelsArray[95] = "BS2|BS4|BS6|FRV (BS2)|H230|H530|M1|M1 (BS6)|M2|M2 (BS4)|M3|M3 (BC3)|V3|V5";
modelsArray[96] = "400|401|402|403|404|405|406|407|408|409|410|411|412|450|603|Beaufighter|Blenheim|Blenheim Speedster|Brigand|Britannia|Fighter|Project Fighter|Speedster";
modelsArray[97] = "Geneva|La Joya";
modelsArray[98] = "Chiron|Veyron|EB 18-3 Chiron|EB 18-4 Veyron|EB Veyron 16.4|251|Divo|EB 110|EB 112|EB 118|EB 218|ID 90|Type 101|Type 55|Type 57|Type 68|Type 73";
modelsArray[99] = "Century|Enclave|Encore|Envision|LaCrosse|LeSabre|Lucerne|Park Avenue|Regal|Rendezvous|Verano|40|70|Blackhawk|Centieme|Centurion|Cielo|Electra|Estate|Estate Wagon|Excelle|GL8|GS|Invicta|Rainier|Reatta|Riviera|Roadmaster|Signia|Skyhawk|Skylark|Special|Super|Terraza|Wildcat|XP2000";
modelsArray[100] = "F0|F3|F3-R|F5|F6|F7|F8|F8 (S8)|Flyer|G3|G6|L3|M6|S6|e6|i6";
modelsArray[101] = "BD132J (CoCo)|BD326J (Moca)";
modelsArray[102] = "ATS|ATS Coupe|CT4|CT4-V|CT5|CT5-V|CT6|CTS|CTS Coupe|CTS-V|CTS-V Coupe|CTS-V Wagon|DTS|DeVille|Eldorado|Escalade|Escalade ESV|SRX|STS|STS-V|XT4|XT5|XT6|XTS|60|61|62|6239D|ATS-V|Allante|Aurora|BLS|Biarritz|Brougham|CTS Wagon|Calais|Catera|Cimarron|ELR|Fleetwood|Imaj|LMP|LSE|Le Mans|Park Avenue|SLS|Series 61|Series 62|Series 70|Seville|Sixty|Sixty Special|Solitaire|V Sixteen|Vizon|XLR";
modelsArray[103] = "C12";
modelsArray[104] = "FX4|FX4S";
modelsArray[105] = "1700|21|7|Beaulieu|C21|CSR|Seven|Super 7|Superlight R";
modelsArray[106] = "Benni";
modelsArray[107] = "Alsvin V7|BenBen Mini|Benni|CM-8|CM8|CS35|CS35PLUS|CS55|CS75|CX20|Chana SM8|Eado|Raeton|Star Truck|Z-Shine";
modelsArray[108] = "Flying|SUV|SUV (CS6)";
modelsArray[109] = "Ideal";
modelsArray[110] = "Aerobus|Town";
modelsArray[111] = "Amulet|Amulet (A15)|Arauca|Arrizo 3|Arrizo 7|B13|Bonus|Bonus (A13)|Bonus 3 (E3/A19)|Cowin|CrossEastar|CrossEastar (B14)|E5|Eastar|Ego|Elara|Face|Fengyun|Fora|Fora (A21)|Fulwin|Fulwin 2|IndiS|IndiS (S18D)|J1|J11|Karry|Kimo|Kimo (A1)|M11|M11 (A3)|Oriental Son (B11)|QQ|QQ6|QQ6 (S21)|QQme|Qiyun|Riich 2|Storm 2|Sweet (QQ)|Tiggo|Tiggo (T11)|Tiggo 2|Tiggo 3|Tiggo 4|Tiggo 5|Tiggo 7|Very|Very (A13)|Windcloud|Windcloud (A11)";
modelsArray[112] = "Avalanche|Aveo|Bel Air|Blazer|Blazer K5|Bolt|Camaro|Captiva|Cavalier|Chevelle|City Express|Cobalt|Colorado|Corvette|Cruze|El Camino|Equinox|Express|Express Cargo|HHR|Impala|Malibu|Monte Carlo|S-10|S-10 Pickup|Silverado 1500|Silverado 2500HD|Silverado 3500HD|Sonic|Spark|Spark EV|Suburban|Tahoe|Trailblazer|Traverse|Trax|Uplander|Volt|150|1700|210|2103|400|APV|Adventure|Aerovette|Agile|Alero|Astra|Astro|Astrovette|Beat|Beretta|Biscayne|C-10|C/K|CSV CR8|Calibra|Caprice|Celebrity|Celta|Chevette|Chevy II|Cheyenne|Citation|Citation II|Classic|Commodore|Comodoro|Corsa|Corsica|Corvair|Corvette Stingray|D20|DeLuxe|DeVille|Delray|Deluxe|Enjoy|Epica|Evanda|Fleetline|Fleetmaster|Forester|Grand Blazer|Grand Vitara|Intimidator|Ipanema|Jimny|Journey|K-20|Kadett|Kalos|Kodiak|Kommando|LUV D-MAX|Lacetti|Lanos|Lova|Lumina|Lumina APV|MW|Master|Matiz|Meriva|Metro|Montana|Monza|N200|N300|Niva|Nomad|Nova|Nubira|Omega|Onix|Opala|Optra|Orlando|Pickup|Prisma|Prizm|Relsamo|Rezzo|SS|SSR|Sabia|Sail|Samurai|Senator|Silverado|Sonora|Special DeLuxe|Spectrum|Spin|Sprint|Starcraft|Stylemaster|Swift|Tacuma|Tandem 2000|Task Force|Tavera|Tornado|Tracker|Triax|Trooper|Vectra|Vega|Venture|Veraneio|Vitara|Viva|Vivant|XP 882|XP 897 GT|XP 898|Zafira";
modelsArray[113] = "200|200 CONVERTIBLE|300|Aspen|Crossfire|Pacifica|PT Cruiser|PT Dream Cruiser|Sebring|Town & Country|Voyager|1609|1610|180|300 Letter Series|300C|300M|Airflite|Alpine|Atlantic|Avenger|Aviat|C|CCV|Centura|Charger|Chronos|Cirrus|Concorde|Conquest|Cordoba|Dart|Daytona|Delta|Drifter|Dynasty|E|ES|ESX 3|Executive|Expresso|Fifth Avenue|GS|Grand Voyager|Horizon|Howler|Hunter|Imperial|Imperial Crown|Intrepid|Java|LHS|Lancer|Laser|LeBaron|Limousine|Millenium|Nassau|Neon|New Yorker|Newport|Panel Cruiser|Phaeton|Prowler|Saratoga|Stratus|Sunbeam|Thunderbolt|Valiant|Valiant Galant|Viper|Vision|Windsor|Ypsilon160";
modelsArray[114] = "Berlingo|C-Elysee|C3|C3 AirCross|C4|C4 AirCross|C5|C5 AirCross|Jumpy|Jumper|1.6|11|15|2 CV|2CV|7|7A|AX|Activa|Ak 350|Ami|Aventure|Axel|BX||Bijou|Bx|C|C 15|C-Airdream|C-Crosser|C-Quatre|C-Triomphe|C-Zero|C1|C15|C2|C3 Picasso|C35|C4 Cactus|C4 Picasso|C4 Spacetourer|C6|C8|CX|Chanson|DS|DS3|DS4|DS5|Dispatch|Dyane|Dyane 4|Dyane 6|E-Mehari|Eco 2000|Eole|Evasion|Fukang|GS|GSA|ID|ID 19|Karin|LN|LNA|Mehari|Mini-Zup|Multispace|Nemo|Osee|Picasso|Pluriel|Rally Raid|SM|Saxo|SpaceTourer|Synergie|Traction|Traction Avant|Visa|XM|Xanae|Xantia|Xsara|Xsara Picasso|ZX";
modelsArray[115] = "V16T";
modelsArray[116] = "T Rex|T-Rex";
modelsArray[117] = "T98";
modelsArray[118] = "L-29";
modelsArray[119] = "Dokker|Duster|Lodgy|Logan|Sandero|1100|1300|1310|1320|1325|1325 Liberta|1410|2000|500|D6|Denem||Gamma|Nova|Pick-Up|Shifter|Solenza|Supernova";
modelsArray[120] = "City Leading|Shuttle|Smoothing";
modelsArray[121] = "Alpheon|Aranos|Arcadia|Brougham|Chairman|Cielo|Damas|Espero|Evanda|Fantasy|G2X|Gentra|Imperial|Joyster|Kalos|Korando|Lacetti|Lacetti Premiere|Lanos|LeMans|Leganza|Maepsy|Maepsy-Na|Magnus|Matiz|Matiz Creative|Musiro|Musso|Musson|Nexia|No 1|Nubira|Pointer|Prince|Racer|Rexton|Rezzo|Royale|Sens|Tacuma|Tico|Tocsa|Tosca|Vada|Veritas|Winstorm";
modelsArray[122] = "44|46|600|66|750|Daffodil";
modelsArray[123] = "Altis|Applause|Atrai|Ayla|Be-go|Bee|Boon|Boon Luminas|Cast|Ceria|Charade|Charmant|Compagno|Consorte|Coo|Copen|Cuore|Delta|Delta Wagon|Domino|Esse|Extol|Fellow|Feroza|Fourtrak|Fourtrak TDX|Freeclimber|Gran Max|Gran Move|Hijet|Leeza|Luxio|MAX|Materia|Mebius|Micros 3l|Midget|Mira|Mira Cocoa|Mira Gino|Mira e:S|Move|Move Canbus|Move Conte|Move Latte|Muse|Naked|Opti|Pyzar|Rocky|Rugger|SP-4|Sirion|Sonica|Sportrak|Storia|Taft|Tanto|Tanto Exe|Taruna|Terios|Terios II|Thor|Trevis|U4 B|Valera|Wake|Wildcat|Xenia|YRV|Zebra";
modelsArray[124] = "4.2|Conquest|DE 27|DE 36|DH27|DK|DS420|One-O-Four|SP250|Sovereign (XJ6)|Super Eight|V8|X300|X308|X350|XJ40|XJS";
modelsArray[125] = "Stradale";
modelsArray[126] = "Fun";
modelsArray[127] = "1000|100A Cherry|1200|1600|1800|200SX|240Z|240Z / 260Z / 280Z|260Z|280|280Z|280ZX|510|610|710|720|810|B-210|Bluebird|Cherry|Fairlady|GO|GO+|Laurel|Patrol|Pickup|Stanza|Sunny|Urvan|Violet|mi-Do|on-DO";
modelsArray[128] = "Bigua|Deauville|Guara|Longchamp|Mangusta|Pantera|Vallelunga";
modelsArray[129] = "D6";
modelsArray[130] = "DMC-12";
modelsArray[131] = "Antelope|Aurora|Cowboy|Land Crown|Plutus|Saladin|Shuttle";
modelsArray[132] = "Custom|Firedome|Fireflite";
modelsArray[133] = "3-6 Monza|3=6|Coupe|F 102|F 11|F 89|F 91|F 93|Junior|Munga|Reichklasse F8|Universal";
modelsArray[134] = "Avenger|Caliber|Caravan|Challenger|Charger|Dakota|Dart|Durango|Grand Caravan|Journey|Magnum|Neon|Nitro|RAM 1500|RAM 2500|RAM 3500|Stratus|Viper|330|400|440|600|A100|Aries|Aspen|Attitude|Brisa|Charger Daytona|Charger RT Concept|Colt|Conquest|Copperhead|Coronet|Custom|Custom Royal|D/W Series|D8|Daytona|Demon|Diplomat|Dynasty|Forza|Hemi Super 8|Intrepid|Kahuna|Lancer|M80|Matador|Maxx Cab|Mayfair|Meadowbrook|Mirada|Monaco|Omni|Phoenix|Polara|Power Box|Power Wagon|Prowler|Raider|Ram|Ram 50|Ram Van|Ramcharger|Rampage|Razor|Royal|SE|SRT Viper|Shadow|Sidewinder|Spirit|Sprinter|St. Regis|Stealth|T-Rex 6x6|Venom";
modelsArray[135] = "370|580|A30|A60|A9|AX3|AX4|AX7|Future|H30|H30 Cross|MPV|Oting|Rich|S30";
modelsArray[136] = "Assol|Condor|Orion|Orion M";
modelsArray[137] = "D8|D8 Cosworth|D8 GT|D8 GTO|D8 Zetec|S8";
modelsArray[138] = "3|3 Crossback|4|5|7|7 Crossback";
modelsArray[139] = "H3|H5";
modelsArray[140] = "GD04B";
modelsArray[141] = "Premier|SS|Summit|Talon|Vision|Vista";
modelsArray[142] = "MPV|MS-6|MS-8|MS-9|RX-7";
modelsArray[143] = "Series IV|Series V";
modelsArray[144] = "Atom|Atomata|Electrina|Electron|Rockette|TX-GT|TX-S|Zeta";
modelsArray[145] = "6371|Audi 100|Audi A6|Besturn B50|Besturn B70|Besturn B90|Besturn X40|Besturn X80|C131|D60|Jiabao|Jinn|Kun Cheng|M80|Oley|S80|Sirius S80|V2|V5|Vela|Vita|Volkswagen Bora|Volkswagen City Golf|Volkswagen Jetta|Xiali N3|Xiali N5|Xiali N7";
modelsArray[146] = "360|458 Italia|488|California|F 12 Berlinetta|F355|F430|500 F2|500 Mondial|500 Superfast|500 Testarossa|512 BB|512 BBi|512 BBi Turbo|512 F1|512 M|512 S|512 TR|550|553 F1|553 F2|575|575 Superamerica|575M|575M Maranello|599|599 GTB Fiorano|612|612 Scaglietti|625 F1|735|750|801 F1|812|812 Superfast|850|Berlinetta Boxer|C62|D 50|Dino|Dino 206 GT|Dino 208/308 GT4|Dino 246 GT|Enzo|F 2005|F1 156|F1 2000|F1 86|F1 88|F1 89|F1 90|F1 93|F310|F333|F399|F40|F50|F512 M|F55|F550|F8|F8 Tributo|FF|FF HELE|FXX K|GTC4Lusso|GTO|KS 360 Modena|LaFerrari|Maranello|Mondial|Monza SP|Mythos|P2|P5|Pinin|Portofino|Roma|Rossa|SF90 Stradale|Superamerica|Testarossa|125|125 F1|125S|126|156|158|159S|166|195|196|206|208|212|225|246|250|250 GT|250 GTE|250 GTO|250 LM|250 Mille Miglia|250 Testarossa|255 S|256 F1|275|288 GTO|306|308|312|328|328 GTB|328 GTS|330|330GT|340 America|340 F1|340 MM|340 Mexico|340 Spider|342 America|348|348 TS Targa|355 Spider|355 TS|365|365 BB|365 California|365 GT|365 GT4|365 GTB|365 GTC|365 GTC 4|365 GTS|375 America|375 Indy|375 Mille Miglia|3Z|400|400 Superamerica|410 Superamerica|412|412 GT|412 MI|412 T2|456|456M";
modelsArray[147] = "124 Spider|500|500L|500X|500e|508|600|750|850|8V|900T|Abarth|Albea|Argenta|Armadillo|Barchetta|Berline|Brava|Bravo|Brio|Cabriolet|Campagnola|Cinquecento|Coupe|Croma|Dino|Doblo|Ducato|Duna|ESV|Ecobasic|Ecobasis|Elba|Fiorino|Freemont|Fullback|Fullback I|Grand Break|Grande Punto|Idea|Legram|Linea|Marea|Marea Weekend|Marengo|Mille|Mirafiori|Multipla|OT|Palio|Palio II|Panda|Panorama|Perla|Premio|Punto|Qubo|Regata|Ritmo|Scudo|Sedici|Seicento|Siena|Spazio|Stilo|Strada|Talento|Tempra|Tipo|Tucan|Ulysse|Uno|V8|Viaggio|Vivace|X 1/9|X1-9|X1/9|1100|1200|124|124 Sport Spider|125|126|127|128|130|1300|1300/1500|131|132|133|1400|147|1500|1600|1800|1800/2100|1900|2100|2300|238";
modelsArray[148] = "Karma";
modelsArray[149] = "F";
modelsArray[150] = "Bronco|Bronco II|C-Max Energi|C-Max Hybrid|Crown Victoria|E-Series|EcoSport|Edge|Escape|Excursion|Expedition|Explorer|Explorer Sport Trac|F-Series|F-150|F-250|F-250 Super Duty|F-350|F-350 Super Duty|F-450|F-450 Super Duty|F-550|Fiesta|Fiesta ST|Five Hundred|Flex|Focus|Focus RS|Focus ST|Freestar|Freestyle|Fusion|Fusion Energi|Fusion Hybrid|GT|Model A|Mustang|Mustang Mach-E|Ranger|Taurus|Thunderbird|Transit Connect|Transit Van|Transit Wagon|Windstar|021 C|12 M|17|17M|2000|24.7|427|49|Aerostar|Anglia|Artic|Aspire|B-MAX|Bantam|C 100|C-Max|Capri|Coin|Comete|Consul|Contour|Corcel|Corsair|Cortina|Cougar|Courier|Crestline|Del Rey|Desert Excursion|Durango|E-150|E-250|E-350|Elite|Equator|Escort|Everest|Extreme EX|F|FPV BF GT|Fairlane|Fairmont|Falcon|Festiva|Figo|Freda|GPA|GPW|GT 40|GT 500|GT 70|GT40|Galaxie|Galaxy|Gran Torino|Granada|Husky|Ikon|Indigo|Ixion|Ka|Kuga|LTD|LTD Crown Victoria|LTD II|Laser|Libre|Limited|Lobo|Lotus Cortina|Lynx|M151|Mainline|Maverick|Megastar II|Meteor|Model T|Model U|Monarch|Mondeo|Mondeo ST|O21 C|Orion|Pampa|Pilot|Pinto|Popular|Prefect|Probe|Puma|RS 200|Ranchero|Royale|S-Max|ST 460|Saetta|Scorpio|Shelby|Shelby GR-1 Concept|Shelby GT 500|Shelby GT500|Sierra|Skyliner|Spectron|Station Wagon|Street KA|Synergy 2010|TE-50|TL-50|TS-50|Taunus|Taurus X|Telstar|Tempo|Territory|Think|Think Neighbor|Tonka|Torino|Tourneo|Tourneo Connect|Tourneo Courier|Tourneo Custom|Tracer|Triton|Urban Explorer|V8|Vedette|Verona|Versailles|XR 8 Xplod|Zephyr|Zodiac|e-Ka";
modelsArray[151] = "Gratour|Midi|Sauvana|Tunland";
modelsArray[152] = "125p|126p|127p|132p|Polonez";
modelsArray[153] = "6500 (Land King)|6500 Land King";
modelsArray[154] = "Trumpchi GS8";
modelsArray[155] = "12 ZIM|13 Chaika|13 ╬ô├╢┬╝Γö¼Γò£Chaika╬ô├╢┬╝╬ô├▓├╣|14 Chaika|14 ╬ô├╢┬╝Γö¼Γò£Chaika╬ô├╢┬╝╬ô├▓├╣|18|21 Volga|21 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|22|22 Volga|22 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|2308 Ataman|2308 ╬ô├╢┬╝Γö¼Γò£Ataman╬ô├╢┬╝╬ô├▓├╣|2330 ╬ô├╢┬╝Γö¼Γò£Tigr╬ô├╢┬╝╬ô├▓├╣|233001 Tigr|24|24 Volga|24 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|24-10|3102 Volga|3102 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|310221 Volga|31029 Volga|31029 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|3103 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|3105 Volga|3105 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|3110|3110 Volga|3110 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|31105 Volga|31105 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|3111|3111 ╬ô├╢┬╝Γö¼Γò£Volga╬ô├╢┬╝╬ô├▓├╣|46|61|64|67|69|A|M-1|M-12 ZiM|M-20|M-20 Pobeda|M-20 ╬ô├╢┬╝Γö¼Γò£Pobeda╬ô├╢┬╝╬ô├▓├╣|M-72|M1|M20|Oct-73|Siber|Sobol|Volga|Volga Siber";
modelsArray[156] = "Atlas|Beauty Leopard|CD|CK|CK (Otaka)|Ck1|Emgrand|Emgrand 7|Emgrand EC7|Emgrand EC8|Emgrand GT|Emgrand X7|FC|FC (Vision)|FY11|GC6|GC9|GE|GS|GX7|HQ|Haoqing|LC|LC (Panda)|LC (Panda) Cross|MK|MK Cross|MR|Otaka|SC7|SL|SX11|TX4|Uliou|Vision";
modelsArray[157] = "G70|G80|G90";
modelsArray[158] = "Metro|Prizm|Spectrum|Storm|Tracker";
modelsArray[159] = "G|G11|G12|G15|G20|G21|G27|G3|G32|G33|G34|G4|G40";
modelsArray[160] = "Acadia|Canyon|Envoy|Savana|Sierra 1500|Sierra 2500HD|Sierra 3500HD|Sonoma|Terrain|Yukon|Yukon XL|Firebird|Jimmy|Safari|Sierra|Syclone|Terra 4|Terracross|Terradyne|Typhoon|Vandura";
modelsArray[161] = "Troy";
modelsArray[162] = "Roadster";
modelsArray[163] = "Madison";
modelsArray[164] = "CoolBear|Cowry|Cowry (V80)|Deer|Florid|Hover|Hover H3|Hover H5|Hover H6|Hover M1 (Peri 4x4)|Hover M2|Hover M4|Pegasus|Peri|Safe|Sailor|Sing RUV|Socool|Steed|Voleex C10|Voleex C10 (Phenom)|Voleex C20R|Voleex C30|Voleex C50|Voleex V80|Wingle|Wingle 3|Wingle 5|X240";
modelsArray[165] = "Bliss|Dacota|Fox|Rocky|Shuttle|Target|Vertus";
modelsArray[166] = "Brio|Junyi|Lobo|Luzun|Minyi|Princip|Saibao|Saima|Sigma|Simbo|Zhongyi";
modelsArray[167] = "2|3|7|CX20|Family|Family F7|Freema|Fstar|H2|M11|M3|M6|M8|S3|S5|S7";
modelsArray[168] = "Rekord|Typ 13";
modelsArray[169] = "F7|F7x|H2|H6|H6 Coupe|H8|H9";
modelsArray[170] = "B21|Boliger|Laville";
modelsArray[171] = "Ambassador|Contessa|Lancer|Pajero";
modelsArray[172] = "K6";
modelsArray[173] = "Adventra|Apollo|Astra|Barina|Barina Spark|Belmont|Berlina|Brougham|Calais|Camira|Caprice|Captiva|Clubsport|Colorado|Combo|Commodore|Crewman|Cruze|Drover|EH|EJ|EK|Epica|FB Special|FC|FE|FJ|FX|Frontera|GTS|GTS-R|Gemini|HB|HD|HR|HRT|HSV|Jackaroo|Kingswood|Malibu|Maloo|Monaro|Monterey|Nova|Piazza|Rodeo|Sandman|Senator|Statesman|Suburban|Sunbird|Tigra|Torana|UTE|Vectra|Viva|Volt|XU 6|Zafira";
modelsArray[174] = "Accord|Accord Crosstour|Accord Hybrid|CR-V|CR-Z|Civic|Crosstour|Element|Fit|HR-V|Insight|Odyssey|Passport|Pilot|Ridgeline|S2000|1300|145|Acty|Airwave|Amaze|Argento Viva|Ascot|Ascot Innova|Avancier|Ballade|Beat|Brio|CR-X|Capa|City|Civic Del Sol|Civic Ferio|Civic Type R|Concerto|Crider|Crossroad|Domani|EV|Edix|Elysion|F1|FCX|FCX Clarity|FR-V|Fit Aria|Fit Shuttle|Freed|Fuya Jo|Grace|HP-X|Horizon|Inspire|Integra|Integra SJ|J-VX|Jade|Jazz|Lagreat|Legend|Life|Logo|MDX|MR-V|Mobilio|Mobilio Spike|Model X|N III|N-BOX|N-One|N-Series|N-WGN|N360|NSX|Orthia|Partner|Prelude|Quint|Quintet|RafagaS500|S600|S660|S800|SSM|Saber|Shuttle|Spirior|Step Wagon|Stepwgn|Stream|Street|That|That's|Today|Torneo|Vamos|Vezel|Vigor|Z|Zest";
modelsArray[175] = "830|853";
modelsArray[176] = "Antelope|Landscape|Plutus|Santa Fe";
modelsArray[177] = "Deluxe Eight|Hornet|Italia Coupe|Metropolitan|Super Jet|Super Six|Super Wasp";
modelsArray[178] = "Hawk|Pullman|Sceptre|Super Snipe";
modelsArray[179] = "H1|H2|H3";
modelsArray[180] = "Albayc╬ô├╢┬úΓö¼├¡n";
modelsArray[181] = "Accent|Azera|Elantra|Elantra GT|Equus|Genesis|Genesis Coupe|Ioniq|Kona|Palisade|Santa Fe|Santa Fe Sport|Sonata|Sonata Hybrid|Tucson|Veloster|Venue|Veracruz|Amica|Aslan|Atos|Avante|Avega|Centennial|Click|Clix|Creta|Dynasty|Entourage|Eon|Euro 1|Excel|Galloper|Getz|Grace|Grand Starex|Grandeur|H1|H100|H200|HB20|HCD-7|HCD-III|HED-5|HED-6|Langdong|Lantra|Lavita|Marcia|Matrix|Maxcruz|Neos|Panel Van|Pony|Portico|Presto|Rohens|S-Coupe|Santamo|Santro|Santro Xing|Satellite|Scoupe|Solaris|Sonica|Starex|Stellar|TB|TQ|Terracan|Tiburon|Tipper|Trajet|Tuscani|Verna|XG|XG 300|XG 350|i10|i20|i25|i30|i30 N|i40|i45|ix20|ix35|ix55";
modelsArray[182] = "EX|FX|G25|G35|G37|JX|M|Q50|Q60|Q70|QX30|QX4|QX50|QX56|QX60|QX70|QX80|G|G20|I|I30|I35|IPL|J|J30|Q|Q30|Q40|Q45|Q60 Convertible|Q60 Coupe|QX";
modelsArray[183] = "950|990|A 40|A40S|C|Elba|IM 3|J4|Koral|Mille|Mini|Small|Turbo De Tomaso";
modelsArray[184] = "Travelall";
modelsArray[185] = "S1";
modelsArray[186] = "Bardo|Dena|Paykan|Runna|Sahra|Samand|Sarir|Soren";
modelsArray[187] = "Commendatore 112i|Imperator|Imperator 108i|Spyder";
modelsArray[188] = "117|3.1|4200|Alterra|Amigo|Ascender|Aska|Axiom|Bellel|Bellett|Bighorn|Campo|Como|Crosswind|D-Max|Fargo|Fargo Filly|Filly|Florian|Frontera|Fuego|Gemini|Hombre|I-280|I-290|I-350|I-Mark|Impulse|KB|Kai|MU|MU-7|MU-X|Midi|Minx|New Bellel|Oasis|Panther|Piazza|Rodeo|Statesman de Ville|Stylus|TF (Pickup)|Trooper|VX-02|VX4|VehiCross|Vehicross|WFR|Wasp|Wizard|ZXS|i-Series";
modelsArray[189] = "Aspid|Buron|Columbus|Formula 4|M12|Machimoto|Medusa|Nazca|Tapiro|Twenty Twenty|Visconti|Volta";
modelsArray[190] = "Massif";
modelsArray[191] = "2125|2126|21261|2715|2717|Moskvich-412";
modelsArray[192] = "J2|J2 (Yueyue)|J3 (Tongyue Tojoy)|J4 (Heyue A30)|J5|J5 (Heyue)|J6 (Heyue RS)|J7 (Binyue)|M1 (Refine)|M5|Refine|Rein|S1 (Rein)|S3|S5|S5 (Eagle)|S7|T6|iEV7S";
modelsArray[193] = "E-Pace|F-Pace|F-Type|I-Pace|S-Type|XE|XF|XJ|XK|XK8|XKA|XKR|XS|220|240|240/340|3.8|420|420G|C-Type|Concept Eight|E-Type|Kensington|MK 10|MK II|MK IV|MK IX|MK V|MK VII|MK VIII|MK X|Mark|Mark 2|Mark IV|Mark IX|R Coupe|R-D6|SS|Sovereign|Type E|Type S|Type-C|Vanden Plas|X-300|X-Type|X400|XFR|XJ-13|XJ220|XJ6|XJ8|XJR|XJR-11|XJR-15|XJS";
modelsArray[194] = "Cherokee|Commander|Compass|Gladiator|Grand Cherokee|Liberty|Patriot|Renegade|Wrangler|2500|CJ|CJ2|CJ2A|CJ3A|CJ5|CJ7|Comanche|Commando|Dakar|Grand Cherokee SRT|Grand Wagoneer|Icon|Jeepster|MB|Station Wagon|Varsity|Wagoneer|Willys|Willys 2";
modelsArray[195] = "4-Litre|541|CV-8|FF|Healey|Interceptor|Jensen-Healey|S-V8|SP|SV-8|Straight 8";
modelsArray[196] = "Alto|Chuanqi|City Spirit";
modelsArray[197] = "Haise";
modelsArray[198] = "Baodian|Baowei|X6|Yuhu|Yusheng";
modelsArray[199] = "2317";
modelsArray[200] = "Cadenza|Forte|K900|Niro|Optima|Optima Hybrid|Rio|Rondo|Sedona|Seltos|Sorento|Soul|Soul EV|Spectra|Sportage|Stinger|Telluride|Avella|Besta|Borrego|Brisa|Capital|Carens|Carnival|Carstar|Ceed|Ceed GT|Cerato|Clarus|Concord|Credos|Elan|Enterprise|Joice|K2700|K3|K5|K7|K9|KCV-4|Lotze|Magentis|Mentor|Mohave|Morning|Opirus|Parktown|Picanto|Potentia|Pregio|Pride|Pride Wagon|ProCeed|Quanlima|Quoris|Ray|Retona|Sephia|Sephia II|Shuma|Shuma II|Spectra5|Stonic|Towner|Venga|Visto|X-Trek|XCeed|XedosAmanti";
modelsArray[201] = "Agera|CC8S|CCR|CCX|CCXR|One:1|Regera";
modelsArray[202] = "X-Bow";
modelsArray[203] = "110|111|1111 Oka|112|117|119|1200|1300|1600|21|2101|2102|2103|2104|2105|2106|2107|2108|2109|21099|2110|2111|2112|2113|2114|2115|2120 Nadezhda|2121 (4x4)|2123|2129|2131 (4x4)|2328|2329|Calina 1118|EL Lada|Granta|Kalina|Largus|Natacha|Niva|Oka|Priora|Revolution|Riva|Roadster|Samara|Samara II|Tarzan|Vaz|Vesta|XRAY";
modelsArray[204] = "Aventador|Gallardo|Huracan|Urus|350 GT|350/400 GT|350GT|400|400GT||Bravo|Cala|Centenario|Concept S|Countach|Diablo|Egoista|Egoista I|Espada|Flying Star|Islero|Jalpa|Jarama|LM|LM001|LM002|Marco Polo|Marzal|Miura|Murcielago|Portofino|Project P140|Reventon|Sesto Elemento|Silhouette|Urraco|Veneno";
modelsArray[205] = "037 Rallye|2000|A 112|A112|Appia|Aprilia|Ardea 3|Aurelia|Beta|D 50|Dedra|Delta|Dialogos|ECV|Flaminia|Flavia|Fulvia|Gamma|Hit|Hyena|Ionos|K|Kappa|Lambda|Lybra|Megagamma|Monte Carlo|Montecarlo|Musa|Nea|Phedra|Prisma|Rally 037|Scorpion|Stratos|Stratos HF|Thema|Thesis|Trevi|Voyager|Y|Y10|Ypsilon|Zeta";
modelsArray[206] = "Defender|Discovery|Discovery Sport|Freelander|LR2|LR3|LR4|Range Rover|Range Rover Evoque|Range Rover Sport|Range Rover Velar|88|A 109|Discovery 3|I|Range Rover Evoque Convertible|Series I|Series II|Series III109";
modelsArray[207] = "Fashion (CV9)|Forward|H9|X5|X6|X7";
modelsArray[208] = "CT|ES|ES 300h|ES 350|GS 350|GS F|GX|GX 470|HS|IS|IS 250|IS 350|LC|LX|LX 570|NX|NX 200t|RC|RC 350|RC F|RX|RX 350|RX 450h|SC|UX|CT 200h|Daytona|FLV|GS|GS 450h|IS 250 C|IS 350 C|IS F|LF-C|LFA|LM|LS|LS 460|LS 600h L|Minority Report|NX 300h";
modelsArray[209] = "Leopard";
modelsArray[210] = "Breez|Breez (520)|Cebrium|Cebrium (720)|Celliya|Celliya (530)|Foison|Murman|Murman (820)|MyWay|Smily|Solano|X50|X60|X70|X80";
modelsArray[211] = "JS 51";
modelsArray[212] = "Aviator|Continental|Corsair|LS|MKC|MKS|MKT|MKX|MKZ|Nautilus|Navigator|Town Car|Blackwood|Capri|Continental Mark Series|Cosmopolitan|Custom|K-Series|MK 9|Mark III|Mark IV|Mark LT|Mark VI|Mark VII|Mark VIII|Mark X|Premiere|Sentinel|Versailles|Zephyr";
modelsArray[213] = "EC30";
modelsArray[214] = "C 1000|Sirius|Testa de Oro";
modelsArray[215] = "11|16|2-Eleven|25|33|340 R|340R|49|7|72|79|Carlton|Eclat|Elan|Elise|Elite|Emme|Esprit|Etna|Europa|Europa S|Evora|Excel|Exige|Extreme|GT 1|M250|Omega|Seven|XI Le Mans";
modelsArray[216] = "TX";
modelsArray[217] = "1302|1302 Volyn|967|969";
modelsArray[218] = "Air Concept";
modelsArray[219] = "5|6|7|Luxgen5|Luxgen7|Luxgen7 MPV|Luxgen7 SUV|U6 Turbo|U7 Turbo";
modelsArray[220] = "Armada|Bolero|CJ-3|CL|Commander|MM|MM 540/550|MM 775|Marshal|NC 640 DP|Scorpio|Verito|Voyager|Xylo";
modelsArray[221] = "1500|1600|1800|2 Litres|GT|GTS|LM 400|LM 500|Le Mans 500|Mantara|Mantis|Mantula|Marcasite|Mini Marcos|TS|TS500|TSO|Ugly Duckling";
modelsArray[222] = "5Exi|Berlinetta|RoadSter|Sportster";
modelsArray[223] = "B1|B2";
modelsArray[224] = "1000|800|Alto|Baleno|Esteem|Gypsy|Omni|Versa|Wagon R|Zen";
modelsArray[225] = "Ghibli|GranTurismo|Levante|Quattroporte|124|150S|151|228|250|300|3200 GT|3500|3500 GT|350S|4|420|4200 GT|420M|430|450|4CL|4CLT|5000 GT|6C|8|8CL|8CLT|A6|A6G|A6GCM|A6GCS|Auge|Barchetta Straale|Barchetta Stradale|Bi Turbo|Biturbo|Boomerang|Bora|Chubasco|Coupe|GT|GT 3500|GranTurismo 3500|GranCabrio|GranSport|GranTurismo Convertible|Indy|Karif|Khamsin|Kubang|Kyalami|MC12|Merak|Mexico|Mistral|Racing|Royale|Sebring|Shamal|Simun|Spider 3500|Spyder|Tipo 60|Tipo 61|Tipo 64|Tipo 65|V8 GranSport";
modelsArray[226] = "Bagheera|D Jet|M25|M530|M630|M650|M660|M670|M72|Murena|Rancho";
modelsArray[227] = "57|62|Exelero|Landaulet|SW|Zeppelin";
modelsArray[228] = "CX-3|CX-30|CX-5|CX-7|CX-9|Mazda2|Mazda3|Mazda5|Mazda6|MazdaSpeed3|MX-5 Miata|RX-8|Tribute|Capella|Carol|Chante|Chantez|Clef|Cosmo|Cronos|Demio|Drifter|DrifterX|Efini MS-6|Efini MS-8|Efini MS-9|Etude|Eunos|Eunos 100|Eunos 300|Eunos 500|Eunos 800|Eunos Cosmo|Familia|Flair|Flair Crossover|Flair Wagon|GLC|Kusabi|Lantis|Laputa|Levante|Luce|MPV|MS-6|MS-8|MS-9|MX|MX-3|MX-5|MX-6|MX-Flexa|Marathon|Miata|Midge|Millenia|Montrose|Navajo|Persona|Porter|Porter Cab|Premacy|Proceed|Proceed Levante|Proceed Marvie|Protege|R 360|R-100|R100|R360|RX 4 Coupe|RX Evolv|RX-01|RX-2|RX-3|RX-4|RX-5|RX-7|RX-Evolv|Revue|Roadpacer AP|Roadster|Rustler|SW-X|Scrum|Sentia|Spiano|Sport Wagon|Verisa|Washu|Xedos 6|Xedos 9|1000|110 S|121|1300|3 MPS|323|6 MPS|616|626|787|808|818|929|AZ|AZ-1|AZ-Offroad|AZ-Wagon|Activehicle|Allegro|Atenza|Autozam AZ-3|Axela|B-Series|B2300|B2500|B3000|B4000|BT-50|Biante|Bongo|Bongo Brawny|Bongo Friendee|Bravo|Brown Collection Verisa|CU-X";
modelsArray[229] = "Crossblade|ForFour|ForTwo|Silverpulse|Smart|Smart & Pulse City Coupe";
modelsArray[230] = "570S|600LT|720S|GT|540C|570GT|650S|650S Coupe|650S Spider|675LT|F1|F1 GTR|F1 LM|M10|M14|M19|M1C|M21|M23|M8E|MP4|MP4-12C|P1|Senna";
modelsArray[231] = "Club|Monte Carlo|Track";
modelsArray[232] = "A-Class|AMG GT|C-Class|CL-Class|CLA-Class|CLK-Class|CLS-Class|E-Class|G-Class|GL-Class|GLA-Class|GLB-Claass|GLC-Class|GLE-Class|GLK-Class|GLS-Class|M-Class|R-Class|S-Class|SL-Class|SLC-Class|SLK-Class|Sprinter|170 V|170S|180|190|190 (W201)|190 SL|200|219|220|230|230.4|240|250|260|280|300|300B|300D|300S|320|350|380|400|420|450|500|560|600|770|B-Class|Bionic Car Concept|CLC-Class|CLK|CW|Citan|F200|F300 Life Jet|MCC|ML|Ponton|SC|SE|SLR McLaren|SLS|SLS AMG|SLS AMG GT|Simplex|Studie|Swatch|T|T V-12|Type 300|V-Class|Vaneo|Version Longue|Viano|Vision|Vito|W 110|W 111|W 123 Coupe|W 136|W 196|W100|W105|W108|W110|W111|W114|W115|W120|W121|W123|W124|W128|W136|W142|W186|W188|W189|W191|W29|X-Class";
modelsArray[233] = "Antser|Bobcat|Brougham|Capri|Colony Park|Comet|Commuter|Cougar|Custom|Cyclone|Eight|El Gato|Grand Marquis|LN7|Lynx|M-47|MC4|Marauder|Mariner|Marquis|Medalist|Meteor|Milan|Monarch|Montclair|Montego|Monterey|Mountaineer|Mystique|Park Lane|Roadster|S-55|Sable|Topaz|Tracer|Turnpike Cruiser|Villager|Voyager|Zephyr";
modelsArray[234] = "Metrocab I|Metrocab II (TTT)|Taxi";
modelsArray[235] = "1100|1100/1300|3|350|5|550|6|750|A|B|C|EX-E|F|GS|MGA|MGB|MGC|Maestro|Magnett|Magnette|Metro|Midget|Montego|RV8|Rover|TD|TD Midget|TF|X10|X20|X80|XPower|Xpower SV|YB|ZR|ZS|ZT|ZT-T";
modelsArray[236] = "F8C|M.Go|M8|MC|Virgo";
modelsArray[237] = "TF 1800";
modelsArray[238] = "Cooper|Cooper Clubman|Cooper Countryman|Cooper Coupe|Cooper Paceman|Cooper Roadster|Cooper S|MK I|MK II|MK III|MK IV|MK V|MK VI|MK VII|One";
modelsArray[239] = "Eclipse|Eclipse Cross|Endeavor|Galant|Lancer|Lancer Evolution|Mirage|Outlander|Outlander Sport|3000 GT|3000GT|380|500|A10|ASX|Adventure|Airtrek|Aspire|Attrage|Bravo|Carisma|Celeste|Challenger|Chariot|Colt|Cordia|Debonair|Delica|Delica D:2|Delica D:3|Delica D:5|Delica Space Gear|Diamante|Dignity|Dingo|Dion|EK Wagon|Emeraude|Eterna|Expo|FTO|Field Guard|Freeca|Fuzion|GTO|Galant Fortis|Gaus|Grandis|Grunder|HSR-V|Jeep|Jeep J|L 200|L100|L200|L300|L400|Lancer Cargo|Lancer Ralliart|Lancer Sportback|Legnum|Libero|Magna|Magnum|Maven|Mighty Max|Minica|Minicab|Montero|Montero Sport|Montero iO|Mum 500|Nessie|Nimbus|Pajero|Pajero Junior|Pajero Mini|Pajero Pinin|Pajero Sport|Pajero iO|Pistachio|Precis|Proudia|RPM 7000|RVR|Raider|SSS|SST|SSU|SUP|SUW|Sapporo|Savrin|Scorpion|Shogun|Shogun Pinin|Shogun Sport|Sigma|Space Gear|Space Liner|Space Runner|Space Star|Space Wagon|Starion|Strada|Toppo|Town BOX|Towny|Tredia|Triton|Valley|Verada|Warrior|XPANDER|Zinger|eK|eK Active|eK Classic|eK Custom|eK Space|eK Sport|i|i-MiEV";
modelsArray[240] = "Classic Type F|Galue|Galue 204|Himiko|Le-Seyde|Like|MC-1|Nouera|Orochi|Ray|Rock Star|Ryoga|Ryugi|Viewt|Yuga|Zero 1";
modelsArray[241] = "GTB Centenaire";
modelsArray[242] = "2000|375|400|Berlinetta|Hai 375|Hai 450|Hai 650|High Speed 375|Military 250 Z Zivil|Safari|Sierra|Tiara";
modelsArray[243] = "1100|1200|127|2500|500|750|750S Berlina|850|850S|Coupe|Golden|La Cita|Panoramica|S Coupe|SS|Spider Turismo";
modelsArray[244] = "3 Wheeler|4|4 Plus 2100|4 Seater|4/4|44|Aero|Aero 8|Aero Coupe|Aero SuperSports|AeroMax|Aeromax|F Super|Plus 4|Plus 4 Plus|Plus 8|Roadster";
modelsArray[245] = "1100|1800|Cowley|Eight|Isis|Marina|Mini Moke|Minor|Oxford|Six|Ten Four|Traveller";
modelsArray[246] = "2136|2137|2138|2140|2141|2142|2315|2335|400|401|402|403|407|408|410|411|412|423|424|426|427|430|Duet|Ivan Kalita|Knyaz Vladimir|Svyatogor|Yuri Dolgoruky";
modelsArray[247] = "Ambassador";
modelsArray[248] = "350Z|370Z|Altima|Armada|Cube|Frontier|GT-R|Juke|Kicks|Leaf|Maxima|Murano|NV|NV200|Pathfinder|Quest|Rogue|Sentra|Titan|Xterra|100 NX|100NX|110|1400|180SX|200 SX Silvia|200SX|211|240 C|240SX|260 ZX|270 R|280ZX|300 ZX|300ZX|400 R|70|AA-X|AD|AXY|AZ-1|Almera|Almera Classic|Almera Tino|Alpha|Altra|Aprio|Auster|Avenir|Axxess|Bassara|Be-1|Bluebird|Bluebird Maxima|Bluebird Sylphy|C 52|CQ-X|Caravan|Cedric|Cefiro|Chappo|Cherry|Cherry Cab|Cima|Clipper|Clipper Rio|Commercial|Crew|DS-2|Datsun|Dayz|Dayz Roox|Dualis|E20|El Grand|Elgrand|Exa|Expert|Fairlady|Fairlady Z|Figaro|Fuga|Fusion|Gazelle|Gloria|Grand Livina|Hardbody|Hikari|Homy|Hustler|HyperMini|Hypermini|Ideo|Interstar|Juke Nismo|Junior|Kix|Kubistar|Lafesta|Langley|Largo|Latio|Laurel|Laurel Spirit|Leopard|Liberta Villa|Liberty|Livina|Lucino|M|MM|March|Micra|Mid4|Mistral|Moco|Multi|Murano CrossCabriolet|NP200|NP300|NV100 Clipper|NV350|NV350 Caravan|NX Coupe|Navara|Navara (Frontier)|Note|Otti|Paladin|Pao|Patrol|Pickup|Pino|Pintara|Pixo|Platina|Prairie|Presage|Presea|President|Primastar|Primera|Pulsar|Qashqai|Qashqai+2|R 390 GT1|R 391|R'nessa|Rasheen|Roox|SUT|Safari|Santana|Sedan|Trailrunner|Tsuru|Urvan|Vanette|Versa|Versa Note|Violet|Violet Liberta|Wingroad|X-Trail|XVL|Z|Zaroot";
modelsArray[249] = "M10|M12|M12 GTO|M14|M15|M600";
modelsArray[250] = "1000|1000 C|1000 LS|1200|Autonova|Prinz|Prinz I|Prinz IV|Ro 80|Sport|Wankel Spider";
modelsArray[251] = "522";
modelsArray[252] = "442|442 Indy Pace Car|66|88|98|Achieva|Aerotech|Alero|Anthem|Aurora|Bravada|Custom Cruiser|Cutlass|Cutlass Calais|Cutlass Ciera|Cutlass Supreme|Delta 88|Deluxe 88|Dynamic 88|Eighty-Eight|Firenza|Incas|Intrigue|Jetfire|LSS|Ninety-Eight|O4|Omega|Profile|Recon|SS|Series 60|Series 70|Silhouette|Starfire|Super 88|Toronado|Trofeo|Vista Cruiser";
modelsArray[253] = "Astra|Corsa|Crossland X|Grandland X|Insignia|Mokka|Vivaro|Zafira|Zafira Life|Adam|Admiral|Agila|Ampera|Antara|Arena|Ascona|Astra OPC|Bedford Blitz|Blazer|Cabrio|Calais|Calibra|Campo|Cascada|Combo|Commodore|Concept M|Corsa OPC|Diplomat|Filo|Frogster|Frontera|GT|Insignia OPC|Kadett|Kapitan|Karl|Kraftfahrzeug|Lotus Omega|M|Manta|Meriva|Meriva OPC|Monterey|Monza|Movano|Olympia|Omega|P 1200|P4|Record|Rekord|Senator|Signum|Sintra|Speedster|Super 6|Super Six|Tigra|Trixx|Vectra|Vectra OPC|Vita|Zafira OPC";
modelsArray[254] = "2500 GT";
modelsArray[255] = "12|200/250|Carribean|Clipper|Custom Eight|One-Twenty|Patrician|Twelve";
modelsArray[256] = "Huayra|Zonda|Zonda C12";
modelsArray[257] = "AIV|Esperante|Q9|Roadster";
modelsArray[258] = "Saloon";
modelsArray[259] = "Alza|Kancil|Kelisa|Kembara|Kenari|MyVi|Nautica|Nippa|Viva";
modelsArray[260] = "108|208|308|508|2008|3008|5008|1007|104|106|107|201|202|203|204|205|205 GTi|206|207|208 GTi|3008|301|302|304|305|306|307|308 GTi|309|4007|4008|401|402|403|404|405|406|407|408|504|504D|505|604|605|607|806|807|907|Bipper|Boxer|Escapade|Expert|H2O|Hoggar|Kart Up|Nautilus|Oxia|Pars|Partner|Persia|Peugette|Promethee|Proxima|Quasar|RC|RCZ|Rifter|Roa|Sesame|Touareg|Traveller|Tulip|Type 202|Vroomster|iOn";
modelsArray[261] = "Cevennes|Hemera|Speedster II";
modelsArray[262] = "Grand C4 Picasso|Grand Espace|Grand Scenic|Porter";
modelsArray[263] = "33|Argento|Coupe|Enjoy|Eta|Hit|Metrocubo|Rossa|Start";
modelsArray[264] = "Acclaim|Barracuda|Belvedere|Breeze|Cambridge|Caravelle|Colt|Fury|GTX|Gran Fury|Horizon|Laser|Neon|Pronto Cruiser|Pronto Spyder|Prowler|Reliant|Road Runner|Satellite|Sundance|Superbird|Trail Duster|Turismo|VIP|Valiant|Volare|Voyager";
modelsArray[265] = "1000|6000|Acadian|Astre|Aztek|Banshee|Bonneville|Catalina|Chieftain|Executive|Fiero|Firebird|Firefly|Firehawk|G3|G4|G5|G6|G8|GPX|GTO|Grand AM|Grand Am|Grand Prix|Grand Safari|Grand Ville|Grande Parisienne|Laurentian|LeMans|Lemans|Matiz|Montana|Parisienne|Pathfinder|Phoenix|Piranha|Pursuit|Rageous|SV 6|Salsa|Solstice|Star Chief|Stinger|Strato Chief|Streamliner|Sunbird|Sunburst|Sunfire|Sunrunner|Super Chief|Tempest|Torpedo|Torrent|Trans Am|Trans Sport|Ventura|Ventura II|Vibe|Wave";
modelsArray[266] = "718|718 Spyder|911|Boxster|Carrera GT|Cayenne|Cayman|Cayman GT4|Macan|Panamera|Taycan|1300|3400|356|550 A|804|904|906|907|908|910|911 GT2|911 GT3|911 R|912|914|917|918|918 Spyder|924|928|930|935|936|944|959|962|964|965|968|993|996|DP|GT2|GT3|Karisma|Kremer 935 K3|Mega Cabriolet Biturbo|Pan Americana|RGT|Tapiro";
modelsArray[267] = "118NE|Padmini";
modelsArray[268] = "300|400|Arena|Exora|Gen|Gen-2|Impian|Inspira|Juara|Perdana|Persona|Persona |Persona 300 Compact|Persona 400|Preve|Putra|Saga|Saloon|Satria|Savvy|Tiara|Waja|Wira (400 Series)";
modelsArray[269] = "G-modell|Pinzgauer";
modelsArray[270] = "AM4|AMV|GTB|GTE";
modelsArray[271] = "3";
modelsArray[272] = "Mangusta";
modelsArray[273] = "1500|2500|3500|CV Tradesman|ProMaster City|Promaster Cargo Van|Promaster Window Van";
modelsArray[274] = "Ambassador";
modelsArray[275] = "Gentra|Matiz|Nexia|Nexia R3|R2|R4";
modelsArray[276] = "Bug|GTC|Kitten|Rebel|Regal|SE6|SE7|Sabre 4|Sabre Six|Scimitar|Scimitar SS1|Scimitar Sabre";
modelsArray[277] = "Tropica Roadster";
modelsArray[278] = "Clio|Captur|Kadjar|Megane|Megane RS|Trafic|Zoe|10|11|12|14|15|16|17|18|19|20|21|25|3|30|4|4CV|5|6|7|8|9|Alaskan|Alliance|Alpine|Argos|Arkana|Avantime|Be Bop|Caravelle|Celtaquatre|Clio RS|Clio V6|Colorale|Dauphine|Dauphine Gordini|Dokker|Duster|Ellypse|Espace|Espider|Etoile|Express|Fifties|Floride|Fluence|Fregate|Fuego|Grand Espace|Grand Scenic|Helem|Juva|Juvaquatre|Kangoo|Kaptur|Koleos|Kwid|Laguna|Latitude|Le Car|Lodgy|Logan|Ludo|Modus|Monaquatre|P55|Primaquatre|Pulse|R5|RE|RS 11|Racoon|Rodeo|Safrane|Sandero|Sandero RS|Scala|Scenic|Scenic II|Siete|Spider|Sport Spider|Super 5|Symbol|TXE|Talisman|Thalia|Torino|Twingo|Twizy|Vel Satis|Vivastella|Wind|Zo|Zoom";
modelsArray[279] = "QM5|QM6|SM3|SM5|SM7";
modelsArray[280] = "Beast|Tank";
modelsArray[281] = "1.5|2.6|4|Apr-68|Elf|Kestrel|MR II|One-Point-Five|Pathfinder|RM A|Two Point Six";
modelsArray[282] = "C Two|Concept_One";
modelsArray[283] = "Chopster";
modelsArray[284] = "Cullinan|Dawn|Ghost|Ghost Series II|Phantom|Wraith|100|20/25|Camargue|Corniche|Flying Spur|Park Ward|Phantom Drophead Coupe|Silver Cloud|Silver Cloud II|Silver Dawn|Silver Ghost|Silver Seraph|Silver Shadow|Silver Spirit|Silver Spirit II|Silver Spur|Silver Wraith";
modelsArray[285] = "Lightning";
modelsArray[286] = "10|100|100 1.4 D|14|16|200|200 Series|2000|214|216|220|25|2600|3.5|3.5 Litre|3500|400|400 Series|414i|416i|420|45|600|600 Series|620i|75|800|800 Series|820|825|825i|City|CityRover|Land Rover Discovery|MGF|Maestro|Metro|Mini|Mini MK|Montego|P2|P3|P4|P5|P5B|P6|Quintet|Range Rover|SD1|Streetwise|Vitesse";
modelsArray[287] = "3-Sep|5-Sep|600|750|9-2X|9-2x|9-3|9-4X|9-5|9-7X|9-X|90|900|9000|900i|92|92B|93|94|95|96|99|MC|Sonett|Sonett II|Sonett III";
modelsArray[288] = "Saina|Tiba";
modelsArray[289] = "S7";
modelsArray[290] = "SM|SM3|SM5|SM7";
modelsArray[291] = "PS-10|PS10";
modelsArray[292] = "Astra|Aura|Curve|ION|L|L-Series|LS|LW|Outlook|Relay|S-Series|SC|SL|SW|Sedan|Sky|Sports Coupe|VUE|Vue";
modelsArray[293] = "FR-S|FR-S Convertible|T2B|iA|iM|iQ|tC|xA|xB|xD";
modelsArray[294] = "Alhambra|Arona|Ateca|Ibiza|Leon|Tarraco|1200/1430 Sport|124|127|128|131|132|133|1400|1430|1500|600|600/800|850|Altea|Arosa|Bolero|Cordoba|Exeo|Formula|Fura|Ibiza Cupra|Inca|Inca Kombi|Leon Cupra|Malaga|Marbella|Mii|Panda|Ritmo|Ronda|Salsa|Tango|Toledo";
modelsArray[295] = "C31|C32|C51|C52|C61|C81";
modelsArray[296] = "E-Car";
modelsArray[297] = "CEO|Noble|Sceo";
modelsArray[298] = "1000|11|1100|1200S|1300|1300/1500|1301|1307|1308|1309|1500|1501|2 Litres|5|6|8|Ariane|Aronde|Horizon|Oceane|Plein Ceil|Type 11 Monoplace|Vedette";
modelsArray[299] = "Gazelle|Hunter|SM 1500|Vogue II|Vogue III";
modelsArray[300] = "Citigo|Fabia|Kamiq|Karoq|Kodiaq|Octavia|Scala|Superb|--- Other Models ---|100|100 Series|1000 MB|105 120|105/120/130|110 L|1200|1201|1202|440|440/445/450|Fabia RS|Favorit|Felicia|Forman|Garde|Kodiaq RS|L|Montreux|Octavia RS|Polular 995|Popular|Praktik|Rapid|Rapid R|Roomster|S 110 R|VOS|Yeti";
modelsArray[301] = "Maple Hysoul|Maple Marindo";
modelsArray[302] = "Forfour|Fortwo|Roadster";
modelsArray[303] = "Moto stroller|S-ZA|S-ZD";
modelsArray[304] = "Lioncel";
modelsArray[305] = "R42|R45";
modelsArray[306] = "C12|C8";
modelsArray[307] = "Actyon|Actyon Sports|Chairman|Chairman H|Chairman W|Istana|Kallista|Korando|Korando Family|Korando Sports|Korando Turismo|Kyron|Musso|Nomad|Rexton|Rodius|Stavic|Tivoli|W Coupe|XLV";
modelsArray[308] = "Aero|Tuatara|Ultimate Aero";
modelsArray[309] = "126|1500|2000|220|500|500D|650T|700C|G-series|Haflinger";
modelsArray[310] = "Avanti|Champion|Commander|Golden Hawk|President State|Silver Hawk|Sky Hawk";
modelsArray[311] = "Ascent|B9 Tribeca|BRZ|Forester|Impreza|Impreza WRX|Impreza WRX STi|Legacy|Outback|Outback Sport|Tribeca|WRX|WRX STi|XV Crosstrek|1000|1400|1500|360|Alcyone|Alfa|BRAT|Baja|Bighorn|Bistro|Coupe|DL|Dex|Dias Wagon|Domingo|Estratto|Exiga|Exiga Crossover 7|FF-1|Fiori|G3X|HM-01|Justy|K 111|Legacy Lancaster|Leone|Levorg|Libero|Loyale|Lucra|Mini Jumbo|Pleo|R-2|R1|R2|Rex|STX|SVX|Sambar|Stella|Traviq|Trezia|Vivio|Vortex|WX 01|XT|XV";
modelsArray[312] = "10|2 Litres|2-Litre|Alpine|Chamois|Imp|Rapier|Tiger|Venezia|Vogue";
modelsArray[313] = "Grand Vitara|SX4|XL-7|Aerio|Alto|Alto Lapin|Baleno|Beidouxing|C2|Cappuccino|Cara|Carry|Celerio|Cervo|Ciaz|Covie|Cultus|Cultus Crescent|EV Sport|Equator|Ertiga|Escudo|Esteem|Every|F1|Forenza|Forsa|Fronte|Fun|GSX R4|Grand Escudo|Grand Nomade|Hustler|Ignis|Jimny|Karimun Estilo|Kei|Khyber|Kizashi|LJ 20|LJ 50|Landy|Lapin|Liana|Light|MR Wagon|Margalla|Mehran|Mighty Boy|Palette|Potohar|Reno|SC 100 GX|SC100|SJ 410|SJ 413|SX|Samurai|Santana|Sea Forenza Wagon|Sidekick|Solio|Spacia|Splash|Swift|Twin|Verona|Vitara|Wagon R|Wagon R+|X-90|X2|X90|XL6";
modelsArray[314] = "Aquila|C-30|C10|C190|C30|Corda|Estina|Road Partner|Tager|Tingo|Vega";
modelsArray[315] = "1510|2500|Avenger|Baby|Horizon|Lago America|Rancho|Samba|Saoutchik|Solara|Sport|Sunbeam-Lotus|T 26|Tagora";
modelsArray[316] = "Aria|B-Line|E|E220|Estate|Indica|Indigo|Mint|Nano|Safari|Sierra|Sumo|Sumo Grande|Telcoline|Xenon";
modelsArray[317] = "57|77|80|87|Beta|MTX|T107|T600|T603|T613|T700|T87|Tatraplan";
modelsArray[318] = "Zero";
modelsArray[319] = "Cybertruck|Model 3|Model S|Model X|Model Y|Roadster";
modelsArray[320] = "City";
modelsArray[321] = "Century|Chenglong|Dragon";
modelsArray[322] = "Admiral";
modelsArray[323] = "131|Albea|Brava|Bravo|Doblo|Dogan|Kartal|Murat 124|Murat 131|Palio|Sahin|Serce|Siena|Tempra|Tipo|UNO";
modelsArray[324] = "4Runner|Avalon|Avalon Hybrid|C-HR|Camry|Camry Hybrid|Camry Solara|Celica|Corolla|FJ Cruiser|Highlander|Highlander Hybrid|Land Cruiser|Matrix|Mirai|Prius|Prius C|Prius Plug-In|Prius Plus|Prius V|RAV4|Sequoia|Sera|Sienna|Supra|Tacoma|Tundra|Venza|Yaris|1000|105|2000GT|AA|AB|AC|AE|Agya|Allex|Allion|Alphard|Altezza|Aqua|Aristo|Ateva|Aurion|Auris|Avanza|Avensis|Avensis T27|Avensis Verso|Aygo|BB|Bandeirante|Belta|Blade|Blizzard|Brevis|Briska|COMS|Caldina|Cami|Carina|Carina E|Carina ED|Carri|Caserta|Cavalier|Celsior|Century|Chaser|Classic|Coaster|Comfort|Condor|Conquest|Corolla Ceres|Corolla II|Corolla Levin|Corolla Rumion|Corolla Spacio|Corolla Verso|Corona|Corona EXiV|Corsa|Cressida|Cresta|Crown|Crown Majesta|Curren|Cynos|Duet|Dyna|ES 3|Echo|Esquire|Estima|Etios|F-1|FCHV 5|FXS|Fortuner|Fun Cargo|FunCargo|GT 86|GT1|GT86|Gaia|Grand HiAce|Granvia|HMV|Harrier|Hi-Ace|Hiace|Hilux|Hilux Surf|Innova|Ipsum|Isis|Ist|Kijang|Kluger|Land Cruiser Prado|Lexcen|Lite Ace|MR-S|MR2|MRJ|Mark II|Mark X|Mark X Zio|Master|Master RR|MasterAce|MasterAce Surf|Mega Cruiser|MiniAce|Model F|Nadia|Noah|Opa|Origin|Paseo|Passo|Passo Sette|Picnic|Pixis|Pixis Epoch|Pixis Joy|Pixis Mega|Pixis Space|Pixis Van|Platz|Pod|Porte|Premio|Previa|ProAce|Probox|Progres|Progress NC 300|Pronard|Publica|Quantum|RSC|Ractis|Raize|Raum|Regius|RegiusAce|Reiz|Retro Cruiser|Rukus|RunX|Rush|SA|Sai|Scepter|Sienta|Soarer|Solara|Soluna|Space Cruiser|Spade|Sparky|Sport 800|Sports 800|Sprinter|Sprinter Carib|Sprinter Marino|Sprinter Trueno|Stallion|Starlet|Stout|Succeed|Super|T100|Tamaraw FX|Tank|Tarago|Tazz|Tercel|Tiara|Touring HiAce|Town Ace|Unser|Urban Cruiser|Vanguard|Vellfire|Venture|Verossa|Verso|Verso-S|Vienta|Vios|Vista|Vitz|Voltz|Voxy|WiLL|Will|Will Cypha|Will VS|Will Vi|Windom|Wish|XYR|Yaris Verso|Zace|Zelas|bB|iQ";
modelsArray[325] = "1.1|600|P 50|P 601|P 70|P50|Universal";
modelsArray[326] = "Tramontana";
modelsArray[327] = "10 Break|1300|1500|1800|2.5|2000|Acclaim|Dolomite|Dove GTR4|GT6|Herald|Mayflower|Renown|Roadster|Spitfire|Stag|TR2|TR3|TR4|TR5|TR6|TR7|TR8|Toledo|Vitesse";
modelsArray[328] = "1600|2500|280|3000|350|350i|390|400|420|450|7|Cerbera|Cerbera Speed 12|Chimaera|Grantura|Griffith|M|S|S-Series|S2|SE|Sagaris|Speed 12|Speed Eight|T 350|T 440 R|Taimar|Tamora|Tasmin|Trident|Tuscan|Tuscan Speed Six|Type S|V8 S|Vixen";
modelsArray[329] = "3128 Astero|3151|3153|3159|3159 Bars|3160|3162 Simbir|452|469|Hunter|Patriot|Pickup";
modelsArray[330] = "GTR";
modelsArray[331] = "Arena|Cascada|Adam|Agila|Ampera|Antara|Astra|Belmont|Calibra|Carlton|Cavalier|Chevette|Corsa|Cresta|Crossland X|Firenza|Frontera|Insignia|Karl|Magnum|Maxx|Meriva|Mokka|Monaro|Movano|Nova|Omega|Royale|Signum|Sintra|Tigra|VX Lightning|VX220|VX4|VXR8|Vectra|Velox|Ventora|Viceroy|Victor|Viscount|Viva|Vivaro|Wyvern|Zafira";
modelsArray[332] = "1111 Oka|2101|2102|2103|2104|2105|2106|2107|2108|2109|21093 |21099|2110|2111|2112|2113|2114|2115|2120 Nadezhda|21213|2123|2131 (4x4)|4x4|Granta|Granta Drive Active|Kalina|Largus|Priora|Vesta|XRAY";
modelsArray[333] = "M12|RD 180|W8|W8 Twin Turbo";
modelsArray[334] = "210|260 LM|300|300 Atlantique|400|400 GT|Atlantique|Cabriolet|Coupe";
modelsArray[335] = "Amarok|Arteon|Atlas|Beetle|e-Golf|Eos|Golf|Golf GTI|Golf R|Golf SportWagen|Jetta|Jetta GLI|Jetta Hybrid|Jetta SportWagen|Passat|Passat CC|Rabbit|Routan|Tiguan|Touareg|1 Litre|1302|1303|1500|1600|181|411|AAC|Beetle Convertible|Bora|Brasilia|Caddy|California|Caravelle|Citi|Citi Golf|Clasico|Commercial|Concept C|Concept T|Corrado|Country Buggy|Derby|Dune Buggy|EuroVan|Eurovan|Fox|Fusca|GLI|GTD|GTI|Gol|Golf Country|Golf Plus|Golf R32|Golf Sportsvan|Hac|ID.3|Iltis|K 70|K70|Karmann Ghia|Karmann-Ghia|Kombi|LT 35 HR Panel Van|Lavida|Lupo|Lupo GTI|Magellan|Magotan|Microbus|Multivan|New Beetle|Parati|Passat Variant|Phaeton|Pickup|Pointer|Polo|Polo GTI|Polo R WRC|Quantum|SP|SP2|Sagitar|Santana|Saveiro|Schwimmwagen|Scirocco|Scirocco R|Sedan|Sharan|Suran|T-Cross|T-Roc|T5|Taro|Tayron|Teramont|Tharu|Touran|Transporter|Type 1|Type 2|Type 3|Type 3 Squareback|Type 4|Up|Variant VII|Vento|Voyage|W12|XL1";
modelsArray[336] = "C70|S40|S60|S80|S90|V60|V70|V90|XC40|XC60|XC70|XC90|120|120 Series|122|140|140 Series|144|145|164|1800|220|240|240 Series|242|244|245|260|260 Series|264|265|300 Series|340|343|360|440|460|480|66|740|760|780|850|940|960|Amazon|C30|Duett|Hatric|Laplander|P 1800|P 1900|P 210 Duett|P1800|P1900|PV|PV 544 1.8|PV 60|PV 801-10|PV444/544|PV60|S60 Cross Country|S70|SCC|V40|V40 Cross Country|V50|V60 Cross Country|V90 Cross Country|X670|YCC";
modelsArray[337] = "Corda|Estina|Tingo";
modelsArray[338] = "Fenyr Supersport|Lykan Hypersport";
modelsArray[339] = "W23|W50";
modelsArray[340] = "1.3|1.3 l Tourist|311|312|353|Sports Convertible";
modelsArray[341] = "Megabusa|SEi & Sport|Seight|XTR 2|ZEI";
modelsArray[342] = "GT|GT MF4|GT MF5|MF3|Roadster";
modelsArray[343] = "Knight Model 20|MB";
modelsArray[344] = "Aero|Aero-Willys 2600|Dauphine|Interlagos|Jeep|Rural";
modelsArray[345] = "Sunshine|Xingwang";
modelsArray[346] = "6|9";
modelsArray[347] = "PICKUP X3|SR-V X3|SUV X3";
modelsArray[348] = "Yo-crossover";
modelsArray[349] = "Bravo|Ferrari 3Z|Hyena|Zeta|Zuma";
modelsArray[350] = "10|102|103|1300|132|1500|2101|65|750|850 K|Florida|Skala|Yugo|Yugo Florida|Yugo Koral|Yugo Skala";
modelsArray[351] = "1102|1102 Tavria|1103|1103 Slavuta|1105|1105 Dana|965|966|968|Chance|Forza|Jalta|Lanos|Sens|Slavuta|Tavrija|Vida|Wagon|ZAZ Tavria Nova (1102)";
modelsArray[352] = "E10";
modelsArray[353] = "ST1";
modelsArray[354] = "MK2";
modelsArray[355] = "110|111|114|117|4104";
modelsArray[356] = "101|110";
modelsArray[357] = "Coupa|Nomad (RX6400)|RX6400|SR9|T300|T600|T700|Z100|Z300";
modelsArray[358] = "Admiral|Grand Tiger|Landmark";

export function populateModels(makeElementId, modelElementId) {
  var selectedMakeIndex = document.getElementById(makeElementId).selectedIndex;

  var modelElement = document.getElementById(modelElementId);

  modelElement.length = 0; // Fixed by Julian Woods
  modelElement.options[0] = new Option('Model', '');

  modelElement.selectedIndex = 0;

  if (modelsArray[selectedMakeIndex]) {
    var models_arr = modelsArray[selectedMakeIndex].split('|');

    if (selectedMakeIndex < 55) {
      modelElement.options[1] = new Option('--- Popular Models ---', 'popular models');
      modelElement.options[1].disabled = true;
      modelElement.options[1].style.textTransform = "capitalize";
      modelElement.options[1].style.color = "#444";
      modelElement.options[1].style.fontWeight = "bold";
    }
    for (var i = 0; i < models_arr.length; i++) {
      modelElement.options[modelElement.length] = new Option(models_arr[i], models_arr[i]);
      if (models_arr[i] === '--- Other Models ---') {
        modelElement.options[modelElement.length - 1].text = "--- Other Models ---";
        modelElement.options[modelElement.length - 1].disabled = true;
        modelElement.options[modelElement.length - 1].style.textTransform = "capitalize";
        modelElement.options[modelElement.length - 1].style.color = "#444";
        modelElement.options[modelElement.length - 1].style.fontWeight = "bold";
      }
    }
  }
  
}

export function populateMakes(makeElementId, modelElementId) {
  // given the id of the <select> tag as function argument, it inserts <option> tags
  var makeElement = document.getElementById(makeElementId);
  makeElement.length = 0;
  makeElement.options[0] = new Option('Make', '-1');
  makeElement.selectedIndex = 0;
  for (var i = 0; i < makesArray.length; i++) {
    makeElement.options[makeElement.length] = new Option(makesArray[i], makesArray[i]);
  }
  makeElement.options[1].disabled = true;
  makeElement.options[1].style.textTransform = "capitalize";
  makeElement.options[1].style.color = "#444";
  makeElement.options[1].style.fontWeight = "bold";
  makeElement.options[55].disabled = true;
  makeElement.options[55].style.textTransform = "capitalize";
  makeElement.options[55].style.color = "#444";
  makeElement.options[55].style.fontWeight = "bold";
  

  // Assigned all makes. Now assign event listener for the models.

  if (modelElementId) {
    makeElement.onchange = function () {
      populateModels(makeElementId, modelElementId);
    };
  }
}