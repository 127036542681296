import React from 'react';
const MAX_PAGES = 3;

function PageNumbers(props) {
  let {
    activePage = 1,
    count = 1,
    onClick = () => null
  } = props;
  let n = Number(count);
  if (isNaN(n) || n < 2) {
    return null;
  }
  let k = Number(activePage);
  let m;
  if (n > MAX_PAGES) {
    m = MAX_PAGES;
  } else {
    m = n;
  }
  let a = Array(m).fill(1);

  let s = ((k - Math.round(MAX_PAGES / 2) > 0) && (n > MAX_PAGES)) ? (k - Math.round(MAX_PAGES / 2) + 1) : 1;
  let e = (k + Math.round(MAX_PAGES / 2) > n) ? n : (k + Math.round(MAX_PAGES / 2));
  if ((s + MAX_PAGES > n) && (n > MAX_PAGES - 1)) {
    s = n - MAX_PAGES + 1;
  }
  let j = s;
  for (let i in a) {
    a[i] = j;
    j++;
  }

  return (<div className="pagination">
    {k>s?(
      <>
        <button className="pagination_previous pagination_previous_first" key="pageNumbersPrev" onClick={() => onClick(1)}><i className="fal fa-chevron-double-left" /></button>
        <button className="pagination_previous" key="pageNumberPrev" onClick={() => onClick(k-1)}><i className="fal fa-chevron-left" /></button>
      </>
    ):null}

    {s>1?(<span className="first" key="pageNumber00">...</span>):null}

    {a.map( (v,i) => (<button 
        className={(v!==k)?'pagination_number':'pagination_current'} key={'pageNumber'+i} 
        
        onClick={() => onClick(v)}>{v}</button>) )}
    
    {e<n && n>MAX_PAGES?(<span className="last" key={'pageNumber'+e+'0'}>...</span>):null}
    {k<n?(
      <>
        <button className="pagination_next" key="pageNumberNext" onClick={() => onClick(k+1)}><i className="fal fa-chevron-right" /></button>
        {/*<button className="pagination_next pagination_next_last" key="pageNumbersNext" onClick={() => onClick(props.count)}><i className="fal fa-chevron-double-right" /></button>*/}
      </>
    ):null}

    
    </div>);
}

export default PageNumbers;