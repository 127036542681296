import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function VideosSideBarMobile(props) {
  return (<SideBarMobile {...props}
    section="videos"
    searchTitle="Search Videos"
    createNewTitle="New Video"
    filterList={[
      { name: 'keywords', value: 'Enter Keywords' },
    ]}
  />);
}

export default VideosSideBarMobile;