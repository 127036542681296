import React, { Component } from 'react';
import moment from 'moment';
import { formatPriceAndCurrency, formatPrice } from '../../../../../shared/util/number';
import {withRouter} from 'react-router-dom';

class PPVPromoPreview extends Component {
    state = {
        showMore: false,
        showMoreLabel: { 'true': 'Hide Details', 'false': 'Show Details' },
        arrowIcon: { 'true': 'fal fa-angle-up', 'false': 'fal fa-angle-down' },
        runningButtons: { 'true': ' mp-button-active', 'false': ''},
        active: true
    }

    componentDidMount() {
        this.setState({ active: this.props.active });
    }

    toggleViewMore = () => {
        this.setState({ showMore: !this.state.showMore });
    }

    onEditCampaign = () => {
        this.props.history.push(`/create-ppv-campaign?item=${this.props.itemId}&cat=${this.props.itemType}&cars=${this.props.isFromCarsDB}&re=${this.props.isFromRealestateDB}&edit=${this.props._id}`);
    }

    onClickPause = () => {
        // this.setState({ active: false });
    }

    onClickRun = () => {
        this.setState({ active: true });
    }

    onClickStop = () => {
        this.setState({ active: false });
    }

    // <p className="mp-container-box-id">Campaign ID: 100001</p>
    render() {
        return(
            <div className="mp-container-box">
                <img src={(!this.props.campaignPhoto)?`/uploads/default/default-product-image.jpg`:`${this.props.campaignPhoto}`} alt="Campaign Name" />

                <p className="mp-container-box-title">{this.props.title || 'Black Friday'} {(Number(this.props.paidViews) >= Number(this.props.desiredViews) || moment(this.props.endDate).isBefore(moment(), 'day'))?(<span className="gppv-end-text">Campaign Ended</span>):null}</p>
                <p className="mp-container-box-id">Type: PPV Campaign</p>
                <p className="mp-container-box-date">{moment(this.props.startDate).format('MM/DD/YY')} - {moment(this.props.endDate).format('MM/DD/YY')}</p>

                { this.state.showMore && (<div className="mp-container-box-audience">
                    <p className="mp-container-box-info-title">Details</p>

                    <table>
                        <tbody>
                        <tr>
                            <td>Gender</td>
                            <td>
                                {!this.props.gender?<span>-</span>:(this.props.gender.male?<span>Male</span>:null)} &nbsp;&nbsp;
                                {!this.props.gender?<span>-</span>:(this.props.gender.female?<span>Female</span>:null)}
                            </td>
                        </tr>

                        <tr>
                            <td>Age Range</td>
                            <td><span>{!this.props.ages?18:this.props.ages.min} - {!this.props.ages?75:this.props.ages.max}</span></td>
                        </tr>

                        <tr>
                            <td>Country</td>
                            <td>{!this.props.countries?<span>-</span>:<span>{this.props.countries.map( (v,i) => (i===0)?v.label:', '+v.label )}</span>}</td>
                        </tr>
 
                        <tr>
                            <td>States</td>
                            <td>{!this.props.states?<span>-</span>:<span>{this.props.states.map( (v,i) => (i===0)?v.label:', '+v.label )}</span>}</td>
                        </tr>

                        <tr>
                            <td>Cities</td>
                            <td>{!this.props.cities?<span>-</span>:<span>{this.props.cities.map( (v,i) => (i===0)?v.label:', '+v.label )}</span>}</td>
                        </tr>

                        <tr>
                            <td>Budget</td>
                            <td><span>{!this.props.budget?'-':formatPriceAndCurrency(this.props.budget * this.props.pricePerView, 'USD', ',')}</span></td>
                        </tr>

                        <tr>
                            <td>Views</td>
                            <td><span>{!this.props.desiredViews?'-':formatPrice(this.props.desiredViews, ',')}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>)}

                
                <button onClick={this.toggleViewMore} className="mp-container-box-showmore">{this.state.showMoreLabel[this.state.showMore]}<i className={this.state.arrowIcon[this.state.showMore]} /></button>
                {/* When open text should be hide details and arrow should point up <i className="fal fa-angle-up"></i> */}


                <div className="mp-container-box-stats">
                    <p className="mp-container-box-info-title">Views Stats</p>
                    
                    <table><tbody>
                        <tr>
                            <td>Paid</td>
                            <td>Organic</td>
                            <td>Total</td>
                        </tr>

                        <tr>
                            <td>{formatPrice(this.props.paidViews, ',')}</td>
                            <td>{formatPrice(this.props.organicViews, ',')}</td>
                            <td>{formatPrice(this.props.paidViews + this.props.organicViews, ',')}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div className="mp-container-box-buttons">
                </div>


            </div>
        );
    }
}

export default withRouter(PPVPromoPreview);
//<div className="mp-container-box-buttons">
//{ ( moment().diff(moment(this.props.startDate)) >= 0 && moment().diff(moment(this.props.endDate)) <= 0 )?(<Box>
//    {/* <button onClick={this.onClickRun} className={"mp-button" + this.state.runningButtons[!!this.state.active]}>Running</button>  */}
//    {/* when active change label to Running, when paused change to Start also add class active */}
//    {/* <button onClick={this.onClickPause} className="mp-button">Pause</button> */}
//    {/* when paused add class mp-button-inactive and change label to pauased */}
//    {/* <button onClick={this.onClickStop} className={"mp-button" + this.state.runningButtons[!this.state.active]}>Stop</button> */}
//    </Box>):(
//        <button className="mp-button">Expired</button>
//    )}
//    <button onClick={this.onEditCampaign} className="mp-button">Edit</button>
//
//    {/* !!!! INFO: when campaign has ended make start, pause and end campaign buttons inactive */}
//</div>