import React from 'react';
import shortFormNumber from './ShortFormNumber';
const defaultClick = () => null;

const EngageButtonsLove = (props) => {
    if ( Number(props.noLove) < 0 ) props.noLove = 0;
    let e = (!props.isClicked)?'light':'red';
    if (!props.isDisabled) {
        return (
            <button className="engage_buttons_love" onClick={props.onClick || defaultClick}>
                <i className={`fas fa-heart ${e}`} /> 
                {/* <span className="engage_button_numbers">{shortFormNumber(props.noLove)}</span> */}
            </button>);
    }
    return (
        <span className="engage_buttons_love">
            <i className={`fas fa-heart ${e}`} /> 
            {/* <span className="engage_button_numbers">{shortFormNumber(props.noLove)}</span> */}
        </span>);
}

export default EngageButtonsLove;