import React, { useState, useEffect } from 'react';
import AddCommentReply from './AddCommentReply';
import CommentTree from './CommentTree';
import { hostNameAndPort } from '../../../util/vars';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';


const PostComments = (props) => {
    const [commentList, setCommentList] = useState([]);
    const [maxCommentCount, setMaxCommentCount] = useState(5);
    const userIsAuthenticated = useSelector(state => state.userIsAuthenticated);
    const pageRefresh = useSelector(state => state.pageRefresh);
    const userLanguage = useSelector(state => state.userLanguage);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const { itemId, websiteSection, onRemoveComment } = props;


    useEffect(() => {
        let c = websiteSection;
        let b = itemId;
        setCommentList([])
        getCommentsForItem(c, b);
    }, [websiteSection, itemId, pageRefresh])

    const getCommentsForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let commentData = new FormData();
        commentData.append('websiteSection', c);
        commentData.append('itemId', b);
        commentData.append('commentReplyTo', false);
        commentData.append('start', 0);
        commentData.append('count', 20);
        let options = {
            url: `${hostNameAndPort}/api/engage/comments/items`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await axios(options);
            let { data } = result;
            if (!data.error) {
                setCommentList(data.items)
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const increaseMaxCommentCount = () => {
        let n = maxCommentCount;
        n = n + 40;
        setMaxCommentCount(n);
    }

    let commentsLabel = 'Comment';
    if (props.commentCount !== 1) commentsLabel = 'Comments';

    console.log(commentList.length, maxCommentCount)

    return (
        <div className="engage_comments">
            {!props.showCommentsTitle ? null : (
                <h3>{props.commentCount}{' '}{getTranslatedPhrase(userLanguage, commentsLabel)}</h3>)}

            {(!loggedInUser) ? null : (
                <AddCommentReply
                    PostCommentUserName={loggedInUser.userName}
                    active={props.clickedToComment && userIsAuthenticated}
                    onCloseCommentBox={props.onCloseCommentBox}
                    onSubmitComment={(c) => props.onAddComment(c, false)}
                    firstCommentReplyBox
                >
                    <img alt={loggedInUser.userName} src={loggedInUser.profileImage} />
                </AddCommentReply>)}

            {commentList.map((v, i) => (i < maxCommentCount) ? (
                <CommentTree
                    key={i}
                    {...props}
                    loggedInUser={loggedInUser}
                    userIsAuthenticated={userIsAuthenticated}
                    userLanguage={userLanguage}
                    {...v}
                    replyTo={v._id}
                    onAddComment={props.onAddComment}
                    onRemoveComment={onRemoveComment}
                />
            ) : null)}


            {commentList.length < maxCommentCount + 1 ? null : (
                <button className="show-more-link view-more-comments" onClick={increaseMaxCommentCount}>{getTranslatedPhrase(userLanguage, 'View More Comments') + '...'}</button>
            )}

        </div>
    );
}

export default connect(null, null)(PostComments);