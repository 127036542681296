import React from "react";
import { connect, useSelector } from "react-redux";
// import MobileHeaderLogo from '../../Footer/FooterAppDownload/icons/followone-app-icon.png';
import { Link, withRouter } from "react-router-dom";
import { titleCase } from "../../../util/string";
import LogoImage from "../../../../assets/car-verve-logo.png";

const Logo = (props) => {
  const isRealestateAgency = useSelector((state) => state.isRealestateAgency);

  let section = "";
  let { hash = "", pathname = "" } = props.history.location;
  let s = [
    "store",
    "autos",
    "food",
    "realestate",
    "jobs",
    "services",
    "travel",
  ];
  pathname = pathname.replace("/", "");
  let end = pathname.indexOf("/");
  if (end < 0) end = pathname.length;
  pathname = pathname.slice(0, end);
  hash = hash.replace("#", "");

  if (isRealestateAgency) section = "real estate";
  else section = "";
  if (s.indexOf(hash) >= 0 || s.indexOf(pathname) >= 0) {
    if (hash)
      switch (hash) {
        case "realestate":
          section = "real estate";
          break;
        default:
          section = hash;
          break;
      }
    if (!section) section = pathname;
    if (section === "realestate") section = "real estate";
  }

  return (
    <div className="logo-header">
      <Link to="/">
        <p className="logo-header-desktop">
          <img src={LogoImage} alt="CarVerve" height="40" />
          {/* {!section ? <span className="logo-com">.com</span> : */}
          {/* <span className="real-estate-logo">{titleCase('by Followone')}</span>  */}
          {/* } */}
        </p>
        {/* <img className="logo-header-mobile" src={MobileHeaderLogo} alt="Followone" /> */}
      </Link>
    </div>
  );
};

export default connect(null, null)(withRouter(Logo));
