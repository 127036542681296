import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import EngageButtons from '../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import ViewsButton from '../../../shared/uielements/Engage/EngageButtons/ViewsButton';
import { Link } from 'react-router-dom';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';


class GroupsListingPreview extends Component {
    onClickHandler = () => {
        if (!!this.props.onClick) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push('/groups/'+this.props.category+'/'+(this.props.subcategory || 'other')+'/'+this.props.slug);
        // this.props.pageShouldRefresh(this.props.GroupItemPreviewURL);
    }

    render () {
        let linkSlug = `/groups/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;
        return (
            <div className="category-list_item group-item-preview"> 
                {/* <button onClick={this.onClickHandler}><img src={this.props.profileImage} alt={this.props.title} /></button> */}
                <Link to={linkSlug} onClick={this.onClickHandler}>
                    <img src={this.props.profileImage} alt={this.props.title} />
                </Link>

                <div className="group-item-preview_details">
                    <div className="group-item-preview_details_title">
                        <span>{this.props.title}</span>
                    </div>

                    <div className="group-item-preview_details_content">
                        <div className="group-stats">
                            <span className="group-stats-members"><i className="fal fa-users" /> <span className="group-stats-members-number">{this.props.memberCount}</span></span>
                            <EngageButtons>
                                <ViewsButton isClicked={false} noViews={this.props.viewCount || 0} />
                            </EngageButtons>
                        </div>
                    </div>

                    <div className="listing-preview-user-buttons">
                        {/* <button className="button button-clear button-small dsp-in-blk" onClick={this.onClickHandler}>{getTranslatedPhrase(this.props.userLanguage,'See Group')}</button> */}
                        <Link to={linkSlug} onClick={this.onClickHandler} className="button button-clear button-small dsp-in-blk">
                            {getTranslatedPhrase(this.props.userLanguage,'See Group')}
                        </Link>
                        <ListingPreviewAdminPanel
                            itemType="groups"
                            itemSlug={this.props.slug}
                            itemId={this.props.itemId}
                            userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                         />
                    </div>
                </div>
            </div> 
        );
    }
}
 

const mapStateToGroupsListingPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToGroupsListingPreviewProps, null)(withRouter(GroupsListingPreview));
