import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { hostNameAndPort } from '../../../../shared/util/vars';

import BlogPreview from '../../../Social/Blogs/BlogPreview';
import BlogsSideBar from '../../../Social/Blogs/BlogsSideBar';
import BlogsSideBarMobile from '../../../Social/Blogs/BlogsSideBarMobile';
import BlogsFeatured from '../../../Social/Blogs/BlogsFeatured';

import PhotoPreview from '../../../Social/Photos/PhotoGalleryPreview';
import PhotosSideBar from '../../../Social/Photos/PhotosSideBar';
import PhotosSideBarMobile from '../../../Social/Photos/PhotosSideBarMobile';
import PhotosFeatured from '../../../Social/Photos/PhotosFeatured';

import VideoPreview from '../../../Social/Videos/VideoPreview';
import VideosSideBar from '../../../Social/Videos/VideosSideBar';
import VideosSideBarMobile from '../../../Social/Videos/VideosSideBarMobile';
import VideosFeatured from '../../../Social/Videos/VideosFeatured';

import EventPreview from '../../../Listings/Events/EventPreview';
import EventsSideBar from '../../../Listings/Events/EventsSideBar';
import EventsSideBarMobile from '../../../Listings/Events/EventsSideBarMobile';
import EventsFeatured from '../../../Listings/Events/EventsFeatured';

import CategoryPage from '../../../../shared/templates/CategoryTemplate/category-page';


const ProfileSocial = (props) => {

    let { category, realestateAgency, userId, dealerId, slug } = props;

    useEffect(() => {
        const getItems = async () => {

            try {
                const filter = new FormData();
                const _data = {};

                if (category === 'autos' && dealerId) {
                    category = 'cars'
                }

                if (dealerId !== '') {
                    _data.dealer = dealerId;

                } else if (category === 'realestate' && realestateAgency !== '') {
                    _data.realestateAgency = realestateAgency;

                } else if (category === 'realestate' && !realestateAgency) {
                    category = 'realestate-old';
                    _data.author = userId;

                } else {
                    _data.author = userId;
                }

                filter.append('filter', JSON.stringify(_data));

                const URL = `${hostNameAndPort}/api/${category}/items`;

                const options = {
                    method: 'POST',
                    data: filter,
                }

                const result = await axios(URL, options);
                const { data } = result;

            } catch (err) {
                console.log(err)
            }
        }

        // getItems();
    }, [category, dealerId, realestateAgency, userId])


    const [categoryList, setCategoryList] = useState([]);

    function onUpdateCategories(c) {
        if (!categoryList.length)
            setCategoryList(c);
    }

    if (!userId) return null;

    return (
        <>
            {category === 'blogs' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Read Blogs or Write Your Own"
                    ItemPreview={BlogPreview}
                    SideBar={BlogsSideBar}
                    SideBarMobile={BlogsSideBarMobile}
                    FeaturedItems={BlogsFeatured}
                />}

            {category === 'photos' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Read Blogs or Write Your Own"
                    ItemPreview={PhotoPreview}
                    SideBar={PhotosSideBar}
                    SideBarMobile={PhotosSideBarMobile}
                    FeaturedItems={PhotosFeatured}
                />}

            {category === 'videos' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Read Blogs or Write Your Own"
                    ItemPreview={VideoPreview}
                    SideBar={VideosSideBar}
                    SideBarMobile={VideosSideBarMobile}
                    FeaturedItems={VideosFeatured}
                />}

            {category === 'events' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Read Blogs or Write Your Own"
                    ItemPreview={EventPreview}
                    SideBar={EventsSideBar}
                    SideBarMobile={EventsSideBarMobile}
                    FeaturedItems={EventsFeatured}
                />}
        </>
    )
}

export default ProfileSocial;