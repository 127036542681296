import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ListingReviews from '../../../../shared/templates/ListingTemplate/components/ListingReviews';
import { hostNameAndPort } from '../../../../shared/util/vars';

const ProfileReviews = (props) => {

    const [reviewsList, setReviewsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {



        const getReviews = async () => {
            const result = await axios.get(`${hostNameAndPort}/api/user/profile/${props.userId}/getreviews`);

            const { data } = result;

            if (!data.error) {
                setReviewsList(data.reviews);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }

        setIsLoading(true);
        getReviews();
    }, [])


    return (
        <div className="profile-tab-limited">
            <ListingReviews
                reviewsList={reviewsList}
                show={true}
                userIsAuthenticated={props.userIsAuthenticated}
                websiteSection="users"
                itemId={props.itemId}
                authorId={props.authorId}
                isLoading={isLoading}
            />
        </div>
    )
}

export default ProfileReviews;