import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import ServicesFeaturedPreview from './ServicesFeaturedPreview';

export default function ServicesFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={ServicesFeaturedPreview}
        />
    );
}