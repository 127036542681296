import React from 'react';
import MemberTile from './MemberTile';
import ProfileInfoBox from '../../shared/components/SideBar/ProfileInfoBox/ProfileInfoBox';
import EngageButtons from '../../shared/uielements/Engage/EngageButtons/EngageButtons';
import Rate from '../../shared/uielements/Engage/EngageButtons/RateButton';
import { getTranslatedPhrase } from '../../shared/util/lang';


function memberComponent(props) {
  let e = props.v.entityContact, f = props.v.entityDetails;
  let c = {};
  if (!!e) {
      c = {
          Category: e['Category'],
          Estimates: f['Estimates'],
          Licensed: f['Licensed'],
          Bonded: f['Bonded'],
          Insured: f['Insured'],
      };
  }
  return (<MemberTile 
      MemberURL={props.v.slug}
      MemberFeaturedImage={(<img src={props.v.coverImage} alt={props.v.userName} />)}
      MemberBrandLogo={(<img src={props.v.profileImage} alt={props.v.userName} />)}
      MemberBusinessName={props.v.userName}
      MemberBusinessRating={<EngageButtons><Rate noStars={props.v.averageRating} /></EngageButtons>}
      MemberBusinessDescription={""} 
      ButtonLabel={getTranslatedPhrase(props.userLanguage,'View Profile')} >
      <ProfileInfoBox 
          infoBoxTitle={getTranslatedPhrase(props.userLanguage,'About The Author')}
          profileDetails={c} />
  </MemberTile>);
}

export default memberComponent;