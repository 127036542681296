import React from 'react';

export default function Toolbar(props) {
  const { title, slug, rightItems } = props;

  function onClickTitle(userSlug) {
    if (!userSlug) return;
    window.open('/' + userSlug, '_otherWindow');
  }

  return (
    <div className="toolbar">
      {/* <div className="left-items">{ leftItems }</div> */}
      <h1 className="toolbar-title" onClick={() => onClickTitle(slug)}>
        {title}
      </h1>
      <div className="right-items">{rightItems}</div>
    </div>
  );
}