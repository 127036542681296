import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function FoodSideBarMobile(props) {
  return (<SideBarMobile {...props}
    section="food"
    searchTitle="Search Foods"
    createNewTitle="New Food"
    filterList={[
      { name: 'keywords', value: 'Enter Keywords' },
    ]}
  />);
}

export default FoodSideBarMobile;