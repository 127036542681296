import React, { useState, useEffect } from 'react';
import LayoutSideBar from '../../util/layout-sidebar';
import PopularCategories from '../../components/SideBar/PopularCategories/PopularCategories';
import SubCategories from '../../components/SideBar/Subcategories/Subcategories';
import SearchBox from '../../components/SideBar/Search/Search';
import CreateNewButton from '../../components/SideBar/CreateNewButton/CreateNewButton';
import SponsoredItems from '../../components/SideBar/ProductsHolder/ProductsHolder';
import Axios from 'axios';
import { hostNameAndPort } from '../../util/vars';
import { withRouter } from 'react-router';

function SideBar(props) {
  const [oldCategoryList, setOldCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sponsoredList, setSponsoredList] = useState([]);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [activeCategory, setCategory] = useState('');
  const [activeSection, setSection] = useState('');
  // const [subcategory, setSubcategory] = useState('');
  let subcategory;

  let { section, category, slug, isProfilePage } = props;

  let loc = props.location.pathname;

  if (loc && !loc.includes('search')) {
    loc = loc.split('/');
    if (!section) section = loc[1];
    if (loc[2]) category = loc[2];
    if (loc[3] && subcategory !== loc[3]) subcategory = loc[3];
  }

  if (loc.includes('search')) {
    category = ''
  }

  const urlParams = new URLSearchParams(window.location.search);

  if (!category || category === 'search') {
    category = decodeURIComponent(urlParams.get('category')) || '';
  }

  if (!subcategory || subcategory.startsWith('filters-')) {
    subcategory = decodeURIComponent(urlParams.get('subcategory')) || '';
  }

  let categoryListChanged = false;
  if (categoryList.length !== oldCategoryList.length) categoryListChanged = true;
  else for (let i = 0; i < categoryList.length; i++)
    if (oldCategoryList[i] !== categoryList[i]) {
      categoryListChanged = true;
      break;
    }



  useEffect(() => {
    let url = `${hostNameAndPort}/api/${section}/categories`;

    if (section && section !== activeSection) {
      Axios.get(url).then(result => {
        setOldCategoryList(categoryList);
        setCategoryList(result.data.categories);
        if (props.onLoadCategories) { 
          props.onLoadCategories(result.data.categories);
        }
      }).catch(err => {
        console.log(err)
      })
    }

  }, [section]);



  useEffect(() => {
    if (section !== activeSection || category !== activeCategory || categoryListChanged) {
      setSection(section);
      // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
      setCategory(category);
      setOldCategoryList(categoryList);
      subcategory = '';
      changeSubcategories(category);
    }
  }, [section, category, categoryListChanged]);



  useEffect(() => {
        getSponsored();
}, [category, section, categoryListChanged]);


  async function getSponsored() {
    let travelListParams = new FormData();
    travelListParams.append('section', section);
    travelListParams.append('category', category);
    travelListParams.append('count', 2);
    let options = {
        url: `${hostNameAndPort}/api/adv/sponsored`,
        method: 'post',
        data: travelListParams
    };
    try {
        let result = await Axios(options);
        let { data } = result;
        if (!data.error) {
          // console.log('DATA IN', data.items)
          setSponsoredList(data.items);
        }
    } catch (err) {
        // Error
    }
}



  function onSubmitSearchHandler(q) {
    if (props.onSubmitSearch) props.onSubmitSearch(q);
    onToggleSidebar();
  }

  function onChangeCategory(...args) {
    // setCategory(...args);
    changeSubcategories(...args);
    if (props.onChangeCategory) props.onChangeCategory(...args);
    onToggleSidebar();
  }

  function changeSubcategories(category) {
    let i = 0;
    while (i < categoryList.length && categoryList[i] !== category) i++;
    let a = [];
    for (let j = i + 1; j < categoryList.length && /^\s*-+/.test(categoryList[j]); j++)
      a.push(categoryList[j].replace(/^\s*-+\s*/, ''));
    setSubcategoryList(a);
  }

  function onToggleSidebar() {
    setToggleSidebar(true);
    setTimeout(() => {
      setToggleSidebar(false);
    }, 500);
  }

  function onChangeSubcategory(v) {
    subcategory = v;
    if (props.onChangeSubcategory) props.onChangeSubcategory(activeCategory, v);// props.category,v);
    onToggleSidebar();
  }

  const {
    searchTitle = 'Search',
    filterList = null,
    createNewTitle = 'Listing'
  } = props;

  // console.log('Data Out',sponsoredList);

  return (<LayoutSideBar
    toggleSidebar={toggleSidebar}
  >

    <SearchBox
      title={searchTitle}
      section={section}
      category={category}
      subcategory={subcategory}
      searchFilterList={filterList}
      onSubmitSearch={onSubmitSearchHandler}
      onChangeCategory={onChangeCategory}
      onChangeSubcategory={onChangeSubcategory}
      isProfilePage={isProfilePage}
      slug={slug}
    />

    <PopularCategories
      activeCategory={category}
      onChangeCategory={onChangeCategory}
      categoryList={categoryList}
      section={section}
    />

    <SubCategories
      activeSubcategory={subcategory}
      onChangeSubcategory={onChangeSubcategory}
      subcategoryList={subcategoryList}
      section={section}
    />

    <CreateNewButton
      section={section}
      title={createNewTitle}
    />

    <SponsoredItems
      title="Sponsored"
      section={section}
      items={sponsoredList}
      onToggleSidebar={onToggleSidebar}
    />


  </LayoutSideBar>);
}

export default withRouter(SideBar);