import React, { Component } from 'react';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import moment from 'moment';
import { hostNameAndPort } from '../../../util/vars';
import { titleCase } from '../../../util/string';
import { getTranslatedPhrase } from '../../../util/lang';
import Axios from 'axios';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import {connect} from 'react-redux';


class StepTwoOptions extends Component {
    state = {
        websiteSection: '',
        itemId: '',
        category: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        promoType: '',
        promoCharge: 0,
        spot: -1,
        chargePerDay: {
            featured: 0,
            sponsored: 0
        },
        categoriesSelect: [],
        errorMessage: '',
        waitingForResponse: false,
    }

    componentDidMount() {
        let c = this.props.websiteSection;
        this.loadCategoriesForSection(c);
        this.setState({ 
            websiteSection: c,
            itemId: this.props.itemId,
            promoType: this.props.promoType
        });
    }

    componentDidUpdate() {
        let p = this.props.promoType, k = this.props.itemId;

        if (p !== this.state.promoType || k !== this.state.itemId) {
            let w = this.props.websiteSection;
            this.loadCategoriesForSection(w);
            this.setState({ 
                websiteSection: w,
                itemId: this.props.itemId,
                promoType: this.props.promoType
            });
        }
    }

    loadPricesForAds = () => {
        let _data = new FormData();
        _data.append('websiteSection', this.state.websiteSection);
        _data.append('category', this.state.category);
        _data.append('adType', this.state.promoType);
        _data.append('spot', this.state.spot);
        let options = { url: `${hostNameAndPort}/api/pay/pricesforads`,
            method: 'post',
            data: _data
        };
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                this.setState({ 
                    chargePerDay: data.chargePerDay, 
                    spot: data.chargePerDay.spot,
                    promoCharge: this.estimateCharge(data.chargePerDay, this.state.startDate, this.state.endDate) 
                });
            }
        })
        .catch(err => {
            // server, network error
        });
    }

    loadCategoriesForSection = (s) => {
        if (['blogs', 'autos', 'cars', 'photos', 'videos', 'jobs', 'realestate', 'services', 'store', 'food', 'travel', 'events', 'groups'].indexOf(s) < 0) {
            // coupons is taken out
            this.setState({ 
                categoriesSelect: [{ a:'first-page', b: getTranslatedPhrase(this.props.userLanguage,'On the page') }],
                category: 'first-page'
            });
            return;
        }

        let url = `${hostNameAndPort}/api/${s}/categories`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                let ws = s[0].toUpperCase() + s.slice(1);
                switch (s) {
                case 'realestate':
                    ws = 'Real Estate'; break;
                case 'autos':
                    ws = 'Auto'; break;
                case 'store':
                    ws = 'Store';break;
                default: break;
                }
                data.categories = data.categories.filter(v => !(/^\s*-+/.test(v)))
                data.categories = data.categories.map( v => ({ a: v, b: getTranslatedPhrase(this.props.userLanguage,titleCase(v.replace(/-/g,' '))) }) );
                data.categories.unshift({a: 'first-page', b: `${getTranslatedPhrase(this.props.userLanguage,ws)} ${getTranslatedPhrase(this.props.userLanguage,'Home')}` });
                this.setState({
                    categoriesSelect: data.categories, 
                    category: '' 
                });
            }
        })
        .catch(err => {
            // server, network error
        });
    }

    onChangeWebsiteSection = (e) => {
        if (this.state.promoType === 'sponsored') {
            let s = e.target.value;
            this.setState({ websiteSection: s }, () => {                
                this.loadCategoriesForSection(s);
                this.loadPricesForAds();
             });
        }
    }

    onChangeInputValue = (e, _prop) => {
        let dd = e.target.value;
        
        if (_prop === 'startDate' && !this.state.endDate) {
            this.setState({ endDate: dd });
        }
        this.setState({ [_prop]: e.target.value, errorMessage: '' }, () => {
            this.loadPricesForAds();
        });
    }

    infoIsValid = () => {
        let _ = this.state;
        let _err = '';
        let result = true;

        if (!_.startDate) {
            _err = getTranslatedPhrase(this.props.userLanguage,'Start date is not set');
            result = false;
        }
        if (!_.endDate) {
            _err = getTranslatedPhrase(this.props.userLanguage,'End date is not set');
            result = false;
        }
        if (!_.websiteSection || _.websiteSection === 'select-website-section') {
            _err = getTranslatedPhrase(this.props.userLanguage,'Choose a valid website section');
            result = false;
        }
        if (!_.category || _.category === 'select-category') {
            _err = getTranslatedPhrase(this.props.userLanguage,'Choose a valid category');
            result = false;
        }
        if (!_.promoCharge) {
            _err = getTranslatedPhrase(this.props.userLanguage,'Unable to calculate the total amount');
            result = false;
        }
        if (result && moment(_.startDate).isBefore( moment(), 'days' ) ) {
            _err = getTranslatedPhrase(this.props.userLanguage,'Start date cannot be in the past');
            result = false;
        }
        if (result && moment(_.endDate).isBefore( moment(_.startDate), 'days' ) ) {
            _err = getTranslatedPhrase(this.props.userLanguage,'Start date cannot be after end date');
            result = false;
        }
        this.setState({ errorMessage: _err });
        return result;
    }

    onClickNext = () => {
        if (!this.infoIsValid()) {
            return;
        }

        let a = this.state;
        let timeSpaceParams = new FormData();
        timeSpaceParams.append('itemId', a.itemId);
        timeSpaceParams.append('websiteSection', a.websiteSection);
        timeSpaceParams.append('category', a.category);
        timeSpaceParams.append('paymentPurpose', a.promoType);
        timeSpaceParams.append('startDate', a.startDate);
        timeSpaceParams.append('endDate', a.endDate);
        timeSpaceParams.append('spot', a.spot);
        let options = {
                url: `${hostNameAndPort}/api/pay/verifyadtimespace`,
                method: 'post',
                data: timeSpaceParams
            };
        this.setState({ waitingForResponse: true });
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                this.onVerified();
            } else {
                this.setState({ 
                    waitingForResponse: false,
                    errorMessage: getTranslatedPhrase(this.props.userLanguage,'Dates are not available for the website section') 
                });
            }
        })
        .catch(err => {
            this.setState({ 
                waitingForResponse: false,
                errorMessage: getTranslatedPhrase(this.props.userLanguage,'Network error ERRFEEDUSRMDST201') 
            });
        });
    }

    onVerified = () => {
        let a = {...this.state};
        delete a.categoriesSelect;
        delete a.promoType;
        delete a.errorMessage;
        delete a.waitingForResponse;
        this.props.onSubmitUserInfo(a);

        this.props.onMoveForward();
    }

    estimateCharge = (chargePerDay,sd,ed) => {
        if (!sd || !ed) {
            return 0;
        }
        let days = Math.floor( ( new Date(ed).getTime() - new Date(sd).getTime() ) / (24 * 60 * 60 * 1000) ) + 1;
        
        if (days < 0) {
            return 0;
        }
        return days * chargePerDay[this.state.promoType];
    }


    render () {
        let label = ['Blogs', 'Photos', 'Videos', 'Events', 'Groups', 'Store', 'Auto', 'Food', 'Real Estate', 'Jobs', 'Services', 'Travel' ];//, 'Coupons'];
        let  section = ['blogs', 'photos', 'videos', 'events', 'groups', 'store', 'autos', 'food', 'realestate', 'jobs', 'services', 'travel' ];//], 'coupons'];
        let featuredSection = '';
        if (section.indexOf(this.state.websiteSection) >= 0) featuredSection = label[section.indexOf(this.state.websiteSection)];
        
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="step-two_options">
                <div className="modal_breadcrumb">{getTranslatedPhrase(this.props.userLanguage,'Ad Type')} &nbsp;&gt;&nbsp; 
                <span>{getTranslatedPhrase(this.props.userLanguage,'Placement & Date')}</span>  &nbsp;&gt;&nbsp; 
                {getTranslatedPhrase(this.props.userLanguage,'Payment')}</div>

                <div className="modal_body">
                    <h4>{getTranslatedPhrase(this.props.userLanguage,'Placement & Date')}</h4>

                    {(this.state.promoType==='featured')?(<div className="form-input-lists">&nbsp;<br/><span>Website Section: {featuredSection}</span></div>):(
                    
                    <div className="form-input-select mgt-md">
                        <select className="form-input-full mgb-sm" value={this.state.websiteSection} onChange={this.onChangeWebsiteSection}>
                            <option value="select-website-section">{getTranslatedPhrase(this.props.userLanguage,'Select Website Section')}</option>
                            {/* <option value="wall">{getTranslatedPhrase(this.props.userLanguage,'Wall')}</option> */}
                            {section.map( (v,i) => (<option value={v} key={v+i}>{getTranslatedPhrase(this.props.userLanguage,label[i])}</option>))}

                        </select>
                    </div>)}
                    
                    <div className="form-input-select">
                        <select className="form-input-full mgb-sm" value={this.state.category} onChange={e => this.onChangeInputValue(e,'category')}>
                            <option value="select-category">{getTranslatedPhrase(this.props.userLanguage,'Select Category')}</option>
                            {this.state.categoriesSelect.map( (v,i) => (<option key={i} value={v.a}>{v.b}</option>) )}
                        </select>
                    </div>

                    <div className="form-input-text form-input-full" style={{textAlign: "left"}}>
                        <span>{this.state.spot !== -1?getTranslatedPhrase(this.props.userLanguage,'Spot Number')+' '+(this.state.spot+1):getTranslatedPhrase(this.props.userLanguage,'No Spots Available')}</span>
                    </div>
                    
                    <div className="modal_body_time-frame">
                        <span>
                            <label>{getTranslatedPhrase(this.props.userLanguage,'Start Date')}</label>
                            <input className="form-input-text form-input-full" type="date" title={getTranslatedPhrase(this.props.userLanguage,'Start Date')} value={this.state.startDate} onChange={e => this.onChangeInputValue(e,'startDate')} />
                        </span>

                         <span>
                            <label>{getTranslatedPhrase(this.props.userLanguage,'End Date')}</label>
                            <input className="form-input-text form-input-full" type="date" title={getTranslatedPhrase(this.props.userLanguage,'End Date')} value={this.state.endDate} onChange={e => this.onChangeInputValue(e,'endDate')} />
                        </span>
                    </div>

                    <p className="modal_body_total-cost">{getTranslatedPhrase(this.props.userLanguage,'Total cost')} = ${this.state.promoCharge}</p>

                    <FormFieldError>{this.state.errorMessage}</FormFieldError>

                    <button className="button button-regular" onClick={this.onClickNext}>{getTranslatedPhrase(this.props.userLanguage,'Next')}</button>
                    <LoadingSvg active={this.state.waitingForResponse} />
                    <br />
                </div>
            </div>
        );
    }
}

const mapStateToStepTwoOptionsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToStepTwoOptionsProps, null)(StepTwoOptions);