import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import { getTranslatedPhrase } from '../../../util/lang';


class confirmDeleteModal extends Component {
    render() {
        if (!this.props.active) {
            return null;
        }
        let message = getTranslatedPhrase(this.props.userLanguage, 'Are you sure you want to delete this?');
        if (!!this.props.modalMessage) {
            message = this.props.modalMessage;
        }

        return (
            <div className="modal-wrapper" style={{zIndex: '99999'}}>
                <Backdrop show={this.props.active} clicked={this.props.onHideConfirmDeleteModal} />
                <div className="modal">
                    <div className="modal_body">
                        <h4>{getTranslatedPhrase(this.props.userLanguage, 'IMPORTANT')}</h4>

                        <div className="moda_body_functions">
                            <p>{message}</p>
                            <br />
                            <button
                                className="button button-regular button-small"
                                onClick={this.props.confirmDeleteOperation}>{getTranslatedPhrase(this.props.userLanguage, 'Confirm')}</button>&nbsp;&nbsp;&nbsp;
                            <button
                                className="button button-sale button-small"
                                onClick={this.props.onHideConfirmDeleteModal}>{getTranslatedPhrase(this.props.userLanguage, 'Cancel')}</button>
                        </div>

                    </div>

                    <span onClick={this.props.onHideConfirmDeleteModal} className="modal_close"><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateToAccountLoginModalProps = (
    state,
    props
) => {
    return {
        active: state.confirmDeleteOperationModalActive,
        modalMessage: state.confirmDeleteOperationModalMessage,
        userLanguage: state.userLanguage
    };
};

const mapDispatchToAccountLoginModalProps = (
    dispatch
) => {
    return {
        onHideConfirmDeleteModal: () => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: false, confirmDeleteOperationModalMessage: '' });
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        confirmDeleteOperation: () => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: false, confirmDeleteOperationModalMessage: '' });
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: true });
        }
    };
};

export default connect(mapStateToAccountLoginModalProps, mapDispatchToAccountLoginModalProps)(confirmDeleteModal);