import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../util/vars';
import axios from 'axios';
import {connect} from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../util/lang';

class YouMayFollow extends Component { 
    state = {
        userList: [],
        userId: '',
    }

    componentDidMount() {
        let u = this.props.userId;
        this.setState({ userId: u });
        this.getUserSuggestionsFromServer();
    }

    componentDidUpdate() {
        let u = this.props.userId;
        if (this.state.userId !== u) {
            this.setState({ userId: u });
            this.getUserSuggestionsFromServer();
        }
    }

    getUserSuggestionsFromServer() {
        let url = `${hostNameAndPort}/api/user/suggest-followers`;

        axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                if (!data.userList) {
                    data.userList = [];
                }
                this.setState({ userList: data.userList });
            }
        })
        .catch(err => {
            // Network error
        });
    }

    sendFollowRequest = (p, i) => {
        let _data = new FormData();
        _data.append('toUserId', p.userId);
        let options = {
            url: `${hostNameAndPort}/api/user/follow`,
            method: 'post',
            data: _data
        };
        axios(options)
        .then(result => {
            let { data } = result;
            if (!data.error) {
                // Update the You May Follow list after 2 seconds
                setTimeout(this.getUserSuggestionsFromServer, 2000);
            }
        })
        .catch(err => {
            // Network error
        });
        let a = this.state.userList;
        a[i].requestIsSent = true;
        this.setState({ userList: a });
    }

    render () {
        if (!this.state.userList || !this.state.userList.length) {
            return null;
        }
        return (
            <div className="main-sidebar_box">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'You May Follow')+'...'}</h4>

                {this.state.userList.map( (v,i) => (<div className="main-sidebar_box_add-follower" key={i}>
                    
                        <Link onClick={() => this.props.pageShouldRefresh(v.slug)} to={v.slug} >
                            <img src={v.profileImage} alt={v.userName} />
                        </Link>
                    
                    <div className="main-sidebar_box_add-follower_info">
                        <p>{v.userName}</p>
                        {(!v.requestIsSent)?
                        (<button onClick={() => this.sendFollowRequest(v, i)}>+ {getTranslatedPhrase(this.props.userLanguage,'Follow')}<span>{getTranslatedPhrase(this.props.userLanguage,'one')}</span></button>):
                        (<button onClick={() => null}>{getTranslatedPhrase(this.props.userLanguage,'Request sent')}</button>)}
                    </div>
                    <div className="clb" />
                </div>) )}

                {/*<a className="ShowMoreLink" href="/">View All</a>*/}
            </div>
        );
    }
}

//export default YouMayFollow;
const mapStateToYouMayFollowProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToYouMayFollowProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToYouMayFollowProps, mapDispatchToYouMayFollowProps)(YouMayFollow);