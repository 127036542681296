import React from 'react';
const defaultClick = () => null;

const EngageButtonsFavorites = (props) => {
    let e = (!props.isClicked)?'light':'green';
    if (!props.isDisabled) {
        return (
            <button className="engage_buttons_favorites" onClick={props.onClick || defaultClick}>
                <i className={`fas fa-bookmark ${e}`} /> <span className="engage_button_numbers" />
            </button>);
    }
    return (
        <span className="engage_buttons_favorites">
            <i className={`fas fa-bookmark ${e}`} /> <span className="engage_button_numbers" />
        </span>);
}

export default EngageButtonsFavorites;