import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function TravelSideBarMobile(props) {
  return (<SideBarMobile {...props}
    section="travel"
    searchTitle="Search Travel"
    createNewTitle="New Travel"
    filterList={[
      { name: 'keywords', value: 'Enter Keywords' },
    ]}
  />);
}

export default TravelSideBarMobile;