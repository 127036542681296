import React from 'react';
import PropertyPreview from '../../../../pages/Listings/RealEstate/PropertyPreview';
import ProductPreview from '../../../../pages/Listings/Store/ProductPreview';
import FoodPreview from '../../../../pages/Listings/Food/FoodPreview';
import AutoListingPreview from '../../../../pages/Listings/Autos/AutosNewPreview';
import TravelListingPreview from '../../../../pages/Listings/Travel/TravelPreview';
import JobsPostPreview from '../../../../pages/Listings/Jobs/JobsPreview';
import ServicePreview from '../../../../pages/Listings/Services/ServicePreview';
import EventsListingPreview from '../../../../pages/Listings/Events/EventPreview';
import GroupsListingPreview from '../../../../pages/Social/Groups/GroupPreview';
import BlogPostPreview from '../../../../pages/Social/Blogs/BlogPreview';
import VideoPreview from '../../../../pages/Social/Videos/VideoPreview';
import PhotoGalleryPreview from '../../../../pages/Social/Photos/PhotoGalleryPreview';
import NewsPreview from '../../../../pages/Social/News/NewsPreview';

function SpecialItem(props) {
    let Item = null;
    switch (props.itemType) {
        case 'store': Item = (<ProductPreview {...props} />); break;
        case 'realestate':
        case 'realestate-old': Item = (<PropertyPreview {...props} />); break;
        case 'food': Item = (<FoodPreview {...props} />); break;
        case 'autos':
        case 'cars': Item = (<AutoListingPreview {...props} />); break;
        case 'travel': Item = (<TravelListingPreview {...props} />); break;
        case 'jobs': Item = (<JobsPostPreview {...props} />); break;
        case 'services': Item = (<ServicePreview {...props} />); break;
        case 'events': Item = (<EventsListingPreview {...props} />); break;
        case 'groups': Item = (<GroupsListingPreview {...props} />); break;
        case 'blogs': Item = (<BlogPostPreview {...props} />); break;
        case 'photos': Item = (<PhotoGalleryPreview {...props} />); break;
        case 'videos': Item = (<VideoPreview {...props} />); break;
        case 'news': Item = (<NewsPreview {...props} />); break;
        default: break;
    }
    
    return Item;
}

export default SpecialItem;