import React, { useState, useEffect } from 'react';
import LayoutSideBar from '../../util/layout-sidebar';

import CategoriesCatMobile from '../../components/CategoryMobile/CategoriesCatMobile';
import SubcategoriesCatMobile from '../../components/CategoryMobile/SubcategoriesCatMobile';
import SearchCatMobile from '../../components/CategoryMobile/SearchCatMobile';
import CreateNewCatMobile from '../../components/CategoryMobile/CreateNewCatMobile';

import Axios from 'axios';
import { hostNameAndPort } from '../../util/vars';
import { withRouter } from 'react-router';

function SideBarMobile(props) {
    const [oldCategoryList, setOldCategoryList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [sponsoredList, setSponsoredList] = useState([]);
    // const [toggleSidebar, setToggleSidebar] = useState(false);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [activeCategory, setCategory] = useState('');
    const [activeSection, setSection] = useState('');
    // const [subcategory, setSubcategory] = useState('');
    let subcategory;

    let { section, category, slug, isProfilePage } = props;

    let loc = props.location.pathname;
    if (loc) {
        loc = loc.split('/');
        if (!section) section = loc[1];
        if (loc[2]) category = loc[2];
        if (loc[3] && subcategory !== loc[3]) subcategory = loc[3];
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (!category || category === 'search') {
        category = decodeURIComponent(urlParams.get('category')) || '';
    }

    if (!subcategory || subcategory.startsWith('filters-')) {
        subcategory = decodeURIComponent(urlParams.get('subcategory')) || '';
    }


    let categoryListChanged = false;
    if (categoryList.length !== oldCategoryList.length) categoryListChanged = true;
    else for (let i = 0; i < categoryList.length; i++)
        if (oldCategoryList[i] !== categoryList[i]) {
            categoryListChanged = true;
            break;
        }

    useEffect(() => {

        let url = `${hostNameAndPort}/api/${section}/categories`;
        let source = Axios.CancelToken.source();

        if (section && section !== activeSection) {
            Axios.get(url, {
                cancelToken: source.token,
            }).then(result => {
                setOldCategoryList(categoryList);
                setCategoryList(result.data.categories);
                if (props.onLoadCategories) props.onLoadCategories(result.data.categories);
            }).catch(err => {
                console.log(err)
            })
        }

        return () => {
            source.cancel("Cancelling Categories");
        }
    }, [section]);




    useEffect(() => {
        if (section !== activeSection || category !== activeCategory || categoryListChanged) {
            setSection(section);
            // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
            setCategory(category);
            setOldCategoryList(categoryList);
            subcategory = '';
            changeSubcategories(category);
        }
    }, [section, category, categoryListChanged]);

    function onSubmitSearchHandler(q) {
        if (props.onSubmitSearch) props.onSubmitSearch(q);
        // onToggleSidebar();
    }

    function onChangeCategory(...args) {
        // setCategory(...args);
        changeSubcategories(...args);
        if (props.onChangeCategory) props.onChangeCategory(...args);
        // onToggleSidebar();
    }

    function changeSubcategories(category) {
        let i = 0;
        while (i < categoryList.length && categoryList[i] !== category) i++;
        let a = [];
        for (let j = i + 1; j < categoryList.length && /^\s*-+/.test(categoryList[j]); j++)
            a.push(categoryList[j].replace(/^\s*-+\s*/, ''));
        setSubcategoryList(a);
    }

    // function onToggleSidebar() {
    //   setToggleSidebar(true);
    //   setTimeout(() => {
    //     setToggleSidebar(false);
    //   }, 500);
    // }

    function onChangeSubcategory(v) {
        subcategory = v;
        if (props.onChangeSubcategory) props.onChangeSubcategory(activeCategory, v);// props.category,v);
        // onToggleSidebar();
    }

    const {
        searchTitle = 'Search',
        filterList = null,
        createNewTitle = 'Listing'
    } = props;


    return (
        <div className="category-mobile-sidebar">

            <CategoriesCatMobile
                activeCategory={category}
                onChangeCategory={onChangeCategory}
                categoryList={categoryList}
                section={section}

            />

            <SubcategoriesCatMobile
                activeSubcategory={subcategory}
                onChangeSubcategory={onChangeSubcategory}
                subcategoryList={subcategoryList}
                section={section}
            />


            <SearchCatMobile
                title={searchTitle}
                section={section}
                category={category}
                subcategory={subcategory}
                searchFilterList={filterList}
                onSubmitSearch={onSubmitSearchHandler}
                onChangeCategory={onChangeCategory}
                onChangeSubcategory={onChangeSubcategory}
                isProfilePage={isProfilePage}
                slug={slug}
            />


            <CreateNewCatMobile
                section={section}
                title={createNewTitle}
            />

        </div>);
}

export default withRouter(SideBarMobile);