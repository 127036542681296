import React from 'react';
import SideBarMobile from '../../../shared/templates/CategoryTemplate/category-sidebar-mobile';

function ServicesSideBarMobile(props) {
  return (<SideBarMobile {...props}
    section="services"
    searchTitle="Search Services"
    createNewTitle="New Service"
    filterList={[
      { name: 'keywords', value: 'Enter Keywords' },
    ]}
  />);
}

export default ServicesSideBarMobile;