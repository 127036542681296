import React, { useState, useEffect } from 'react';
import LayoutSideBar from '../../../shared/util/layout-sidebar';
import PopularCategories from '../../../shared/components/SideBar/PopularCategories/PopularCategories';
import SearchBox from '../../../shared/components/SideBar/Search/Search';
import CreateNewButton from '../../../shared/components/SideBar/CreateNewButton/CreateNewButton';
import SponsoredItems from '../../../shared/components/SideBar/ProductsHolder/ProductsHolder';
import Axios from 'axios';
import { hostNameAndPort } from '../../../shared/util/vars';
import SubCategories from '../../../shared/components/SideBar/Subcategories/Subcategories';
import { withRouter } from 'react-router';
// import ZSidebar from './zsidebar';

function SideBar(props) {
  const [oldCategoryList, setOldCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sponsoredList, setSponsoredList] = useState([]);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [activeCategory, setCategory] = useState('');
  const [activeSection, setSection] = useState('');
  const [subcategory, setSubcategory] = useState('');

  let { section, category, slug, isProfilePage } = props;

  let loc = props.location.pathname;
  if (loc) {
    loc = loc.split('/');
    if (!section) section = loc[1];
    if (loc[2]) category = loc[2];
    if (loc[3] && subcategory !== loc[3]) setSubcategory(loc[3]);
  }

  let categoryListChanged = false;
  if (categoryList.length !== oldCategoryList.length) categoryListChanged = true;
  else for (let i = 0; i < categoryList.length; i++)
    if (oldCategoryList[i] !== categoryList[i]) {
      categoryListChanged = true;
      break;
    }

  async function getCategories() {
    let url = `${hostNameAndPort}/api/${section}/categories`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setOldCategoryList(categoryList);
        setCategoryList(data.categories);
        if (props.onLoadCategories) props.onLoadCategories(data.categories);
      }
    } catch (err) {
      // server, network error
    }
  }

  useEffect(() => {
    if (section && section !== activeSection) getCategories();
  }, [section]);

  async function getSponsored() {
    let itemsParams = new FormData();
    itemsParams.append('section', section);
    itemsParams.append('category', category);
    itemsParams.append('count', 2);
    let options = {
      url: `${hostNameAndPort}/api/adv/sponsored`,
      method: 'post',
      data: itemsParams
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        setSponsoredList(data.items);
      }
    } catch (err) {
      // Error
    }
  }

  useEffect(() => {
    if (section !== activeSection || category !== activeCategory) {
      // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
      // setCategory(category);
      // setSection(section);
      getSponsored();
    }
  }, [section, category]);

  useEffect(() => {
    if (section !== activeSection || category !== activeCategory || categoryListChanged) {
      setSection(section);
      // if ((!category || category === 'all') && (!activeCategory || activeCategory === 'all')) return;
      setCategory(category);
      setOldCategoryList(categoryList);
      setSubcategory('');
      changeSubcategories(category);
    }
  }, [section, category, categoryListChanged]);

  function onSubmitSearchHandler(q) {
    if (props.onSubmitSearch) props.onSubmitSearch(q);
    onToggleSidebar();
  }

  function onChangeCategory(...args) {
    // setCategory(...args);
    changeSubcategories(...args);
    if (props.onChangeCategory) props.onChangeCategory(...args);
    onToggleSidebar();
  }

  function changeSubcategories(category) {
    let i = 0;
    while (i < categoryList.length && categoryList[i] !== category) i++;
    let a = [];
    for (let j = i + 1; j < categoryList.length && /^\s*-+/.test(categoryList[j]); j++)
      a.push(categoryList[j].replace(/^\s*-+\s*/, ''));
    setSubcategoryList(a);
  }

  function onToggleSidebar() {
    setToggleSidebar(true);
    setTimeout(() => {
      setToggleSidebar(false);
    }, 500);
  }

  function onChangeSubcategory(v) {
    setSubcategory(v);
    if (props.onChangeSubcategory) props.onChangeSubcategory(activeCategory, v);// props.category,v);
    onToggleSidebar();
  }

  const {
    searchTitle = 'Search',
    filterList = null,
    createNewTitle = 'Listing'
  } = props;

  return (<LayoutSideBar toggleSidebar={toggleSidebar}>

    <SearchBox
      title={searchTitle}
      section={section}
      category={category}
      subcategory={subcategory}
      searchFilterList={filterList}
      onSubmitSearch={onSubmitSearchHandler}
      onChangeCategory={onChangeCategory}
      onChangeSubcategory={onChangeSubcategory}
      isProfilePage={isProfilePage}
      slug={slug}
    />

    {/* Andrei Check ~ Double Section ~ */}

    {/* {!isProfilePage && (props.section === 'realestate') &&
      <>
        <PopularCategories
          section="realestate"
          activeCategory={category}
          onChangeCategory={onChangeCategory}
          categoryList={categoryList}
          section={section}
        />

        <SubCategories
          activeSubcategory={subcategory}
          onChangeSubcategory={onChangeSubcategory}
          subcategoryList={subcategoryList}
          section={section}
        />
      </>
    } */}

    <CreateNewButton
      section={section}
      title={createNewTitle}
    />

    <SponsoredItems
      title="Sponsored"
      section={section}
      items={sponsoredList}
      onToggleSidebar={onToggleSidebar}
    />


  </LayoutSideBar>);
}

export default withRouter(SideBar);