const actionType = {
  USER_LOGIN: 'USER_LOGIN',
  LOGIN_MODAL: 'LOGIN_MODAL',
  ACCOUNT_INFORMATION_MODAL: 'ACCOUNT_INFORMATION_MODAL',
  ACCOUNT_TYPE_MODAL: 'ACCOUNT_TYPE_MODAL',
  WELCOME_MODAL: 'WELCOME_MODAL',
  ADVERTISE_MODAL: 'ADVERTISE_MODAL',
  MEDIA_LIGHTBOX_MODAL: 'MEDIA_LIGHTBOX_MODAL',
  CONFIRM_DELETE_OPERATION_MODAL: 'CONFIRM_DELETE_OPERATION_MODAL',
  DELETE_OPERATION: 'DELETE_OPERATION',
  ALERT_MODAL: 'ALERT_MODAL',
  VIEW_SEARCH_RESULTS: 'SEARCH_RESULTS',
  MESSAGES_PAGE: 'MESSAGES_PAGE',
  PAGE_REFRESH: 'PAGE_REFRESH',
  UPDATE_USER_LANGUAGE: 'UPDATE_USER_LANGUAGE',
  CURRENT_GEOLOCATION: 'CURRENT_GEOLOCATION',
  PAYPERVIEW_REDIRECT: 'PAYPERVIEW_REDIRECT',
  REFERRED_BY: 'REFERRED_BY',
  SELECT_REASON: 'SELECT_REASON',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  REALESTATE_AGENCY: 'REALESTATE_AGENCY',
  SIGN_IN_REFRESH: 'SIGN_IN_REFRESH'
};

// from Messenger
export const actions = {
  NEW_MESSAGE_FLAG: 'NEW_MESSAGE_FLAG',
  ACTIVE_CONVERSATION: 'ACTIVE_CONVERSATION',
  CONVERSATION_TITLE: 'CONVERSATION_TITLE',
  LAST_MESSAGE: 'LAST_MESSAGE',
  CONVERSATION_SEARCH: 'CONVERSATION_SEARCH',
  UPDATE_SEEN_MESSAGES: 'UPDATE_SEEN_MESSAGES',
  CONVERSATIONS_CHANGED_ORDER: 'CONVERSATIONS_CHANGED_ORDER',
  CONVERSATION_STATUS: 'CONVERSATION_STATUS',
  TOGGLE_HIDE_CONVERSATION_LIST: 'TOGGLE_HIDE_CONVERSATION_LIST'
};

export default actionType;