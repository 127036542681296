import React from 'react';


const CategoryLoaderItem = () => (
  <div className="category-list_item cat-loader">
      <div className="cat-loader-image" />

      <div className="cat-loader-price">
          <span /><span /><span />
      </div>

      <div className="cat-loader-title" />
      <div className="cat-loader-meta" />

      <div className="cat-loader-button" />

      <div className="cat-loader-social">
          <span /><span /><span /><span />
      </div>
  </div>
)

const CategoryLoader = props => {

    return (
      <>
          <div className="cat-loader-spinner">
              <i className="fad fa-spinner fa-spin" />
          </div>

          <div className="category-list">
              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />

              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />

              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />

              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />
              <CategoryLoaderItem />
          </div>
      </>
    )
}

export default CategoryLoader;