import React, { Component } from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import Axios from 'axios';
import { hostNameAndPort } from '../../../util/vars';
import {withRouter} from 'react-router-dom';

class CurrentAccType extends Component {
    state = {
        subscription: 'free'
    }

    componentDidMount() {
        this.getSubscriptionInfoFromServer();
    }

    getSubscriptionInfoFromServer = async () => {
        let url = `${hostNameAndPort}/api/user/subscription`;
        try { let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                let s = data.subscription;
                s = s.split('-').map(v => (v[0].toUpperCase() + v.slice(1))).join(' ');
                this.setState({ subscription: s });
            }
        } catch(err) {
            // Network error
        }
    }

    onClickUpgrade = () => {
        this.props.history.push('/subscription-settings');
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return(
            <div className={"main-sidebar_box main-sidebar_box-"+this.state.subscription.toLowerCase()}>
                <h3>{titleCase(getTranslatedPhrase(this.props.userLanguage, this.state.subscription))}</h3>
                <p>{getTranslatedPhrase(this.props.userLanguage, 'Membership')}</p>
                <button onClick={this.onClickUpgrade} className="button button-white button-small">{getTranslatedPhrase(this.props.userLanguage, 'Change')}</button>
            </div>
        );
    }
}

const mapStateToCurrentAccTypeProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToCurrentAccTypeProps,null)(withRouter(CurrentAccType));