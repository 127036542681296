const shortFormNumber = (n) => {
    n = Number(n) || 0;
    if (n < 1000) {
        return ''+n
    }
    if ( (n > 1000 ) && ( n < 1000000) ) {
        return '' + Math.floor(n / 100) / 10 + 'K';
    }
    if ( (n > 1000000) && (n < 1000000000) ) {
        return '' + Math.floor(n / 100000) / 10 + 'M';
    }
    if ( (n > 1000000000) && (n < 10000000000) ) {
        return '' + Math.floor(n / 100000000) / 10 + 'B';
    }
    return `\u221e`;
}

export default shortFormNumber