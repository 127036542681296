import React from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import ItemPreview from '../../Advertising/SpecialItem/SpecialItem';

function ProductsHolder(props) {
    const userLanguage = useSelector(state => state.userLanguage);
    const { items, title, onToggleSidebar } = props;

    if (!items || !items.length) {
        return null;
    }
    
    return (
        <div className="main-sidebar_box main-sidebar_box_1rempad">
            <h4>{getTranslatedPhrase(userLanguage, title)}</h4>

            <div className="main-sidebar_box_sponsored">
                {items.slice(0, 2).map((v, i) => <ItemPreview key={'sidebar-sponsored-' + i} itemType={v.originalWebsiteSection} {...v} onToggleSidebar={onToggleSidebar} />)}
            </div>
        </div>
    );
}

export default connect(null, null)(ProductsHolder);