import React, { useState } from 'react';
import FavoritesMain from '../../../Profile/ToolsUser/Favorites/FavoritesMain';

const ProfileFavorites = (props) => {

    const [stats, setStats] = useState({});

    const onClickOnFavorite = () => {
        let newStats = { ...stats };
        newStats.totalFavorites = Number(stats.totalFavorites) - 1;
        setStats(newStats)
    }


    return (
        <div>
            <FavoritesMain totalCount={props.totalCount} updateTotalFavorites={props.updateTotalFavorites} slug={props.slug} onClickOnFavorite={onClickOnFavorite} />
        </div>
    )
}

export default ProfileFavorites;