import React, { Component } from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import {withRouter} from 'react-router-dom';
import Axios from 'axios';
import {hostNameAndPort} from '../../../util/vars';


class StepOneSelect extends Component {
    state = {
        accountType: 'personal',
        affiliateId: '',
    }

    componentDidMount() {
        this.getUserDataFromServer();
    }

    componentDidUpdate() {
        this.getUserDataFromServer();
    }

    getUserDataFromServer = async () => {
        let url = `${hostNameAndPort}/api/user/whoami`;
        try { let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                if (data.user.accountType !== this.state.accountType)
                    this.setState(data.user);
            }
        } catch(err) {
            // Network error
        }
    }
      
    onChooseOption = (c) => {
        this.props.onSubmitUserInfo({ promoType: c });
        this.props.onMoveForward();
    }

    onChoosePPV = () => {
        this.props.onCloseModal();
        this.props.history.push(`/create-ppv-campaign?item=${this.props.itemId}&cars=${this.props.isFromCarsDB}&cat=${this.props.itemType}&re=${this.props.isFromRealestateDB}`);
    }


    render () {
        if (!this.props.active) {
            return null;
        }
        return (
            <div className="step-one_select">
                <div className="modal_breadcrumb"><span>{getTranslatedPhrase(this.props.userLanguage,'Ad Type')}</span> &nbsp;/&nbsp; 
                {getTranslatedPhrase(this.props.userLanguage,'Placement & Date')}  &nbsp;/&nbsp; {getTranslatedPhrase(this.props.userLanguage,'Payment')}</div>

                <div className="modal_body">
                    <h4>{getTranslatedPhrase(this.props.userLanguage,'Select Ad Type')}</h4>

                    <div className="modal_body_functions modal_body_functions_advertise-step-1">
                        <div className="form-input-lists" onClick={() => this.onChooseOption('featured')}>
                            <input type="radio" id="advertiseFeatured" name="advertiseTypeSelection" value="on" /> 
                            <label htmlFor="advertiseFeatured"><span><i className="fal fa-check" /></span> {getTranslatedPhrase(this.props.userLanguage,'Featured')}</label>
                        </div>
                        <div className="form-input-lists" onClick={() => this.onChooseOption('sponsored')}>
                            <input type="radio" id="advertiseSponsored" name="advertiseTypeSelection" value="on" /> 
                            <label htmlFor="advertiseSponsored"><span><i className="fal fa-check" /></span> {getTranslatedPhrase(this.props.userLanguage,'Sponsored')}</label>
                        </div>
                        { this.state.accountType !== 'personal' && (
                        <div className="form-input-lists" onClick={this.onChoosePPV}>
                            <input type="radio" id="advertisePPV" name="advertiseTypeSelection" value="on" /> 
                            <label htmlFor="advertisePPV"><span><i className="fal fa-check" /></span> {getTranslatedPhrase(this.props.userLanguage,'Pay Per View')}</label>
                        </div>)}
                    </div>
                    


                </div>
            </div>
        );
    }
}

const mapStateToStepOneSelectProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToStepOneSelectProps, null)(withRouter(StepOneSelect));