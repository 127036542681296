import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { hostNameAndPort } from '../../../../shared/util/vars';

import ProductPreview from '../../../Listings/Store/ProductPreview';
import StoreSideBar from '../../../Listings/Store/StoreSideBar';
import StoreSideBarMobile from '../../../Listings/Store/StoreSideBarMobile';
import StoreFeatured from '../../../Listings/Store/StoreFeatured';

import FoodPreview from '../../../Listings/Food/FoodPreview';
import FoodSideBar from '../../../Listings/Food/FoodSideBar';
import FoodSideBarMobile from '../../../Listings/Food/FoodSideBarMobile';
import FoodFeatured from '../../../Listings/Food/FoodFeatured';

import JobsPreview from '../../../Listings/Jobs/JobsPreview';
import JobsSideBar from '../../../Listings/Jobs/JobsSideBar';
import JobsSideBarMobile from '../../../Listings/Jobs/JobsSideBarMobile';
import JobsFeatured from '../../../Listings/Jobs/JobsFeatured';

import ServicePreview from '../../../Listings/Services/ServicePreview';
import ServicesSideBar from '../../../Listings/Services/ServicesSideBar';
import ServicesSideBarMobile from '../../../Listings/Services/ServicesSideBarMobile';
import ServicesFeatured from '../../../Listings/Services/ServicesFeatured';

import TravelPreview from '../../../Listings/Travel/TravelPreview';
import TravelSideBar from '../../../Listings/Travel/TravelSideBar';
import TravelSideBarMobile from '../../../Listings/Travel/TravelSideBarMobile';
import TravelFeatured from '../../../Listings/Travel/TravelFeatured';

import AutosNewPreview from '../../../Listings/Autos/AutosNewPreview';
import AutosSideBar from '../../../Listings/Autos/AutosSideBar';
import AutosSideBarMobile from '../../../Listings/Autos/AutosSideBarMobile';
import AutoCategoryPageProfile from '../../../Listings/Autos/category-page-autos-profile';
import AutosFeatured from '../../../Listings/Autos/AutosFeatured';

import PropertyPreview from '../../../Listings/RealEstate/PropertyPreview';
import PropertyCategoryPage from '../../../Listings/RealEstate/category-page-realestate-profile';
import PropertySidbar from '../../../Listings/RealEstate/RealEstateSideBar';
import PropertySidbarMobile from '../../../Listings/RealEstate/RealEstateSideBarMobile';
import PropertyFeatured from '../../../Listings/RealEstate/RealEstateFeatured';

import CategoryPage from '../../../../shared/templates/CategoryTemplate/category-page';


const ProfileStore = (props) => {

    let { category, realestateAgency, userId, dealerId, slug } = props;

    useEffect(() => {
        const getItems = async () => {

            try {
                const filter = new FormData();
                const _data = {};

                if (category === 'autos' && dealerId) {
                    category = 'cars'
                }

                if (dealerId !== '') {
                    _data.dealer = dealerId;

                } else if (category === 'realestate' && realestateAgency !== '') {
                    _data.realestateAgency = realestateAgency;

                } else if (category === 'realestate' && !realestateAgency) {
                    category = 'realestate-old';
                    _data.author = userId;

                } else {
                    _data.author = userId;
                }

                filter.append('filter', JSON.stringify(_data));

                const URL = `${hostNameAndPort}/api/${category}/items`;

                const options = {
                    method: 'POST',
                    data: filter,
                }

                const result = await axios(URL, options);
                const { data } = result;

            } catch (err) {
                console.log(err)
            }
        }

        // getItems();
    }, [category, dealerId, realestateAgency, userId])


    const [categoryList, setCategoryList] = useState([]);

    function onUpdateCategories(c) {
        if (!categoryList.length)
            setCategoryList(c);
    }

    if (!userId) return null;

    return (

        <>
            {category === 'autos' &&
                <AutoCategoryPageProfile
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Cars"
                    ItemPreview={AutosNewPreview}
                    SideBar={(props) => (<AutosSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<AutosSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={AutosFeatured}
                />
                // :
                // <CategoryPage
                //     isProfilePage={true}
                //     userId={userId}
                //     dealerId={dealerId}
                //     realestateAgency={realestateAgency}

                //     section={category}
                //     description="Cars"
                //     ItemPreview={AutosNewPreview}
                //     SideBar={(props) => (<AutosSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                //     FeaturedItems={AutosFeatured}
                // />
            }


            {category === 'realestate' &&
                <PropertyCategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Properties"
                    ItemPreview={PropertyPreview}
                    SideBar={(props) => (<PropertySidbar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<PropertySidbarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={PropertyFeatured}
                />
                // :
                // <CategoryPage
                //     isProfilePage={true}
                //     userId={userId}
                //     dealerId={dealerId}
                //     realestateAgency={realestateAgency}

                //     section={category}
                //     description="Properties"
                //     ItemPreview={PropertyPreview}
                //     SideBar={(props) => (<PropertySidbar onLoadCategories={onUpdateCategories} {...props} />)}
                //     FeaturedItems={PropertyFeatured}
                // />
            }

            {category === 'store' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Products"
                    ItemPreview={ProductPreview}
                    SideBar={(props) => (<StoreSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<StoreSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={StoreFeatured}
                />}

            {category === 'food' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Food"
                    ItemPreview={FoodPreview}
                    SideBar={(props) => (<FoodSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<FoodSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={FoodFeatured}
                />}

            {category === 'jobs' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Jobs"
                    ItemPreview={JobsPreview}
                    SideBar={(props) => (<JobsSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<JobsSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={JobsFeatured}
                />}

            {category === 'services' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Services"
                    ItemPreview={ServicePreview}
                    SideBar={(props) => (<ServicesSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<ServicesSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={ServicesFeatured}
                />}

            {category === 'travel' &&
                <CategoryPage
                    isProfilePage={true}
                    userId={userId}
                    dealerId={dealerId}
                    realestateAgency={realestateAgency}
                    slug={slug}

                    section={category}
                    description="Travel"
                    ItemPreview={TravelPreview}
                    SideBar={(props) => (<TravelSideBar onLoadCategories={onUpdateCategories} {...props} />)}
                    SideBarMobile={(props) => (<TravelSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
                    FeaturedItems={TravelFeatured}
                />}

        </>
    )
}

export default ProfileStore;