 import React, {Component} from 'react';
import { titleCase } from '../../../shared/util/string';
import {connect} from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';


class BlogPostPreview extends Component {
    // componentDidUpdate(){
    //     setTimeout(()=>{
    //         let myDiv = document.getElementById("blogContentPreviewDiv");
    //         myDiv.innerHTML = this.props.blogContent.substring(0, 100);
    //       }, 100);
    // }

    onClickHandler = () => {
        let urlSlug = `/blogs/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;
        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push(urlSlug || '/blogs');
        this.props.pageShouldRefresh(urlSlug);
    }

    render() {
        if (!this.props.slug) {
            return null; 
        }
        let {onClickOnFavorite = ()=>null} = this.props;

        let linkSlug = `/blogs/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`

        return (
            <div className="category-list_item blog-item-preview">
                
                {/* <button onClick={this.onClickHandler}>
                    <img src={this.props.featuredImage} alt={this.props.title} />
                </button> */}
                <Link to={linkSlug} onClick={this.onClickHandler}>
                    <img src={this.props.featuredImage} alt={this.props.title} />
                </Link>


                <div className="slp">
                    <p className="slp-title">{this.props.title}</p>
                    <p className="slp-brand">
                        <span>{getTranslatedPhrase(this.props.userLanguage,'by')}</span> {this.props.authorName} / <span>{getTranslatedPhrase(this.props.userLanguage,'in')}</span> {getTranslatedPhrase(this.props.userLanguage,titleCase(this.props.category.replace(/-/g,' ')))}
                    </p>

                    <p className="slp-desc">
                        {this.props.blogContent && this.props.blogContent.replace(/(<([^>]+)>)/g, "").substring(0,115)+"..."}
                    </p>

                    <div className="slp-buttons">
                        <Link className="button button-clear button-small dsp-in-blk" to={linkSlug} onClick={this.onClickHandler}>{getTranslatedPhrase(this.props.userLanguage,'Read More')}</Link>
                        <ListingPreviewAdminPanel
                          itemType="blogs"
                          itemSlug={this.props.slug}
                          itemId={this.props.itemId}
                          userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                        />
                    </div>

                    <div className="slp-social">
                        <EngageBox
                          websiteSection={"blogs"}
                          itemId={this.props.itemId}
                          authorId={this.props.authorId}
                          withComments={true}
                          hideAllComments={true}
                          getClickToCommentEvent={true}
                          onLeaveComment={this.onClickHandler}
                          onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToBlogPostPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToBlogPostPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToBlogPostPreviewProps, mapDispatchToBlogPostPreviewProps)(withRouter(BlogPostPreview));