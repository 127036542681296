import React, { useEffect, useState } from 'react';
import PostComment from './PostComment/PostComment';
import PostCommentReply from './PostComment/PostCommentReply';
import AddCommentReply from './AddCommentReply';
import Box from '../../../util/box';
import { hostNameAndPort } from '../../../util/vars';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { getTranslatedPhrase } from '../../../util/lang';
import {connect} from 'react-redux';
import actionType from '../../../../redux-store/action-type';


const InvisibleReplyBox = (props) => {
    return (
        <PostCommentReply>
            <AddCommentReply
                PostCommentUserName={props.userName}
                active={props.active}
                onCloseCommentBox={props.onCloseCommentBox}
                onSubmitComment={props.onSubmitComment}
            >
                {props.children}
            </AddCommentReply>
        </PostCommentReply>
    );
}

function CommentTree(props) {
    const [commentList, setCommentList] = useState([]);
    const [replyList, setReplyList] = useState([]);
    const [clickedToComment, setClickedToComment] = useState(false);
    const [maxCommentCount, setMaxCommentCount] = useState(5);
    const { websiteSection, itemId, userIsAuthenticated, replyTo, userLanguage, loggedInUser } = props;

    useEffect(() => {
        getCommentsForItem(websiteSection, itemId);
    }, [websiteSection, itemId]);

    const getCommentsForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let commentData = new FormData();
        commentData.append('websiteSection', c);
        commentData.append('itemId', b);
        commentData.append('commentReplyTo', replyTo);
        commentData.append('start', 0);
        commentData.append('count', 20);
        let options = {
            url: `${hostNameAndPort}/api/engage/comments/items`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await axios(options);
            let { data } = result;
            if (!data.error) {
                setCommentList(data.items);
                setReplyList(Array(data.items.length).fill(false));
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const onClickToComment = (n) => {
        if (!userIsAuthenticated) {
            return;
        }
        if (n < 0) {
            setClickedToComment(true);
        } else {
            let r = replyList;
            r[n] = true;
            setReplyList(r);
        }
    }

    const onCloseCommentBox = (n) => {
        if (n < 0) {
            setClickedToComment(false);
        } else {
            let r = replyList;
            r[n] = false;
            setReplyList(r);
        }
    }

    const onLikeComment = async (n, s) => {
        if (!userIsAuthenticated) {
            return;
        }
        let _commentId, _commentAuthorId;
        if (n < 0) {
            _commentId = replyTo;
            _commentAuthorId = props.commentAuthorId;
        } else {
            _commentId = commentList[n]._id;
            _commentAuthorId = commentList[n].commentAuthorId;
        }
        if (s) {
            onClickToRemoveLike(n);
            return;
        }
        let commentData = new FormData();
        commentData.append(`itemId`, _commentId);
        commentData.append(`itemAuthorId`, _commentAuthorId);
        let options = {
            url: `${hostNameAndPort}/api/engage/addlike/comments`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await axios(options);
            let { data } = result;
            if (!data.error) {
                //    
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const onClickToRemoveLike = async (n, s) => {
        let _commentId, _commentAuthorId;
        if (n < 0) {
            _commentId = replyTo;
            _commentAuthorId = props.commentAuthorId;
        } else {
            _commentId = commentList[n]._id;
            _commentAuthorId = commentList[n].commentAuthorId;
        }
        if (s) {
            onLikeComment(n);
            return;
        }
        let commentData = new FormData();
        commentData.append(`itemId`, _commentId);
        commentData.append(`itemAuthorId`, _commentAuthorId);
        let options = {
            url: `${hostNameAndPort}/api/engage/removelike/comments`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await axios(options);
            let { data } = result;
            if (!data.error) {
                //
            }
        } catch (err) {
            //console.log(err)
            //console.log('Network error');
        }
    }

    const increaseMaxCommentCount = () => {
        let n = maxCommentCount;
        n = n + 40;
        setMaxCommentCount(n);
    }

    if (!props.commentAuthorSlug) {
        return null;
    }

    let currentLoggedInUser = { userName: "Default User", profileImage: "" };

    if (loggedInUser) {
        currentLoggedInUser = loggedInUser;
    }

    return (<>

        <PostComment
            PostCommentId={replyTo}
            PostCommentEditable={props.commentEditable}
            userIsAuthenticated={userIsAuthenticated}
            PostCommentUserName={props.commentAuthorName}
            PostTheComment={props.comment}
            PostCommentIsLiked={props.commentIsLiked}
            PostCommentNoLikes={props.commentLikeCount}
            PostCommentDateStamp={moment(props.commentCreationDate).local().format('MMM D, YYYY') + ' / ' + moment(props.commentCreationDate).local().format('H:m A')}
            onReplyToComment={() => onClickToComment(-1)}
            onLikeComment={(s) => onLikeComment(-1, s)}
            onRemoveComment={props.onRemoveComment}
        >
            <Link to={'/' + props.commentAuthorSlug} onClick={() => props.pageShouldRefresh(props.commentAuthorSlug)}><img alt={props.commentAuthorName} src={props.commentAuthorProfileImage} /></Link>
        </PostComment>

        {(!loggedInUser) ? null : (
            <PostCommentReply>
                <AddCommentReply
                    PostCommentUserName={currentLoggedInUser.userName}
                    active={clickedToComment}
                    userLanguage={userLanguage}
                    onCloseCommentBox={() => onCloseCommentBox(-1)}
                    onSubmitComment={(c) => props.onAddComment(c, replyTo)}
                >
                    <img alt={currentLoggedInUser.userName} src={currentLoggedInUser.profileImage} />
                </AddCommentReply>
            </PostCommentReply>)}

        {commentList.map((v, i) => (i < maxCommentCount) ? (
            <Box key={i} >
                <PostCommentReply>
                    <PostComment
                        PostCommentId={v._id}
                        PostCommentEditable={v.commentEditable}
                        userIsAuthenticated={userIsAuthenticated}
                        PostCommentUserName={v.commentAuthorName}
                        PostTheComment={v.comment}
                        PostCommentIsLiked={v.commentIsLiked}
                        PostCommentNoLikes={v.commentLikeCount}
                        PostCommentDateStamp={moment(v.commentCreationDate).local().format('lll')}
                        onReplyToComment={() => onClickToComment(i)}
                        onLikeComment={(s) => onLikeComment(i, s)}
                        onRemoveComment={props.onRemoveComment}
                    >
                        <Link to={'/' + v.commentAuthorSlug} onClick={() => props.pageShouldRefresh(v.commentAuthorSlug)}><img alt={v.commentAuthorName} src={v.commentAuthorProfileImage} /></Link>
                    </PostComment>
                </PostCommentReply>

                <InvisibleReplyBox
                    userName={currentLoggedInUser.userName}
                    active={replyList[i]}
                    onCloseCommentBox={() => onCloseCommentBox(i)}
                    onSubmitComment={(c) => props.onAddComment(c, replyTo)}
                >
                    <img alt={currentLoggedInUser.userName} src={currentLoggedInUser.profileImage} />
                </InvisibleReplyBox>
            </Box>
        ) : null)
        }


        {
            commentList.length < maxCommentCount ? null : (
                <button className="show-more-link view-more-replies" onClick={increaseMaxCommentCount}>{getTranslatedPhrase(userLanguage, 'View More Replies') + '...'}</button>
            )
        }

    </>
    );
}


const mapDispatchToCommentTreeProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p })
    };
};

export default connect(null, mapDispatchToCommentTreeProps)(CommentTree);