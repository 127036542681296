import React, { useEffect, useState } from 'react';
import ConversationListItem from '../Sidebar/ConversationListItem';
import ContactListItem from '../Sidebar/ContactListItem';
import Toolbar from '../Toolbar/Toolbar';
import LoadingSvg from '../../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import axios from 'axios';
import { useSelector, connect, useDispatch } from 'react-redux';
import actionType from '../../../../../redux-store/action-type';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// function sleep(timeout) {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve();
//     }, timeout);
//   });
// }

function ToolbarInput(props) {
  const { rightItems, keyword } = props;

  return (
    <div className="toolbar">
      <input type='text' placeholder={`Search ${keyword}`} className="form-input-text form-input-full" style={{ margin: '10px', height: "4rem", padding: "5px 10px" }} onChange={props.onChange} />
      <div className="right-items">{rightItems}</div>
    </div>
  );
}

function ConversationList(props) {

  const loggedInUser = useSelector(state => state.loggedInUser);
  const messagesDefaultMessage = useSelector(state => state.messagesDefaultMessage);
  const messagesDefaultUser = useSelector(state => state.messagesDefaultUser);

  // updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),

  const dispatch = useDispatch();
  const { conversationId = '' } = useParams();

  const history = useHistory();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [chatTitle, setChatTitle] = useState('Chats');
  const [searchBar, toggleSearchBar] = useState(false);
  const [searchUser, setSearchUser] = useState(new RegExp('', 'ig'));
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState('');
  const [contacts, setContacts] = useState([])
  const [contactIdForLoggedInUser, setContactIdForLoggedInUser] = useState('');

  const { refreshLists, stopRefreshList } = props;
  let loggedInUserId = null;


  if (loggedInUser) {
    loggedInUserId = loggedInUser.userId;
  } else {
    loggedInUserId = null;
  }


  useEffect(() => {
    const loadData = async () => {
      await loadContacts();
    }

    if (!loggedInUserId) {
      return;
    }

    loadData();

    console.log('loading contacts....');

  }, [])



  useEffect(() => {
    const loadData = async () => {
      await loadContacts();
    }

    if (!loggedInUserId) {
      return;
    }

    loadData();
    stopRefreshList();

  }, [chatTitle, activeConversation, refreshLists, loggedInUserId])


  useEffect(() => {


    let conversationExists = false;

    for (let conversation of conversations) {
      if (activeConversation === conversation._id) {
        conversationExists = true;
        break
      }
    }

    if (conversations.length && !conversationExists) history.push('/')

    updateConversationToSeen();
  }, [activeConversation, dataLoaded])


  const loadContacts = async () => {
    try {
      let result = await axios(`${hostNameAndPort}/api/chat/allcontacts/${loggedInUser.userId}`)

      let { data } = result;

      if (!data.error) {

        setContacts(data.contacts);
        setContactIdForLoggedInUser(data.contactId)
        await loadConversations(data.contactId);
        setDataLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    const createNewConversationFromStore = async () => {
      try {

        console.log(messagesDefaultMessage, 'toUser:', messagesDefaultUser, 'fromUser', contactIdForLoggedInUser)

        let result = await axios({
          url: `${hostNameAndPort}/api/chat/create-conversation-from-store`,
          method: 'post',
          data: {
            user1Id: contactIdForLoggedInUser,
            user2FoId: messagesDefaultUser.userId,
            messageContent: messagesDefaultMessage
          }
        });

        let { data } = result;

        if (!data.error) {

          dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: {}, messagesDefaultMessage: '' })

          if (data.conversation._id !== activeConversation) {
            setChatTitle('Chats');
            setActiveConversation(data.conversation._id);
            props.onClickListItem(data.conversation._id, contactIdForLoggedInUser)
          }
        } else {
          console.log(data.error);
        }

      } catch (err) {
        console.log(err);
      }

      // console.log(messagesDefaultMessage, 'toUser:', messagesDefaultUser)
    }

    if (messagesDefaultMessage && contactIdForLoggedInUser) {
      createNewConversationFromStore();
    }
  }, [messagesDefaultMessage, contactIdForLoggedInUser])


  useEffect(() => {
    if (conversationId && activeConversation !== conversationId && contactIdForLoggedInUser) {
      openConversationHandler(conversationId);
    }
  }, [conversationId, contactIdForLoggedInUser])



  const loadConversations = async (contactId) => {
    try {
      let result = await axios(`${hostNameAndPort}/api/chat/allconversations/${contactId}`)

      let { data } = result;

      if (!data.error) {
        setConversations(data.conversations);
      }

    } catch (err) {
      console.log(err);
    }
  }


  const updateConversationToSeen = async () => {
    try {

      if (!activeConversation || !contactIdForLoggedInUser) {
        return;
      }

      let result = await axios({
        url: `${hostNameAndPort}/api/chat/conversation/${activeConversation}/${contactIdForLoggedInUser}`,
        method: 'patch'
      })

    } catch (err) {
      console.log(err);
    }
  }

  const showSearchUserBar = () => {
    toggleSearchBar(!searchBar);
  }

  const closeSearchBar = () => {
    toggleSearchBar(!searchBar);
    setSearchUser(new RegExp('', 'i'));
  }

  const showContactList = () => {
    setChatTitle('Contacts')
  }

  const showConversations = () => {
    setChatTitle('Chats')
  }

  const isLoading = false;

  function onChangeSearchUser(e) {
    let s = new RegExp(e.target.value, 'ig');
    setSearchUser(s);
  }

  const createNewConversationHandler = async (otherContactId) => {
    try {
      let result = await axios({
        url: `${hostNameAndPort}/api/chat/conversation`,
        method: 'post',
        data: {
          user1Id: contactIdForLoggedInUser,
          user2Id: otherContactId,
        }
      });

      let { data } = result;

      if (!data.error) {
        if (data.conversation._id !== activeConversation) {
          setChatTitle('Chats');
          setActiveConversation(data.conversation._id);
          props.onClickListItem(data.conversation._id, contactIdForLoggedInUser)
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  const openConversationHandler = (id) => {
    setActiveConversation(id);
    props.onClickListItem(id, contactIdForLoggedInUser)
  }



  if (conversations.length > 0 && activeConversation === '') {
    history.push(`/chat/${conversations[0]._id.toString()}`)
  }










  return (
    <div className="conversation-list">
      {!searchBar ? (
        <Toolbar
          title={chatTitle}
          rightItems={<>
            <button onClick={showConversations} className={chatTitle === 'Chats' ? 'active' : ''}><i className="fal fa-comments"></i></button>
            <button onClick={showContactList} className={chatTitle === 'Contacts' ? 'active' : ''}><i className="fal fa-users"></i></button>
            <button onClick={showSearchUserBar}><i className="fal fa-search" /></button>
          </>
          }
        />
      ) : (
        <ToolbarInput
          onChange={onChangeSearchUser}
          keyword={chatTitle}
          rightItems={<button onClick={closeSearchBar}><i className="fal fa-times" /></button>}
        />
      )}

      {chatTitle === 'Chats' && contacts.length > 0 &&
        <button className="conversation-list-new-chat-btn" onClick={showContactList}><i className="fal fa-plus"></i> New Chat</button>
      }

      {chatTitle === 'Chats' && contacts.length === 0 &&
        <p className="conversation-list-no-contacts">No chats started.</p>
      }

      {chatTitle === 'Contacts' && contacts.length === 0 &&
        <p className="conversation-list-no-contacts">Follow users to start writing messages.</p>
      }

      {chatTitle === 'Chats' &&
        conversations.map((conversation, i) => (!!searchUser && searchUser.test(conversation.entityName) && conversation._id !== '000000000000000000000000') ? (
          <ConversationListItem
            key={conversation._id}
            data={conversation}
            onClick={() => openConversationHandler(conversation._id)}
            activeConversationMarker={conversation._id.toString() === activeConversation.toString()}
            contactIdForLoggedInUser={contactIdForLoggedInUser}
          />) : null
        )
      }

      {
        chatTitle === 'Contacts' &&

        contacts.map(contact => (!!searchUser && searchUser.test(contact.entityName) && contact._id !== '000000000000000000000000') ? (
          <ContactListItem
            key={contact._id}
            data={contact}
            onClick={() => {
              createNewConversationHandler(contact._id)
            }
            }
          />) : null
        )
      }

      {isLoading ? (<LoadingSvg active={true} />) : null}
    </div>
  );
}





export default ConversationList;

