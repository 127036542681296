import React from 'react';
import shortFormNumber from './ShortFormNumber'
const defaultClick = () => null

const EngageButtonsComment = (props) => {
    let e = (!props.isClicked)?'l':'s';
    if (!props.isDisabled) {
        return (
            <button className="engage_buttons_comments" onClick={props.onClick || defaultClick}>
             <i className={`fa${e} fa-comment-alt`} /> <span className="engage_button_numbers">{shortFormNumber(props.noComments)}</span>
            </button>)
    } 
    return (
        <span className="engage_buttons_comments">
        <i className={`fa${e} fa-comment-alt`} /> <span className="engage_button_numbers">{shortFormNumber(props.noComments)} </span>
        </span>)
}

export default EngageButtonsComment