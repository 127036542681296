import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { titleCase } from '../../util/string';
import { getTranslatedPhrase } from '../../util/lang';

const bcInit = {
    sectionLink: '/',
    categoryLink: '/',
    sectionLabel: '',
    categoryLabel: '',
    postLabel: '',
}

const Breadcrumb = (props) => {
    const locationPathnameProps = props.location.pathname;
    const [bcState, setBcState] = useState(bcInit);
    const userLanguage = useSelector(state => state.userLanguage);

    useEffect(() => {
        let p = locationPathnameProps;
        let m = p.split('/');

        let s, c, l;
        const registeredPages = ['blogs', 'photos', 'videos', 'events', 'groups', 'store', 'autos', 'food', 'coupons', 'realestate', 'jobs', 'services', 'travel', 'follow-requests', 'search',
            'affiliateurl', 'logout', 'signin', 'login', 'signout', 'about', 'meet-the-team', 'directory', 'help', 'careers', 'press', 'admin', 'paid-notifications', 'create-ppv-campaign', 'news',
            'affiliates', 'investors', 'start-ups', 'advertise', 'privacy', 'terms-of-service', 'copyright', 'contact', 'downloadapp', 'specials', 'cash-in-application', 'reseller-application'];

        props.updateWindowLocation(p)

        const registeredEditPages = ['new-auto-listing', 'edit-auto-listing', 'new-product', 'edit-product', 'new-food-listing', 'edit-food-listing', 'new-property', 'edit-property', 'new-travel', 'edit-travel', 'new-job', 'edit-job', 'new-service', 'edit-service', 'new-event', 'edit-event'];

        m.shift();

        let pageName;

        if (m.length > 1 && registeredEditPages.indexOf(m[1]) >= 0) {
            pageName = m.pop();
        }

        if (m.length < 2) {
            s = m[0];
            c = '';
        } else {
            s = m[0];
            c = m[1];
        }

        if (!c && (registeredPages.indexOf(s) < 0 || registeredEditPages.indexOf(pageName) >= 0)) {
            s = '';
        }

        if ((registeredPages.indexOf(s) < 0 || registeredEditPages.indexOf(pageName) >= 0)) {
            s = '';
        }

        if (s === 'chat') {
            s = '';
        }

        if (m.length === 3) {
            l = titleCase(m[2].replace(/-[a-f0-9]+$/, '').replace(/[\W]+/g, ' ').replace(/-/g, ' '));
        } else {
            l = '';
        }
        if ((bcState.sectionLink === '/' + s) && (bcState.categoryLink === '/' + s + '/' + c) &&
            (bcState.postLabel === l)) {
            return;
        }

        setBcState(prevBc => {
            return {
                ...prevBc,
                sectionLink: '/' + s,
                sectionLabel: getLabelFor(s),
                categoryLink: '/' + s + '/' + c,
                categoryLabel: getLabelFor(c),
                postLabel: l,
            }
        })
    }, [locationPathnameProps])


    const getLabelFor = (s) => {
        let result = '';
        switch (s) {
            case 'store':
                result = 'Store';
                break;
            case 'autos':
                result = 'Auto';
                break;
            case 'food':
                result = 'Food';
                break;
            case 'realestate':
                result = 'Real Estate';
                break;
            case 'blogs':
                result = 'Blogs';
                break;
            case 'photos':
                result = 'Photo Galleries';
                break;
            case 'videos':
                result = 'Videos';
                break;
            case 'events':
                result = 'Events';
                break;
            case 'groups':
                result = 'Groups';
                break;
            case 'jobs':
                result = 'Jobs';
                break;
            case 'services':
                result = 'Services';
                break;
            case 'coupons':
                result = 'Coupons';
                break;
            case 'travel':
                result = 'Travel';
                break;
            case 'search':
                result = 'Search';
                break;
            default:
                result = titleCase(s.replace(/-/g, ' '));
                break;
        }
        return result;
    }

    // const onClickBack = (e) => {
    //     props.history.goBack();
    //     // this.props.pageShouldRefresh(''+(new Date().getTime()));
    // }

    return (
        (!bcState.sectionLabel) ? null : (
            <div className="breadcrumb">
                {/* <button onClick={this.onClickBack} className="breadcrumb-return"><i className="fas fa-arrow-left"></i></button> */}
                <Link to="/">{getTranslatedPhrase(userLanguage, 'Home')}</Link>
                <span>/</span>
                {(!!bcState.sectionLabel) ? <Link to={bcState.sectionLink}>{getTranslatedPhrase(userLanguage, bcState.sectionLabel)}</Link> : null}
                {(!!bcState.categoryLabel) ? <span>/</span> : null}
                { (bcState.categoryLabel === "Search" && !!bcState.categoryLabel) ? getTranslatedPhrase(userLanguage, bcState.categoryLabel) : null}
                { (bcState.categoryLabel !== "Search" && !!bcState.categoryLabel) ? <Link to={bcState.categoryLink}>{getTranslatedPhrase(userLanguage, bcState.categoryLabel)}</Link> : null}
                {(!!bcState.postLabel) ? <span>/</span> : null}
                {(!!bcState.postLabel) ? bcState.postLabel : null}
            </div>
        )
    );
}


const mapDispatchToBreadcrumbProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p }),
        updateWindowLocation: (p) => dispatch({ type: actionType.UPDATE_LOCATION, windowLocation: p })
    };
};

export default connect(null, mapDispatchToBreadcrumbProps)(withRouter(Breadcrumb));