import React from 'react';
import Backdrop from '../Backdrop/Backdrop';

const Modal = props => {
    return (
        <div className="modal-wrapper">
            <Backdrop show={true} clicked={props.closeModal} />
            <div className={`modal modal-${props.modalClass || 'small'}`}>

                <div className={`modal_body modal-${props.modalBodyClass || null}`}>
                    <div className={`modal_header modal-${props.modalHeaderClass || null}`}>
                        <h4>{props.title}</h4>
                    </div>
                    <div className={`modal_content modal-${props.modalContentClass || null}`}>
                        {props.children}
                    </div>

                    <span className="modal_close" onClick={props.closeModal}><i className="fal fa-times" /></span>
                </div>
            </div>
        </div>
    )
}

export default Modal;