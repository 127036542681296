import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import CreateCouponModal from '../../../../shared/components/Modals/Account/CreateCouponModal';
import moment from 'moment';
import Axios from 'axios';
import { hostNameAndPort } from '../../../../shared/util/vars';
import ListLoader from '../../../../shared/util/list-loader';
import SettingsMenu from "../SettingsMenu";


class ManangeCoupons extends Component {

    state = {
        showCouponModal: false,
        couponList: [],
        deleteItemId: '',
        currentCoupon: null,
        Loader: null,
        loaded: false,
        waitingForDeleteConfirmation: false,
        deleteOperationConfirmed: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.initLoader({ filter: JSON.stringify({ author: 1, showInactive: 1 }) });
        }, 500);
    }

    componentDidUpdate() {
        if (this.state.deleteOperationConfirmed === this.props.deleteOperationConfirmed) {
            return;
        }
        if (!this.props.deleteOperationConfirmed) {
            this.setState({
                waitingForDeleteConfirmation: false,
                deleteOperationConfirmed: false,
                deleteItemId: ''
            });
            return;
        }
        else {
            if (!!this.state.waitingForDeleteConfirmation) {
                this.onDeleteConfirmedNow(this.state.deleteItemId);
                this.setState({
                    waitingForDeleteConfirmation: false,
                    deleteOperationConfirmed: false,
                    deleteItemId: ''
                });
            }
            this.props.resetDeleteOperationConfirmation();
        }
    }

    initLoader = (formParams) => {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 3000,
                count: 20,
                request: {
                    url: '/api/coupons/items',
                    method: 'post',
                    formParams
                }
            }
        });
        Loader.init();
        this.setState({ Loader });
    }

    onLoad = () => {
        if (!this.state.Loader || !this.state.Loader.items) return;
        this.setState({ couponList: this.state.Loader.items, loaded: true });
    }

    displayCouponValue = (value) => {
        if (isNaN(value)) return '';
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }

    onClickDeleteHandler = (id) => {
        this.setState({ waitingForDeleteConfirmation: true, deleteItemId: id });
        this.props.onShowConfirmDeleteModal(getTranslatedPhrase(this.props.userLanguage, 'This will remove the coupon from all your items. Please confirm.'));
    }

    onUpdateList = (id) => {
        let a = this.state.couponList;
        a = a.filter(v => (v.itemId !== id));
        if (!a || !a.length) a = [];
        this.setState({ couponList: [...a] });
    }

    onDeleteConfirmedNow = async (id) => {

        this.onUpdateList(id);

        let options = {
            url: hostNameAndPort + '/api/coupons/' + id,
            method: 'delete',
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.onUpdateList(id);
            }
        } catch (err) {
            alert(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEMNGCPN01'));
        }
    }

    onClickNewCoupon = (e) => {
        this.setState({ showCouponModal: true, currentCoupon: null });
    }

    onCloseCouponModal = (_newCoupon) => {
        let a = this.state.couponList;
        if (!!_newCoupon && !this.state.currentCoupon) {
            a.unshift(_newCoupon);
        } else if (!!_newCoupon) {
            for (let i in a) {
                if (a[i].itemId === this.state.currentCoupon.itemId) {
                    a[i] = _newCoupon;
                    break;
                }
            }
        }
        this.setState({ showCouponModal: false, couponList: a });
    }

    onEditCoupon = (c) => {
        this.setState({ currentCoupon: c || null, showCouponModal: true });
    }

    render() {
        return (
            <SettingsMenu ManangeCouponsLiActive="active">



                <ProfileSettingsBox>

                    <div className="manage-coupons-box">
                        <h4>{getTranslatedPhrase(this.props.userLanguage, 'Manage Coupons')}</h4>

                        <table>
                            <tbody>
                                <tr className="table-header">
                                    <td>{getTranslatedPhrase(this.props.userLanguage, 'Coupon Code')}</td>
                                    <td>{getTranslatedPhrase(this.props.userLanguage, 'Value')}</td>
                                    <td>{getTranslatedPhrase(this.props.userLanguage, 'Start Date')}</td>
                                    <td>{getTranslatedPhrase(this.props.userLanguage, 'End Date')}</td>
                                    <td>{' '}</td>
                                </tr>

                                {this.state.couponList.map((v) => (<tr key={v.title}>
                                    <td>{!v.isDeal ? (!!v.active ? v.title : v.title + ' - EXPIRED') : 'DEAL'}</td>
                                    <td>{(v.couponCurrency === '%') ? v.couponValue + '%' : this.displayCouponValue(v.couponValue) + v.couponCurrency}</td>
                                    <td>{moment(v.startDate).format('L')}</td>
                                    <td>{moment(v.endDate).format('L')}</td>
                                    <td><button onClick={() => this.onEditCoupon(v)}>{getTranslatedPhrase(this.props.userLanguage, 'Edit')}</button> <span>/</span>
                                        <button onClick={() => this.onClickDeleteHandler(v.itemId)}>{getTranslatedPhrase(this.props.userLanguage, 'Delete')}</button> </td>
                                </tr>))}
                            </tbody>
                        </table>

                        <button onClick={this.onClickNewCoupon} className="button button-regular"><i className="fal fa-plus" /> {getTranslatedPhrase(this.props.userLanguage, 'New Coupon Code')}</button>
                    </div>

                </ProfileSettingsBox>


                <CreateCouponModal
                    active={this.state.showCouponModal}
                    onClose={this.onCloseCouponModal}
                    oldCoupon={this.state.currentCoupon}
                    initialPrice={999999999999}
                    initialWarning={!this.state.currentCoupon ? '' : getTranslatedPhrase(this.props.userLanguage, 'Changes apply to all products using this coupon!')}
                    couponCurrency=''
                />
            </SettingsMenu>
        );
    }
}

const mapStateToManageCouponsProps = (
    state,
    props
) => {
    return {
        deleteOperationConfirmed: state.deleteOperationConfirmed,
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToManageCouponsProps = (
    dispatch
) => {
    return {
        onShowConfirmDeleteModal: (msg) => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true, confirmDeleteOperationModalMessage: msg });
        },
        resetDeleteOperationConfirmation: () => {
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        // showAlert: (m, r, s) => {
        //     dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m, alertModalRedirect: r, alertModalButtonLabel: s });
        // },
    };
};

export default connect(mapStateToManageCouponsProps, mapDispatchToManageCouponsProps)(ManangeCoupons);