import React, { Component } from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import shortFormNumber from '../../../uielements/Engage/EngageButtons/ShortFormNumber';

class AccountStats extends Component {
    render () {
        return (
            <div className="main-sidebar_box main-sidebar_box_admin">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Stats')}</h4>
                {/*<p className="account-level">{getTranslatedPhrase(this.props.userLanguage,'Level')}: {this.props.AccountLevel}</p>*/}

                <div className="account-stats">
                    <div className="account-stats_box">
                        <div className="account-stats_box_content">
                            <div>{shortFormNumber(this.props.NoOfFollowers) || 0}</div>
                            <span>{getTranslatedPhrase(this.props.userLanguage,'Followers')}</span>
                        </div>
                    </div>

                    <div className="account-stats_box">
                        <div className="account-stats_box_content">
                            <div>{shortFormNumber(this.props.NoOfPosts) || 0}</div>
                            <span>{getTranslatedPhrase(this.props.userLanguage,'Listings')}</span>
                        </div>
                    </div>

                    <div className="account-stats_box">
                        <div className="account-stats_box_content">
                            <div>{shortFormNumber(this.props.NoOfViews) || 0}</div>
                            <span>{getTranslatedPhrase(this.props.userLanguage,'Views')}</span>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

const mapStateToAccountStatsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToAccountStatsProps, null)(AccountStats);