import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import { connect } from 'react-redux';


class SubCategories extends Component {
    state = {
        showMoreOrLess: 'Show More',
        subcategoryCount: 10,
        userLanguage: '',
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({
                userLanguage: v,
                showMoreOrLess: getTranslatedPhrase(this.props.userLanguage, this.state.showMoreOrLess)
            });
        }
    }

    onClickMore = () => {
        let s = this.state.showMoreOrLess;
        if (s === getTranslatedPhrase(this.props.userLanguage, 'Show More')) {
            s = getTranslatedPhrase(this.props.userLanguage, 'Show Less');
        } else {
            s = getTranslatedPhrase(this.props.userLanguage, 'Show More');
        }
        let n = this.state.subcategoryCount;
        let a;
        if (!this.props.subcategoryList) {
            a = 10;
        } else {
            a = this.props.subcategoryList.length;
        }

        if (n === a) {
            n = 10;
        } else {
            n = a;
        }
        this.setState({
            showMoreOrLess: s,
            subcategoryCount: n,
        });
    }

    onClickHandler = (v) => {
        if (v !== this.props.activeSubcategory) this.props.onChangeSubcategory(v);
    }

    getClassNameFor = (v) => {
        if (v !== this.props.activeSubcategory) return 'main-sidebar_category-active';
        return 'main-sidebar_category-inactive';
    }

    render() {
        if (!this.props.subcategoryList || !this.props.subcategoryList.length) {
            return null;
        }
        else {
            return (
                <div className="main-sidebar_box main-sidebar_box-subcategories">
                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Subcategories')}</h4>

                    <ul>
                        <li><button className={this.getClassNameFor('')} onClick={() => this.props.onChangeSubcategory('')}>{getTranslatedPhrase(this.props.userLanguage, 'All Subcategories')}</button></li>
                        {this.props.subcategoryList.slice(0, this.state.subcategoryCount).map((v, i) => (<li key={i}>
                            <button className={this.getClassNameFor(v)} onClick={() => this.onClickHandler(v)}>{getTranslatedPhrase(this.props.userLanguage, (this.props.section === 'realestate') ? v.replace(/_/g, '/') : titleCase(v.replace(/-/g, ' ')))}</button>
                        </li>))}
                    </ul>

                    {!!this.props.subcategoryList && this.props.subcategoryList.length > 10 ? (<button onClick={this.onClickMore} className="show-more-link">{this.state.showMoreOrLess}</button>) : null}
                </div>
            );
        }
    }
}

const mapStateToSubCategoriesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage
    };
};

export default connect(mapStateToSubCategoriesProps, null)(SubCategories);