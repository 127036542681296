import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import PhotosFeaturedPreview from './PhotosFeaturedPreview';

export default function PhotosFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={PhotosFeaturedPreview}
        />
    );
}