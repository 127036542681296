import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import ListingHeader from "../../../shared/templates/ListingTemplate/components/ListingHeaderAuto";
import ListingPhotos from "../../../shared/templates/ListingTemplate/components/ListingPhotos";
import AutosListingSidebar from "./AutoListing-sidebar";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { Link, withRouter } from "react-router-dom";
import { titleCase } from "../../../shared/util/string";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import AutoDoors from './Assets/auto-doors.svg';
import AutoDriveTrain from './Assets/auto-drivetrain.svg';
import AutoEngine from './Assets/auto-engine.svg';
import AutoExteriorColor from './Assets/auto-exterior-color.svg';
import AutoFuelType from './Assets/auto-fueltype.svg';
import AutoInteriorColor from './Assets/auto-interior-color.svg';
import AutoMiles from './Assets/auto-miles.svg';
import AutoMPG from './Assets/auto-mpg.svg';
import AutoTrans from './Assets/auto-transmission-auto.svg';
import AutoClutch from './Assets/auto-transmission-clutch.svg';
import AutoCertified from './Assets/auto-certified.svg';
import AutoCleanTitle from './Assets/auto-clean-title.svg';
import AutoOneOwner from './Assets/auto-one-owner.svg';

import { formatPriceAndCurrency } from "../../../shared/util/number";
import { connect, useSelector } from 'react-redux';
import { hostNameAndPort } from '../../../shared/util/vars';
import Axios from 'axios';

// import ListingMeta from '../../../shared/templates/ListingTemplate/components/ListingMeta';
// import ListingSpecs from '../../../shared/templates/ListingTemplate/components/ListingSpecs';
// import ListingDescription from '../../../shared/templates/ListingTemplate/components/ListingDescription';
// import ListingPolicies from '../../../shared/templates/ListingTemplate/components/ListingPolicies';
// import ListingMap from '../../../shared/templates/ListingTemplate/components/ListingMap';
// import ListingReviews from '../../../shared/templates/ListingTemplate/components/ListingReviews';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import actionType from '../../../redux-store/action-type';


const AutoListingDetail = props => { 

  const [car, setCar] = useState(null);
  const [pageViewStart, setPageViewStart] = useState(moment());
  const [author, setAuthor] = useState();

  // Andrei Check - why we don't use pageRefreshLocal ?
  const [pageRefreshLocal, setPageRefresh] = useState('this-auto-listing' + Date.now());
  const [afterDiscountPrice, setAfterDiscountPrice] = useState('');
  const [fullDesc, setFullDesc] = useState(false);
  const [fullOptions, setFulloptions] = useState(false);
  const [fullFeat, setFullFeat] = useState(false);
  const [section, setSection] = useState('autos');

  const payperviewRedirect = useSelector(state => state.payperviewRedirect);
  const userLanguage = useSelector(state => state.userLanguage);
  const loggedInUser = useSelector(state => state.loggedInUser);
  const pageRefresh = useSelector(state => state.pageRefresh);
  const { resetPayperviewRedirect } = props;
  const { storeItem = '' } = props.match.params;

  useEffect(() => {
    // if (pageRefreshLocal !== pageRefresh) {
    setPageRefresh(pageRefresh);
    window.scrollTo(0, 0);
    if (storeItem) getListing(storeItem);
    // }
  }, [pageRefresh, storeItem]);

  useEffect(() => {
    if (!car || car === 'null' || car === 'undefined') return;
    let itemId = car.itemId;
    let socialItemId = car.socialAutoId;
    let currentSection = section;
    getAuthor(car.dealer_id);
    setTimeout(() => {
      if (moment().diff(moment(pageViewStart), 'seconds') >= 5) {
        if (!!payperviewRedirect) {
          handlePPVCampaignViewer('', itemId, currentSection, socialItemId);
          resetPayperviewRedirect();
        } else {
          handlePPVCampaignViewer('organic', itemId, currentSection, socialItemId);
        }
      }
    }, 5100);
  }, [car]);

  function handlePPVCampaignViewer(isOrganic, itemId, section, socialItemId) {
    if (!itemId) return;
    let ppvData = new FormData();
    ppvData.append('campaignId', payperviewRedirect);
    ppvData.append('itemId', itemId);
    ppvData.append('socialItemId', socialItemId);
    ppvData.append('itemType', section);
    let options = {
      url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
      method: 'post',
      data: ppvData
    };
    Axios(options)
      .then(result => {
        let { data } = result;
        if (!data.error) {
          // Success! Nothing to do
        }
      })
      .catch(error => {
        // Network error
      });
  }

  // function onAddReview(rating) {
  //   let reviewCount = car.reviewCount + 1;
  //   let averageRating = (car.averageRating * (reviewCount - 1) + rating) / reviewCount;
  //   let nextCar = {...car};
  //   nextCar.averageRating = averageRating;
  //   nextCar.reviewCount = reviewCount;
  //   setCar(nextCar);
  // }


  const getAuthor = (dealerId) => {
    let options = {
        url: `${hostNameAndPort}/api/user/dealer-by-id/${dealerId}`,
        method: 'get',
    };

    Axios(options)
    .then(result => {
        let { data } = result;
        if (!data.error) {
            setAuthor(data.user);
        }
        else {
            let emc = data.error;
            console.log(emc);
        }
    })
    .catch(err => {
      console.log(err);
    });
}

  function getListing(b) {
    if (!!car) {
      let nextCar = Object.assign({}, car);
      nextCar.photo_links = '';
      setCar(nextCar);
    }
    let url = `${hostNameAndPort}/api/autos/${b}`;
    Axios(url)
      .then(result => {
        let { data } = result;
        if (!data.error) {
          if (data.item.discountId) {
            if (moment(data.item.discountId.startDate).isAfter(moment(), 'days') || moment(data.item.discountId.endDate).isBefore(moment(), 'days')) {
              data.item.addDiscount = '';
              data.item.discountId = '';
            } else if (data.item.discountId.couponCurrency !== '%')
              data.item.discountId.couponValue = Number(data.item.discountId.couponValue) / 100;
          }
          if (!data.item.currency) data.item.currency = 'USD';

          data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
          if (data.item.brandName === 'null') data.item.brandName = '';
          if (!data.item.specList) {
            data.item.specList = [];
          }

          let moreSpecs = [];
          if (data.item.category === 'cars') {
            moreSpecs = [{
              specName: 'Year',
              specValue: data.item.autoYear
            }, {
              specName: 'Make',
              specValue: titleCase(data.item.autoMake.replace(/-/g, ' '))
            }, {
              specName: 'Model',
              specValue: data.item.autoModel
            }];
            if (!!data.item.autoTrim) {
              moreSpecs.push({
                specName: 'Trim',
                specValue: data.item.autoTrim
              });
            }
          } else {
            // data.item.specList.splice(0, 5);

            data.item.specList.push({
              specName: 'Sold By',
              specValue: titleCase(data.item.seller)
            });
          }
          data.item.specList = data.item.specList.map(v => ({ specName: v.specName, specValue: (v.specValue === true) ? 'Yes' : v.specValue }))
          data.item.specList = [...moreSpecs, ...data.item.specList];
          data.item.allPhotos = [data.item.featuredImage, ...data.item.extraPhotos];
          let theAddress = '';
          let a = data.item;

          console.log(a);

          if (!!a['address']) {
            theAddress = a['address'] + ',';
          }
          if (!!a['unit']) {
            theAddress = theAddress + ' #' + a['unit'] + ',';
          }
          if (!!a['city']) {
            theAddress = theAddress + ' ' + a['city'];
          }
          if (!!a['state'] && a['state'] !== 'n/a') {
            theAddress = theAddress + ' ' + a['state'];
          }
          if (!!a['zipCode']) {
            theAddress = theAddress + ', ' + a['zipCode'];
          }
          if (!!a['country']) {
            theAddress = theAddress + ', ' + a['country'];
          }

          data.item.theAddress = theAddress;



          data.item.price = Number(data.item.price) / 100;

          setSection('autos');
          setPageViewStart(moment());
          setCar(data.item);
          computeAfterDiscountPrice(data.item.price, data.item.currency, data.item.discountId);
        } else {
          // Try again on cars database
          Axios(`${hostNameAndPort}/api/cars/${b}`)
            .then(result => {
              let { data } = result;
              if (!data.error) {
                if (data.item.discountId) {
                  if (data.item.discountId.couponCurrency !== '%')
                    data.item.discountId.couponValue = Number(data.item.discountId.couponValue) / 100;
                }
                if (!data.item.currency) data.item.currency = 'USD';
                data.item.price = Number(data.item.price) / 100;

                setSection('cars');
                setCar(data.item);
              } else {
                props.history.push('/404');
              }
            })
            .catch(err => {
              props.history.push('/404');
            });
        }
      })
      .catch(err => {
        // console.log(err);
        props.history.push('/404');
      });
  }

  function computeAfterDiscountPrice(p, c, d) {
    // let c = s.currency;
    // let p = s.price;
    // let d = s.addDiscount;
    if (!!d) {
      let theDiscount = d.couponValue;
      if (!d.couponCurrency || d.couponCurrency === '%') {
        p = Math.round((p - Math.ceil(p * Number(theDiscount) / 100)) * 100) / 100;
      } else {
        p = Math.round((p - Number(theDiscount)) * 100) / 100;
      }
      if (p > 0) {
        setAfterDiscountPrice(p)
        // formatPriceAndCurrency(p, c, ','));
      } else {
        setAfterDiscountPrice('0');
      }
    } else {
      setAfterDiscountPrice('');
    }
  }

  function getCouponCode(v, cc, pc) {
    if (cc !== '%') {
      return formatPriceAndCurrency(v, pc, ',');
    }
    return v + cc;
  }

  function onClickContact(e) {
    if (!loggedInUser) {
      props.onShowLoginModal();
      return;
    }
    // if (loggedInUser.dealer_id === car.dealer_id) {
    //   props.showAlertModal(getTranslatedPhrase(userLanguage, 'This feature is not allowed!'));
    //   return;
    // }
    let u = {
      userId: car.authorId,
      userName: car.authorName,
      profileImage: car.authorProfileImage,
      slug: car.authorSlug
    }
    let m = window.location.href;
    props.updateMessagesPageProps(u, getTranslatedPhrase(userLanguage, 'I am writing in regards to this') + ' ' + getTranslatedPhrase(userLanguage, 'auto') + `: ${m}`);
    props.history.push('/chat');
  }


  let id = props.id;
  if (car && !!car.id) id = car.id;



  if (!car) return (<LoadingSvg active={true} />);

  let carTitle;
  let sellerName;
  let sellerPhone;
  let sellerEmail;
  let sellerType;
  let sellerWebsite;
  let theAddress;
  let allPhotos;
  let options;
  let allOptions;
  let initOptionsLength;
  let features;
  let allFeatures;
  let initFeaturesLength;
  let autoDescription = '';
  let initDescLength;
  let carPrice;
  let carCurrency;
  let carMSRP;
  let fspPrice;
  let cityMPG;
  let highwayMPG;
  let carMiles;
  let carStock;
  let carVin;
  let carYear;
  let carMake;
  let carModel;
  let carTrim;
  let carCondition;
  let carDrivetrain;
  let carGasType;
  let carEngine;
  let carTransmission;
  let carDoors;
  let carOneOwner;
  let carCleanTitle;
  let carCertified;
  let carExteriorColor;
  let carInteriorColor;
  let carDaysOnMarket;
  let carPhotoUrl;

  let addDiscount;
  let callForPricing;
  let discountId;

  let bikeCC;

  if (!id) {
    if (!car) return null;

    // console.log(car)

    const findSpec = (specName, type) => {
      let spec = {};
      let i = 0;
      for (; i < car.specList.length; i++) {
        if (car.specList[i].specName === specName) {
          spec = car.specList[i];
          break;
        }
      }
      if (type === 'city') {
        for (; i < car.specList.length; i++) {
          if (car.specList[i].specName === specName) {
            spec = car.specList[i];
            break;
          }
        }
      }
      if (type === 'highway') {
        for (; i < car.specList.length; i++) {
          if (car.specList[i].specName === specName) {
            spec = car.specList[i + 1];
            break;
          }
        }
      }

      return spec.specValue;
    }


    // console.log(car);

    carTitle = car.title;
    sellerName = car.authorName;
    sellerPhone = car.numberToCall;
    // sellerEmail = 'd';
    sellerType = car.seller;
    // sellerWebsite = 'f';
    theAddress = car.theAddress;
    allPhotos = car.allPhotos;
    carPrice = car.price;
    // carMSRP = 'j';
    carCurrency = car.currency;
    cityMPG = findSpec('Miles / Gallon', 'city');
    highwayMPG = findSpec('Miles / Gallon', 'highway');
    carMiles = findSpec("Mileage") || findSpec("Kilometers");
    bikeCC = findSpec("Cubic Capacity cc");
    // carStock = car.stock_no;
    carVin = findSpec("VIN");
    carYear = car.autoYear;
    carMake = car.autoMake;
    carModel = car.autoModel;
    carTrim = car.autoTrim;
    carCondition = car.productCondition;
    carDrivetrain = findSpec("Drive");
    carGasType = findSpec("Fuel Type");
    carEngine = findSpec("Cylinders");
    carTransmission = findSpec("Transmission");
    carDoors = findSpec("Doors");
    // carOneOwner = car.carfax_1_owner;
    // carCleanTitle = car.carfax_clean_title;
    // carCertified = car.is_certified;
    carExteriorColor = findSpec("Exterior Color");
    carInteriorColor = findSpec("Interior Color");
    options = !car.options ? [] : car.options.split('|').sort().filter(option => option.length < 100);
    features = !car.features ? [] : car.features.split('|').sort().filter(feature => feature.length < 100);
    autoDescription = car.description;
    addDiscount = car.addDiscount;
    callForPricing = car.callForPricing;
    discountId = car.discountId;
    carDaysOnMarket = moment().diff(moment(car.creationDate), 'days');
  } else {
    if (!car) return null;
    carTitle = car.heading;
    sellerName = car.seller_name;
    sellerPhone = car.seller_phone;
    sellerEmail = car.seller_email;
    sellerType = car.seller_type;
    sellerWebsite = car.source;
    theAddress = `${car.street}, ${car.city} ${car.state} ${car.zip}, ${car.country}`
    allPhotos = car.photo_links.split('|');
    carPrice = car.price;
    carMSRP = car.msrp;
    carCurrency = car.currency;
    cityMPG = car.city_mpg;
    highwayMPG = car.highway_mpg;
    carMiles = car.miles;
    carStock = car.stock_no;
    carVin = car.vin;
    carYear = car.year;
    carMake = car.make;
    carModel = car.model;
    carTrim = car.trim;
    carCondition = car.inventory_type;
    carDrivetrain = car.drivetrain;
    carGasType = car.fuel_type;
    carEngine = car.engine;
    carTransmission = car.transmission;
    carDoors = car.doors;
    carOneOwner = car.carfax_1_owner;
    carCleanTitle = car.carfax_clean_title;
    carCertified = car.is_certified;
    carExteriorColor = car.exterior_color;
    carInteriorColor = car.interior_color;
    carDaysOnMarket = car.dom_active;
    options = car.options.split('|').sort().filter(option => option.length < 100);
    features = car.features.split('|').sort().filter(feature => feature.length < 100);
    autoDescription = car.seller_comments;
    carPhotoUrl = car.photo_url;
  }

  allOptions = [...new Set(options)];
  initOptionsLength = allOptions.length;

  allFeatures = [...new Set(features)];
  initFeaturesLength = allFeatures.length;

  initDescLength = autoDescription.length;

  if (!fullDesc && initDescLength > 250) {
    autoDescription = autoDescription.substring(0, 250).concat("...");
  }

  if (!fullOptions && allOptions.length > 24) allOptions.length = 24;
  if (!fullFeat && allFeatures.length > 24) allFeatures.length = 24;

  const toggleOptions = () => {
    setFulloptions(!fullOptions)
  }

  const toggleFeatures = () => {
    setFullFeat(!fullFeat)
  }

  const toggleDesc = () => {
    setFullDesc(!fullDesc);
  }

  if (carPrice > 80000) {
    fspPrice = Math.floor((car.price * 1 - (carPrice * 3 / 100)))
  } else {
    fspPrice = Math.floor((car.price * 1 - (carPrice * 5 / 100)))
  }

  if (!carPrice || carPrice === 0) {
    car.price = "Call for Pricing"
  }

  let combinedGasMillage;

  if (cityMPG && highwayMPG) {
    combinedGasMillage = (cityMPG * 1 + highwayMPG * 1) / 2
  }



  let a = theAddress.trim();
  if (!a) {
    a = "0";
  }
  a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#).?\s?\d+/g, "");
  a = a.replace(/\s\s*/g, "+");
  a = a.replace(/&/g, "and");
  let cheie3 = "QUl6YVN5QzNzUENUN1cxMXRUUDhMQ0FOdk9Cb0tlMGRLMDhBWW9J";
  let url = 'https://www.google.com/maps/embed/v1/place?k';
  url = url + `ey=${atob(cheie3)}&q=${a}`

  if (carMiles === "") {
    carMiles = 0;
  } else if (carMiles === undefined) {
    carMiles = "Not Available"
  }

  if (carDaysOnMarket === 0 || carDaysOnMarket === '') {
    carDaysOnMarket = '0'
  }

  // console.log(car);

  if (car.more_info && !author) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{carTitle} | CarVerve</title>
        <meta name="description" content={autoDescription.substring(0, 250)} />
        {/*<link rel="canonical" href={"https://carverve.com/" + this.props.section + "/" + car.category + "/" + car.subcategory + "/" + car.slug} />*/}
      </Helmet>

      <ReactTooltip place="bottom" type="info" effect="solid" backgroundColor="#555555" />

      <div className="listing-body auto-listing-body">
        <div className="main-container store-product">
          <ListingHeader
            address={theAddress}
            carCondition={carCondition}
            photos={allPhotos}
            carPrice={carPrice}
            carCurrency={carCurrency}
            carDaysOnMarket={carDaysOnMarket}
            sellerType={sellerType}
            websiteSection={section}
            comment="for new cars, itemId would be the id of the social part of the car"
            itemId={!car.id ? car.itemId : car.socialAutoId}
            favoriteItemId={!car.id ? '' : car.itemId}
            authorId={car.authorId}
            withComments={false}
            carTitle={carTitle}
            authorSlug={car.authorSlug}
            sellerName={sellerName}
            category={car.category}
            subcategory={car.subcategory}
            theAddress={theAddress}
            sellerPhone={sellerPhone}
            sellerEmail={sellerEmail}
            sellerWebsite={sellerWebsite}
            onClickContact={onClickContact}
            fspPrice={fspPrice}
            addDiscount={addDiscount}
            discountId={discountId}
            afterDiscountPrice={afterDiscountPrice}
            carPhotoUrl={carPhotoUrl}
          />

          <div className="product-main-details">

            <div className="auto-listing-title-map">
              <div className="car-meta-info">
                <h1>{carTitle}</h1>
              </div>



              <div className="cost-map">
                <div className="auto-details-cost">
                    <div className="dealer-cost">
                        {(!carPrice || carPrice === "Call for Pricing")
                            ? <span className="call-for-pricing">Call for Pricing</span>
                            : !afterDiscountPrice
                                ? formatPriceAndCurrency(carPrice, carPrice !== "Call for Pricing" ? carCurrency : "", ",")
                                : formatPriceAndCurrency(afterDiscountPrice, afterDiscountPrice !== "Call for Pricing" ? carCurrency : "", ",")
                        }
                    </div>

                    {!!carPrice && (carPrice !== "Call for Pricing" && !addDiscount && sellerType === 'dealer' && (!!author && author.fspShow)) &&
                        <div className="fsp-cost">
                            VSP: <span>{formatPriceAndCurrency(fspPrice, carCurrency, ",")}</span>
                            <i data-multiline="true"
                                data-tip="The Verve Suggested Price (VSP),<br> doesn't not reflect the value of the car,<br> or the deal offered by the dealer.<br> It is based on our assumption that this<br> is a good negotiation starting point."
                                className="fal fa-info-circle" />
                        </div>}

                    <div className="original-price">
                        {!addDiscount ? null :
                            <span className="product-cost-discount">
                                {formatPriceAndCurrency(carPrice, carCurrency, ",")}
                            </span>
                        }
                    </div>
                </div>

                 {/* {this.props.carCondition &&
                    <div className="cost-map-icon">
                        <img src={AutoExteriorColor} alt="car condition" />
                        <p>Condition <br /><strong className="capitalize">{this.props.carCondition}</strong>
                        </p>
                    </div>
                }
                {!!this.props.carDaysOnMarket &&
                    <div className="cost-map-icon">
                        <i className="fal fa-calendar-alt" />
                        <p>On Market<br /><strong>{this.props.carDaysOnMarket} Days</strong></p>
                    </div> */}
            </div>



              <div className="product-main-contact">
                <div className="ProductDetailInfo">
                  by <Link className="capitalize" to={'/' + car.authorSlug}><strong>{sellerName}</strong></Link>
                          &nbsp;/&nbsp;
                          in <Link to={`/autos/${car.category}`}><strong>{titleCase(car.category)}</strong></Link>
                          &nbsp;/&nbsp;
                          {titleCase(car.subcategory || 'All')}
                </div>

                <div className="touch-address"><i className="fal fa-map-pin" /> {theAddress}</div>

                <div className="touch-contact">
                  {/* {(!loggedInUser || (loggedInUser && loggedInUser.userId !== car.authorId)) &&
                    <button data-tip="Chat" onClick={onClickContact}><i className="fa-light fa-inbox-full" /></button>
                  } */} 

                  {((!loggedInUser || (loggedInUser && loggedInUser.userId !== car.authorId)) && (author && (author.ownership && author.isDealer))) &&
                    <button data-tip="Chat" onClick={onClickContact}><i className="fa-light fa-inbox-full" /></button>
                  }

                  {((!loggedInUser || (loggedInUser && loggedInUser.userId !== car.authorId)) && !author) &&
                    <button data-tip="Chat" onClick={onClickContact}><i className="fa-light fa-inbox-full" /></button>
                  }

                  {!sellerPhone ? null :
                    (<a data-tip="Call" href={`tel:${sellerPhone}`}><i className="fal fa-phone-alt" /></a>)}

                  {!sellerEmail ? null : (
                    <a data-tip="eMail" href={`mailto:${sellerEmail}`}><i className="fal fa-envelope" /></a>)}

                  <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${theAddress}/`}><i className="fal fa-map-pin" /></a>

                  {(!sellerWebsite || (sellerType !== "dealer")) ? null : (
                    car.more_info 
                      ? <a href={`${car.more_info}`} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a> 
                      : <a href={`https://www.${sellerWebsite}`} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>
                    )}
                </div>
 
              </div>


            </div>

            {!callForPricing && !!addDiscount && addDiscount === 'coupon' && (<>
              <div className="listing-coupon">
                <div className="coupon-code-text-box">
                  <span>
                    {getTranslatedPhrase(props.userLanguage, 'SAVE')}
                    {getCouponCode(discountId.couponValue, discountId.couponCurrency, carCurrency)}
                  </span>
                  <br />
                  {addDiscount === 'coupon' && getTranslatedPhrase(props.userLanguage, 'Coupon Code') + ' :'}

                </div>

                <div className="coupon-code-box">
                  <p className="listing-coupon-code">
                    <span className="listing-main-code">
                      {addDiscount === 'coupon' && (<>{discountId.title}<br /></>)}
                      <span className="listing-coupon-terms">{moment(discountId.endDate).isAfter(moment("2190-01-01")) ? getTranslatedPhrase(props.userLanguage, 'No expiration date') : getTranslatedPhrase(props.userLanguage, 'Ends') + ": " + moment(discountId.endDate).format('L')}</span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="listing-coupon-divider" />
            </>)}

            <div className="ProductDetailMeta">
              {/* <h4 className="mgb-sm">Overview</h4> */}

              <div className="auto-info">

                <div className="auto-primary-info">
                  <table>
                    <tbody>
                      {sellerType === "dealer" &&
                        <>
                          {carMSRP &&
                            <tr>
                              <td>MSRP</td>
                              <td><strong>{formatPriceAndCurrency(carMSRP, carCurrency, ',')}</strong></td>
                            </tr>
                          }
                          {carStock &&
                            <tr>
                              <td>Stock #:</td>
                              <td><strong>{carStock}</strong></td>
                            </tr>
                          }

                        </>
                      }
                      {carVin &&
                        <tr>
                          <td>VIN:</td>
                          <td><strong>{carVin}</strong></td>
                        </tr>
                      }
                      {sellerType &&
                        <tr>
                          <td>Seller Type:</td>
                          <td><strong>{sellerType}</strong></td>
                        </tr>
                      }
                      {carYear &&
                        <tr>
                          <td>Year:</td>
                          <td><strong>{carYear}</strong></td>
                        </tr>
                      }
                      {carMake &&
                        <tr>
                          <td>Make:</td>
                          <td><strong>{carMake}</strong></td>
                        </tr>
                      }
                      {carModel &&
                        <tr>
                          <td>Model:</td>
                          <td><strong>{carModel}</strong></td>
                        </tr>
                      }
                      {carTrim &&
                        <tr>
                          <td>Trim:</td>
                          <td><strong>{carTrim}</strong></td>
                        </tr>
                      }
                      {carCondition &&
                        <tr>
                          <td>Condition:</td>
                          <td><strong>{carCondition}</strong></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>

                <div className="car-detail-container">





                  <div className="auto-secondary-info">

                    {car.category !== 'commercial' && car.category !== 'other' && car.category !== 'rVs-&-Campers' &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoMiles} alt="miles" />
                        <p>Miles / Km<br /><strong>{carMiles}</strong> </p>
                      </div>}

                    {carDrivetrain &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoDriveTrain} alt="drivetrain" />
                        <p>Drivetrain<br /><strong>{carDrivetrain}</strong></p>
                      </div>}

                    {carGasType &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoFuelType} alt="fuel type" />
                        <p>Gas Type<br /><strong>{carGasType}</strong></p>
                      </div>}


                    {cityMPG &&
                      <div className="auto-secondary-info-box">
                        <i className="fal fa-city"></i>
                        <p>City<br /><strong>{cityMPG} MPG</strong></p>
                      </div>}

                    {highwayMPG &&
                      <div className="auto-secondary-info-box">
                        <i className="fal fa-road"></i>
                        <p>Highway<br /><strong>{highwayMPG} MPG</strong></p>
                      </div>}

                    {combinedGasMillage > 0 &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoMPG} alt="combined gas millage" />
                        <p>Combined<br /><strong>{combinedGasMillage} MPG</strong></p>
                      </div>
                    }

                    {carEngine &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoEngine} alt="engine" />
                        <p>Engine<br /><strong>{carEngine}</strong></p>
                      </div>}


                    {bikeCC &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoEngine} alt="engine" />
                        <p>Engine<br /><strong>{bikeCC} CC</strong></p>
                      </div>}



                    {carTransmission &&
                      <div className="auto-secondary-info-box">
                        {carTransmission === "Automatic" && <img src={AutoTrans} alt="transmission" />}
                        {carTransmission === "Manual" && <img src={AutoClutch} alt="transmission" />}
                        <p>Transmission<br /><strong>{carTransmission}</strong></p>
                      </div>}

                    {carDoors &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoDoors} alt="doors" />
                        <p>Doors<br /><strong>{carDoors}</strong></p>
                      </div>}


                    {/* if listing is dealer and car is used */}
                    {(sellerType === "dealer" && carCondition === "used" && carOneOwner === '1') &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoOneOwner} alt="1 owner" />
                        <p>CARFAX <br /> <strong>1 Owner</strong></p>
                      </div>}

                    {/* if listing is dealer and clean title is true */}
                    {(sellerType === "dealer" && carCondition === "used" && carCleanTitle === '1') &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoCleanTitle} alt="clean title" />
                        <p>CARFAX <br /> <strong>Clean Title</strong></p>
                      </div>}

                    {/* if listing is dealer and certified is true */}

                    {(sellerType === "dealer" && carCondition === "used" && carCertified === '1') &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoCertified} alt="is certified" />
                        <p> Pre-Owned<br /><strong>Certified</strong></p>
                      </div>
                    }

                    {carExteriorColor &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoExteriorColor} alt="exterior color" />
                        <p>Exterior Color<br /><strong>{carExteriorColor}</strong></p>
                      </div>}

                    {carInteriorColor &&
                      <div className="auto-secondary-info-box">
                        <img src={AutoInteriorColor} alt="interior color" />
                        <p>Interior Color<br /><strong>{carInteriorColor}</strong></p>
                      </div>}

                    {/*{sellerType === "dealer" && carDaysOnMarket &&*/}
                    {/*<div className="auto-secondary-info-box">*/}
                    {/*  <img src={AutoDaysOnMarket} alt="days on market" />*/}
                    {/*  <p>Days on Market<br /><strong>{carDaysOnMarket}</strong></p>*/}
                    {/*</div>*/}
                    {/*}*/}
                    </div>


                  {autoDescription !== "" &&
                    <div className="auto-description">
                      <h4>Description</h4>
                      <p>{autoDescription}</p>

                      {initDescLength > 250 && <button className="button button-small button-white mgt-md" onClick={toggleDesc}>Show {fullDesc ? 'Less' : 'More'}</button>}

                      {!callForPricing && !!addDiscount && addDiscount === "deal" && (
                        <>
                          <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'Discount Terms & Conditions')}</strong></p>
                          <p>
                            {moment(discountId.endDate).isAfter(moment("2190-01-01"))
                              ? getTranslatedPhrase(props.userLanguage, 'Deal expires at the discretion of the seller.')
                              : getTranslatedPhrase(props.userLanguage, 'Discount valid through: ') + ": " + moment(discountId.endDate).format('L')}
                          </p>
                        </>
                      )}
                    </div>}


                    {allOptions.length !== 0 && allOptions[0] !== '' &&
                <div className="auto-options">
                  <h4>Options</h4>
                  <ul>
                    {allOptions.map((option, i) => {
                      return <li key={option + i}>{option}</li>
                    })}
                  </ul>
                  {initOptionsLength > 24 && <button className="button button-small button-white mgt-md" onClick={toggleOptions}>Show {fullOptions ? 'Less' : 'More'}</button>}
                </div>} 

              {allFeatures.length !== 0 && allFeatures[0] !== '' &&
                <div className="auto-features">
                  <h4>Features</h4>
                  <ul>
                    {allFeatures.map((feature, i) => {
                      return <li key={feature + i}>{feature}</li>
                    })}
                  </ul>
                  {initFeaturesLength > 24 && <button className="button button-small button-white mgt-md" onClick={toggleFeatures}>Show {fullFeat ? 'Less' : 'More'}</button>}
                </div>}

                { car.dealer_id && 
                  <div className="auto-legal-disclaimer">
                    <p><strong>DISCLAIMER</strong></p>
                    <p>The information shown on CarVerve, may be different from the information listed on the dealer's website. Information shown on CarVerve updates daily, and pricing shown is subject to change. Check the dealer's website for the latest pricing information. </p>
                    <p>Pricing on CarVerve is not the final selling price and does not include any dealer fees, selling fees or selling tax. </p>
                  </div>
                }

                </div>
              </div>

              

              
            </div>
          </div>

          {(allPhotos.length > 0 && allPhotos[0] !== '') &&
            <ListingPhotos photoList={allPhotos} show={true} />
          }

          {/* On auto no return or shipping policy */}
        </div>

        <AutosListingSidebar
          section={section}
          category={car.category}
          itemSlug={!car.id ? car.slug : car.id}
          itemId={car.itemId}
          carId={car.id}
          isFromCarsDB={!car.id ? false : true}
          authorId={(!car.id) ? car.authorId : car.dealer_id}
          authorSlug={car.authorSlug}
          authorName={car.authorName}
          authorProfileImage={car.authorProfileImage}
          likeList={car.likes || []}
          createNewTitle={"New Auto Listing"}
          userIsAuthorized={car.userIsAuthorized}
          carDetails={car}
        />

      </div>

    </>
  );
}

const mapDispatchToAutoListingProps = (
  dispatch
) => {
  return {
    updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),
    resetPayperviewRedirect: () => dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: false }),
    onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
    showAlertModal: (m) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m })
  };
};

export default connect(null, mapDispatchToAutoListingProps)(withRouter(AutoListingDetail));