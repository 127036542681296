import {getTranslatedPhrase} from './lang';
import moment from 'moment';

export function howRecentIsThis(jpDate, userLanguage) {
  if (!jpDate) {
      return '';
  }
  let _d = moment(jpDate);
  let _now = moment();
  let _minutes = _now.diff(_d, 'minutes');

  if (_minutes < 1) {
      return getTranslatedPhrase(userLanguage,'Less Than A Minute Ago');
  }
  if (_minutes < 60) {
      return (_minutes === 1)?getTranslatedPhrase(userLanguage,`A Minute Ago`):`${_minutes} ${getTranslatedPhrase(userLanguage,'Minutes Ago')}`;
  }
  let _hours = _now.diff(_d, 'hours');
  if (_hours < 25) {
      return (_hours === 1)?getTranslatedPhrase(userLanguage,`An Hour Ago`):`${_hours} ${getTranslatedPhrase(userLanguage,'Hours Ago')}`;
  }
  let _days = _now.diff(_d, 'days');
  if (_days < 29) {
      return (_days === 1)?getTranslatedPhrase(userLanguage,`A Day Ago`):`${_days} ${getTranslatedPhrase(userLanguage,'Days Ago')}`;
  }
  let _months = _now.diff(_d, 'months');
  if (_months < 13) {
      if (_months === 0 || _months === 1) {
          return getTranslatedPhrase(userLanguage,`A Month Ago`);
      }
      return `${_months} ${getTranslatedPhrase(userLanguage,'Months Ago')}`;
  }
  let _years = _now.diff(_d, 'years');
  return (_years === 1)?getTranslatedPhrase(userLanguage,`A Year Ago`):`${_years} ${getTranslatedPhrase(userLanguage,'Years Ago')}`;
}