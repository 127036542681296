import en_EN from './translations/en_EN.json';
import ro_RO from './translations/ro_RO.json';
import it_IT from './translations/it_IT.json';
import ru_RU from './translations/ru_RU.json';

export function getTranslatedPhrase(lang, phrase) {
  switch (lang) {
      case 'en_EN':
      return en_EN[phrase] || phrase;
      case 'ro_RO':
      return ro_RO[phrase] || phrase;
      case 'it_IT':
      return it_IT[phrase] || phrase;
      case 'ru_RU':
      return ru_RU[phrase] || phrase;
      default:
      return phrase;
  }
}

export function getCountryFilter(filter) {
  let country = 'USA';
  if (filter) country = JSON.parse(filter).country || 'USA';
  return country;
}