import React from 'react';
import ShareModal from './ShareModal';
import shortFormNumber from './ShortFormNumber';
const defaultClick = () => null;

function EngageButtonsShare(props) {
    // return (<span className="engage_buttons_share">
    //     <i className={`fal fa-share-alt`}></i> <span className="engage_button_numbers">{shortFormNumber(props.noShares)} </span>
    // </span>);
    let e = (!props.isClicked) ? 'l' : 's';
    if (!props.isDisabled) {
        return (<><div className="engage_buttons_share">
            <button className="engage_buttons_share_button" onClick={props.onClick || defaultClick}>
                <i className={`fa${e} fa-share-alt`} /> <span className="engage_button_numbers">{!Number(props.noShares) ? '' : shortFormNumber(props.noShares)} </span>
            </button>

            </div>
            <ShareModal {...props} />
        </>
        );
    }
    return (<span className="engage_buttons_share">
        <i className={`fa${e} fa-share-alt`} /> <span className="engage_button_numbers">{!Number(props.noShares) ? '' : shortFormNumber(props.noShares)} </span>
    </span>);
}

export default EngageButtonsShare;