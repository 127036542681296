import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import actionType from '../../../../redux-store/action-type';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { titleCase } from '../../../../shared/util/string';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import { hostNameAndPort } from '../../../../shared/util/vars';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Axios from 'axios';
import moment from 'moment';
import ListLoader from '../../../../shared/util/list-loader';
import SettingsMenu from "../SettingsMenu";
import PageNumbers from '../../../../shared/uielements/Pagination/page-numbers'


class PostsHistory extends Component {
    state = {
        blogs: [],
        photos: [],
        videos: [],
        store: [], 
        food: [],
        autos: [],
        realestate: [],
        jobs: [],
        events: [],
        groups: [],
        travel: [],
        texts: [],
        services: [],
        tabs: [ 'Products', 'Foods', 'Auto Listings', 'Properties', 'Jobs', 'Services', 'Travel', 'Blogs', 'Photo Galleries', 'Videos', 'Events', 'Groups' ],
        sections: { 
            'Products': 'store',
            'Foods': 'food',
            'Auto Listings': 'autos',
            'Properties': 'realestate',
            'Jobs': 'jobs',
            'Services': 'services',
            'Travel': 'travel',
            'Blogs': 'blogs',
            'Photo Galleries': 'photos',
            'Videos': 'videos',
            'Events': 'events',
            'Groups': 'groups' 
        },
        tabActive: {},
        tabNumbers: {},
        currentPage: 1,
        currentSection: 'Products',
        itemsPerPage: 20,
        manageResults: 0, 
        listIsLoading: false,
        itemType: '',
        itemId: '',
        waitingForDeleteConfirmation: false,
        deleteOperationConfirmed: false,
        Loader: null
    }

    componentDidMount() {
        this.setState({ waitingForDeleteConfirmation: false, listIsLoading: true });
        this.initTabs();
        setTimeout( () => {
            this.getListingsCount();
            this.initLoader();
        },500);
    }
    
    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;
        let currentSection = urlParams.get('section');
        
        if (currentPage !== this.state.currentPage) {
            this.setState({ currentPage });
            return;
        }
        if (!currentSection || currentSection === 'null') currentSection = 'Products';
        if (!this.state[this.state.sections[currentSection]].length) {
            let lastSection = currentSection;
            for (let i of Object.keys(this.state.sections))
                if (this.state[this.state.sections[i]].length) {
                    lastSection = i;
                    break;
                }
            if (currentSection !== lastSection) {
                this.changeTab(lastSection, currentPage);
            }
            return;
        }
        if (!this.state.tabActive[currentSection]) {
            this.changeTab(currentSection, currentPage);
            return;
        }

        if (this.state.deleteOperationConfirmed === this.props.deleteOperationConfirmed) {
            return;
        }
        if (!this.props.deleteOperationConfirmed) {
            this.setState({ 
                waitingForDeleteConfirmation: false,
                deleteOperationConfirmed: false,
                itemType: '',
                itemId: ''
            });
            return;
        }
        else { 
            if (!!this.state.waitingForDeleteConfirmation) {
                this.onDeleteConfirmedNow(this.state.itemType, this.state.itemId);
                this.setState({ 
                    waitingForDeleteConfirmation: false,
                    deleteOperationConfirmed: false,
                    itemType: '',
                    itemId: ''
                });
            }
            this.props.resetDeleteOperationConfirmation();
        }
    }

    componentWillUnmount() {
        if (this.state.Loader) this.state.Loader.reset();
    }

    initLoader = (formParams={}) => {
      let Loader = new ListLoader({
          cb: this.onLoad,
          options: {
              delay: 3000,
              count: 20,
              request: {
              url: '/api/user/all/items',
              method: 'post',
              formParams
              }
          }
      });
      Loader.init();
      this.setState({ Loader });
  }

  onLoad = () => {
    try {
        if (!this.state.Loader) {
            this.setState({ listIsLoading: false });
            return;
        }
        let {items} = this.state.Loader;
        if (!items) throw Error('Items is null.');

        /**** DELETE THIS AND CHECK TITLES TOO */
        // for (let i in items) {
        //     let c = items[this.state.sections[this.state.currentSection]][items[this.state.sections[this.state.currentSection]].length-1];
        //     if (!c) continue;
        //     items[i].push(...Array(25).fill(c))
        // }
        /*** */


        this.setState({ ...items, listIsLoading: false });
    }
    catch (err) {
        this.setState({ listIsLoading: false });
    }
  }

    onClickDeleteHandler = (t, id) => {
        this.setState({ waitingForDeleteConfirmation: true, itemType: t, itemId: id });
        this.props.onShowConfirmDeleteModal();
    }

    onUpdateList = (t, id) => {
        let a = this.state[t];
        a = a.filter( v => v.itemId !== id);

        let allSections = [ 'store', 'food', 'autos', 'realestate', 'jobs', 'services', 'travel', 'blogs', 'photos', 'videos', 'events', 'groups' ];
        let the = [ 'Products', 'Foods', 'Auto Listings', 'Properties', 'Jobs', 'Services', 'Travel', 'Blogs', 'Photo Galleries', 'Videos', 'Events', 'Groups' ];
        let label = allSections.indexOf(t);
        if (label<0) return;
        let b = this.state.tabNumbers;
        b[the[label]] = b[the[label]] - 1;
        this.setState({ 
            [t]: a,
            tabNumbers: b
        });
    }

    onDeleteConfirmedNow = async (t,id) => {
        let options = {
            url: `${hostNameAndPort}/api/${t}/${id}`,
            method: 'delete',
        };
        try { 
            let result = await Axios(options);
            let {data} = result;
            // console.log(data)
            if (!data.error) {
                this.onUpdateList(t, id);
            }
        } catch(err) {
            if (process.env.NODE_ENV === 'development') console.log(err);
        }
    }

    initTabs() {
        let _tabs = this.state.tabs;
        let a = {};
        let b = {};
        for (let i in _tabs) {
            a[_tabs[i]] = false;
            b[_tabs[i]] = 0;
        }

        this.setState({ 
            tabActive: a,
            tabNumbers: b
        });
    }

    getListingsCount = async (u) => {
        let options = `${hostNameAndPort}/api/user/all/count`;
        try { 
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                let n = 0;
                for (let i in data.count) {
                    n = n + data.count[i];
                }
                
                let _tabAct = this.state.tabActive;
                for (let i in _tabAct) _tabAct[i] = false;
                let tbs = this.state.tabs;
                for (let j in tbs) {
                    if (!!data.count[tbs[j]]) {
                        _tabAct[tbs[j]] = true;
                        break;
                    }
                }
        
                this.setState({ 
                    tabActive: _tabAct,
                    manageResults: n,
                    tabNumbers: data.count,
                });
            }
        } catch(err) {
            // Network error
        }
    }

    verifyUserSubscription = async (t) => {
        try {
            let _data = new FormData();
            _data.append('itemType', t);
            let url = `${hostNameAndPort}/api/pay/verifysubscription`;
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    onPromoteItem = async (t, id, isFromCarsDB = false, isFromRealestateDB = false, isActive) => {
        if ( !!isActive && await this.verifyUserSubscription(t) ) {
            this.props.onShowAdvertiseModalHandler(t, id, isFromCarsDB, isFromRealestateDB);
        } else {
            this.props.showAlert(getTranslatedPhrase(this.props.userLanguage,'Please upgrade your subscription'), '/subscription-settings', getTranslatedPhrase(this.props.userLanguage,'Upgrade Now'));
        }
    }

    onUpdatePageNumber = (n = 1) => {
        window.scrollTo(0,0);
        const urlParams = new URLSearchParams(window.location.search);
        let s = 'page=' + n + '&';
        for (const [key,value] of urlParams.entries())
          if (key !== 'page') s = s + key + '=' + value + '&';
        s = s.substring(0, s.length - 1);
        this.props.history.push(this.props.match.url + '?' + encodeURI(s));
    }

    changeTab = (t,p) => {
        // if (!t || t === 'null') {
        //     for (let i of Object.keys(this.state.sections))
        //         if (this.state[this.state.sections[i]].length) {
        //             t = i;
        //             break;
        //         }
        //     if (!t || t === 'null') t = 'Products';
        // }
        let a = this.state.tabActive;
        for (let i in a) {
            a[i] = false;
        }
        a[t] = true;

        if (!p) p = 1;
        let s = `section=${t}&page=${p}`;
        this.props.history.push(this.props.match.url + '?' + encodeURI(s));

        this.setState({ tabActive: a, currentSection: t, currentPage: p });
    }

    renderNavItem = (s, h) => {
        return (!this.state.tabNumbers[s])?null:(
        <button key={s+'-nav-item'} 
            className={(!this.state.tabActive[s])?'':'active'}
            onClick={e => this.changeTab(s)}>({this.state.tabNumbers[s]}) {getTranslatedPhrase(h,s)}
        </button>)
    }

    showAlert = () => {
        this.props.showAlert(getTranslatedPhrase(this.props.userLanguage,'Feature not available for this listing!'));
    }

    render () {
        let totalCount = this.state[this.state.sections[this.state.currentSection]].length;
        
        return (

            <SettingsMenu PostHistoryLiActive="active">
                <ProfileSettingsBox>

                    <h4>{getTranslatedPhrase(this.props.userLanguage, "Edit Listings")}</h4>

                    <div className="manage-listings_nav">
                        <span className="manage-listings_nav_header">{getTranslatedPhrase(this.props.userLanguage,'Select Listing Type')}</span>
                        <div className="manage-listings_nav_items">
                            {this.state.tabs.map( (v,i) => this.renderNavItem(v, this.props.userLanguage) )}
                        </div>

                    </div>

                    <LoadingSvg active={this.state.listIsLoading} />
                    {(!this.state.manageResults && !this.state.listIsLoading)?getTranslatedPhrase(this.props.userLanguage,'There are no listings to manage'):null}
                    {(!this.state.manageResults)?null:(<div className="posts-history-section posts-history-section_manage-listings">
                    <table><tbody>
                        <tr className="tableHeader">
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Date')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Post Name')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Category')}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'')}</td>
                        </tr>

                        {/* REAL ESTATE */}
                    
                        {(!this.state.tabActive['Properties'])?null:this.state.realestate.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'realestate-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/realestate/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() => this.onPromoteItem('realestate', v.itemId, false, v.isFromRealestateDB, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(!v.isFromRealestateDB)?(<Link to={'/realestate/edit-property?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>):<button onClick={this.showAlert}>{getTranslatedPhrase(this.props.userLanguage, 'Edit')}</button>}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('realestate', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* STORE ITEM */}
                    
                        {(!this.state.tabActive['Products'])?null:this.state.store.map( (v, i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'storeitem-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/store/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('store', v.itemId, false, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/store/edit-product?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('store', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* FOOD */}
                    
                        {(!this.state.tabActive['Foods'])?null:this.state.food.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'food-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/food/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('food', v.itemId, false, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/food/edit-food-listing?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('food', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* AUTO STORE */}
                    
                        {(!this.state.tabActive['Auto Listings'])?null:this.state.autos.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'autostoreitem-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/autos/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('autos', v.itemId, v.isFromCarsDB, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(!v.isFromCarsDB)?(<Link to={'/autos/edit-auto-listing?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>):<button onClick={this.showAlert}>{getTranslatedPhrase(this.props.userLanguage, 'Edit')}</button>}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('autos', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* JOB LISTING */}
                    
                        {(!this.state.tabActive['Jobs'])?null:this.state.jobs.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'job-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/jobs/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('jobs', v.itemId, false, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/jobs/edit-job?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('jobs', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* SERVICES */}
                    
                        {(!this.state.tabActive['Services'])?null:this.state.services.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'service-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/services/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('services', false, false, v.itemId, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/services/edit-service?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('services', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* TRAVEL */}
                    
                        {(!this.state.tabActive['Travel'])?null:this.state.travel.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'travel-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to={'/travel/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('travel', v.itemId, false, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/travel/edit-travel?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('travel', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* PHOTO GALLERY */}
                    
                        {(!this.state.tabActive['Photo Galleries'])?null:this.state.photos.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'photo-'+i}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td><Link to={'/photos/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link></td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td><button onClick={() =>this.onPromoteItem('photos', v.itemId, true)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button> <span>/</span> 
                            <Link to={'/photos/edit-photo?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('photos', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* VIDEO */}
                    
                        {(!this.state.tabActive['Videos'])?null:this.state.videos.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'video-'+i}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td><Link to={'/videos/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link></td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td><button onClick={() =>this.onPromoteItem('videos', v.itemId, true)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button> <span>/</span> 
                            <Link to={'/videos/edit-video?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('videos', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* BLOG */}
                    
                        {(!this.state.tabActive['Blogs'])?null:this.state.blogs.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'blog-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td><Link to={'/blogs/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link></td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td><button onClick={() =>this.onPromoteItem('blogs', v.itemId, true)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button> <span>/</span> 
                            <Link to={'/blogs/edit-blog?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('blogs', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* TEXT */}
                    
                        {(!this.state.tabActive['Texts'])?null:this.state.texts.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'text-'+i}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{v.title}</td>
                            <td>-</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,'Promote')} <span>/</span> 
                            {getTranslatedPhrase(this.props.userLanguage,'Edit')}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('texts', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* EVENTS*/}
                    
                        {(!this.state.tabActive['Events'])?null:this.state.events.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'event-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td><Link to={'/events/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link></td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('events', v.itemId, false, false, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            <Link to={'/events/edit-event?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('events', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* GROUPS */}
                    
                        {(!this.state.tabActive['Groups'])?null:this.state.groups.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'group-'+i}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td><Link to={'/groups/'+v.category+'/'+(v.subcategory || 'all')+'/'+v.slug}>{v.title}</Link></td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td><button onClick={() =>this.onPromoteItem('groups', v.itemId, true)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button> <span>/</span> 
                            <Link to={'/groups/edit-group?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('groups', v.itemId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )}

                        {/* COUPON */}
                    
                        {/* {(!this.state.tabActive['Coupons'])?null:this.state.coupons.map( (v,i) => (i<this.state.itemsPerPage*(this.state.currentPage-1) || i>=this.state.itemsPerPage*this.state.currentPage)?null:(<tr key={'coupon-'+i} className={!v.active?'inactive-listing':''}>
                            <td>{moment(v.creationDate).local().format('LLL')}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage, v.title):(<Link to="/postshistory">{v.title}</Link>)}</td>
                            <td>{getTranslatedPhrase(this.props.userLanguage,titleCase(v.category.replace(/-/g,' ')))}</td>
                            <td>{!v.active?getTranslatedPhrase(this.props.userLanguage,'Promote'):(<button onClick={() =>this.onPromoteItem('coupon', v.couponId, v.active)}>{getTranslatedPhrase(this.props.userLanguage,'Promote')}</button>)} <span>/</span> 
                            {!v.active?getTranslatedPhrase(this.props.userLanguage, 'Edit'):(<Link to={'/coupons/edit-coupon?q='+v.slug}>{getTranslatedPhrase(this.props.userLanguage,'Edit')}</Link>)}
                            <span>/</span> <button onClick={() => this.onClickDeleteHandler('coupon', v.couponId)}>{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button></td>
                        </tr>) )} */}
                        
                        
                        {(!this.state.waitForNewPostsIntervalId)?null:(<tr>
                            <td>&nbsp;</td>
                            <td><LoadingSvg active={true} /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>)}

                    </tbody></table>

                    <PageNumbers
                        count={Math.ceil(totalCount / this.state.itemsPerPage)}
                        activePage={this.state.currentPage}
                        onClick={this.onUpdatePageNumber} />
                </div>) }

                </ProfileSettingsBox>
                


            </SettingsMenu>
        );
    }
}

const mapStateToPostsHistoryProps = (
    state,
    props
) => {
    return {
        deleteOperationConfirmed: state.deleteOperationConfirmed,
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToPostsHistoryProps = (
    dispatch
) => {
    return {
        onShowAdvertiseModalHandler: (adItemType, adItemId, adFromCars, adFromRealestate) => {
            dispatch({type: actionType.ADVERTISE_MODAL, advertiseModalActive: true, advertiseItemType: adItemType, advertiseItemId: adItemId, advertiseIsFromCarsDB: adFromCars, advertiseIsFromRealestateDB: adFromRealestate });
        },
        onShowConfirmDeleteModal: () => {
            dispatch({type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true, confirmDeleteOperationModalMessage: ''});
        },
        resetDeleteOperationConfirmation: () => {
            dispatch({type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        showAlert: (m, r, s) => {
            dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m, alertModalRedirect: r, alertModalButtonLabel: s });
        },
    };
};

export default connect(mapStateToPostsHistoryProps, mapDispatchToPostsHistoryProps)(withRouter(PostsHistory));

/* <tr>
    <td>04/04/2018</td>
    <td><a href="/">How to Detail Your Car</a></td>
    <td>Auto</td>
    <td><button>Promote</button> <span>/</span> <button>Edit</button> <span>/</span> <button>Delete</button></td>
</tr>

<tr>
    <td>04/01/2018</td>
    <td><a href="/">Top 10 Cars of 2018</a></td>
    <td>Auto</td>
    <td><button>Promote</button> <span>/</span> <button>Edit</button> <span>/</span> <button>Delete</button></td>
</tr>

<tr>
    <td>03/24/2018</td>
    <td><a href="/">Mazda 6, All You Need to Know</a></td>
    <td>Auto</td>
    <td><button>Promote</button> <span>/</span> <button>Edit</button> <span>/</span> <button>Delete</button></td>
</tr> */
