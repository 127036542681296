import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import Modal from '../../../uielements/Modal/Modal';
import { displayPriceValue } from '../../../util/number';
import { populateCountries, populateStates } from '../../../util/countries';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';

const AdvServices = props => {
    const userLanguage = useSelector(state => state.userLanguage);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [rateType, setRateType] = useState('');
    const [title, setTitle] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [priceAscending, setPriceAscending] = useState(0);
    const [viewsDescending, setViewsDescending] = useState(false);
    const [withCoupons, setWithCoupons] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingClass, setRatingClass] = useState(Array(5).fill('fal fa-star'));
    const [errorMessage, setErrorMessage] = useState('');

    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const [stateVar, setStateVar] = useState('');
    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [countryElementId, setCountryElementId] = useState('');
    const [stateElementId, setStateElementId] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const { section } = props;
    const windowLocationSearch = window.location.search;

    useEffect(() => {
        setCategoryList(props.categoryList);
    }, [section]);

    const b1 = document.getElementById("stateElementForAdvServices");
    const b2 = document.getElementById("countryElementForAdvServices");
    const b2Len = (b2 && b2.options.length > 1) ? b2.options.length : 0;
    const b1Len = (b1 && b1.options.length) ? b1.options.length : 0;
    useEffect(() => {
        if (
            countryElementId
            && stateElementId
            && b1
            && b1Len
            && b2
            && b2Len
        ) {
            let cc = b2;
            let i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === country) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
            populateStates(countryElementId, stateElementId);
            cc = document.getElementById("stateElementForAdvServices");
            i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === stateVar) break;

            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
        }
    }, [b1, b2, b2Len, b1Len]);

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;
    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                subcategory,
                withCoupons,
                rateType,
                title,
                priceMin,
                priceMax,
                priceAscending,
                rating,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            if (!!props.category) props.history.push('/food');
        }
    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {
            const ts = {
                category,
                subcategory,
                withCoupons,
                rateType,
                title,
                priceMin,
                priceMax,
                priceAscending,
                viewsDescending,
                rating,
                country,
                stateVar,
                city,
                zipCode
            };
            for (let i of Object.keys(ts)) onChangeItem(i, '');
            setCountry(currentCountry);
            return;
        }
        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';
        if (category !== cat) setCategory(cat);
        if (subcategory !== subcat) setSubcategory(subcat);

        const ts = {
            category,
            subcategory,
            withCoupons,
            rateType,
            title,
            priceMin,
            priceMax,
            priceAscending,
            viewsDescending,
            rating,
            country,
            stateVar,
            city,
            zipCode
        };
        let changeState = false;

        const ns = {
            category: urlParams.get('category') || '',
            subcategory: urlParams.get('subcategory') || '',
            withCoupons: urlParams.get('withCoupons') || false,
            rateType: urlParams.get('rateType') || '',
            title: urlParams.get('keywords') || '',
            priceMin: urlParams.get('priceMin') || '',
            priceMax: urlParams.get('priceMax') || '',
            priceAscending: Number(urlParams.get('priceAscending')) || '',
            viewsDescending: urlParams.get('viewsDescending') || false,
            rating: urlParams.get('rating') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || ''
        };
        if (!ns.viewsDescending || ns.viewsDescending === 'false') ns.viewsDescending = false; else ns.viewsDescending = true;
        if (!ns.withCoupons || ns.withCoupons === 'false') ns.withCoupons = false; else ns.withCoupons = true;

        let keywordsParts = ns.title.split('-');
        if (keywordsParts.length > 1) {
            keywordsParts.shift();
            keywordsParts.pop();
        }
        ns.title = keywordsParts.join('-');

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;
        if (ns.address !== ts.address || ns.withCoupons !== ts.withCoupons || ts.priceMin !== ns.priceMin || ts.priceMax !== ns.priceMax || ts.priceAscending !== ns.priceAscending || ts.viewsDescending !== ns.viewsDescending ||
            ns.rating !== ts.rating || ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode || ns.title !== ts.title)
            changeState = true;


        if (changeState) {
            for (let i of Object.keys(ns)) {
                if (i !== 'address' && i !== 'stateVar' && i !== 'city' && i !== 'zipCode') onChangeItem(i, ns[i]);
            }
            if (!ns.address) {
                onChangeItem('stateVar', ns.stateVar);
                onChangeItem('city', ns.city);
                onChangeItem('zipCode', ns.zipCode);
                onChangeItem('address', ns.address);
            } else {
                let newCity = '', newState = '', newZipCode = '';
                let a = ns.address.split(',');
                if (a.length < 2) {
                    if (/[-A-Za-z0-9]*/i.test(a)) newZipCode = a[0].trim();
                    else newCity = a[0].trim();
                } else {
                    newCity = a[0].trim();
                    newState = a[1].trim();
                }
                onChangeItem('stateVar', newState);
                onChangeItem('city', newCity);
                onChangeItem('zipCode', newZipCode);
                onChangeItem('address', ns.address);
            }
        }
    }, [windowLocationSearch]);

    function onChangeItem(_prop, _value, regularChange) {
        let a = {
            category: setCategory,
            subcategory: setSubcategory,
            rateType: setRateType,
            title: setTitle,
            priceMin: setPriceMin,
            priceMax: setPriceMax,
            withCoupons: setWithCoupons,
            priceAscending: setPriceAscending,
            viewsDescending: setViewsDescending,
            country: setCountry,
            stateVar: setStateVar,
            city: setCity,
            zipCode: setZipCode
        };
        if (_prop === 'category') {
            setSubcategory('');
            onChangeCategory(_value);
        }

        if (_prop === 'priceMin' || _prop === 'priceMax') _value = _value.replace(/,/g, '').replace('.', '');
        if (_prop === 'priceAscending' && priceAscending === _value && !!regularChange) _value = 0;

        if (_prop === 'rating') onChangeRatingHandler({ preventDefault: () => null }, _value);
        else if (a[_prop]) a[_prop](_value);

    }

    function onChangeCategory(c) {
        if (c === 'none' || !c) {
            setSubcategoryList([]);
            return;
        }
        let i = 0;
        let s = [];
        while (i < props.categoryList.length && props.categoryList[i] !== c) i++;
        for (i++; i < props.categoryList.length && /^\s*-+/.test(props.categoryList[i]); i++) s.push(props.categoryList[i].replace(/^\s*-+\s*/, ''));
        setSubcategoryList(s);
    }

    function onChangeRatingHandler(e, n) {
        e.preventDefault();
        let a = ratingClass;
        if (rating === n) n = 0;
        a.forEach((v, i) => (i < n) ? (a[i] = 'fas fa-star') : (a[i] = 'fal fa-star'));
        setRatingClass(a);
        setRating(n);
    }

    function onSubmitSearch(e) {
        e.preventDefault();
        let priceRange = false;
        if (Number(priceMin) || Number(priceMax)) priceRange = true;
        let f;
        if (!title) f = "filters-" + Date.now();
        else f = "filters-" + title + "-" + Date.now();
        // let s_q = JSON.parse(searchQuery || '{}');
        let state = stateVar;
        // if (!state && country === s_q.country) state = s_q.region;
        if (!state && city) {
            setErrorMessage('Please select the state!');
            return;
        } else setErrorMessage('');

        let s = `/services/search/${f}?priceAscending=${priceAscending}&priceMin=${priceMin}&priceMax=${priceMax}&viewsDescending=${viewsDescending}&priceRange=${priceRange}&withCoupons=${withCoupons}&rating=${rating}
&rateType=${rateType}&country=${country}&state=${state}&city=${city}&zipCode=${zipCode}&category=${category.replace(/&/g, '%26')}&subcategory=${subcategory.replace(/&/g, '%26')}&keywords=${f}`;
        props.onSubmitHandler(e, s);
        props.closeModal();
    }

    return (
        <Modal
            title="Services Advanced Search"
            closeModal={props.closeModal}
            modalClass="medium"
            modalBodyClass="adv-body"
        >
            <div className="adv-search">
                <div className="adv-search-content">
                    <div className="adv-search-content-box">
                        <p><strong>KEYWORDS</strong></p>
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Find pros for your next project." value={title} onChange={e => onChangeItem('title', e.target.value)} />
                        <p><strong>TYPE</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={category} onChange={e => onChangeItem('category', e.target.value)}>
                                <option value="none" disabled="">{getTranslatedPhrase(userLanguage, 'Select Category')}</option>
                                {categoryList.filter(v => (!/^\s*-+/.test(v))).map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={subcategory} onChange={e => onChangeItem('subcategory', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Select Subcategory')}</option>
                                {subcategoryList.map(v => (
                                    <option key={v} value={v}>{getTranslatedPhrase(userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>
                                ))}
                            </select>
                        </div>

                        <p><strong>PRICING</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm" value={rateType} onChange={e => onChangeItem('rateType', e.target.value)}>
                                <option value="" disabled="">{getTranslatedPhrase(userLanguage, 'Rate Type')}</option>
                                <option key={'item-h1'} value="hour">{getTranslatedPhrase(userLanguage, 'Hour')}</option>
                                <option key={'item-h2'} value="day">{getTranslatedPhrase(userLanguage, 'Day')}</option>
                                <option key={'item-h3'} value="package">{getTranslatedPhrase(userLanguage, 'Package')}</option>
                                <option key={'item-h4'} value="volunteer">{getTranslatedPhrase(userLanguage, 'Volunteer')}</option>
                            </select>
                        </div>

                        <div className="flex mgb-sm">
                            <input type="text" className="form-input-text form-input-full" placeholder="Min $" value={displayPriceValue(priceMin)} onChange={e => onChangeItem('priceMin', e.target.value)} />
                            <span>to</span>
                            <input type="text" className="form-input-text form-input-full" placeholder="Max $" value={displayPriceValue(priceMax)} onChange={e => onChangeItem('priceMax', e.target.value)} />
                        </div>


                    </div>

                    <div className="adv-search-content-box">
                        <p><strong>FILTERS</strong></p>

                        <p className="input-label mgt-md">Sort by Price:</p>
                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="sortprice" id="lowtohigh" value="lowtohigh" checked={priceAscending === 1} onChange={() => null} />
                            <label htmlFor="lowtohigh" onClick={e => onChangeItem('priceAscending', 1, true)}><i className="fal fa-check" />Low to High</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="sortprice" id="hightolow" value="hightolow" checked={priceAscending === 2} onChange={() => null} />
                            <label htmlFor="hightolow" onClick={e => onChangeItem('priceAscending', 2, true)}><i className="fal fa-check" />High to Low</label>
                        </div>

                        <p className="input-label mgt-md">Sort by Most Views:</p>
                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="mostviews" id="mostviews" value="mostviews" checked={viewsDescending} onChange={() => null} />
                            <label htmlFor="mostviews" onClick={e => onChangeItem('viewsDescending', !viewsDescending)}><i className="fal fa-check" />Most Views</label>
                        </div>

                        <p className="input-label mgt-md">Rating:</p>
                        <div className="engage_buttons mgt-sm">
                            <span className="engage_buttons_rating">
                                {ratingClass.map((v, j) => (<button key={'rating-ab-' + j}
                                    onClick={(e) => onChangeRatingHandler(e, j + 1)}>
                                    <i className={v} />
                                </button>)
                                )}
                            </span>
                            <div style={{ fontSize: "1.5rem" }}>&amp; Up</div>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="discount" id="discount" value="discount" checked={withCoupons} onChange={() => null} />
                            <label htmlFor="discount" onClick={e => onChangeItem('withCoupons', !withCoupons)}><i className="fal fa-check" />Deals</label>
                        </div>
                    </div>

                    <div className="adv-search-content-box">


                        <p><strong>LOCATION</strong></p>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="countryElementForAdvServices"
                                ref={n => {
                                    if (!countryElementId) setCountryElementId("countryElementForAdvServices");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={country}
                                onChange={e => onChangeItem('country', e.target.value)}>
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm"
                                id="stateElementForAdvServices"
                                ref={n => {
                                    if (!stateElementId) setStateElementId("stateElementForAdvServices");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}
                                value={stateVar}
                                onChange={e => onChangeItem('stateVar', e.target.value)}>
                                <option value="">Select State *</option>
                            </select>
                        </div>

                        {/*<input type="text" className="form-input-text form-input-full mgb-sm" placeholder="Zip/Postal Code" value={zipCode} onChange={e => onChangeItem('zipCode', e.target.value)}/>*/}
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="City" value={city} onChange={e => onChangeItem('city', e.target.value)} />

                    </div>
                </div>
                <div><FormFieldError> {errorMessage} </FormFieldError></div>
                <div className="adv-search-buttons">
                    <button className="button button-regular" onClick={onSubmitSearch}>Search</button>
                    <button className="button button-white" onClick={props.closeModal}>Cancel Search</button>
                </div>
            </div>
        </Modal >
    )
}

export default connect(null, null)(AdvServices);