import React, { Component } from 'react';
import Coupon from './Coupons/Coupon';
import moment from 'moment';
import { hostNameAndPort } from '../../../util/vars';
import axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

class CouponsSideBar extends Component {
    state = {
        couponList: [],
    }

    componentDidMount() {
        this.getSponsored(this.props.category || 'first-page');
    }

    getSponsored = async (k) => {
        let couponListParams = new FormData();  
        couponListParams.append('section', this.props.section || 'coupons');
        couponListParams.append('category', k);
        couponListParams.append('count', 2);
        let options = {
            url: `${hostNameAndPort}/api/adv/sponsored`,
            method: 'post',
            data: couponListParams
        };
        try {
            let result = await axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({ sponsoredList: data.items });
            }
        } catch(err) {
            // Error
        }
    }

    render () {
        if (!this.state.couponList || !this.state.couponList.length) {
            return null;
        }
        return (
            <div className="main-sidebar_box">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Coupons')}</h4>

                {this.state.couponList.map( (v,i) => (<Coupon key={i}
                    couponFeaturedImage={(!v.featuredImage)?null:(<img src={v.featuredImage} alt={v.title} className="couponFeaturedImage" />)}
                    saleAmount={v.couponValue+' '+v.couponCurrency}
                    saleBrand={v.authorName}
                    couponCode={v.title}
                    startDate={moment(v.startDate).local().format('L')}
                    endDate={moment(v.endDate).local().format('L')}
                    redeemURL={v.redeemURL}
                >
                    <img src={v.authorProfileImage} alt={v.authorName} />
                </Coupon>) )}
            </div>
        );
    }
}

const mapStateToCouponsSideBarProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToCouponsSideBarProps, null)(CouponsSideBar);