export const toHttps = url => {
    if (!url) return;

    if (url.startsWith('http://')){
        url = url.replace("http://","https://")
    } else if (url.startsWith('/')){
        url = `https://${url.replace(/^\/+/, '')}`
    } else if (!url.startsWith('https://')) {
        url = `https://${url}`
    }


    return url;
}