import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';

class VideosFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
        <div className="featured-item featured-video-gallery">
        
            <span className="resp-video-cat featured-video-gallery_video">
                <iframe title={v.title} src={v.videoURL} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
            </span>
             
            <Link className="featured-video-gallery_details" to={'/videos/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <span>{getTranslatedPhrase(v.userLanguage,'by')} {v.authorName}</span>
                <h3 className="featured-gallery_title">{v.title}</h3>
            </Link>

            {/* <Link className="featured-gallery_video" to={'/videos/'+v.category+'/'+v.slug}>&nbsp;
            </Link> */}
        </div>
        );
    }
}


const mapStateToVideosFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToVideosFeaturedPreviewProps, null)(VideosFeaturedPreview);
