import React, { useEffect, useState } from 'react';

import LoveButton from './EngageButtons/LoveButton';
import CommentsButton from './EngageButtons/CommentButton';
import ShareButton from './EngageButtons/ShareButton';
import FavoritesButton from './EngageButtons/FavoritesButton';
import EngageButtons from './EngageButtons/EngageButtons';
import Comments from './Comments/Comments';
import ShareItem from './ShareItem/ShareItem';
import Axios from 'axios';
import { hostNameAndPort } from '../../util/vars';
import { connect, useSelector } from 'react-redux';
import ViewsButton from './EngageButtons/ViewsButton';
import actionType from '../../../redux-store/action-type';

let DISABLE_CLICK_FOR_A_WHILE = false;
const DISABLED_CLICK_TIME = 300;


function EngageBox(props) {
    const [likeCount, setLikeCount] = useState(0);
    const [shareCount, setShareCount] = useState(0);
    const [commentCount, setCommentCount] = useState(0);
    const [viewCount, setViewCount] = useState(0);
    const [shareLink, setShareLink] = useState('');
    const [shareImage, setShareImage] = useState('');
    const [itemIsFavorite, setItemIsFavorite] = useState(false);
    const [itemIsLiked, setItemIsLiked] = useState(false);
    const [itemIsShared, setItemIsShared] = useState(false);
    const [clickedToComment, setClickedToComment] = useState(props.clickedToComment);
    const [clickedToShare, setClickedToShare] = useState(false);

    const userIsAuthenticated = useSelector(state => state.userIsAuthenticated);
    const pageRefresh = useSelector(state => state.pageRefresh);
    const { itemId, favoriteItemId, authorId, websiteSection, mlsProp = '', isDisabled = false } = props;
    const clickedToCommentProp = props.clickedToComment;

    useEffect(() => {
        setClickedToComment(clickedToCommentProp);
    }, [clickedToCommentProp])

    useEffect(() => {
        let b = itemId;
        let f = favoriteItemId;
        let c = websiteSection;
        getCommentCountForItem(c, b);
        getLikesForItem(c, b);
        getSharesForItem(c, b);
        getViewsForItem(c, b);
        if (!f) {
            checkFavorite(c, b);
            getURLForItem(c, b);
        } else {
            checkFavorite(c, f);
            getURLForItem(c, f);
        }
        DISABLE_CLICK_FOR_A_WHILE = false;
    }, [itemId, websiteSection, favoriteItemId, pageRefresh]);

    const getLikesForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let likeData = new FormData();
        likeData.append('websiteSection', c);
        likeData.append('itemId', b);
        let options = {
            url: `${hostNameAndPort}/api/engage/getlikes`,
            method: 'post',
            data: likeData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setLikeCount(data.likeCount);
                setItemIsLiked(data.itemIsLiked);
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const getSharesForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let shareData = new FormData();
        shareData.append('websiteSection', c);
        shareData.append('itemId', b);
        let options = {
            url: `${hostNameAndPort}/api/engage/getshares`,
            method: 'post',
            data: shareData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setShareCount(data.shareCount);
                setItemIsShared(data.itemIsShared);
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const onRemoveComment = () => {
        props.pageShouldRefresh(new Date().getTime());
    }

    const getViewsForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let viewData = new FormData();
        viewData.append('websiteSection', c);
        viewData.append('itemId', b);
        let options = {
            url: `${hostNameAndPort}/api/engage/getviews`,
            method: 'post',
            data: viewData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setViewCount(data.viewCount);
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const checkFavorite = async (c, b) => {
        if (!b) {
            return;
        }
        let favoriteData = new FormData();
        favoriteData.append('websiteSection', c);
        favoriteData.append('itemId', b);
        favoriteData.append('mlsProp', mlsProp);
        let options = {
            url: `${hostNameAndPort}/api/engage/checkfavorite`,
            method: 'post',
            data: favoriteData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setItemIsFavorite(data.itemIsFavorite)
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const getURLForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let likeData = new FormData();
        likeData.append('websiteSection', c);
        likeData.append('itemId', b);
        let options = {
            url: `${hostNameAndPort}/api/engage/geturl`,
            method: 'post',
            data: likeData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // shareLink: window.location.href,
                setShareLink(data.itemURL);
                setShareImage(data.itemImage);
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const getCommentCountForItem = async (c, b) => {
        if (!b) {
            return;
        }
        let commentData = new FormData();
        commentData.append('websiteSection', c);
        commentData.append('itemId', b);
        let options = {
            url: `${hostNameAndPort}/api/engage/comments/count`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setCommentCount(data.count);
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const onClickToAddFavorite = async () => {
        if (!userIsAuthenticated) {
            props.showLoginModal();
            return;
        }
        if (itemIsFavorite) return onClickToRemoveFavorite();
        if (DISABLE_CLICK_FOR_A_WHILE) return;
        DISABLE_CLICK_FOR_A_WHILE = true;
        let w = websiteSection;
        let favoriteData = new FormData();
        if (!favoriteItemId) favoriteData.append(`itemId`, itemId);
        else favoriteData.append(`itemId`, favoriteItemId);
        favoriteData.append('mlsProp', mlsProp);
        let options = {
            url: `${hostNameAndPort}/api/engage/addfavorite/${w}`,
            method: 'post',
            data: favoriteData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setTimeout(() => {
                    DISABLE_CLICK_FOR_A_WHILE = false;
                }, DISABLED_CLICK_TIME);
                setItemIsFavorite(true);
            } else setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        } catch (err) {
            setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        }
    }

    const onClickToRemoveFavorite = async () => {
        if (DISABLE_CLICK_FOR_A_WHILE) return;
        DISABLE_CLICK_FOR_A_WHILE = true;
        let w = websiteSection;
        let favoriteData = new FormData();
        if (!favoriteItemId) favoriteData.append(`itemId`, itemId);
        else favoriteData.append(`itemId`, favoriteItemId);
        favoriteData.append('mlsProp', mlsProp);
        let options = {
            url: `${hostNameAndPort}/api/engage/removefavorite/${w}`,
            method: 'post',
            data: favoriteData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setTimeout(() => {
                    DISABLE_CLICK_FOR_A_WHILE = false;
                }, DISABLED_CLICK_TIME);
                setItemIsFavorite(false);
                if (props.onClickOnFavorite) props.onClickOnFavorite();
            } else setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        } catch (err) {
            setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        }
    }

    const onClickToLike = async () => {
        if (!userIsAuthenticated) {
            props.showLoginModal();
            return;
        }
        if (!!itemIsLiked) {
            return onClickToRemoveLike();
        }
        if (DISABLE_CLICK_FOR_A_WHILE) return;
        DISABLE_CLICK_FOR_A_WHILE = true;
        setLikeCount(likeCount + 1);
        setItemIsLiked(true);
        let w = websiteSection;
        let commentData = new FormData();
        commentData.append(`itemId`, itemId);
        commentData.append(`favoriteItemId`, favoriteItemId);
        commentData.append(`itemAuthorId`, authorId);
        let options = {
            url: `${hostNameAndPort}/api/engage/addlike/${w}`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setTimeout(() => {
                    DISABLE_CLICK_FOR_A_WHILE = false;
                }, DISABLED_CLICK_TIME);
            } else setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        } catch (err) {
            setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        }
    }

    const onClickToRemoveLike = async () => {
        if (DISABLE_CLICK_FOR_A_WHILE) return;
        DISABLE_CLICK_FOR_A_WHILE = true;
        setLikeCount(likeCount - 1);
        setItemIsLiked(false);
        let w = websiteSection;
        let commentData = new FormData();
        commentData.append(`itemId`, itemId);
        commentData.append(`favoriteItemId`, favoriteItemId);
        commentData.append(`itemAuthorId`, authorId);
        let options = {
            url: `${hostNameAndPort}/api/engage/removelike/${w}`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                setTimeout(() => {
                    DISABLE_CLICK_FOR_A_WHILE = false;
                }, DISABLED_CLICK_TIME);
            } else setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        } catch (err) {
            setTimeout(() => {
                DISABLE_CLICK_FOR_A_WHILE = false;
            }, DISABLED_CLICK_TIME);
        }
    }

    const onClickToComment = () => {
        if (!userIsAuthenticated) {
            props.showLoginModal();
            return;
        }
        if (!props.getClickToCommentEvent) {
            setClickedToComment(true);
        } else {
            props.onLeaveComment();
        }
    }

    const onCloseCommentBox = () => {
        setClickedToComment(false);
        if (!!props.resetOutsideClickToComment) {
            props.resetOutsideClickToComment();
        }
    }

    const onAddComment = async (c, r) => {
        let w = websiteSection;
        let commentData = new FormData();
        commentData.append(`itemId`, itemId);
        commentData.append(`itemAuthorId`, authorId);
        commentData.append('comment', c);
        commentData.append('commentReplyTo', r);
        let options = {
            url: `${hostNameAndPort}/api/engage/addcomment/${w}`,
            method: 'post',
            data: commentData,
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                props.pageShouldRefresh(new Date().getTime());
            }
        } catch (err) {
            //console.log(err)
        }
    }

    const onClickToShare = () => {
        // if (!userIsAuthenticated) {
        //     props.showLoginModal();
        //     return;
        // }
        setClickedToShare(!clickedToShare);
    }

    const closeShareModal = () => {
        setClickedToShare(false);
    }

    const onShareCopyLink = () => {
        ShareItem.ShareCopyLink({ shareLink });
        setTimeout(() => {
            setClickedToShare(false);
        }, 3000);
    }


    if (!itemId) {
        return null;
    }
    
    return (
        <>
            <EngageButtons>
                {props.children}
                {(!props.onlyComments && !props.hideButtons && !props.withoutLoveButton) ? (
                    <LoveButton
                        isDisabled={isDisabled}
                        noLove={likeCount}
                        isClicked={itemIsLiked}
                        onClick={onClickToLike}
                    />) : null}

                {((!props.withComments && !props.onlyComments) || props.hideButtons) ? null : (
                    <CommentsButton
                        isDisabled={isDisabled}
                        noComments={commentCount}
                        isClicked={!!commentCount}
                        onClick={onClickToComment}
                    />)}

                {(!props.onlyComments && !props.hideButtons && !props.noViewsButton) ? (
                    <ViewsButton
                        isDisabled={isDisabled}
                        noViews={viewCount}
                        isClicked={false}
                    />) : null}

                {(!props.onlyComments && !props.hideButtons) ? (
                    <ShareButton
                        isDisabled={isDisabled}
                        noShares={shareCount}
                        isClicked={itemIsShared}
                        onClick={onClickToShare}
                        active={clickedToShare}
                        shareLink={shareLink}
                        shareImage={shareImage}
                        onClickShare={closeShareModal}
                        onShareCopyLink={onShareCopyLink}
                    />) : null}

                {(!props.onlyComments && !props.hideButtons && !props.noFavoritesButton) ? (
                    <FavoritesButton
                        isDisabled={isDisabled}
                        isClicked={itemIsFavorite}
                        onClick={onClickToAddFavorite}
                    />) : null}
            </EngageButtons>

            {((!props.withComments && !props.onlyComments) || props.hideAllComments) ? null : (
                <Comments
                    {...props}
                    clickedToComment={clickedToComment}
                    commentCount={commentCount}
                    onCloseCommentBox={onCloseCommentBox}
                    onAddComment={onAddComment}
                    onRemoveComment={onRemoveComment}
                />)}
        </>);
}


const mapDispatchToEngageBoxProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: p => dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p }),
        showLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname })
    };
};

export default connect(null, mapDispatchToEngageBoxProps)(EngageBox);